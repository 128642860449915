import * as React from 'react';
import { Component } from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {hidePrintPreviewModal, showModal} from '../../actions/modalActions';
import { Modal } from 'react-bootstrap'; 
import {IHistory} from '../../interfaces';
import {UserModel} from '../../model/UserModel';
import {getAllReferences, deleteReferences} from '../../services/miscellaniousService';
import {getCurrentUserCompanyName, showAlert} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {AlertModal} from '../../model/Alertmodel';
import {ConfirmationModal} from './ConfirmationModal';
import {ImageCardContainer} from './ImageCardContainer';
import './referencePage.scss';
import {toast} from 'react-toastify';
import {DELETE_REFERENCE_SUCCESS} from '../../constants/generals';
import { parseJwt } from '../../services/loginService';
import { PdfViewer } from '../reusableComponents/FormComponents/PdfViewer';
import Dialog from 'react-bootstrap-dialog';



declare const require: any;
const oshaIcon = 'https://enviroconnect.s3.us-west-2.amazonaws.com/enviroconnect.png' // require('../../images/logo_.png');
const spiLogo = 'https://enviroconnect.s3.us-west-2.amazonaws.com/spi.png'// require('../../images/spi.png');

export interface IReferences {
    referenceId: string;
    referenceLink: string;
    referenceTitle: string;
    isDeletable?: boolean;
    referenceBanner?: string;
    referenceExt: string;
}

export interface IReferencePageProps {
    referenceFiles: IReferences[];
    history?: IHistory;
    instance: UserModel;
    Access_Level: string;
}

export interface IReferencePageState {
    references: IReferences[];
    addEditClass: boolean;
    index: number;
    selectedIds: string[];
    selectedIndexes: number[];
    displayView: boolean;
    pdfViewUrl: string;
    downloadPdf: boolean;
}

export class ReferencePageImpl extends React.Component<IReferencePageProps, IReferencePageState> {
    componentRef = null;
    dialog: Dialog;

    constructor(props: IReferencePageProps) {
        super(props);
        this.state = {selectedIndexes: [], selectedIds: [], index: 0, references: [], addEditClass: false, displayView: false, pdfViewUrl: '',downloadPdf: false};
    }

    componentWillReceiveProps(nextProps: IReferencePageProps) {
        this.setState({
            references: nextProps.referenceFiles
        });
    }

    promise = async () => {
        await getAllReferences(getCurrentUserCompanyName());
    }

    /* handleSingleFileDelete = (index: number) => {
        this.dialog.show({
            body: `Are you sure you want to delete this reference ?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                        deleteReferences([this.state.references[index].referenceId]).then(r =>
                            toast.success(DELETE_REFERENCE_SUCCESS, {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'success-toast',
                                autoClose: 1000,
                            })),
                        showModal('delete-reference', 'Confirm Delete File?', this.state.references[index].referenceId)}
            )]
        });
    } */
    handleSingleFileDelete = (index: number) => {
        this.dialog.show({
            body: `Are you sure you want to delete this reference?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                    deleteReferences([this.state.references[index].referenceId])
                        .then(r => {
                            toast.success(DELETE_REFERENCE_SUCCESS, {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'success-toast',
                                autoClose: 1000,
                            });
                            showModal('delete-reference', 'Confirm Delete File?', this.state.references[index].referenceId);
                        });
                })
            ]
        });
    }
        

    handleOnMouseOver = (index: number) => {


        if (UserModel.checkUserAccess('reference_delete')) {
            this.setState({
                index,
                addEditClass: true
            });
        }
    }

    handleOnMouseLeave = (index: number) => {
        if (UserModel.checkUserAccess('reference_delete')) {
            this.setState({
                index,
                addEditClass: false
            });

        }
    }

    handleFileSelect = (index: number) => {
        const referenceId = this.state.references[index].referenceId;
        const referenceIds = this.state.selectedIds;
        referenceIds.push(referenceId);
        const selectedIndexes = this.state.selectedIndexes;
        selectedIndexes.push(index);
        this.setState({
            selectedIndexes,
            selectedIds: referenceIds
        });

    }

    handleFileDeselect = (index: number) => {
        const referenceId = this.state.references[index].referenceId;
        const referenceIds = this.state.selectedIds.filter(id => id !== referenceId);
        const selectedIndexes = this.state.selectedIndexes.filter(indexVal => indexVal !== index);
        this.setState({
            selectedIndexes,
            selectedIds: referenceIds
        });
    }

    handleMultipleReferenceDelete = () => {
        /* showModal('delete-reference', 'Confirm Delete File?', this.state.selectedIds);
        deleteReferences(this.state.selectedIds).then(r =>
            toast.success(DELETE_REFERENCE_SUCCESS, {
                position: toast.POSITION.TOP_CENTER,
                className: 'success-toast',
                autoClose: 1000,
            }));
        this.setState({selectedIndexes: []}); */
        this.dialog.show({
            body: `Are you sure you want to delete these reference ?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                    deleteReferences(this.state.selectedIds).then(r => {
                            toast.success(DELETE_REFERENCE_SUCCESS, {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'success-toast',
                                autoClose: 1000,
                            });
                            showModal('delete-reference', 'Confirm Delete File?', this.state.selectedIds);
                            this.setState({selectedIndexes: []});
                        });
                })
            ]
        });
    }

    handleViewerPdf = (url) => {
        this.setState({
            displayView: true,
            pdfViewUrl: url
        })
    }

    renderContent = () => {
        return <div style={{textAlign: 'center'}}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {(this.state.references || []).length > 0 ?
                    this.state.references.map((reference, index) => {
                        return <ImageCardContainer
                            key={index}
                            selectedIndexes={this.state.selectedIndexes}
                            index={index}
                            activeIndex={this.state.index}
                            href={reference.referenceLink || ''}
                            fext={reference.referenceExt || ''}
                            src={reference.isDeletable ? spiLogo : (reference.referenceBanner ? reference.referenceBanner : oshaIcon)}
                            onSingleFileDelete={this.handleSingleFileDelete}
                            text={reference.referenceTitle || ''}
                            addEditClass={this.state.addEditClass}
                            onMouseOver={!reference.isDeletable ? () => this.handleOnMouseOver(index) : null}
                            onFileSelect={this.handleFileSelect}
                            onFileDeselect={this.handleFileDeselect}
                            onopenInNewViewTab={this.handleViewerPdf}
                            onMouseLeave={!reference.isDeletable ? () => this.handleOnMouseLeave(index) : null}
                            isDeletable={reference.isDeletable || false}
                            // history={this.props.history}
                        />;
                    }) :
                    <div className="no-reference">No Reference Document Uploaded
                        {
                            <button
                                onClick={() => this.props.history && this.props.history.push('/references/edit')}
                            >
                                Upload One Now?
                            </button>

                        }
                    </div>}
            </div>
            {this.state.selectedIndexes.length > 0 ?
                <button style={{
                    color: 'white',
                    width: '100px', margin: '50px',
                    borderRadius: '10px', height : '50px', fontSize: '12px',
                    border: '10px solid #a4bf43',
                    backgroundColor: '#a4bf43',
                    display : 'inline-block'

                }} onClick={this.handleMultipleReferenceDelete}> Delete Selected </button> :
                ''}
            {this.props.referenceFiles && this.props.referenceFiles.length > 0 && (this.props.Access_Level === 'L2' || this.props.Access_Level === 'L1') ?
                <button
                    style={{
                        color: 'white',
                        width: '100px', margin: '50px',
                        borderRadius: '10px', height : '50px', fontSize: '12px',
                        border: '10px solid #a4bf43',
                        display : 'inline-block',
                        backgroundColor: '#a4bf43'
                    }}
                    title="Click to Add/Upload New Reference"
                    className="add-new-report"
                    onClick={() => this.props.history && this.props.history.push('/references/edit')}
                >

                    <i className="fa fa-plus fa-2x" aria-hidden="true"/>
                </button>
                : ''}
        </div>;
    }
    hidePreviewModal = () => {
        this.setState({
            displayView: false,
            downloadPdf: false
        })
        hidePrintPreviewModal();
    }
    
    render() {
        
        return (
            <BasePage pageTitle="References">
                <div className="reference">
                    {
                        // @ts-ignore
                        <Dialog className="confirmation-modal" ref={(el: any) => {
                            this.dialog = el;
                        }}/>
                    }
                    {this.state.displayView === true ?
                        <Modal size="lg" className="print-preview-outer" show={true} onHide={() => this.hidePreviewModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: 'white' }}>Reference Document. </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ color: 'black', backgroundColor: 'white' }}>
                            <PdfViewer  htmlFor={this.state.pdfViewUrl} />
                        </Modal.Body>
                    </Modal > : ''}
                    <Async
                        identifier="ReferencePage"
                        promise={this.promise}
                        loader={<Loader/>}
                        error={<ErrorPage/>}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    let userId = state.login.get('userId');
    const instance = UserModel.get(userId);
    const Access_Level = parseJwt(state.login.get('token')).Access_Level;
    return {
        instance,
        referenceFiles: state.miscellaneous.get('referenceFiles'),Access_Level
    };
}

export const ReferencePage =
    withRouter(connect<IReferencePageProps, any, any>(mapStateToProps)(ReferencePageImpl as any));
