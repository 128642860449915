import * as React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

export interface IProtectedRouteImplProps {
    isLoggedIn?: boolean;
    path: string;
    component: React.ComponentClass;
    exact?: boolean;
    pathName?: string;
}

class AuthRouteImpl extends React.PureComponent<IProtectedRouteImplProps, {}> {

    render() {
        const {path, component, isLoggedIn, exact, pathName} = this.props;
        return (
            <div>
                {isLoggedIn ? <Route exact={exact || false} path={path} component={component}/> :
                    <Redirect to={`/?redirect=${pathName}`}/>}
            </div>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    return {
        isLoggedIn: state.login.get('isLoggedIn'),
        pathName: ownProps.location ? ownProps.location.pathname : ''
    };
}

export const AuthRoute = connect<{}, {}, IProtectedRouteImplProps>(mapStateToProps)(AuthRouteImpl as any);
