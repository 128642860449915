import * as React from 'react';
import {BaseModel} from './BaseModel';

export interface ISettledFormModelProps {
    id: string;
    inspections: object;
}

export interface IOutfall {
    outfall?: boolean;
}

export class SettledFormModel extends BaseModel<ISettledFormModelProps> {

    static resource = 'settled-forms';

    constructor(props: ISettledFormModelProps) {
        super(props,'settled-forms');
    }
}
