import '../Reports/reports.scss';
import {format, getQuarter} from 'date-fns';
import * as React from 'react';
import {DropdownButton, Dropdown, OverlayTrigger, Popover, PopoverContent, PopoverTitle} from 'react-bootstrap';
import {setLoading, setSuccess} from '../../actions/loadingActions';
import {quarterMonths, years } from '../../constants/generals';
import {IHistory} from '../../interfaces';
import {getSettledForms} from '../../services/plantService';
import {RDatePicker} from '../reusableComponents/DatePicker';
import {RWeeklyPicker} from '../reusableComponents/WeekPicker';
import {MonthPicker} from '../reusableComponents/MonthPicker';
import {toast} from 'react-toastify';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import moment from "moment";
// import { DateRangePicker } from 'rsuite';
import '../Reports/reports.scss'; 
import {SettledFormModel} from '../../model/SettledFormModel';
import {findCurrentQuarter, getCurentDate, isMobileDevice } from '../../utils/generalUtils';
import {UserModel} from '../../model/UserModel';

const queryString = require('query-string');

export interface IPointSource {
    PsId: string | null;
    PsName: string | null;
    InspectionId: string | null;
    IsSelected?: boolean | null;
}

export interface IFooterCardProps {
       
    date?: Date;
    permitType?: string; 
    inspections?: Array<any>;
    freq?: string;
    history?: IHistory; 
    plantInfo?: any;
    location?: {
        search: string;
    };
    /* modalType?: string; */
    state: any
    onDateChange?: () => void; 
    pageNum?: any;
    searchInput?: string;
}

export interface IFooterCardState {
    isLoading: boolean;
    date: Date;
    month: string;
    year: string;
    quarter: string;
    selectedPS: IPointSource;
    selectedInspectionId: string | null;
    popoverMonth: Date;
    monthSelect: string;
    quarterString: string;
}

export class FooterCardImpl extends React.PureComponent<IFooterCardProps, IFooterCardState> {
    
    userData = UserModel.getUserData();
    isMobile = isMobileDevice();
    constructor(props: IFooterCardProps) {
        super(props);

        sessionStorage.setItem('inspectionDetails', '');
        //let date = new Date();
        let date = getCurentDate(moment(), 'MM/DD/yyyy', true);
        /* let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`; */ 
        let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`;
        let monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
        let quarterString;
        quarterMonths(this.props.location).map((year, index) => {
            if (year.value === quarter) {
                quarterString = year.label;
            }
            return true;
        });
        monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
        if (this.props?.location?.search) {
            const {search} = this.props.location;
            const dateSelected = (queryString.parse(search).date);
            //date = moment(dateSelected).tz('America/Chicago').toDate();
            let dateformat = ''
            const dateFormats = ['MM-DD-YYYY', 'YYYY-MM-DD'];
            for (let format of dateFormats) {
                if (moment(dateSelected, format, true).isValid()) {
                    dateformat=  format;
                }
            }
            date = getCurentDate(moment(dateSelected, dateformat), 'MM/DD/YYYY', true);

            const qdate = moment(date, dateformat);
            // Determine the quarter
            const qtr = qdate.quarter();

            // Format the quarter as '01', '02', '03', or '04' quarterString
            const formattedQuarter = qtr.toString().padStart(2, '0');
            quarter = queryString.parse(search).quarter ? queryString.parse(search).quarter : date ? formattedQuarter : quarter;
            monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
            monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
            quarterMonths(this.props.location).map((year, index) => {
                if (year.value === quarter) {
                    quarterString = year.label;
                }
                return true;
            });
        }
        this.state = {
            selectedInspectionId: null,
            date: date, isLoading: false,
            // month: format(date, 'MM-dd-yyyy'),
            month:  moment(date).format('MM-DD-YYYY'),
            year: date.getFullYear().toString(), quarter,
            selectedPS: {PsName: 'Select', PsId: null, InspectionId: null}, popoverMonth: moment().toDate(),
            monthSelect, quarterString,
        };
        
        /* this.state = {
            selectedInspectionId: null,
            date: date, isLoading: false, month: getCurentDate(moment(), 'MM-DD-yyyy'),
            year: date.getFullYear().toString(), quarter,
            selectedPS: {PsName: 'Select', PsId: null, InspectionId: null}, popoverMonth: new Date(),
            monthSelect, quarterString,
        }; */
    }

    handleDateChange = async (date) => {
        SettledFormModel.deleteAll(); 
        await this.setState({
            date: date
        }, async () => {
            const dateFormatted = date.split('-');
            this?.props?.history?.push(`?date=${date}`);
            const selected = `${dateFormatted[0]}${dateFormatted[1]}${dateFormatted[2]}`;
            setLoading('InspectionFooter'); 
            await getSettledForms(selected, this.props.plantInfo.props.id); 
            if(this.props.onDateChange) {  
                this.props.onDateChange();
            } 
            setSuccess('InspectionFooter');
        });
        /* const dateFormatted = date.split('-');
        this?.props?.history?.push(`?date=${date}`);
        const selected = `${dateFormatted[0]}${dateFormatted[1]}${dateFormatted[2]}`;
        setLoading('InspectionFooter'); 
        await getSettledForms(selected, this.props.plantInfo.props.id); 
        if(this.props.onDateChange) {  
            this.props.onDateChange();
        } 
        setSuccess('InspectionFooter'); */
    }

    renderPointSourceSelector = (inspection) => {
        return <div><br/><Dropdown
            id="dropdown"
            className="bootstrap-dropdown">
            <DropdownToggle variant="" id={'dropdown-basic'}>{this.state.selectedPS ? this.state.selectedPS.PsName : 'Select'}</DropdownToggle>
            <DropdownMenu>{inspection.PointSources.map((ps, index) => {
                const className = ps.InspectionId ? 'completed' : '';
                return <DropdownItem
                    className={className}
                    key={index}
                    eventKey="4"
                    onClick={e => {
                        this.setState({selectedPS: ps, selectedInspectionId: ps.InspectionId});
                    }}>
                    {ps.PsName}
                    &nbsp;
                    {className && <i className="fa fa-check" aria-hidden="true"/>}
                </DropdownItem>;
            })}</DropdownMenu>
        </Dropdown></div>;
    }

    /* findCurrQuarter = async (selectedMonth) => {
        const formattedDate = moment(selectedMonth, 'YYYY-MM-DD').format('MM-DD-YYYY');

        let currQuarter = findCurrentQuarter(formattedDate)

        const date = moment(formattedDate, 'MM-DD-YYYY');
        // Determine the quarter
        const quarter = date.quarter();

        // Format the quarter as '01', '02', '03', or '04' quarterString
        const formattedQuarter = quarter.toString().padStart(2, '0');

        let quarterString;
        quarterMonths(this.props.location).map((year, index) => {
            if (year.value === formattedQuarter) {
                quarterString = year.label;
            }
            return true;
        });

        await this.setState({
            quarterString
        });
    } */

    // Daily inspections popover
    renderDatePopover = (inspection) => { 
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?'; 
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">{title}</Popover.Title>
            <PopoverContent className="popover-content">
                <div className="list-footer-daily">
                    <RDatePicker
                        format="MM-DD-YYYY"
                        maxDate={getCurentDate(moment(),'MM-DD-yyyy',true)}
                        value={this.state.date}
                        onChange={this.handleDateChange}
                    />
                    {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                </div>
                {inspection.FormActive ?
                <div className="popover-footer-button"> 
                    {(inspection.PointSources && inspection.PointSources.length > 0) ? 
                        <>{ this.state.selectedPS.PsId ?
                            <button onClick={() => this.loadInspectionForm(inspection)}>
                                {!inspection.InspectionId || !this.state.selectedInspectionId ? 'Begin Form' : 'Edit Form'}
                            </button>
                            :<></>}
                        </>
                        :
                        <button onClick={() => this.loadInspectionForm(inspection)}>
                            {!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}
                        </button>
                    } 
                </div> :
                <div className="popover-footer-NotActivebutton">
                    <button >{!inspection.InspectionId ? 'In-Active' : 'In-Active'}</button>
                </div>}
            </PopoverContent>
        </Popover>;
    }

    handleMonthChange = async (month, selectedMonth, selectedMonthForAPI) => {
        // this.findCurrQuarter(selectedMonth); 
        await this.setState({
            month,
            popoverMonth: month
        });
        this?.props?.history?.push(`?date=${selectedMonth}`);
        setLoading('InspectionFooter');
        await getSettledForms(selectedMonthForAPI, this.props.plantInfo.props.id);
        if(this.props.onDateChange) {  
            this.props.onDateChange();
        }
        setSuccess('InspectionFooter');
    }

    // Weekly inspections popover
    /* renderWeeklyPopOver = (inspection) => {
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?';
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <PopoverTitle as='h2' className="popover-title">{title}</PopoverTitle>
            <PopoverContent className="popover-content">
                <div className="list-footer-monthly">
                    <RWeeklyPicker
                        format="MM-DD-YYYY"
                        maxDate={moment().toDate()}
                        value={this.state.date}
                        onChange={this.handleDateChange}
                    />
                    {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                </div>
                {inspection.FormActive ?
                <div className="popover-footer-button">
                    <button
                        onClick={() => this.loadInspectionForm(inspection)}
                    >{!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}</button>
                </div> :
                <div className="popover-footer-NotActivebutton">
                    <button
                        // onClick={() => this.loadInspectionForm(inspection)}
                    >{!inspection.InspectionId ? 'In-Active' : 'Edit Form'}</button>
                </div>}
            </PopoverContent>
        </Popover>;
    } */

    // Monthly inspections popover
    renderMonthlyPopOver = (inspection) => {
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?'; 
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <PopoverTitle as='h2' className="popover-title">{title}</PopoverTitle>
            <PopoverContent className="popover-content"> 
                <div className="popover-footer-button">
                    <div className="list-footers-monthly">
                        <MonthPicker
                            defaultValue={this.state.month}
                            years={[]}
                            onClick={this.handleMonthChange}
                            popover={true}
                        />
                        {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                    </div>
                    {inspection.FormActive ?
                        <div className="popover-footer-button"> 
                            {(inspection.PointSources && inspection.PointSources.length > 0) ? 
                                <>{ this.state.selectedPS.PsId ?
                                    <button onClick={() => this.loadInspectionForm(inspection)}>
                                        {!inspection.InspectionId || !this.state.selectedInspectionId ? 'Begin Form' : 'Edit Form'}
                                    </button>
                                    :<></>}
                                </>
                                :
                                <button onClick={() => this.loadInspectionForm(inspection)}>
                                    {!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}
                                </button>
                            } 
                        </div>
                    :
                        <div className="popover-footer-NotActivebutton">
                            <button 
                            >{!inspection.InspectionId ? 'In-Active' : 'Edit Form'}</button>
                        </div>
                    }
                </div> 
            </PopoverContent>
        </Popover>;
    }


    /* handleWeekChange = async (year, quarter) => {
        let quarterString;
        quarterMonths(this.props.location).map((year, index) => {
            if (year.value === quarter) {
                quarterString = year.label;
            }
            return true;
        });
        let monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
        monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
        const {selectedPS} = this.state;
        this.setState({
            year, quarter, monthSelect, quarterString
        });
        const date = `${monthSelect}01${year}`;
        const dateSelected = `${monthSelect}-01-${year}`;
        this?.props?.history?.push(`?date=${dateSelected}&&quarter=${quarter}`);
        setLoading('InspectionFooter');
        await getSettledForms(date, this.props.plantInfo.props.id);
        if(this.props.onDateChange) {  
            this.props.onDateChange();
        }
        setSuccess('InspectionFooter');
    } */

    // Weekly inspections popover
    renderWeeklyPopOver = (inspection) => {
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?';
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <PopoverTitle as='h2' className="popover-title">{title}</PopoverTitle>
            <PopoverContent className="popover-content">
                <div className="list-footer-monthly">
                    <RWeeklyPicker
                        format="MM-DD-YYYY"
                        maxDate={moment().toDate()}
                        value={this.state.date}
                        onChange={this.handleDateChange}
                    />
                    {/* <DateRangePicker oneTap showWeekNumbers	showOneCalendar hoverRange="week" ranges={[]} /> */}
                    {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                </div>
                {inspection.FormActive ?
                <div className="popover-footer-button">
                    {/* { this.userData.orgId==='705bf30b-082e-453b-8f51-0ada593e4ca2' && moment('05-30-2024').isAfter(moment(), 'day') ? <p>You can't Add inspections forms until 06/07/2024. Please <a target="_blank" href='https://argosems.com/'>click here</a> for old Argos EMS portal.</p>: ( <button
                        onClick={() => this.loadInspectionForm(inspection)}
                    >{!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}</button> ) } */}
                    {(inspection.PointSources && inspection.PointSources.length > 0) ? 
                        <>{ this.state.selectedPS.PsId ?
                            <button onClick={() => this.loadInspectionForm(inspection)}>
                                {!inspection.InspectionId || !this.state.selectedInspectionId ? 'Begin Form' : 'Edit Form'}
                            </button>
                            :<></>}
                        </>
                        :
                        <button onClick={() => this.loadInspectionForm(inspection)}>
                            {!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}
                        </button>
                    }
                </div> :
                <div className="popover-footer-NotActivebutton">
                    <button
                        // onClick={() => this.loadInspectionForm(inspection)}
                    >{!inspection.InspectionId ? 'In-Active' : 'Edit Form'}</button>
                </div>}
            </PopoverContent>
        </Popover>;
    }


    handleQuarterChange = async (year, quarter) => {
        let quarterString;
        quarterMonths(this.props.location).map((year, index) => {
            if (year.value === quarter) {
                quarterString = year.label;
            }
            return true;
        });
        let monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
        monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
        const {selectedPS} = this.state;
        this.setState({
            year, quarter, monthSelect, quarterString
        });
        const date = `${monthSelect}01${year}`;
        const dateSelected = `${monthSelect}-01-${year}`;
        this?.props?.history?.push(`?date=${dateSelected}&&quarter=${quarter}`);
        setLoading('InspectionFooter');
        await getSettledForms(date, this.props.plantInfo.props.id);
        if(this.props.onDateChange) {  
            this.props.onDateChange();
        }
        setSuccess('InspectionFooter');
    }

    // Quarterly inspections popover
    renderQuarterlyPopOver = (inspection) => {
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?'; 
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">{title}</Popover.Title>
            <PopoverContent className="popover-content">
                <div className="list-footer-quarterly">
                    <Dropdown
                        id="dropdown"
                        className="bootstrap-dropdown dropdown"
                        onChange={(e: any) => this.handleQuarterChange(e.target.value, this.state.quarter)}
                    >
                        <DropdownToggle variant="" id={'dropdown-basic'}>
                            {this.state.year}
                        </DropdownToggle>
                        <DropdownMenu>
                            {years().map((year, index) => {
                                return <DropdownItem
                                    key={index}
                                    eventKey="4"
                                    onClick={e => this.handleQuarterChange(year.value, this.state.quarter)}
                                >
                                    {year.label}
                                </DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    <br/>
                    <Dropdown
                        id="dropdown"
                        className="bootstrap-dropdown"
                        onChange={(e: any) => this.handleQuarterChange(this.state.year, e.target.value)}
                    >
                        <DropdownToggle variant="" id={'dropdown-basic'}>{this.state.quarterString}</DropdownToggle>
                        <DropdownMenu>
                            {quarterMonths(this.props.location).map((year, index) => {
                                return <Dropdown.Item
                                    key={index}
                                    eventKey="4"
                                    onClick={e => this.handleQuarterChange(this.state.year, year.value)}
                                >
                                    {year.label}
                                </Dropdown.Item>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                </div>
                {inspection.FormActive ?
                <div className="popover-footer-button">
                    {/* {(inspection.PointSources && inspection.PointSources.length > 0) && this.state.selectedPS.PsId ? this.state.selectedPS.InspectionId : inspection.InspectionId} */}
                    {/* {this.state.selectedInspectionId}{inspection.InspectionId} */}
                    {(inspection.PointSources && inspection.PointSources.length > 0) ? 
                        <>{ this.state.selectedPS.PsId ?
                            <button onClick={() => this.loadInspectionForm(inspection)}>
                                {!inspection.InspectionId || !this.state.selectedInspectionId ? 'Begin Form' : 'Edit Form'}
                            </button>
                            :<></>}
                        </>
                        :
                        <button onClick={() => this.loadInspectionForm(inspection)}>
                            {!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}
                        </button>
                    } 
                </div> :
                <div className="popover-footer-NotActivebutton">
                    <button >{!inspection.InspectionId ? 'In-Active' : 'Edit Form'}</button>
                </div>}
            </PopoverContent>
        </Popover>;
    }
    handleYearlyChange = async (year ) => {
        const date = `0101${year}`;
        const dateSelected = `01-01-${year}`;
        this?.props?.history?.push(`?date=${dateSelected}`);
        setLoading('InspectionFooter');
        await getSettledForms(date, this.props.plantInfo.props.id);
        if(this.props.onDateChange) {  
            this.props.onDateChange();
        }
        setSuccess('InspectionFooter');
    }
    renderYearlyPopOver = (inspection) => {
        const title = !inspection.InspectionId ? 'Begin Form?' : 'Edit Form?';
        return <Popover
            id="popover-positioned-top"
            placement={this.isMobile?'bottom':'bottom'}
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">{title}</Popover.Title>
            <PopoverContent className="popover-content">
                <div className="list-footer-quarterly">
                    <Dropdown
                        id="dropdown"
                        className="bootstrap-dropdown dropdown"
                        onChange={(e: any) => this.handleYearlyChange(e.target.value )}
                    >
                        <DropdownToggle variant="" id={'dropdown-basic'}>
                            {this.state.year}
                        </DropdownToggle>
                        <DropdownMenu>
                            {years().map((year, index) => {
                                return <DropdownItem
                                    key={index}
                                    eventKey="4"
                                    onClick={e => this.handleYearlyChange(year.value )}
                                >
                                    {year.label}
                                </DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown> 
                    {inspection.PointSources.length > 0 && this.renderPointSourceSelector(inspection)}
                </div>
                {inspection.FormActive ?
                <div className="popover-footer-button"> 
                    {(inspection.PointSources && inspection.PointSources.length > 0) ? 
                        <>{ this.state.selectedPS.PsId ?
                            <button onClick={() => this.loadInspectionForm(inspection)}>
                                {!inspection.InspectionId || !this.state.selectedInspectionId ? 'Begin Form' : 'Edit Form'}
                            </button>
                            :<></>}
                        </>
                        :
                        <button onClick={() => this.loadInspectionForm(inspection)}>
                            {!inspection.InspectionId ? 'Begin Form' : 'Edit Form'}
                        </button>
                    }
                </div> :
                <div className="popover-footer-NotActivebutton">
                    <button >{!inspection.InspectionId ? 'In-Active' : 'Edit Form'}</button>
                </div>}
            </PopoverContent>
        </Popover>;
    }


    loadInspectionForm = (inspection) => {
        if ((inspection.PointSources && inspection.PointSources.length > 0) && !this.state.selectedPS.PsId) {
            toast.error('Please Select an Outfall / Point source first.', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        inspection.frequency = this.props.freq;
        const {history: {push}}: any = this.props;
        sessionStorage.setItem('inspectionDetails', JSON.stringify({
            ...inspection,
            selectedInspectionId: (inspection.PointSources && inspection.PointSources.length > 0) && this.state.selectedPS.PsId ? this.state.selectedPS.InspectionId : inspection.InspectionId,
            selectedPS: this.state.selectedPS,
            parentId: inspection.ParentId,
            frequency: this.props.freq,
            plantInfo: this.props.plantInfo.props,
            date: moment(this.state.date).format('MM-DD-YYYY'),// format(this.state.date, 'MM-dd-yyyy'),
            permitType: this.props.permitType
        }));

        let currTab = this.props.history?.location.pathname;
        let urls: string = currTab+'?date='+moment(this.state.date).format('MM-DD-YYYY')+'&&plantId='+this.props.plantInfo.props.id+'&&plant='+this.props.plantInfo.props.plantId+'&&pagenum='+this.props.pageNum+'&&search='+this.props.searchInput;
        push(urls);
        let url: string;
 
        if (inspection.ParentId == 'Maintenace Record263' || inspection.ParentId == 'Improvements & CAPEX708') {
            url = '/inspections/maintenance/' + inspection.ParentId+'?date='+moment(this.state.date).format('MM-DD-YYYY')+'?plantId='+this.props.plantInfo.props.id;
        } else {
            url = '/inspections/common/' + inspection.ParentId+'?date='+moment(this.state.date).format('MM-DD-YYYY')+'?plantId='+this.props.plantInfo.props.id;
        }
        return push(url);
    }

    handleInspectionSelection = (inspection) => { 
        const {history: {push}}: any = this.props;  
        if (this.props.freq === 'Daily') {
            return this.renderDatePopover(inspection);
        } else if (this.props.freq === 'Weekly') {
            return this.renderWeeklyPopOver(inspection);
        } else if (this.props.freq === 'Monthly') {
            return this.renderMonthlyPopOver(inspection);
        } else if (this.props.freq === 'Quarterly') {
            return this.renderQuarterlyPopOver(inspection);
        } else if (this.props.freq === 'Yearly') {
            return this.renderYearlyPopOver(inspection);
        }  else {
            return <span/>;
        } 
    }

    resetSelectedItems = () => {
        setTimeout(() => {
            this.setState({
                selectedInspectionId: null,
                selectedPS: {PsName: 'Select', PsId: null, InspectionId: null}
            });
        }, 200);
    } 

    render() {
        const {history: {push}}: any = this.props;  
        return this.props.inspections?.map((inspection, index) => {
            return <div key={inspection.ParentId}>
                    <>{ 
                        <OverlayTrigger
                            rootClose
                            trigger="click" 
                            placement={this.isMobile?'bottom':'right'} 
                            overlay={ this.handleInspectionSelection(inspection) }
                        >
                            <div 
                                className={"inspection-box"}
                                title={inspection.InspectionId ? 'Click to Edit Form' : ''}
                            >
                                <div className="form-title">{inspection.FormName}{inspection.InspectionId &&
                                <span className="fa fa-pencil-square-o d-flex align-items-center justify-content-center"/>}</div>
                            </div>
                    </OverlayTrigger>}</>
            </div>;
        });
    }
}

export function mapStateToProps(state) {
    return {
        state
    };
}

export const FooterCard = withRouter(connect<IFooterCardProps, any, any>(mapStateToProps)(FooterCardImpl as any))
