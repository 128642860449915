import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Modal } from 'react-bootstrap'; 
// import {hidePrintPreviewModal, showModal} from '../../actions/modalActions';
import { hidePrintPreviewModal } from '../../../actions/modalActions';




export interface IIPdfViewerPropsProps {
    htmlFor?: string;
    className?: string;
    style?: React.CSSProperties;
    sm?: number;
    required?: boolean;
}
export default function PDFViewer({fileUrl}) {
    // let componentRef = useRef();
    // const printPluginInstance = printPlugin();
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // const { print } = defaultLayoutPluginInstance.toolbarPluginInstance.printPluginInstance;
    const defaultZoomPercentage = 1;
    return (
      <>
        <div className="PDFViewer" >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={defaultZoomPercentage}
                />
            </Worker>
        </div>
      </>
    );
    return <></>;
  }
export class PdfViewer extends React.PureComponent<IIPdfViewerPropsProps> {
    constructor(props: IIPdfViewerPropsProps | Readonly<IIPdfViewerPropsProps>) {
        super(props);
    }

    static defaultProps = {
        required: false
    };
    // hidePreviewModal = () => {
    //     // this.setState({
    //     //     displayView: false,
    //     //     downloadPdf: false
    //     // })
    //     hidePrintPreviewModal();
    // }

    render() {
        return (
            // "https://enviroconnect-beta.s3.amazonaws.com/images/be7dbc12-1c33-472e-b9a4-73a217cbad9c_JEy5.pdf" 
            <PDFViewer fileUrl={this.props.htmlFor} /> 
        );
    }
}
