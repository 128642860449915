import {fromJS} from 'immutable';
import {HIDE_ALERT, HIGHLIGHT_EMPTY_FIELD, REMOVE_EMPTY_FIELD, SHOW_ALERT} from '../constants/actions';

export function alertReducer(state: any = fromJS({}), action) {
    switch (action.type) {
        case SHOW_ALERT:
            return state.set('alertText', action.alertText)
                .set('alertID', action.alertID)
                .set('alertType', action.alertType);
        case HIDE_ALERT:
            return state.delete('alertText')
                .delete('alertID')
                .delete('alertType');
        case HIGHLIGHT_EMPTY_FIELD:
            return state.set('emptyFieldModel', action.modelName);
        case REMOVE_EMPTY_FIELD:
            return state.delete('emptyFieldModel');
        default:
            return state;
    }
}
