import {getQuarter} from 'date-fns';
import {fromJS} from 'immutable';
import * as moment from 'moment';

import {
    DELETE_PLANT_DETAILS,
    REMOVE_FILTER_DATA,
    RESET_DASHBOARD_FILTERS,
    SAVE_DAILY_FORM_DATA,
    SAVE_DAILY_FORM_FILTER,
    SAVE_FLAGGED_DAILY_FORM_DATA,
    SAVE_FLAGGED_FORM_DATA,
    SAVE_FLAGGED_FORM_DETAILS,
    SAVE_FLAGGED_MONTHLY_FORM_DATA,
    SAVE_FLAGGED_QUARTERLY_FORM_DATA,
    SAVE_FORM_COUNTS,
    SAVE_LINE_CHART_DATA,
    SAVE_LINE_CHART_FILTER,
    SAVE_MONTHLY_FORM_DATA,
    SAVE_MONTHLY_FORM_FILTER,
    SAVE_PLANT_DETAILS,
    SAVE_QUARTERLY_FORM_DATA,
    SAVE_QUARTERLY_FORM_FILTER,
} from '../constants/actions';
import {formatDateToUS, splitDate} from '../utils/generalUtils';

export function dashboardReducer(state: any = fromJS({}), action) {
    state = state.set('resetFilters', false);
    const q = getQuarter(new Date());
    const {mm, dd, yyyy} = splitDate((moment.utc(new Date()).format('yyyy-MM-dd')).toString());
    switch (action.type) {
        case SAVE_DAILY_FORM_DATA:
            return state.set('dailyFormSummary', action.instance);
        case SAVE_MONTHLY_FORM_DATA:
            return state.set('monthlyFormSummary', action.instance);
        case SAVE_QUARTERLY_FORM_DATA:
            return state.set('quarterlyFormSummary', action.instance);
        case SAVE_FORM_COUNTS:
            return state.set('formCounts', action.instance);
        case SAVE_LINE_CHART_DATA:
            return state.set('lineChartData', action.instance);
        case SAVE_LINE_CHART_FILTER:
            return state.set('lineChartFilter', {date: action.date, region: action.region});
        case SAVE_DAILY_FORM_FILTER:
            return state.set('dailyFilter', {date: action.date, region: action.region});
        case SAVE_MONTHLY_FORM_FILTER:
            return state.set('monthlyFilter', {date: action.date, region: action.region});
        case SAVE_QUARTERLY_FORM_FILTER:
            return state.set('quarterlyFilter', {date: action.date, region: action.region});
        case REMOVE_FILTER_DATA:
            return state.delete('quarterlyFilter').delete('monthlyFilter').delete('dailyFilter');
        case SAVE_PLANT_DETAILS:
            return state.set('plantDetails', action.plantDetails);
        case DELETE_PLANT_DETAILS:
            return state.delete('plantDetails');
        case SAVE_FLAGGED_FORM_DATA:
            return state.set('flaggedForms', action.instance);
        case SAVE_FLAGGED_DAILY_FORM_DATA:
            return state.set('flaggedDailyForms', action.instance).set('dailyCount', action.dailyCount);
        case SAVE_FLAGGED_QUARTERLY_FORM_DATA:
            return state.set('flaggedQuarterlyForms', action.instance).set('quarterlyCount', action.quarterlyCount);
        case SAVE_FLAGGED_MONTHLY_FORM_DATA:
            return state.set('flaggedMonthlyForms', action.instance).set('monthlyCount', action.monthlyCount);
        case SAVE_FLAGGED_FORM_DETAILS:
            return state.set('flaggedFormDetails', action.formDetails).set('completedBy',
                action.formDetails.CompletedBy).set('followUpComments', action.formDetails.FlaggedComments)
                .set('dateCompleted', formatDateToUS(action.formDetails.DateCompleted));
        case RESET_DASHBOARD_FILTERS:
            return state.set('lineChartFilter', {date: new Date(), region: ''})
                .set('dailyFilter', {date: new Date(), region: ''})
                .set('monthlyFilter', {date: new Date(), region: ''})
                .set('quarterlyFilter', {date: new Date(), region: ''})
                .set('resetFilters', true);
        default:
            return state.set('dailyFilter', {
                date: (state.get('dailyFilter') ?
                    state.get('dailyFilter').date : new Date()),
                region: state.get('dailyFilter') ? state.get('dailyFilter').region : ''
            })
                .set('monthlyFilter', {
                    date: (state.get('monthlyFilter') ?
                        state.get('monthlyFilter').date : new Date()),
                    region: state.get('monthlyFilter') ? state.get('monthlyFilter').region : ''
                })
                .set('lineChartFilter', {
                    date: (state.get('lineChartFilter') ?
                        state.get('lineChartFilter').date : new Date()),
                    region: state.get('lineChartFilter') ? state.get('lineChartFilter').region : ''
                })
                .set('quarterlyFilter', {
                    date: (state.get('quarterlyFilter') ?
                        state.get('quarterlyFilter').date : new Date()),
                    region: state.get('quarterlyFilter') ? state.get('quarterlyFilter').region : ''
                });
    }
}
