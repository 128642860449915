import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {IHistory} from '../../interfaces';
import {FlaggedFormModel} from '../../model/FlaggedFormModel';
import {getAllCompletedFlaggedForms} from '../../services/dashboardService';
import {getCurrentUserUUID} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {ListPage} from '../reusableComponents/ListPage';
import {Loader} from '../reusableComponents/Loader';
import {FlaggedListItem} from './FlaggedFormListItem';
import {Pagination} from '../reusableComponents/Pagination/pagination';
import {setLoading, setSuccess} from '../../actions/loadingActions';

const queryString = require('query-string');

export interface ICompletedFlaggedFormListProps {
    userId?: string;
    formType?: string;
    instances?: FlaggedFormModel[];
    history?: IHistory;
    filterBy?: string;
    location?: any;
}

export interface ICompletedFlaggedFormListState {
    filterBy: string;
    filteredInstances?: FlaggedFormModel[];
    searchFor: string;
    currentPage: number;
}

export class FlaggedFormListImpl extends React.Component
    <ICompletedFlaggedFormListProps, ICompletedFlaggedFormListState> {
    static defaultProps: ICompletedFlaggedFormListProps = {
        formType: 'Daily'
    };

    constructor(props: ICompletedFlaggedFormListProps) {
        super(props);
        let formType = 'Daily';
        let searchFor = 'complete';
        let pageNum = 1;
        if (this.props.location.search) {
            formType = queryString.parse(this.props.location.search).formType;
            searchFor = queryString.parse(this.props.location.search).searchFor;
            pageNum = queryString.parse(this.props.location.search).pageNum;
        }
        this.state = {filterBy: formType, searchFor, currentPage: Number(pageNum)};
    }

    promise = async () => {
        const {searchFor, filterBy} = this.state;
        const formType = queryString.parse(this.props.location.search).formType;
        const pageNum = queryString.parse(this.props.location.search).pageNum;
        await getAllCompletedFlaggedForms(getCurrentUserUUID(), this.state.filterBy, searchFor, formType, pageNum);
    }

    getCalculatedPageHeadings = () => {
        if ((this.state.searchFor || '').toLowerCase() === 'complete') {
            return {
                pageHeadings: ['Plant ID', 'Region', 'Type', 'Date Submitted', 'Date Completed'],
                cols: [2, 3, 3, 2, 2]
            };
        } else {
            return {
                pageHeadings: ['Plant ID', 'Region', 'Type', 'Date Submitted'],
                cols: [2, 3, 3, 4]
            };
        }
    }
    handlePageNumbers = async (pageNumber: number) => {
        this.setState({currentPage: pageNumber});
        const {searchFor} = this.state;

        const formType = queryString.parse(this.props.location.search).formType;
        const dashboardQueryString =
            `/flagged-forms?searchFor=${searchFor}&&formType=${formType || ''}&&pageNum=${pageNumber}`;
        this.props.history!.push(dashboardQueryString);
        setLoading('ListPageAll');
        await getAllCompletedFlaggedForms(getCurrentUserUUID(), this.state.filterBy, searchFor, formType, pageNumber);
        setSuccess('ListPageAll');
    }
    renderPages = () => {
        const {instances}: any = this.props;
        const {currentPage} = this.state;
        let pageNumberArray: Array<any> = [];
        const pageNumbers = instances[0] && instances[0].props.PagesCount;
        for (let i = 1; i <= pageNumbers; i++) {
            pageNumberArray[i - 1] = i;
        }
        return (
            <ul style={pagination}>
                {pageNumberArray.map((page, index) => (
                    <li key={index} style={(index + 1) === currentPage ?
                        paginationItems : paginationremainingItems}
                        onClick={() => this.handlePageNumbers(index + 1)}>
                        {page}
                    </li>
                ))}
            </ul>
        );
    }

    renderContent = () => {
        const {instances} = this.props;

        return (
            <React.Fragment>
                <ListPage
                    key={1}
                    // @ts-ignore
                    identifier="CompletedFlaggedForms"
                    instances={this.state.filteredInstances || instances}
                    listItemComponent={FlaggedListItem}
                    searchBy={['PlantId', 'Region', 'type', 'Date', 'CompletedBy', 'DateCompleted']}
                    searchPlaceHolder="Enter region, completed by, date completed or form type to search..."
                    sort={[{'heading': 'Date Submitted', 'sortBy': 'Date'}, {
                        'heading': 'Date Completed',
                        'sortBy': 'Date'
                    }]}
                    instanceOf={'plantInfo'}
                    promise={() => setSuccess('ListPageAll')}
                    cols={this.getCalculatedPageHeadings().cols}
                    pageHeadings={this.getCalculatedPageHeadings().pageHeadings}
                    emptyListMessage={this.state.searchFor === 'complete' ? 'No Completed Flagged forms found'
                        : 'No InComplete Flagged forms found'}
                    renderPages={<Pagination
                        instances={this.props.instances}
                        currentPage={this.state.currentPage}
                        handlePageNumbers={this.handlePageNumbers}
                    />}
                />
            </React.Fragment>);
    }

    render() {
        return [
            <BasePage key={22} className="list-page-base form-reports"
                      pageTitle=
                          {`${this.state.searchFor === 'complete' ? 'Completed Flagged Form' : 'Incomplete Flagged Forms'}`}>
                <Async
                    identifier="CompletedFlaggedFormList"
                    promise={this.promise}
                    loader={<Loader type="async"/>}
                    content={this.renderContent()}
                    error={<ErrorPage/>}
                />
            </BasePage>
        ];
    }

}

export function mapStateToProps(state: any) {
    const instances = FlaggedFormModel.getAllBy('key', 'ListPageData');
    const userId = state.login.get('userId');
    return {
        instances, userId, filterBy: state.miscellaneous.get('completedFlaggedForm')
    };
}

export const FlaggedFormList =
    withRouter(connect<ICompletedFlaggedFormListProps, any, any>(mapStateToProps)(FlaggedFormListImpl as any));

const pagination: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none'
};

const paginationItems: React.CSSProperties = {
    padding: '10px',
    borderRadius: '50%',
    background: '#95CC41',
    cursor: 'pointer',
};
const paginationremainingItems: React.CSSProperties = {
    padding: '10px',
    borderRadius: '50%',
    background: 'grey',
    cursor: 'pointer',
};
