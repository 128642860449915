import './monthlyForm.scss';
import './commonInspectionForm.scss';

import {format} from 'date-fns';
import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as _ from 'lodash';
import Dialog from 'react-bootstrap-dialog';

import {SIGNATURE_ERROR} from '../../constants/generals';
import {IHistory} from '../../interfaces';
import {
    getCommonFormQuestions,
    getCommonInspectionFormAnswers,
    saveCommonFormAnswers,
    uploadImages
} from '../../services/formService';
import {parseJwt} from '../../services/loginService';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {CustomSignaturePad} from '../reusableComponents/CustomSignaturePad';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Form} from '../reusableComponents/FormComponents/Form';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {ImageUpload} from '../reusableComponents/ImageUpload';
import {FileUpload} from '../reusableComponents/FileUpload';
import {Loader} from '../reusableComponents/Loader';
import {AdvancedQuestRenderer} from '../reusableComponents/FormComponents/AdvancedQuestionsRenderer';
import {AdvancedMaintenaceQuestRenderer} from '../reusableComponents/FormComponents/AdvancedMaintenanceQuestRenderer';
import {calculateActualTitleDate, formatDate} from '../../utils/generalUtils';


export interface IMaintenanceInspectionFormProps {
    userName: string;
    userUID: string;
    history?: IHistory;
}

export interface IMaintenanceInspectionFormState {
    questionsList: Array<any>;
    permanentToggle: { show: boolean; isPermanent: string; };
    uploadfilename : Array<any>;
    isLoading: boolean;
    addmodule: Array<any>; 
    addmoreQuestionArr : Array<any>; 
    moduleQuesFlag: boolean;
    formQuesCount: number;
}

export class MaintenanceInspectionFormImpl extends React.Component<IMaintenanceInspectionFormProps, IMaintenanceInspectionFormState> {
    static defaultProps: IMaintenanceInspectionFormProps;
    dialog: Dialog;

    imagesList: Array<any> = [];
    fileList: Array<any> = [];
    refs: {
        signatureInput: CustomSignaturePad;
        images: FileUpload;
        fileExt: FileUpload;
    };

    basicParams = JSON.parse(sessionStorage.getItem('inspectionDetails') || '{}');
    questionCount = 0;

    infoRows = [
        'Use this form to document operational checks of the cathodic protection system rectifier every month.',
        'If any significant variance is observed, please report this to the Environmental'
        + ' Manager so that any necessary repairs or adjustments can be made.',
        'This report will be kept in the corporate office for at least 5 years.',
        'Rectifier Manufacturer: Universal Rectifiers, Inc.',
        'Rectifier Model: Single Phase E5',
        'Rectifier Serial Number: 104993',
        'Rated DC Output: 20 volts; 5 amps',
    ];

    constructor(props: IMaintenanceInspectionFormProps) {
        super(props);
        this.state = {
            permanentToggle: {show: true, isPermanent: 'Yes'},
            questionsList: [],
            uploadfilename: [],
            isLoading : false,
            addmodule:[],
            addmoreQuestionArr: [],
            moduleQuesFlag: false,
            formQuesCount: 4
        };
    }

    get value() {
        return this.refs.images.value;
    }
    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }
    promise = async () => {
        const {selectedInspectionId, FormId, ParentId, date} = this.basicParams;
        let QuestCount = ParentId == 'Maintenace Record263' ? 4 : 3;
        if (selectedInspectionId) {
            await getCommonInspectionFormAnswers(FormId, selectedInspectionId,'Inspection', date).then(res => {
                const uniqueQuestionNums = new Set();

                // Use filter to keep only the first occurrence of each questionNum
                const filteredArray = res.questionAndAnswer.filter(item => {
                if (!uniqueQuestionNums.has(item.questionId)) {
                    uniqueQuestionNums.add(item.questionId);
                    return true;
                }
                return false;
                });
                
                const distinctModuleIndexes: string[] = Array.from(new Set(res.questionAndAnswer.map(item => item.moduleIndex)));
                if (res) {
                    this.imagesList = res.ImageUrls;
                    this.setState({
                        questionsList: filteredArray,
                        addmodule: distinctModuleIndexes.length>0 ? distinctModuleIndexes : [0],
                        addmoreQuestionArr: res.questionAndAnswer,
                        formQuesCount : QuestCount
                    });
                }
            });
        } else {
            await getCommonFormQuestions(FormId, '3').then(res => {
                this.setState({
                    questionsList: res,
                    addmodule: [0],
                    addmoreQuestionArr: res,
                    formQuesCount : QuestCount
                });
            });
        }  

    }

    pickSelectedObjProps = (object: any, allowedProps: Array<string>) => {
        return _.pick(object, allowedProps);
    }

    formatFinalAnswer = (answers: Array<any>) => {
        const finalAnswerSet: Array<any> = [];
        _.forEach(answers, (answer) => {
            const fieldToPick = ['answer', 'questionId', 'isFlagged', 'moduleIndex'];
            if (answer.subQuestions && answer.subQuestions.length > 0) {
                fieldToPick.push('subQuestions');
            }

            if (answer.questionType === 'Title') {
                answer.answer = null;
            }
            const formattedAnswer = this.pickSelectedObjProps(answer, fieldToPick);
            if (formattedAnswer.subQuestions && formattedAnswer.subQuestions.length > 0) {
                formattedAnswer.subQuestions = this.formatFinalAnswer(formattedAnswer.subQuestions);
            }
            finalAnswerSet.push(formattedAnswer);
        });
        return finalAnswerSet;
    }

    refineImageDataToSend = (currentImagesList: Array<any>) => {
        const imagesListToSend: Array<any> = [];
        _.forEach(currentImagesList, (image) => {
            if (typeof image === 'object') {
                imagesListToSend.push(image);
            } else {
                const imageIndex = _.findIndex(this.imagesList, {imageURL: image});
                if (imageIndex > -1) {
                    imagesListToSend.push(this.imagesList[imageIndex]);
                }
            }
        });
        return imagesListToSend;
    }

    handleFormSubmit = async () => { 
        
        if ((this.basicParams.isDeclarationRequired) && (!this.refs.signatureInput || !this.refs.signatureInput.signature)) {
            toast.error(SIGNATURE_ERROR, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        this.setState({isLoading:true});
        const currentImagesList = await uploadImages(this.refs.images.value, 'images');
        let signatureURL: any = null;
        if ((this.basicParams.isDeclarationRequired)) {
            signatureURL = await uploadImages([this.refs.signatureInput.signature], 'signatures');
        }

        // let answersToSend = this.formatFinalAnswer(_.cloneDeep(this.state.questionsList));
        let answersToSend = this.formatFinalAnswer(_.cloneDeep(this.state.addmoreQuestionArr));


        const data = {
            userId: this.props.userUID,
            plantId: this.basicParams.plantInfo.id,
            /* submittedDate: format(new Date(), 'MM-dd-yyyy'),
            inspectionDate: format(new Date(this.basicParams.date.replace(/-/g, '/')), 'MM-dd-yyyy'), */
            submittedDate: formatDate(format(new Date(), 'yyyy-MM-dd'), 'MM-dd-yyyy', true),
            inspectionDate: formatDate(format(new Date(this.basicParams.date.replace(/-/g, '/')), 'yyyy-MM-dd'), 'MM-dd-yyyy', true),
            formId: this.basicParams.FormId,
            answers: answersToSend,
            time: format(new Date(), 'hh:mm a'),
            inspectionId: this.basicParams.selectedInspectionId,
            //imageUrls: this.refineImageDataToSend(currentImagesList),
            imageUrls: (currentImagesList),
            attachmentfilename: this.state.uploadfilename,
            signatureUrls: signatureURL,
            orgId: this.basicParams.plantInfo.orgId,
            permitType: this.basicParams.permitType,
            facilityType: this.basicParams.plantInfo.facilityType,
            pointSource: this.basicParams.selectedPS.PsId || null,
            isPermanent: this.state.permanentToggle.isPermanent,
            inspectionType: this.basicParams.InspectionType,
            frequency: this.basicParams.frequency
        };
        
        return saveCommonFormAnswers(data, this.basicParams.selectedInspectionId, this.props.history);
    }

    getQuestionCount = (questData: any) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph')) ? this.questionCount += 1 : null;
    }

    onDataUpdate = (quest: any) => {
        const questionsList = JSON.parse(JSON.stringify(this.state.questionsList));
        const indexOfQuest = _.findIndex(this.state.questionsList, {questionId: quest.questionId});
        if (indexOfQuest > -1) {
            questionsList[indexOfQuest] = quest;
        }
        this.setState({
            questionsList,
            moduleQuesFlag: false
        });
    }
    handlemoduleDelete = async (index) =>{
        const filteredQuesArray = this.state.addmoreQuestionArr.filter(item => item.moduleIndex != index);
        const moduleIndexArr = this.state.addmodule.filter(item => item !== index);

        const newArray = moduleIndexArr.map(item => (item > index ? item - 1 : item)); 
        // const newQuesArray = filteredQuesArray.map(item => (item.moduleIndex > index ? item.moduleIndex - 1 : item.moduleIndex)); 
        const newQuesArray = filteredQuesArray.map(item => ({
            ...item,
            moduleIndex: item.moduleIndex > index ? (parseInt(item.moduleIndex, 10) - 1).toString() : item.moduleIndex
          }));
        await this.setState({
            addmodule: newArray,
            addmoreQuestionArr: newQuesArray,
            moduleQuesFlag: true
        })
        setTimeout(() => {
            // this.getRegionsList();
            this.renderContent()

        }, 500);    
    }
    handlemoduleDeleteDialog = async (index) => {
        this.dialog.show({
            body: `Are you sure you want to delete No:${index+1} set of questions module ?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => this.handlemoduleDelete(index))
            ]
        });
    }
    handleAddMore = async () => {
        
        const addmoreArr = this.state.addmodule;
        if (this.state.addmodule.length == 0) {
            addmoreArr.push(1);
        }else{
            let lastValue = this.state.addmodule[this.state.addmodule.length - 1]
            addmoreArr.push(lastValue+1);
            
        }

        const newArray = this.state.questionsList.map((item) => {
            return {
              ...item,
              answer: null,
              moduleIndex: this.state.addmodule[this.state.addmodule.length - 1],
            };
        });

        const combinedArray = [...this.state.addmoreQuestionArr, ...newArray];

        await this.setState({
            addmodule: addmoreArr,
            addmoreQuestionArr: combinedArray
        })
        
    }
    handlefileExt = async (childData) => {
        this.setState({uploadfilename: childData});
        // await this.renderContent()
        // this.setState({isLoading : false});

    }
    getRandomKey = () => {
        return Math.random().toString(36).substring(7);
    };

    renderContent = () => {
        this.questionCount = 0;
        
        return <Form
            model="forms.commonInspectionForm"
            onSubmit={this.handleFormSubmit}
            className="form">
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            {this.basicParams.ParentId === 'WaterMonthlyUSTRect' && <div className="information">
                <h3>UST Rectifier Reading Form</h3>
                <ul>
                    {this.infoRows.map((infoData, index) => {
                        return <li key={index}>{infoData}</li>;
                    })}
                </ul>
            </div>}
            {/*  {
                <div className="information question-row">Last modified date: {format(new Date(), 'MM-dd-yyyy')}</div>
            }*/}
            <React.Fragment>
                <div className="question-row"
                     style={{borderBottom: '2px solid #a4bf43', marginBottom: '1%', width: '50%', marginLeft: '1%'}}>
                    <div className="question">
                        {/*<div className="question-no">{localQuestNo}</div>*/}
                        {'Is a permenant facility?'}
                    </div>
                    <div className={`buttons`}>
                        {[{label: 'Yes', value: true}, {label: 'No', value: false}].map((btn, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    const permanentToggle = this.state.permanentToggle;
                                    permanentToggle.isPermanent = btn.label;
                                    this.setState({
                                        permanentToggle
                                    });
                                }}
                                type="button"
                                className={(this.state.permanentToggle.isPermanent === btn.label) ? 'button-yes'
                                    : 'button-no'}
                            >{btn.label}</button>
                        ))}
                    </div>
                </div>
            </React.Fragment>
            
            <div className='questionRender'>
            {this.state.addmodule.map((item, index) => ( 
                <div key={index} className='ADDModules'>
                    {/* {item} */}
                    {
                // @ts-ignore
                <Dialog className="confirmation-modal" ref={(el: any) => {
                    this.dialog = el;
                }}/>}   
                    {this.state.addmoreQuestionArr && this.state.addmoreQuestionArr.map((quesData, index: number) => (

                        <AdvancedMaintenaceQuestRenderer
                            key={this.state.moduleQuesFlag ? this.getRandomKey(): index}
                            quest={quesData}
                            localQuestNo={this.getQuestionCount(quesData)}
                            onDataUpdate={this.onDataUpdate}
                            handlemoduleDelete={this.handlemoduleDeleteDialog}
                            moduleIndex={item+1}
                            quesCount={this.state.formQuesCount}
                        />
                    ))}
                </div>
            ))
            }
        </div>
            <hr className="line-break"/>
            {(this.basicParams.isDeclarationRequired) && <div style={{padding: '45px', fontSize: '18px'}}>
                I certify under penalty of law that this document and all attachments were
                prepared under my direction or supervision in accordance with a system designed to
                assure that qualified personnel properly gathered and evaluated the information
                submitted. Based on my inquiry of the person or persons who manage the system, or those
                persons directly responsible for gathering the information, the information submitted
                is, to the best of my knowledge and belief, true, accurate, and complete. I am
                aware that there are significant penalties for submitting false information,
                including the possibility of fine and imprisonment for knowing violations.
            </div>}
            <div className="image-signature-container">
                {/* <ImageUpload
                    ref="images"
                    multi={true}
                    imagesList={this.imagesList}
                    label="Select Images to Upload:"
                /> */}
                <div>
                <span className='addmore' onClick={this.handleAddMore}>
                    <i className="fa fa-plus-circle addmore-icon" aria-hidden="true" />
                     Add More..
                </span>
            </div>
                <FileUpload
                    ref="images"
                    multi={true}
                    imagesList={this.imagesList}
                    fileExtFunction={this.handlefileExt}
                    fileList={this.fileList}
                    label="Select Attachment:"
                />
                
                {(this.basicParams.isDeclarationRequired) &&
                <CustomSignaturePad ref="signatureInput" userName={this.props.userName}/>}
            </div>
            {
                <FormFooter redirectTo="/inspections"/>}
        </Form>;
    }

    render() {
        const {FormName, permitType, plantInfo, frequency, date, selectedPS} = this.basicParams;
        const outfall = selectedPS.PsName !== 'Select' ? ' (' + selectedPS.PsName + ')' : '';
        return <BasePage
            pageTitle={`${FormName + outfall} - ${permitType} Permit - ${plantInfo.facilityType} - ${plantInfo.plantId} - ${calculateActualTitleDate(frequency, date)}`}>
            <Async
                identifier="MonthlyForm"
                promise={this.promise}
                content={this.renderContent()}
                loader={<Loader type="async"/>}
                error={<ErrorPage/>}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    return {
        userUID: state.login.get('UniqueId'),
        userName
    };
}

export const MaintenanceInspectionForm = withRouter(connect< IMaintenanceInspectionFormProps, any, any>(mapStateToProps)(MaintenanceInspectionFormImpl));
