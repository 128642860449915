import {combineReducers} from 'redux';
import {loadingReducer} from './loadingReducer';
import {modelReducer} from './modelReducer';
import {modalReducer} from './modalReducers';
import {alertReducer} from './alertReducer';
import {forms} from './formReducer';
import {loginReducer} from './loginReducer';
import {miscellaneousReducer} from './miscellaneousReducer';
import {dashboardReducer} from './dashboardReducer';

let reducer = combineReducers({
    loading: loadingReducer,
    models: modelReducer,
    modal: modalReducer,
    alert: alertReducer,
    login: loginReducer,
    miscellaneous: miscellaneousReducer,
    dashboard: dashboardReducer,
    forms
});

export const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return reducer(state, action);
};
