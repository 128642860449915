import {CircularProgress} from 'material-ui';
import {MuiThemeProvider} from 'material-ui/styles';
import * as React from 'react';
import loaderImage from '../../images/loader.gif';

export interface ILoaderProps {
    type?: 'async' | 'submission';
    size?: number;
    thickness?: number;
    bgColor?: string;
    key?: any;
}

export class Loader extends React.PureComponent<ILoaderProps, {}> {

    getStyle = () => {

        const {type, bgColor} = this.props;
        if (this.props.type === 'async') {
            return {...progressStyle, backgroundColor: bgColor};
        } else {
            return submissionLoader;
        }
    }

    scrollToSection = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const {size, thickness} = this.props;
        this.scrollToSection();
        return (
            <div style={this.getStyle()} className={'loader-image'}>
                <div style={this.props.type !== 'async' ? loadingImageStyle : {}}>
                    {this.props.type === 'submission' ? <MuiThemeProvider>
                        <CircularProgress size={size || 150} color="#A4BF43" thickness={thickness || 5}/>
                    </MuiThemeProvider> : <div>
                        <img alt={'loader'} src={loaderImage} width="140px"/>
                    </div>}
                </div>
            </div>
        );
    }
}

const progressStyle: React.CSSProperties = {
    minHeight: '50vh',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent'
};

const submissionLoader: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1000,
    backgroundColor: 'black',
    overflow: 'hidden',
    opacity: 0.7,
};

const loadingImageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '0.5%'
    //top: '3%'
};
