import {format} from 'date-fns';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {IHistory} from '../../../interfaces';
import { MarkAsCompletedPopOver } from '../../reusableComponents/MarkAsCompletePopOver';
import {getDailyChecklistData, getFormSummaryQuestions, saveFlagCompletions} from '../../../services/dailyFormService';
import {setLoading, setSuccess} from '../../../actions/loadingActions';



export interface IListItemProps {
    instance?: any;
    index?: number;
    frequency?: string;
    listItemProp?: string;
    history?: IHistory;
    onFlagSubmitParent?: any;

}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    static defaultProps: IListItemProps;
    renderDetailPage() {
        /* const {instance, index, history, frequency} = this.props;
        const {plantId, plantRegionName, facilityType, permitType, inspectionName, inspectionId, submittedDate, lastUpdatedDate, parentInspectionName, FormId } = instance;
        instance.frequency = frequency;
        localStorage.setItem('reportsMetaData', JSON.stringify(instance));
        history?.push(`/reports/${frequency}/${inspectionId}/${FormId}`); */
        /* return <h4 key={index} className="answer-column"> {isFlagged ? <div className="flagged-answer"><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={data.Date} questionId={key} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue} inspectionId={data.inspectionUId}>
                                <div className={`${followUpClass} mark-as-complete-col`} title="Click to enter the follow-up comment">
                                    <i className={followUpLogo} aria-hidden="true"> </i> {answerToShow}
                                </div> 
                            </MarkAsCompletedPopOver></div> : answerToShow } </h4> */
    }
    handleFlagAnswerUpdate = async (comments, date, questionId, inspectionId) => {
        let {instance, index, history, frequency} = this.props;
        // instance = { ...instance, isCompleted: 1 };

        await saveFlagCompletions(inspectionId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'), history).then(async (res) => {
            setLoading('DailyChecklistData');
            await this.props.onFlagSubmitParent(instance);
            setSuccess('DailyChecklistData');
        });
    }
    render() {
        const {instance, index, history} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {plantId, plantName, questionText, AnswerForFlag, formDate, isCompleted, frequency, ActualDate,questionNum, InspectionUId, followUpRemark, followUpDate, pagedataCnt, plantRegionName, facilityType, permitType, inspectionName, inspectionId, submittedDate, lastUpdatedDate} = instance;
        let followUpLogo = '';
        let followUpClass = '';
        let defaultValue = {};
        // if(quest && quest.isFlagged ) {
        if(isCompleted) {
            followUpLogo = 'fa fa-check-square-o';
            followUpClass = 'followUpRemarkComplete';
            defaultValue = {'followUpRemark': followUpRemark, 'followUpDate': followUpDate }
        } else {
            followUpLogo = 'fa fa-flag';
            followUpClass = 'followUpRemarkUnComplete';
        }
        // }  
        return <Row className="form-list-item" onClick={() => { this.renderDetailPage();
           
        }}>
            <Col className="clickable-list-item serial-no"
                 sm={1}
                 xs={1}
            >
                {/* {index+' - '+pagedataCnt} */}
                {pagedataCnt}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {plantId+' - '+plantName}
            </Col>
            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {inspectionName+' ('+frequency+')'}
            </Col>
            <Col className="clickable-list-item"
                 xs={4}
                 sm={4}
            >
                {questionText}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {AnswerForFlag}
            </Col>
            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {formDate ? format(new Date(formDate + 'T17:00:00.00Z'), 'MM/dd/yyyy') : 'NA'}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {/* <i className="fa fa-check-square-o completed-flag" aria-hidden="true">&nbsp;</i> */}
                <h4 key={index} className="answer-column"> 
                    {/* {isCompleted ? */}
                     <div className="flagged-answer">
                        <MarkAsCompletedPopOver ref="popOver" formSubmittedDate={followUpDate} questionId={questionNum} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue} inspectionId={InspectionUId}>
                            <div className={`${followUpClass} mark-as-complete-col`} title="Click to enter the follow-up comment">
                                <i className={followUpLogo} aria-hidden="true"> </i> 
                                {/* {answerToShow} */}
                            </div> 
                        </MarkAsCompletedPopOver>
                    </div>
                     {/* : <></> }  */}
                     </h4>
            </Col>

            {/* <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM/dd/yyyy') : 'NA'}
            </Col> */}
        </Row>;
    }
}

export function mapStateToProps(state) {
    return { };
}

export const ListItem = withRouter(connect< IListItemProps, any, any>(mapStateToProps)(ListItemImpl as any));
