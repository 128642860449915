import '../Maintenance/maintenance.scss';

import {format} from 'date-fns';
import * as React from 'react';
import Dialog from 'react-bootstrap-dialog';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';

import {UserModel} from '../../model/UserModel';
import {deleteUser, getUser, updatePasswordUser, userDeActivate} from '../../services/userService';
import {formatArrayItemDisplay} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {parseJwt} from '../../services/loginService';
import { Col } from 'react-bootstrap';

export interface IUserProfileProps {
    userInstance: UserModel;
    currentUserInstance: UserModel;
    userId: string;
    loggedInUserInfo: any;
    history?: IHistory;
}

export interface IUserProfileState {
    showConfirmationModal: boolean;
    reload: boolean;
    userId: string;
    isLoading: boolean;
}

export class UserProfileImpl extends React.Component<IUserProfileProps, IUserProfileState> {
    dialog: Dialog;

    constructor(props: IUserProfileProps) {
        super(props);
        this.state = {showConfirmationModal: false, reload: false, userId: props.userId, isLoading: false};
    }

    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }

    promise = async () => {
        await getUser(this.state.userId);
    }

    handleUserDelete = () => {
        this.setState({ isLoading:true });
        this.dialog.show({
            body: `Are you sure you want to delete user ${this.props.userInstance.props.First_Name}?`,
            actions: [
                Dialog.CancelAction(()=> this.setState({ isLoading:false })),
                Dialog.OKAction(() => deleteUser(this.props.userInstance.props.id, this.props.history))
            ]
        });
        

    }
    handleUserPasswordUpdate = () => { 
        this.setState({ isLoading:true });
        this.dialog.show({
            body: `Are you sure you want to send password for user ${this.props.userInstance.props.First_Name}?`,
            actions: [
                Dialog.CancelAction(()=> this.setState({ isLoading:false })),
                Dialog.OKAction(() => this.updateUserPassword() )
            ]
        });
    }
    updateUserPassword = async () => {
        await updatePasswordUser(this.props.userInstance.props.id, this.props.history);
        this.setState({ isLoading:false });
        return true;
    }
    handleUserDeActivate = () => {
            const {Active} = this.props.userInstance.props;
            let alrtMsg = Active == 2 ? `Are you sure you want to Activate user ${this.props.userInstance.props.First_Name}?` : `Are you sure you want to De-Activate user ${this.props.userInstance.props.First_Name}?`
        this.dialog.show({
            body: alrtMsg,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => userDeActivate(this.props.userInstance.props.id, Active, this.props.history))
            ]
        });
    }

    renderUserButtons = () => {
        const loggedInAccessLevel = parseInt(this.props.loggedInUserInfo.Access_Level.replace(/\D/g, ''));
        const currentUserAccessLevel = parseInt(this.props.userInstance.props.Access_Level.replace(/\D/g, ''));

        if (loggedInAccessLevel === 1 || this.props.loggedInUserInfo.UniqueId === this.props.userInstance.props.UniqueId || loggedInAccessLevel <= currentUserAccessLevel) {
            return <div className="buttons-container">
                {((this.props.loggedInUserInfo.UniqueId !== this.props.userInstance.props.UniqueId && this.props.currentUserInstance.props.orgId === this.props.loggedInUserInfo.orgId)) &&
                [this.props.loggedInUserInfo.Access_Level==='L2' && <div
                    onClick={this.handleUserPasswordUpdate}
                    title={`Click to Update password of user ${this.props.userInstance.props.First_Name}`}
                    className="delete-details-button">
                    <i className="fa fa-key" aria-hidden="true"/>
                </div>,
                
                <div
                    onClick={this.handleUserDeActivate}
                    title={this.props.userInstance.props.Active == 2 ? `Click to Activate ${this.props.userInstance.props.First_Name} account.`:`Click to De-Activate ${this.props.userInstance.props.First_Name} account.`}
                    className="delete-details-button">
                    {this.props.userInstance.props.Active == 2 ? <i className="fa fa-lock" aria-hidden="true"/> : <i className="fa fa-unlock" aria-hidden="true"/>}
                </div>]
                }
                <div
                    onClick={() => this.props.history?.push(`/user/update/${this.props.userInstance.props.id}`)}
                    title={`Click to Edit the Details of User ${this.props.userInstance.props.First_Name}`}
                    className="edit-details-button">
                    <i className="fa fa-pencil" aria-hidden="true"/>
                </div>
                {(this.props.loggedInUserInfo.Access_Level === 'L2' && this.props.loggedInUserInfo.UniqueId !== this.props.userInstance.props.UniqueId && this.props.currentUserInstance.props.orgId === this.props.loggedInUserInfo.orgId && loggedInAccessLevel <= currentUserAccessLevel) &&
                <div
                    onClick={this.handleUserDelete}
                    title={`Click to Delete user ${this.props.userInstance.props.First_Name}`}
                    className="delete-details-button">
                    <i className="fa fa-trash" aria-hidden="true"/>
                </div>}
            </div>;
        } else {
            return <span/>;
        }
    }

    renderUserDetails = () => {
        if (!this.props.userInstance) {
            return <div/>;
        }
        let {
            props: {
                UserId, Job_Title, Regions, Access_Level, Last_Name, Job_Desc, Last_Updated,
                Date_Created, Middle_Name, Plants, orgName, First_Name, Email,Active,
            }
        }: any = this.props.userInstance;

        if (Access_Level === 'L1') {
            Regions = ['All'];
            Plants = ['All'];
        }
        return <div style={{padding: '2vh'}} className="details-page-wrapper">
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            {
                // @ts-ignore
                <Dialog className="confirmation-modal" ref={(el: any) => {
                    this.dialog = el;
                }}/>}
            <div className="details-left-section" style={{float: 'unset'}}>
                <DisplayDetails label="User ID" value={UserId}/>
                <DisplayDetails label="Full Name" value={`${First_Name} ${Middle_Name || ''} ${Last_Name}`}/>
                <DisplayDetails label="Access Level" value={Access_Level}/>
                <DisplayDetails label="Job Title" value={Job_Title}/>
                <DisplayDetails label="Job Description" value={Job_Desc}/>
                <DisplayDetails label="Email" value={Email}/>
                <DisplayDetails label="Organisation" value={orgName}/>
                {(Access_Level === 'L3' || Access_Level === 'L4') &&
                <DisplayDetails label="Regions" value={formatArrayItemDisplay(Regions, 'regionName')}/>}
                {Access_Level === 'L4' &&
                <DisplayDetails label="Plants" value={formatArrayItemDisplay(Plants, 'plantName')}/>}
                <DisplayDetails label="Date Created" value={format(new Date(Date_Created), 'MM/dd/yyyy')}/>
                <DisplayDetails label="Status" value={Active == 2 ? 'In-Active': 'Active'}/>
            </div>
            {this.renderUserButtons()}
        </div>;
    }

    render() {
        return (
            <BasePage pageTitle="User Information">
                <Async
                    identifier="UserProfile"
                    promise={this.promise}
                    loader={<Loader type="async"/>}
                    error={<ErrorPage/>}
                    content={this.renderUserDetails()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    const userInstance = UserModel.get(ownProps.match.params.id);
    const userId = ownProps.match.params.id;
    const currentUserInstance = UserModel.get(userId, state);
    const loggedInUserInfo = parseJwt(state.login.get('token'));
    return { userInstance, currentUserInstance, loggedInUserInfo, userId: ownProps.match.params.id };
}

export const Profile = withRouter(connect< IUserProfileProps, any, any>(mapStateToProps)(UserProfileImpl as any));

const DisplayDetails = ({label, value}) => {
    return <div className="details-row">
        <Label labelText={label} />
        <Value value={value}/>
    </div>;
};

const Label = ({labelText}) => {
    return <Col className="details-label" xs={4} sm={6} md={6} lg={6} xl={6} >
        {labelText}:
    </Col>;
};

const Value = ({value}) => {
    return <Col className="details-value" xs={4} sm={6} md={6} lg={6} xl={6}>
        {value || 'NA'}
    </Col>;
};

const modalHeader: React.CSSProperties = {
    backgroundColor: '#A4BF43',
    border: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
};
