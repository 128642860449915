import './autoGenerateSignature.scss';

import * as React from 'react';
import Select from 'react-select';

import {SelectOptionComponent} from './SelectOptionComponent';
import {dropdownStyles} from "../SelectDropdown";
import {ISelectOptions} from "../../../interfaces";

declare const require: any;
const html2canvas = require('html2canvas');

interface IAutoSignature {
    name?: string;
    width?: string;
    height?: string;
    fontSize?: string;
    toDataURL?: (base64: string) => void;
    onSave?: (base64: string) => void;
}

interface IAutoSignatureState {
    selectedOption: string;
    signature: string | null;
    valueToShow: any;
}

export class AutoGenerateSignature extends React.Component<IAutoSignature, IAutoSignatureState> {

    selectOptions = [
        {value: 'Font1', label: (this.props.name || 'Demo')},
        {value: 'Font2', label: (this.props.name || 'Demo')},
        {value: 'Font3', label: (this.props.name || 'Demo')},
        {value: 'Font4', label: (this.props.name || 'Demo')},
        {value: 'Font5', label: (this.props.name || 'Demo')},
    ]

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.name || 'Font1',
            signature: null,
            valueToShow: {value: '', label: 'Select'}
        };
    }

    get value() {
        return this.state.signature;
    }

    handleSave = async () => {
        const self = this;
        html2canvas(document.getElementById('signature'), {logging: false}).then(function (canvas) {
            try {
                const dataURL = canvas.toDataURL();
                self.setState({signature: dataURL});
                // self.props.toDataURL(dataURL)
                if (self.props.onSave) {
                    self.props.onSave(dataURL);
                }
            } catch (error) {
                console.error(error);
            }
        });
    }

    handleChange = (obj) => {
        this.setState({
            valueToShow: obj,
            selectedOption: obj ? obj.value : ''
        });
    }

    render() {
        return (
            <div className="signature-generator">
                <div
                    className="signature-container b"
                    id="signature"
                    style={{
                        width: `${this.props.width || '300px'}`,
                        height: `${this.props.height || '150px'}`,
                        fontSize: `${this.props.fontSize || '50px'}`,
                        border: '1px solid #d3d3d3',
                        fontFamily: this.state.selectedOption,
                        backgroundColor: 'white',
                        color: 'black'
                    }}>
                    {this.props.name || 'Demo'}
                </div>
                <div className="footer-container" style={{
                    width: `${this.props.width || '300px'}`,
                }}>
                    <div>
                        <button
                            type="button"
                            className="react-signature-button button"
                            onClick={this.handleSave}
                        >
                            Save
                        </button>
                    </div>
                    <Select
                        name="form-field-name"
                        value={this.state.valueToShow}
                        styles={dropdownStyles}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#a4bf43',
                                primary: '#a4bf43',
                            },
                        })}
                        onChange={this.handleChange}
                        options={this.selectOptions}
                        valueComponent={CustomOptionComponent}
                        formatOptionLabel={formatOptionLabel}
                        isClearable={false}
                    />
                </div>
            </div>
        );
    }
}

const CustomOptionComponent = ({value, option, children}) => {
    return (
        <div className="Select-value" style={{paddingRight: '30px'}}>
            <span className="Select-value-label">
                <div style={{fontFamily: value ? value.value : ''}}>
                    {children || 'NA'}
                </div>
            </span>
        </div>
    );
};

const formatOptionLabel = ({value, label, customAbbreviation}) => {
    return (
        <div style={{display: "flex"}}>
            <div style={{fontFamily: value}}>{label}</div>
        </div>
    )

}
