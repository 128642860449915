import * as React from 'react';
import {ReactElement} from 'react';
import {DispatchProp} from 'react-redux';
import {actions, Form as RRFForm} from 'react-redux-form'; 
import {dispatch, ModelContext} from '../../../utils/generalUtils';
import {Loader} from '../Loader';

export interface IFormProps extends DispatchProp<any> {
    model: string;
    className?: string;
    onSubmit: (val) => void;
    loadDefaultValues?: Function;
}

export interface IFormState {
    showSubmissionLoader: boolean;
}

export class Form extends React.PureComponent<IFormProps, IFormState> {
    static defaultProps: IFormProps;

    constructor(props: IFormProps) {
        super(props);
        this.state = {showSubmissionLoader: false};
    }

    componentWillMount() {
        dispatch(actions.reset(this.props.model));
    }

    componentDidMount() {
        if (this.props.loadDefaultValues) {
            this.props.loadDefaultValues();
        }
    }

    handleSubmit = async (values) => {
        const {onSubmit} = this.props;
        this.setState({
            showSubmissionLoader: true
        });
        try {
            await this.props.onSubmit(values);
            this.setState({
                showSubmissionLoader: false
            });
        } catch (error) {
            this.setState({
                showSubmissionLoader: false
            });
            throw error;
        }
    }

    renderClonedChildren = () => {
        const childrenWithModelName = React.Children.map(this.props.children, child => {
            return React.cloneElement((child as ReactElement<any> || <span/>), {
                formmodel: this.props.model
            });
        });
        return childrenWithModelName;
    }

    render() {
        return [
            < RRFForm 
                model={this.props.model}
                className={`${this.props.className} form-container`}
                onSubmit={this.handleSubmit}
            >

                {this.state.showSubmissionLoader ? <Loader key={1} type="submission"/> : ''}
                <ModelContext.Provider value={this.props.model}>
                    {this.renderClonedChildren()}
                </ModelContext.Provider>
            </RRFForm>
        ];
    }
}
