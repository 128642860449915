import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {IHistory} from '../../interfaces';
import {updatePassword} from '../../services/userService';
import {BasePage} from '../reusableComponents/BasePage';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {Loader} from '../reusableComponents/Loader';
import {ErrorMessageLabel} from '../reusableComponents/Errormessagelabel/errormessagelabel';

export interface IUpdatePasswordProps {
    userUID: string;
    history?: IHistory;
}

export interface IUpdatePasswordState {
    oldPassword: string;
    newPassword: string;
    isLoading: boolean;
    popOver: boolean;
}

export class UpdatePasswordImpl extends React.PureComponent<IUpdatePasswordProps, IUpdatePasswordState> {
    constructor(props: IUpdatePasswordProps) {
        super(props);
        this.state = {oldPassword: '', newPassword: '', isLoading: false, popOver: true};
    }
    
    handleSubmit = async () => {
        const {oldPassword, newPassword} = this.state;
        const {history, userUID} = this.props;
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
       
        if ( oldPassword.length<1 || newPassword.length<1){ 
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); 
            return;
        } 
        /* if (oldPassword!== newPassword)  {
            toast.error('Passwords do not match', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); 
            return;
        } */
        if (!strongRegex.test(newPassword)) {
            this.setState({popOver: true});
            toast.error('Invalid password. You password should match the conditions', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); 
            return;
        }
        this.setState({
            popOver: false,
            isLoading: true
        });
        await updatePassword(oldPassword, newPassword, userUID, this, history);
        this.setState({
            isLoading: false
        });
    }

    handleSomething = (e, src) => {
        if (src === 'np') {
            this.setState({newPassword: e.target.value});
        } else {
            this.setState({oldPassword: e.target.value});
        }
        /* this.setState({popOver: false}); */
    }

    renderPasswordField = () => {
        return (<React.Fragment>
            <RRFInput
                id="password"
                type="password"
                label="New Password:"
                placeholder="Enter your new password..."
                model=".newPassword"
                onChange={(e) => this.handleSomething(e, 'np')}
            />
            <div><ErrorMessageLabel isActive={this.state.popOver}
                               errorMessage="* Password should have at least one Uppercase Letter,
                one Lowercase character , one numeric character, 
                one special symbol and minimum length of password should be 8 character"/>
            </div>
        </React.Fragment>);
    }
    renderContent = (isLoading) => {
        return <div style={{position: 'relative', /* width: 'max-content', */ margin: 'auto'}}>
            {isLoading ? <Loader type="submission"/> : ''}
            <RRFInput
                model=".oldPassword"
                type="password"
                onChange={e => this.handleSomething(e, 'op')}
                placeholder="Enter your old password..."
                id="Old_Password"
                label="Old Password:"
            />
            {this.renderPasswordField()}
            { // @ts-ignore
                < FormFooter onSubmit={this.handleSubmit} onCancel={() => this.props.history?.push('/home')}/>}
        </div>;
    }

    render() {
        return <BasePage pageTitle="Update Your Password">
            {this.renderContent(this.state.isLoading)}
        </BasePage>;
    }
}

export function mapStateToProps(state) {
    const userUID = state.login.get('UniqueId');
    return {
        userUID
    };
}

export const UpdatePassword = withRouter(connect< IUpdatePasswordProps, any, any>(mapStateToProps)(UpdatePasswordImpl as any));
