import {toast} from 'react-toastify';

import {SUBMISSION_FAILED} from '../constants/generals';
import {IHistory} from '../interfaces';
import {UserModel} from '../model/UserModel';
import {delDev, getDev, postDev, putDev, updatePasswordDev} from '../utils/HTTPDev';
import {logoutUser} from './loginService';
import {isArray} from 'util';
import {setLoading, setSuccess} from '../actions/loadingActions';
import {getCurrentUserUUID, resetModels} from '../utils/generalUtils';

export async function getUser(userId: string) {
    try {
        setLoading('UserProfile');
        const response: any = await getDev(`/userdetails/${userId}`);
        response.data.data.id = response?.data.data.UniqueId;
        new UserModel(response?.data.data).$save();
        setSuccess('UserProfile');
        return response;
    } catch (error) {
        setSuccess('UserProfile');
        throw error;
    }
}

export async function getAllUsers(isAsc = false, sortIndex = 'UserId', curPageNo = 1, searchText: string = 'None', axiosPreviousToken = null) {
    try {
        const dataToSend = {
            'user': getCurrentUserUUID(),
            'isAsc':isAsc,
            'sortIndex': sortIndex,
            'curPageNo': curPageNo,
            'searchText':searchText
        };
        // if (searchText != null) {
        searchText = searchText.replace(/[^a-zA-Z0-9 ]/g, '');
        // }   

        setLoading('UsersList');

        const response = await getDev(`/user/${isAsc}/${sortIndex}/${curPageNo}/${searchText}`, dataToSend, axiosPreviousToken);
        UserModel.deleteAll();

        for (const key in response?.data.data) {
            const userInstance = response?.data.data;
            userInstance[key].id = key; 
            userInstance[key].UserId = userInstance[key].UserId.toString();
            new UserModel(userInstance[key]).$save();

        }
        setSuccess('UsersList');

    } catch (error) {
        throw error;
    }
}

export async function createNewUser(userData, history?: any) {

    const userInstance = {
        UserId: userData.UserId,
        Password: userData.Password,
        First_Name: userData.First_Name,
        Middle_Name: userData.Middle_Name || null,
        Last_Name: userData.Last_Name,
        Email: userData.Email,
        Access_Level: userData.Access_Level,
        orgId: userData.orgId,
        Regions: getPlants(userData.Regions),
        Plants: getPlants(userData.Plants),
        Job_Title: userData.Job_Title,
        Job_Desc: userData.Job_Desc
    };
    try {
        const response = await postDev('/user', userInstance);
        if (response) {
            // history.push(`/users`);
            await toast.success('User Created Successfully.', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'success-toast',
                onClose: () => history.push('/users'),
                autoClose: 1000
            });
            await getAllUsers();
            return response;
        } 
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function deleteUser(userId: string, history?: IHistory) {
    try {
        const response = await delDev(`/userdetails/${userId}`);
        const userInstance = UserModel.get(userId);
        new UserModel(userInstance.props).$delete();
        // history.push(`/users`);
        await getAllUsers();
        await toast.success('User Deleted Successfully.', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            onClose: () => history?.push('/users'),
            autoClose: 1000
        });
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function updatePasswordUser(userId: string, history?: IHistory) {
    try {
        const response = await updatePasswordDev(`/updateuserpassword/${userId}`);
        history?.push(`/users`);
        toast.success(response?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
        });
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function userDeActivate(userId: string, status: number, history?: IHistory) {
    try {
        const response = await putDev(`/updateuserstatus/${userId}/${status}`,'');
        history?.push(`/users`);
        toast.success('User Updated Successfully.', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
        });
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

function getPlants(data) {
    if (typeof data === 'string') {
        if (data.length === 0) {
            return [];
        }
        return data.split(',');
    }
    return data;
}

export async function updateUser(userData, userId, history) {
    const updateData = {
        UserId: (userData.UserId),
        First_Name: userData.First_Name,
        Middle_Name: userData.Middle_Name || null,
        Last_Name: userData.Last_Name,
        Email: userData.Email,
        Access_Level: userData.Access_Level,
        orgId: userData.orgId,
        Regions: getPlants(userData.Regions),
        Plants: getPlants(userData.Plants),
        Job_Title: userData.Job_Title,
        Job_Desc: userData.Job_Desc
    };
    try {
        const response = await putDev(`/userdetails/${userId}`, updateData);
        if (response !== undefined || response) {
            const userInstance = UserModel.get(userId);
            new UserModel(userInstance).$delete();
            toast.success('User Updated Successfully.', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'success-toast',
                onClose: history.goBack(),
                autoClose: 1000
            });
            await getAllUsers();
            return response;
        }
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function updatePassword(oldPassword, newPassword, userId, instance, history) {
    try {
        const response = await putDev(`/updatepassword/${userId}`,
            {Old_Password: oldPassword, New_Password: newPassword});
        logoutUser(history);
        toast.success('Password Updated Successfully.', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast'
        });
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        throw error;
    }
}
export async function getUsersByPlantAndLevel(userLevel: string, plantId: string) {
    try {
        const updateData = { userLevel, plantId };
        const response = await postDev(`/getUsersByPlantAndLevel`, updateData);
        const userInstance = response?.data.data;
        for (const key in response?.data.data) {
            userInstance[key].id = key;
            new UserModel(userInstance[key]).$save();
        }
        return true;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}
