import {REMOVE_INSTANCE, SAVE_INSTANCE, UPDATE_INSTANCE} from '../constants/actions';
import {BaseModel} from '../model/BaseModel';
import {dispatch} from '../utils/generalUtils';

/* export function saveInstance(instance: BaseModel<{}>, key: string) {
    return dispatch({
        type: SAVE_INSTANCE,
        instance,
        key
    });
} */
export function saveInstance(instance: any, key: string, identifier?: string) {
    return dispatch({
        type: SAVE_INSTANCE,
        instance,
        key
    });
}


export function removeInstance(key: string) {
    return dispatch({
        type: REMOVE_INSTANCE,
        key
    });
}

export function updateInstance(key: string, instance: any) {
    return dispatch({
        type: UPDATE_INSTANCE,
        key,
        instance
    });
}
