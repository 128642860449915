import {fromJS} from 'immutable';

import {SAVE_LOGIN_DATA} from '../constants/actions';

export function loginReducer(state: any = fromJS({}), action) {
    switch (action.type) {
        case SAVE_LOGIN_DATA:
            return state.set('isLoggedIn', true).set('token', action.loginData.token)
                .set('UniqueId', action.loginData.UniqueId);
        default:
            return state;
    }
}
