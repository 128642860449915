import {fromJS} from 'immutable';

import {
    HIDE_INCOMPLETE_PLANTS_MODAL,
    HIDE_PRINT_PREVIEW_MODAL,
    HIDE_UST_MODAL,
    SHOW_INCOMPLETE_PLANTS_MODAL,
    SHOW_PRINT_PREVIEW_MODAL,
    SHOW_UST_MODAL,
} from '../constants/actions';

export function modalReducer(state: any = fromJS({}), action) {
    switch (action.type) {
        case SHOW_INCOMPLETE_PLANTS_MODAL:
            return state.set('listModalID', action.id)
                .set('listData', action.data)
                .set('listTitle', action.listTitle)
                .set('formType', action.formType);
        case HIDE_INCOMPLETE_PLANTS_MODAL:
            return state.delete('listModalID');
        case SHOW_PRINT_PREVIEW_MODAL:
            return state.set('previewModalID', action.id);
        case HIDE_PRINT_PREVIEW_MODAL:
            return state.set('previewModalID', '');
        case SHOW_UST_MODAL:
            return state.set('showUSTModal', true).set('formType', action.formType)
                .set('formInstance', action.instance)
                .set('selectedDate', action.selectedDate);
        case HIDE_UST_MODAL:
            return state.delete('showUSTModal').delete('formType').delete('formInstance').delete('selectedDate');
        default:
            return state;
    }
}
