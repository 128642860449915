import 'moment-timezone';
import * as React from 'react';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css'

const moment = require('moment');

export interface IDatePickerProps {
    value: any;
    format?: string;
    maxDate?: any;
    minDate?: any;
    onChange: Function;
}

export interface IDatePickerState {
    value: any;
}

export class RDatePicker extends React.PureComponent<IDatePickerProps, IDatePickerState> {
    constructor(props: IDatePickerProps) {
        super(props);
        this.state = {
            value: props.value
        }; 
    }

    handleChange = (date) => {
        const {format, onChange} = this.props;
        /*   const newDate = format ? moment(date).tz('America/Chicago').format(format) :
               moment(date).tz('America/Chicago').toDate();*/
        const newDate = format ? moment(date).format(format) :
            moment(date).toDate();
        onChange(newDate);
        this.setState({
            value: newDate
        });
    }

    componentWillReceiveProps(nextProps: Readonly<IDatePickerProps>) {
        if(nextProps.value!==this.state.value) {
            this.setState({
                value: nextProps.value
            }); 
        }
     }

    /* isValidDate = (currentDate, selectedDate) => {
        if(this.props.minDate) {
            return currentDate.isSameOrAfter(new Date(this.props.minDate))
        } else {
            if (selectedDate) {
                return selectedDate.isSameOrBefore(new Date(this.props.maxDate ? this.props.maxDate : new Date()))
            } else {
                return currentDate.isSameOrBefore(new Date(this.props.maxDate ? this.props.maxDate : new Date()))
            }
        }

    } */

    isValidDate = (currentDate, selectedDate) => {
    
        /* if (minDate) {
            return currentDate.isSameOrAfter(minDate);
        } else {
            if (selectedDate) {
                return selectedDate.isSameOrBefore(maxDate);
            } else {
                return currentDate.isSameOrBefore(maxDate);
            }
        } */

        if(this.props.minDate) {
            return currentDate.isSameOrAfter(moment(this.props.minDate))
        } else {
            if (selectedDate) {
                return selectedDate.isSameOrBefore(moment(this.props.maxDate ? this.props.maxDate : moment()))
            } else {
                return currentDate.isSameOrBefore(moment(this.props.maxDate ? this.props.maxDate : moment()))
            }
        }
    }

    render() {
        const {value} = this.props; 
        return (
            <Datetime
                closeOnSelect={true}
                closeOnClickOutside={true}
                timeFormat={false}
                dateFormat={this.props.format}
                onChange={this.handleChange}
                value={this.state.value}
                isValidDate={this.isValidDate}
            
            />
            /* <Datetime
                 value={this.state.value}
                 onChange={this.handleChange}
                 locale="en-US"
                 maxDate={this.props.maxDate ? this.props.maxDate : new Date()}
             />*/
        );
    }
}
