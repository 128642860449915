import * as React from 'react';
import {connect} from 'react-redux';
import './maintenance.scss';
import {Map, Marker} from 'google-maps-react';
import Geocode from 'react-geocode';
import * as _ from 'lodash';
import {SketchPicker} from 'react-color';
import {toast} from 'react-toastify';
import {white} from 'material-ui/styles/colors';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { Col, Row } from 'react-bootstrap';

export interface IPlantLocationProps {
    plantInfo?: any;
    type?: 'edit' | 'nonEdit';
    isPlantAddressChanged?: boolean;
    updateCoords?: (coords) => void;
    updateShapesList?: (shapesList) => void;
    updateOutfallsList?: (outfallsList) => void;
    setAddressChangedStatus?: (status) => void;
    updateCoordinatesPointerList?: (coordinatesPointerList) => void;
}

export interface IPlantLocationStates {
    lineColor: string;
    currentShapeToDraw: string | null;
    markerShapeText: string | null;
    selectedShape: any;
    enableShapesInputField: boolean;
    plantInfo: any;
    isPlantAddressChanged?: boolean | null;
    center: any;
    outfallsList: Array<any>;
    activeMarker: any;
    showingInfoWindow: boolean;
    isInfoWindowEditable: boolean;
    isMouseTooltipVisible: boolean;
    coordinatesPointerList: Array<any>;
}

export class PlantLocationMapImpl extends React.Component<IPlantLocationProps, IPlantLocationStates> {

    map: any;
    drawingManager: google.maps.drawing.DrawingManager;
    markerShapeText: string | null = null;
    shapesList: any = {
        shapes: [],
        namedLocations: []
    };
    isMapInitialized: boolean = false;
    mousePointerCoordinates: any = {
        lat: 0,
        lng: 0
    };

    constructor(props: IPlantLocationProps) {
        super(props);
        this.state = {
            isInfoWindowEditable: false,
            activeMarker: null,
            showingInfoWindow: false,
            outfallsList: props.plantInfo.outfallsList,
            selectedShape: null,
            lineColor: '#FF0000FF',
            currentShapeToDraw: null,
            markerShapeText: null,
            enableShapesInputField: false,
            plantInfo: this.props.plantInfo,
            isPlantAddressChanged: props.isPlantAddressChanged,
            center: props.plantInfo.coords || {lat: 0, lng: 0},
            isMouseTooltipVisible: false,
            coordinatesPointerList: props.plantInfo.coordinatesPointerList,
        };
        this.changeShapesColor = this.changeShapesColor.bind(this);
        Geocode.setApiKey('AIzaSyAf2IZLhSyMcpxc6ryUlzACo5JwF9j5G34');
    }

    initializeMap = () => {
        const {street, city, state, country, zip} = this.state.plantInfo;
        const locationString = (street ? street + ', ' : '') + (city ? city + ', ' : '') + (state ? state + ', ' : '') + (country ? country + ', ' : '') + (zip || '');
        if (!locationString || locationString === 'USA, ') {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: {lat: position.coords.latitude, lng: position.coords.longitude}
                });
            });
        } else {
            this.formPlantMapData();
        }
    }

    componentDidMount() {
        this.initializeMap();
    }

    componentDidUpdate(prevProps: Readonly<IPlantLocationProps>, prevState: Readonly<IPlantLocationStates>, snapshot?: never) {
        if (this.isMapInitialized) {
            const formattedShapesList = this.buildingMapShapesJSON();
            if (this.props.updateShapesList) {
                this.props.updateShapesList(formattedShapesList);
            }

            if (this.props.updateOutfallsList) {
                this.props.updateOutfallsList(this.state.outfallsList);
            }

            if (this.props.updateCoordinatesPointerList) {
                this.props.updateCoordinatesPointerList(this.state.coordinatesPointerList);
            }

            if (this.props.setAddressChangedStatus) {
                this.props.setAddressChangedStatus(false);
            }
        }
        this.isMapInitialized = true;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({plantInfo: nextProps.plantInfo, isPlantAddressChanged: nextProps.isPlantAddressChanged}, () => {
            this.initializeMap();
        });
    }

    formPlantMapData = () => {
        let location: string | null = null;
        const {street, city, state, country, zip, coords} = this.state.plantInfo;
        if (!this.state.isPlantAddressChanged && coords && (coords.lat !== '0' && coords.lat !== null && coords.lat !== 0 && coords.lat !== undefined)) {
            this.setState({
                isPlantAddressChanged: false,
                center: {lat: coords.lat, lng: coords.lng}
            });
        } else {
            location = (street ? street + ', ' : '') + (city ? city + ', ' : '') + (state ? state + ', ' : '') + (country ? country + ', ' : '') + (zip || '');
            this.getCoordsFromAddress(location);
        }
    }

    getCoordsFromAddress = (locationString) => {
        if (locationString !== 'USA, ') {
            Geocode.fromAddress(locationString).then(
                response => {
                    const {lat, lng} = response?.results[0].geometry.location;
                    this.setState({
                        isPlantAddressChanged: false,
                        center: {lat, lng}
                    }, () => {
                        if (this.props.updateCoords) {
                            this.props.updateCoords({lat, lng});
                        }
                    });
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    handleApiLoaded = (mapProps, map) => {
        map.setMapTypeId('satellite');
        this.map = map;
        setTimeout(() => {
            this.loadSavedShapes();
        });
    }

    onMarkerClick = (a, b, c) => {
        const currentMarkerCoords = {
            lat: c.latLng.lat(),
            lng: c.latLng.lng()
        };
        if (this.props.updateCoords) {
            this.props.updateCoords(currentMarkerCoords);
        }
    }

    buildingMapShapesJSON = () => {
        const jsonOfShapesList: Array<any> = [];
        this.shapesList.shapes.forEach((shape: any) => {
            const shapeType = shape.type;
            const shapeOverlay = shape.overlay;
            if (shapeType === 'polyline') {
                jsonOfShapesList.push({
                    type: shapeType,
                    properties: {
                        path: shapeOverlay.getPath().getArray(),
                        strokeColor: shapeOverlay.strokeColor,
                        strokeWeight: shapeOverlay.strokeWeight,
                        strokeOpacity: shapeOverlay.strokeOpacity,
                        draggable: true,
                        editable: true
                    }
                });
            } else if (shapeType === 'circle') {
                jsonOfShapesList.push({
                    type: shapeType,
                    properties: {
                        center: {lat: shapeOverlay.getCenter().lat(), lng: shapeOverlay.getCenter().lng()},
                        radius: shapeOverlay.getRadius(),
                        strokeColor: shapeOverlay.strokeColor,
                        strokeWeight: shapeOverlay.strokeWeight,
                        strokeOpacity: shapeOverlay.strokeOpacity,
                        fillColor: shapeOverlay.fillColor,
                        fillOpacity: shapeOverlay.fillOpacity,
                        draggable: true,
                        editable: true
                    }
                });
            } else if (shapeType === 'polygon') {
                jsonOfShapesList.push({
                    type: 'polygon',
                    properties: {
                        path: shape.overlay.getPath().getArray(),
                        strokeColor: shapeOverlay.strokeColor,
                        strokeWeight: shapeOverlay.strokeWeight,
                        strokeOpacity: shapeOverlay.strokeOpacity,
                        fillColor: shapeOverlay.fillColor,
                        fillOpacity: shapeOverlay.fillOpacity,
                        draggable: true,
                        editable: true
                    }
                });
            } else if (shapeType === 'rectangle') {
                jsonOfShapesList.push({
                    type: 'rectangle',
                    properties: {
                        bounds: shapeOverlay.getBounds(),
                        strokeColor: shapeOverlay.strokeColor,
                        strokeWeight: shapeOverlay.strokeWeight,
                        strokeOpacity: shapeOverlay.strokeOpacity,
                        fillColor: shapeOverlay.fillColor,
                        fillOpacity: shapeOverlay.fillOpacity,
                        draggable: true,
                        editable: true
                    }
                });
            } else if (shapeType === 'marker') {
                jsonOfShapesList.push({
                    type: 'marker',
                    properties: {
                        position: shapeOverlay.getPosition(),
                        icon: shapeOverlay.icon,
                        label: shapeOverlay.label,
                        draggable: true,
                        editable: true
                    }
                });
            }
        });
        return jsonOfShapesList;
    }

    clearShapeSelection = () => {
        if (this.state.selectedShape) {
            if (this.state.selectedShape && this.state.selectedShape.setEditable) {
                const selectedShape = this.state.selectedShape;
                selectedShape.setEditable(false);
                selectedShape.setDraggable(false);
            }
            this.setState({
                currentShapeToDraw: null,
                selectedShape: null
            });
        }
    }

    setShapeSelection = (shape: any) => {
        const selectedShape = shape;
        if (selectedShape && selectedShape.setEditable) {
            selectedShape.setEditable(true);
            selectedShape.setDraggable(true);
        }
        this.setState({
            selectedShape
        });
    }

    deleteSelectedShape = () => {
        if (this.state.selectedShape) {
            let selectedShape = this.state.selectedShape;
            if (!selectedShape) {
                alert('There are no shape selected');
                return;
            }
            selectedShape.setMap(null);
            _.remove(this.shapesList.shapes, (shape: any) => {
                return shape.overlay === selectedShape;
            });
            this.setState({
                selectedShape: null,
                currentShapeToDraw: null
            });
        } else if (this.state.activeMarker) {
            let outfallsList = this.state.outfallsList;
            let coordinatesPointerList = this.state.coordinatesPointerList;
            const activeOutfall = this.state.activeMarker;
            // const currentOutfallIndex = _.findIndex(outfallsList, {id: activeOutfall.id});
            _.remove(outfallsList, {id: activeOutfall.id});
            _.remove(coordinatesPointerList, {id: activeOutfall.id});

            let tempCoordinatesPointerList: any = [];
            //coordinatesPointerList || [].forEach((coord: any) => { console.log(coor)
            (coordinatesPointerList || []).map((coord, index) => {
                tempCoordinatesPointerList.push({
                    id: coord.id,
                    latitude: coord.latitude,
                    longitude: coord.longitude,
                    name: `Coord ${index + 1}`,
                    pointerType: 'coordinate'
                });
            })
            this.setState({
                activeMarker: null,
                outfallsList, coordinatesPointerList: tempCoordinatesPointerList
            });

        }
    }

    changeShapesColor = (colorEvent: any) => {
        this.setState({
            lineColor: colorEvent.hex
        }, () => {
            this.drawingManager.setOptions({polylineOptions: {strokeColor: this.state.lineColor}});
        });
    }

    setMarkerText = (textValue) => {
        const text = textValue.target.value;
        if (!text) {
            toast.error('Please enter some text', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        this.initMarker(text);
    }

    mapShapesEvents = (drawingManager: any) => {
        google.maps.event.addListener(drawingManager, 'overlaycomplete', (event: any) => {
            let shape: any;
            shape = event.overlay;
            if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
                this.setState({
                    currentShapeToDraw: 'polyline'
                });
            } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.setState({
                    currentShapeToDraw: 'circle'
                });
            } else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
                this.setState({
                    currentShapeToDraw: 'rectangle'
                });
            } else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                this.setState({
                    currentShapeToDraw: 'polygon'
                });
            } else if (event.type === google.maps.drawing.OverlayType.MARKER) {
                this.setState({
                    currentShapeToDraw: 'marker'
                });
            }

            this.shapesList.shapes.push(event);

            shape.setOptions({
                clickable: true
            });
            shape.type = event.type;
            if (this.drawingManager) {
                this.drawingManager.setDrawingMode(null);
            }
            if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                shape.setEditable(false);
                shape.setDraggable(false);
            }
            google.maps.event.addListener(shape, 'click', (e) => {
                this.clearShapeSelection();
                this.setState({
                    activeMarker: null,
                    currentShapeToDraw: event.type,
                    enableShapesInputField: false
                });
                this.setShapeSelection(shape);
            });
            google.maps.event.addListener(shape, 'mousemove', (e) => {
                this.setState({
                    isMouseTooltipVisible: true
                })
                this.mousePointerCoordinates = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                }
            });
            google.maps.event.addListener(shape, 'mouseout', (e) => {
                this.setState({
                    isMouseTooltipVisible: false
                })
            });
        });

        google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
            const selectedShape = this.state.selectedShape;
            if (selectedShape && selectedShape.setEditable) {
                selectedShape.setEditable(false);
                selectedShape.setDraggable(false);
            }
            this.setState({
                selectedShape: null,
                currentShapeToDraw: null,
                enableShapesInputField: false
            });
        });
    }

    initPolylineDraw = () => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['Polyline']
            },
            polylineOptions: {
                strokeColor: this.state.lineColor,
                strokeWeight: 5,
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.POLYLINE
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);
    }

    initCircleDraw = () => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['Circle']
            },
            circleOptions: {
                strokeColor: '#37ff00',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: '#00ffb7',
                fillOpacity: 0.35,
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.CIRCLE
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);
    }

    initRectangleDraw = () => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['rectangle']
            },
            rectangleOptions: {
                strokeColor: '#eec90e',
                strokeOpacity: 0.8,
                strokeWeight: 5,
                fillColor: '#d4c594',
                fillOpacity: 0.35,
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.RECTANGLE
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);
    }

    initPolygonDraw = () => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['polygon']
            },
            polygonOptions: {
                strokeColor: '#0040ff',
                strokeOpacity: 0.8,
                strokeWeight: 5,
                fillColor: '#adadc1',
                fillOpacity: 0.35,
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.POLYGON
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);
    }

    initMarker = (text) => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['marker']
            },
            markerOptions: {
                icon: {
                    url: '../src/images/markerShape.jpg',
                    scaledSize: new google.maps.Size(22, 52)
                },
                label: {
                    color: '#f7faf8',
                    fontWeight: 'bold',
                    text,
                    fontSize: '20px',
                },
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.MARKER
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);
    }

    /* initCoordinationMarker = (text='') => {
        this.clearShapeSelection();
        const options = {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: ['marker']
            },
            markerOptions: {
                icon: {
                    url: '../src/images/markerShape.jpg',
                    scaledSize: new google.maps.Size(22, 52)
                },
                label: {
                    color: '#0000FF',
                    fontWeight: 'bold',
                    text: text,
                    border: '1px #fff solid',
                    fontSize: '16px',
                },
                draggable: true,
                editable: true
            },
            drawingMode: google.maps.drawing.OverlayType.MARKER
        };
        // @ts-ignore
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.mapShapesEvents(this.drawingManager);  
    } */

    selectOrDeselectShape = (type: string) => {
        if (this.drawingManager) {
            this.drawingManager.setMap(null);
        }
        this.clearShapeSelection();
        let updatedCurrentShapeToDraw = this.state.currentShapeToDraw;
        if (!updatedCurrentShapeToDraw || updatedCurrentShapeToDraw !== type) {
            updatedCurrentShapeToDraw = type;
            this.setState({
                activeMarker: null,
                currentShapeToDraw: type
            });
        } else {
            updatedCurrentShapeToDraw = null;
            this.setState({
                activeMarker: null,
                enableShapesInputField: false,
                currentShapeToDraw: null
            });
        }

        if (updatedCurrentShapeToDraw === 'polyline') {
            this.setState({
                enableShapesInputField: true
            }, () => {
                this.initPolylineDraw();
            });
        } else if (updatedCurrentShapeToDraw === 'circle') {
            this.initCircleDraw();
        } else if (updatedCurrentShapeToDraw === 'rectangle') {
            this.initRectangleDraw();
        } else if (updatedCurrentShapeToDraw === 'polygon') {
            this.initPolygonDraw();
        } else if (updatedCurrentShapeToDraw === 'marker') {
            this.setState({
                enableShapesInputField: true
            });
        } else if (updatedCurrentShapeToDraw === 'locationPin') {
            /* google.maps.event.addListener(this.map, 'click', (e) => { 
                const location = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                }
                let locString = location.lat.toFixed(5) + ','+ location.lng.toFixed(5); 
                this.clearShapeSelection(); 
                this.initCoordinationMarker(locString.toString())
            }); */

            /*  google.maps.event.addListener(this.map, 'click', (ev) => { 
             }) */
            /*  this.initCoordinationMarker(); */
        }
    }

    loadSavedShapes = () => {
        const shapesList: Array<any> = this.props.plantInfo.shapesList;

        shapesList.forEach((shape: any) => {
            let addedShape: any = null;
            if (shape.type === 'polyline') {
                addedShape = new google.maps.Polyline(shape.properties);
            } else if (shape.type === 'circle') {
                addedShape = new google.maps.Circle(shape.properties);
            } else if (shape.type === 'rectangle') {
                addedShape = new google.maps.Rectangle(shape.properties);
            } else if (shape.type === 'polygon') {
                addedShape = new google.maps.Polygon(shape.properties);
            } else if (shape.type === 'marker') {
                addedShape = new google.maps.Marker(shape.properties);
            }

            if (addedShape) {
                if (addedShape.setEditable) {
                    addedShape.setEditable(false);
                }
                if (addedShape.setDraggable) {
                    addedShape.setDraggable(false);
                }
                addedShape.setMap(this.map);
                this.shapesList.shapes.push({type: shape.type, overlay: addedShape});
                google.maps.event.addListener(addedShape, 'click', () => {
                    if (this.props.type === 'edit') {
                        this.clearShapeSelection();
                        this.setState({
                            activeMarker: null,
                            currentShapeToDraw: shape.type
                        }, () => {
                            this.setShapeSelection(addedShape);
                        });
                    }
                });
                google.maps.event.addListener(addedShape, 'mousemove', (e) => {
                    this.setState({
                        isMouseTooltipVisible: true
                    })
                    this.mousePointerCoordinates = {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    }
                });
                google.maps.event.addListener(addedShape, 'mouseout', (e) => {
                    this.setState({
                        isMouseTooltipVisible: false
                    })
                });
                /*  google.maps.event.addListener(addedShape, 'dblclick', (e) => {
                     const location = {
                         lat: e.latLng.lat(),
                         lng: e.latLng.lng()
                     }
                     let locString = location.lat + ','+ location.lng; 
                     this.initMarker(locString.toString())
                 }); */
            }
        });
    }

    addOutfall = () => {
        this.clearShapeSelection();
        const outfallsList = this.state.outfallsList;
        outfallsList.push({
            id: new Date().getTime() + 1,
            latitude: parseFloat(this.state.center.lat.toString()) + 0.000562345,
            longitude: parseFloat(this.state.center.lng.toString()) + 0.000562345,
            name: 'outfall',
            pointerType: 'outfall'
        });
        this.setState({
            outfallsList,
            enableShapesInputField: true,
            isInfoWindowEditable: true,
        });
    }

    onSourceCoordsChange = (of: any, i) => {
        let outfallsList = this.state.outfallsList;
        const currentOutfallIndex = _.findIndex(outfallsList, {id: of.id});
        outfallsList[currentOutfallIndex] = {
            id: of.id,
            latitude: parseFloat(i.position.lat()),
            longitude: parseFloat(i.position.lng()),
            name: of.name,
            pointerType: 'outfall'
        };
        this.setState({
            outfallsList
        });
    }

    addCoordsPointer = () => {
        this.clearShapeSelection();
        const coordinatesPointerList = this.state.coordinatesPointerList;
        coordinatesPointerList.push({
            id: new Date().getTime() + 1,
            latitude: parseFloat(this.state.center.lat.toString()) + 0.000562345,
            longitude: parseFloat(this.state.center.lng.toString()) + 0.000562345,
            name: `Coord ${coordinatesPointerList.length + 1}`,
            pointerType: 'coordinate'
        });
        this.setState({
            coordinatesPointerList,
            /* enableShapesInputField: true,
            isInfoWindowEditable: true, */
        });
    }

    onCoordsPointerChange = (of: any, i) => {
        let coordinatesPointerList = this.state.coordinatesPointerList;
        const currentCoordinatesIndex = _.findIndex(coordinatesPointerList, {id: of.id});
        coordinatesPointerList[currentCoordinatesIndex] = {
            id: of.id,
            latitude: parseFloat(i.position.lat()),
            longitude: parseFloat(i.position.lng()),
            name: of.name,
            pointerType: 'coordinate'
        };
        this.setState({
            coordinatesPointerList
        });
    }

    onSourceNameChange = (e: any) => {
        const text = e.target.value;
        if (!text) {
            toast.error('Please enter some text', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        let outfallsList = this.state.outfallsList;
        const activeOutfall = this.state.activeMarker;
        const currentOutfallIndex = _.findIndex(outfallsList, {id: activeOutfall.id});
        outfallsList[currentOutfallIndex] = {
            id: activeOutfall.id || new Date().getTime(),
            latitude: parseFloat(activeOutfall.position.lat),
            longitude: parseFloat(activeOutfall.position.lng),
            name: text
        };
        this.setState({
            outfallsList,
            isInfoWindowEditable: false
        });
    }

    onChangeSourceNameText = (e) => {
        const activeMarker = {...this.state.activeMarker};
        activeMarker.name = e.target.value;
        this.setState({
            activeMarker
        });
    }

    onSourceMarkerClick = (props, marker) => {
        this.clearShapeSelection();
        let addtProps = {pointerType: 'outfall'}
        props = {...props, ...addtProps};
        //props['pointerType'] = 'outfall';
        this.setState({
            activeMarker: props,
            showingInfoWindow: true
        });
    }

    onCoordsMarkerClick = (props, marker) => {
        this.clearShapeSelection();
        let addtProps = {pointerType: 'coordinate'}
        props = {...props, ...addtProps};
        this.setState({
            /* currentShapeToDraw: 'locationPin', */
            activeMarker: props,
            showingInfoWindow: true
        });
    }

    onMapClicked = () => {
        this.clearShapeSelection();
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });
    }

    renderCoordinateHints() {
        let id = this.state.activeMarker && this.state.activeMarker.pointerType=== "coordinate" &&
            this.state.activeMarker.id || ''
        return this.state.coordinatesPointerList.map((data: any) => (
            [<Row className={id && id===data.id?'highlight-coordinate-row':''}>
                <Col sm={3}>{data.name}</Col>
                <Col sm={9}>{data.latitude + ', ' + data.longitude}</Col>
            </Row>]
        ));
    }
    render() {
        const containerStyle = {
            position: 'relative',
            width: '100%',
            height: '100%'
        };
        // @ts-ignore
        const googleObj = window.google;
        const {street, city, state, country, zip} = this.state.plantInfo;
        const locationString = (street ? street + ', ' : '') + (city ? city + ', ' : '') + (state ? state + ', ' : '') + (country ? country + ', ' : '') + (zip || '');
        return locationString && locationString !== 'USA, ' ? <div className="map-button-container">
            <MouseTooltip
                visible={this.state.isMouseTooltipVisible}
                offsetX={15}
                offsetY={10}
                className={'cursor-tooltip'}
            >
                <span style={{color: 'red', opacity: 1}}>
                    <p>
                        {'Lat: ' + this.mousePointerCoordinates.lat?.toFixed(5)}
                    </p>
                    <p>
                        {'Lng: ' + this.mousePointerCoordinates.lng?.toFixed(5)}
                    </p>
                </span>
            </MouseTooltip>
            <div className="map-container">
                <Map onReady={this.handleApiLoaded}
                     google={googleObj} zoom={18}
                     center={this.state.center}
                    // @ts-ignore
                     disable={true}
                     onClick={this.onMapClicked}
                     streetViewControl={false}
                     disableDoubleClickZoom={true}
                     mapTypeControl={false}
                     disableDefaultUI={true}
                     fullscreenControl={false}
                     zoomControl={true}
                     containerStyle={containerStyle}
                     style={{width: '100%', height: '50vh'}}>
                    {// @ts-ignore
                        <Marker onDragend={this.onMarkerClick} title="" draggable={this.props.type === 'edit'}
                                position={this.state.center}/>}
                    {this.state.outfallsList.map((of, index) => {
                        return <Marker key={'of' + index}
                            // @ts-ignore
                                       onDragend={(e, i) => this.onSourceCoordsChange(e, i)}
                                       onClick={this.onSourceMarkerClick}
                                       draggable={this.props.type === 'edit'}
                                       position={{lat: of.latitude, lng: of.longitude}}
                                       id={of.id}
                                       title={of.name}
                                       label={ {text: of.name, color: 'white'} }
                                       name={of.name}
                                       icon={{
                                           url: 'http://maps.google.com/mapfiles/ms/micons/blue.png',
                                           anchor: new google.maps.Point(25, 25),
                                           scaledSize: new google.maps.Size(45, 45)
                                       }}>
                        </Marker>;
                    })}

                    {(this.state.coordinatesPointerList || []).map((of, index) => {
                        return <Marker key={'pointer' + index}
                            // @ts-ignore
                                       onDragend={(e, i) => this.onCoordsPointerChange(e, i)}
                                       onClick={this.onCoordsMarkerClick}
                                       draggable={this.props.type === 'edit'}
                                       position={{lat: of.latitude, lng: of.longitude}}
                                       id={of.id}
                                       title={of.name}
                                       label={ {text: of.name, color: 'white'} }
                                       name={of.name}
                                       icon={{
                                           url: 'http://maps.google.com/mapfiles/ms/micons/orange.png',
                                       }}>
                        </Marker>;
                    })}

                </Map>
            </div>
            {this.props.type === 'edit' && <div className="action-btns">
                <button type="button" className={this.state.activeMarker && this.state.activeMarker.pointerType==='outfall' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.addOutfall()}>Add Source
                </button>
                <button type="button"
                        className={this.state.currentShapeToDraw === 'polyline' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.selectOrDeselectShape('polyline')}>Line
                </button>
                <button type="button"
                        className={this.state.currentShapeToDraw === 'polygon' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.selectOrDeselectShape('polygon')}>Shape
                </button>
                <button type="button"
                        className={this.state.currentShapeToDraw === 'circle' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.selectOrDeselectShape('circle')}>Circle
                </button>
                <button type="button"
                        className={this.state.currentShapeToDraw === 'rectangle' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.selectOrDeselectShape('rectangle')}>Rectangle
                </button>
                <button type="button"
                        className={this.state.activeMarker && this.state.activeMarker.pointerType==='outfall' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.selectOrDeselectShape('marker')}>Text
                </button>
                <button type="button"
                        className={this.state.activeMarker && this.state.activeMarker.pointerType==='coordinate' ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.addCoordsPointer()}>Coordinates
                </button>
                <button type="button"
                        className={this.state.selectedShape || this.state.activeMarker ? 'shape-enable' : 'shape-disable'}
                        onClick={() => this.deleteSelectedShape()}>Delete
                </button>
            </div>}
            {(this.props.type === 'edit' && this.state.enableShapesInputField) && <div className="shape-inputs">
                {this.state.currentShapeToDraw === 'polyline' &&
                <SketchPicker color={this.state.lineColor} onChangeComplete={this.changeShapesColor}/>}
                {this.state.currentShapeToDraw === 'marker' &&
                <input aria-label="Enter your text..." onBlur={this.setMarkerText} className="map-text-input"/>}
                {this.state.activeMarker && <input onBlur={this.onSourceNameChange} value={this.state.activeMarker.name} onChange={this.onChangeSourceNameText} className="map-text-input"/>}
                {(this.state.currentShapeToDraw === 'marker' || this.state.activeMarker) &&
                <button type="button" className={'map-text-save-button'}>Save</button>}
            </div>}
            {this.state.coordinatesPointerList && this.state.coordinatesPointerList.length>0 && <div className='plant-coordinates-details' style={{marginTop: '10px' }}>
                <h4>Coordinate Details:</h4>
                <Row>
                    <Col sm={3}><h5>Name</h5></Col>
                    <Col sm={9}><h5>Coordinates</h5></Col>
                </Row>
                { this.renderCoordinateHints() }
            </div>}
        </div> : <span/>;
    }
}

function mapStateToProps() {
    return {}
}

export const PlantLocationMap = connect< IPlantLocationProps, any, any>(mapStateToProps)(PlantLocationMapImpl as any);
