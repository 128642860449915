import * as React from 'react';
import {CircularProgress} from 'material-ui';

export interface IModalErrorProps {
}

export class ModalError extends React.PureComponent<IModalErrorProps, {}> {
    constructor(props: IModalErrorProps) {
        super(props);
    }

    render() {
        return <div style={{
            minHeight: '25vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress size={150} color="#A4BF43" thickness={5}/>
        </div>;
    }
}
