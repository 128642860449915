import './maintenance.scss';

import * as React from 'react';
import Dialog from 'react-bootstrap-dialog';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, OverlayTrigger, Popover, PopoverTitle, PopoverContent} from 'react-bootstrap';
import * as _ from 'lodash';
import {PlantLocationMap} from './plantLocationMap';
import {IHistory} from "../../interfaces";
import {PlantModel} from '../../model/PlantModel';
import {parseJwt} from '../../services/loginService';
import {deletePlant, getPlantDetails, deActivatePlant} from '../../services/plantService';
import {optimizeInspections, selectedOutfallsCountCalculator, printDetails} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import { showPrintPreviewModal } from '../../actions/modalActions';
import {PrintPreviewModal} from '../reusableComponents/PrintPreview';


export interface IDetailsPageProps {
    instance: PlantModel;
    history?: IHistory;
    id: string;
    plantId: string;
    Access_Level: string;
    userId: string;
} 

export interface IDetailsPageState {
    printFlag: boolean;
}

export class DetailsPageImpl extends React.Component<IDetailsPageProps, IDetailsPageState> {
    dialog: Dialog;
    outfallOverlay: any;

    constructor(props: IDetailsPageProps) {
        super(props);
            
        this.state = {printFlag: false};
    }

    promise = async () => {
        // if (!this.props.instance) {
        await getPlantDetails(this.props.id);
        // }
    }

    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }

    handlePlantDelete = () => {
        this.dialog.show({
            body: `Are you sure you want to delete plant ${this.props.instance.props.plantName}?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => deletePlant(this.props.instance.props.id, this.props.history))
            ]
        });
    }
    handlePlantDeActivate = () => {
        this.dialog.show({
            body: this.props.instance.props.Active === 2 ? `Are you sure you want to Activate plant ${this.props.instance.props.plantName}?` : `Are you sure you want to De-Activate plant ${this.props.instance.props.plantName}?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => deActivatePlant(this.props.instance.props.id, this.props.history))
            ]
        });
        
    }

    handleOutfallsHover = (form, title) => {
        form.PointSources = form.PointSources.sort((a, b) => (a.PsName > b.PsName) ? 1 : ((b.PsName > a.PsName) ? -1 : 0));
        return <Popover id="popover-positioned-top"
                        className="outfall-selector-popover">
            <PopoverTitle as='h2' className="outfall-popover-title">{title}</PopoverTitle>
            <PopoverContent><Row className="outfall-list-container">
                <Col className="outfall-list">
                    {form.PointSources.map((ps, index) => {
                        ps.FormId = form.FormId;
                        return (<div
                            key={index}
                            className={`outfall-box ${ps.IsSelected ? 'active-box' : 'inactive-box'}`}>
                            <div className="outfall-name">{ps.PsName}</div>
                        </div>);
                    })}
                </Col>
                {/*  <Col className="popover-footer-button">
                    <div className="btn btn-success" onClick={() => this.outfallOverlay.hide()}>Close</div>
                </Col>*/}
            </Row></PopoverContent>
        </Popover>;
    }

    renderList = () => {
        const inspections: any = optimizeInspections(JSON.parse(JSON.stringify(this.props.instance.props.inspections)));

        return Object.keys(inspections).map((permitType, index) => {
            return (<Row className="form-type-boxes" key={permitType + index}>
                <Col sm="12" ><div className={ this.state.printFlag === false ? "permit-type-header" : "print-permit-type-header"} >{permitType}</div><br /></Col>
                {
                    Object.keys(inspections[permitType]).map((freq, ind) => {
                        if (_.isEmpty(inspections)) {
                            return <div/>;
                        }
                        const inspectionsForms = _.filter(inspections[permitType][freq], {IsSelected: true});
                        
                        if (inspectionsForms.length) {
                            return (<Row><Col key={freq + permitType + ind}>
                                <div className="freq-header">{freq}</div>
                                {
                                    inspectionsForms.map((form, i) => {
                                        let outfallsCount = 0;
                                        if (form.PointSources.length > 0) {
                                            outfallsCount = selectedOutfallsCountCalculator(form.PointSources);
                                        }
                                        return (<div className={this.state.printFlag === false ? "green-card" : "print-green-card"} key={form.FormId + i}>
                                            <div className="form-name">{form.FormName}</div>
                                            {form.IsSelected && outfallsCount > 0 && <OverlayTrigger
                                                rootClose
                                                trigger="click"
                                                // @ts-ignore
                                                id={freq + permitType + form.FormId + i}
                                                placement="top"
                                                /*    ref={(ref) => this.outfallOverlay = ref}*/
                                                overlay={this.handleOutfallsHover(form, permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                                <div
                                                    className="outfall-count">{permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'}-{outfallsCount}</div>
                                            </OverlayTrigger>}
                                        </div>);
                                    })
                                }
                            </Col></Row>);
                        }
                        return true
                    })
                }
            </Row>);
        });

        /* return <div className="inspections-list-parent-container">
            {Object.keys(inspections).map((permitType: string, index: number) => {
                return <Row className="permit-type-container" key={permitType + index}>
                    <Col sm="12"><p className="permit-type-header">{permitType}</p></Col>
                    {Object.keys(inspections[permitType]).map((freq, i) => {
                        return <Col className="inspections-list" key={permitType + freq + i}>
                            <div className="freq-title">{freq}</div>
                            <>  
                                <FooterCard
                                    // @ts-ignore
                                    date={this.state.date}
                                    permitType={permitType}
                                    freq={freq}
                                    plantInfo={plantInfo}
                                    inspections={inspections[permitType][freq]}
                                    modalType={this.props.modalType}>
                                </FooterCard>
                                
                            </>
                        </Col>;
                    })}
                </Row>;
            })}
        </div>; */
    }


    renderUserTable() {
        const { userdetailsList } : any | undefined = this.props.instance.props;

        return (
            <div >
            <table className='ArgosWeeklyDustCollector'>
                <thead>
                <>
                    <tr>
                        <th colSpan={12} className="table-col-tabtitle">Plant Users</th>
                    </tr> 
                    <tr> 
                        <th className="table-col-unit-3">UserID</th>
                        <th className="table-col-unit-2">Name </th>
                        <th className="table-col-unit-1">Access Level</th> 
                        <th className="table-col-unit-3">Status</th> 
                    </tr>
                </>
                </thead>
                <tbody>
                    {userdetailsList.map((item, index) => (
                    <tr key={index} 
                        onClick={() => this.props.history?.push(`/user/update/${item[6]}`)} 
                        className='ADDModuless'
                    > 
                        <td key={index} className="table-col-units">{item[4]}</td>
                        <td key={index} className="table-col-units">{item[1]}</td>
                        <td key={index} className="table-col-units">{item[3]}</td>
                        <td key={index} className="table-col-units">{item[5]=== 2 ? 'In-Active': 'Active'}</td>
                    
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
        )
    }

    renderContent = () => {
        if (!this.props.instance) {
            return <Loader type="async"/>;
        }

        return <div className="details-page-wrapper">
            {
                // @ts-ignore
                <Dialog className="confirmation-modal" ref={(el: any) => {
                    this.dialog = el;
                }}/>}
            {this.state.printFlag === true ? <div className="print-title-row">
             <Row>
                <Col sm={3} md={3} lg={3}></Col>
                <Col sm={6} md={6} lg={6}>
                    <div className="print-title">Maintenance
                    </div>    
                </Col>
                <Col sm={3} md={3} lg={3}>
                    {/* <div className="pdf-pagebreak-before"></div> */}
                </Col>
            </Row>
            </div> : ''}

            <Row style={{width: '100%', display: 'block'}}>
                <Col className="details-left-section" xs={12} sm={6} md={6} lg={6} xl={6}>
                    <DisplayDetails label="Plant ID" value={this.props.instance.props.plantId}/>
                    <DisplayDetails label="Region" value={this.props.instance.props.plantRegionName}/>
                    <DisplayDetails label="Name" value={this.props.instance.props.plantName}/>
                    <DisplayDetails label="Street" value={this.props.instance.props.street}/>
                    <DisplayDetails label="Address City" value={this.props.instance.props.city}/>
                    <DisplayDetails label="State" value={this.props.instance.props.state}/>
                    <DisplayDetails label="Zip Code" value={this.props.instance.props.zip}/>
                    <DisplayDetails label="Country" value={this.props.instance.props.country}/>
                    <DisplayDetails label="Facility Type" value={this.props.instance.props.facilityType}/>
                    <DisplayDetails label="Status" value={this.props.instance.props.Active == 2 ? 'In-Active' : 'Active'}/>
                </Col>
                <Col className={this.state.printFlag === false ? "details-right-section" : "details-right-section prints-disabled"} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <PlantLocationMap plantInfo={this.props.instance.props} type="nonEdit"/>
                </Col>
            </Row>
            <Row className="inspections-details-container">
                <div className="inspections-form-container">
                    {this.props.instance.props && this.renderList()}
                </div>
            </Row>
            <Row className="inspections-details-container">
                <div className="inspections-form-container">
                    {this.props.instance.props && this.renderUserTable()}
                </div>
            </Row>
            {this.state.printFlag === false ?
            <div className={this.state.printFlag === false ? "buttons-container" : "buttons-container prints-disabled"}>
                {(this.props.Access_Level === 'L2' || this.props.Access_Level === 'L1') &&
                    <div
                        onClick={() => this.props.history?.push(`/maintenance/update/${this.props.instance.props.id}`)}
                        title={`Click to Edit the Details of Plant ${this.props.instance.props.plantId}`}
                        className="edit-details-button">
                        <i className="fa fa-pencil" aria-hidden="true"/>
                    </div>}
                {this.props.Access_Level === 'L1' &&
                    <div
                        onClick={this.handlePlantDelete}
                        title={`Click to Delete the Details of Plant ${this.props.instance.props.plantId}`}
                        className="delete-details-button">
                        <i className="fa fa-trash" aria-hidden="true"/>
                    </div>}
                    {this.props.Access_Level === 'L2' &&
                    <div
                        onClick={this.handlePlantDeActivate}
                        title={this.props.instance.props.Active == 2 ? `Click to Activate Plant ${this.props.instance.props.plantId}` : `Click to De-Activate Plant ${this.props.instance.props.plantId}`}
                        // className={this.state.printFlag == false ? "buttons-container" : "buttons-container prints-disabled"
                        className={this.props.Access_Level == 'L2' ? "active-details-button  deactivate-btn" : "active-details-button"}>
                        {this.props.instance.props.Active == 2 ? <i className="fa fa-lock" aria-hidden="true"/> : <i className="fa fa-unlock" aria-hidden="true"/>}
                    </div>} 
                </div> :''}
                

        </div>;
    }
    ref: any;
    exportChart = (ref: any) => {
        printDetails(this.ref, 'dashboard');
    }
    printAction = async () => {

        await this.setState({
            printFlag : true
            // lineChartData : listLineChart
        })
        showPrintPreviewModal('dashboard');
    }
    renderPrintButton = () => {
        return <div style={{
            position: 'absolute',
            right: '20px',
        }} className="button d-print-none" onClick={this.printAction} id="print-button">
            
            &nbsp;
            <span>Export as PDF. </span>&nbsp;&nbsp;
            <i className="fa fa-file-pdf-o" aria-hidden="true"/>
        </div>;
    }
    handlePrint = (childData) => {
        this.setState({printFlag: childData});
        this.renderContent()
    }

    render() {
        return (
            <BasePage sideButton={this.renderPrintButton()} pageTitle="Plant Information">
                {this.state.printFlag === true ?
                <PrintPreviewModal
                    id="dashboard"
                    ref="printPreview"
                    onClickYes={this.exportChart}
                    style={{ boxShadow: 'none' }}
                    fileName="maintenance"
                    className="dashboard"
                    onSelectPrint={this.handlePrint}
                    // onSelectPrintStyle={this.handlePrintStyle}
                >
                    {this.renderContent()}
                </PrintPreviewModal> : ''}

                <Async
                    identifier="PlantDetailsPage"
                    promise={this.promise}
                    loader={<Loader type="async"/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    const instance = PlantModel.get(ownProps.match.params.id); 
    const plantId = ownProps.match.params.plantId;
    const userId = state.login.get('UniqueId');
    const Access_Level = parseJwt(state.login.get('token')).Access_Level;
    return {
        userId, instance, plantId, id: ownProps.match.params.id, Access_Level
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl as any));

const DisplayDetails = ({label, value}) => {
    return <div className="details-row">
        <Label labelText={label}/>
        <Value value={value}/>
    </div>;
};

const Label = ({labelText}) => {
    return <div className="details-label">
        {labelText}:
    </div>;
};

const Value = ({value}) => {
    return <div className="details-value">
        {value}
    </div>;
};
// detailsPage.tsx