import './selectDropdown.scss';

import * as React from 'react';
import Select from 'react-select';
import * as _ from 'lodash';
import {IHistory} from '../../interfaces';
import {ISelectOptions} from '../../interfaces';

export interface ISelectDropdownProps {
    id?: string;
    menuItems: ISelectOptions[];
    onChange: Function;
    isMultiSelect?: boolean;
    className?: string;
    defaultValue?: any;
    placeholder?: string;
    title?: string;
    history?: IHistory;
}

export interface ISelectDropdownState {
    selectedValue: any;
}

export class SelectDropdown extends React.PureComponent<ISelectDropdownProps, ISelectDropdownState> {
    constructor(props: ISelectDropdownProps) {
        super(props);
        this.state = {selectedValue: this.attachValueObjects(props.defaultValue) || null};
    }

    componentWillReceiveProps(nextProps: ISelectDropdownProps) {
        this.setState({
            selectedValue: this.attachValueObjects(nextProps.defaultValue || null)
        });
    }

    attachValueObjects = (value) => {
        if (!value){
            return
        }
        if (!this.props.isMultiSelect) {

            setTimeout(() => {
                let valueToSingleSelect: any = {}
                valueToSingleSelect = this.props.menuItems.find((obj: ISelectOptions) => {
                    return obj.value === value
                });
                this.setState({selectedValue: valueToSingleSelect})
                return valueToSingleSelect || {}
            }, 800);

            let valueToSingleSelect: any = {}
            valueToSingleSelect = this.props.menuItems.find((obj: ISelectOptions) => {
                return obj.value === value
            });
            this.setState({selectedValue: valueToSingleSelect})
            return valueToSingleSelect || {}
        } else {
            const valueToMultiSelect: Array<any> = [];
            _.forEach(value, (v) => {
                const item = _.find(this.props.menuItems, {value: v})
                if (item?.value) {
                    valueToMultiSelect.push(item)
                }
            })
            return valueToMultiSelect
        }
    }

    handleChange = (obj) => {
        if (this.props.isMultiSelect) {
            const selectedValue: any = this.state.selectedValue;
            const newValue = selectedValue?.push(obj)
            this.setState({selectedValue: newValue});
            this.props.onChange(_.map(obj, 'value'));
        } else {
            this.setState({selectedValue: obj});
            this.props.onChange(obj.value);
        }
    }

    render() {
        return <div className={this.props.className || 'select-dropdown'}>
            {/* <div className='selDropdown-title'><h6>Region</h6></div> */}
            <div style={{textAlign: 'center'}} className="filter-title">{this.props.title || ''}</div>

            <Select
                id={this.props.id}
                className=""
                isMulti={this.props.isMultiSelect || false}
                value={this.state.selectedValue}
                onChange={this.handleChange}
                options={this.props.menuItems}
                placeholder={this.props.placeholder}
                clearable={false}
                styles={dropdownStyles}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#a4bf43',
                        primary: '#a4bf43',
                    },
                })}
            />
        </div>;
    }
}

export const dropdownStyles = {
    container: (provided) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        flexGrow: 1,
        margin: 0,
        minWidth: 150,
        width: '100%',
        border: '2px solid #a4bf43',
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
        borderRadius: '30px'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999999999,
        backgroundColor: 'black'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#ffffff',
        backgroundColor: '#a4bf43'
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#ffffff',
    })
}
