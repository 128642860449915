import * as React from 'react';

export interface ICustomToolTipProps {
    eventObject: {
        payload: {
            name: string;
            value: string;
        }[]
        label: string;
    };
}

export class CustomToolTip extends React.PureComponent<ICustomToolTipProps, {}> {
    constructor(props: ICustomToolTipProps) {
        super(props);
    }

    render() {
        const {eventObject: e} = this.props;
        if (e && e.payload && e.payload.length > 0) {
            return <div style={tootTip}>
                <div>{e.label}</div>
                {e.payload.map((payloadData, index) => {
                    return <div key={index}>
                        {payloadData.name}:{payloadData.value}
                      {/*  <div>
                            < br/>
                            Click < br/> To View < br/>{payloadData.name} Plants
                        </div>*/}
                    </div>;
                })}
            </div>;
        }
        return <div></div> /*<div style={tootTip}>
            Click < br/> To View < br/>Incomplete Plants
        </div>*/;
    }
}

const tootTip: React.CSSProperties = {
    color: 'gray',
    textAlign: 'center',
    backgroundColor: 'white',
    border: '1px solid gray',
    padding: '5px',
    zIndex: 99999
};
