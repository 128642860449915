import * as React from 'react';
import {connect} from 'react-redux';

export interface IAlertProps {
    id?: string;
    alertText?: string;
    alertType?: string;
    alertID?: string;
    className?: any;
}

export class AlertImpl extends React.PureComponent<IAlertProps, {}> {

    render() {
        const {alertID, id, alertText, alertType} = this.props;
        if (alertID === id) {
            return <div style={alertType === 'success' ? successAlert : dangerAlert}>
                {alertType === 'success' ? <i className="fa fa-check-square" style={{fontSize: '16px'}}></i>
                    : <i className="fa fa-exclamation-circle" style={{fontSize: '16px'}}></i>
                }
                &nbsp;
                {alertText}
            </div>;
        }
        return <div></div>;
    }
}

export function mapStateToProps(state) {
    return {
        alertText: state.alert.get('alertText'),
        alertType: state.alert.get('alertType'),
        alertID: state.alert.get('alertID')
    };
}

export const Alert = connect< IAlertProps, any, any>(mapStateToProps)(AlertImpl as any);

const successAlert: React.CSSProperties = {
    color: '#a4bf43',
    textAlign: 'center',
    padding: '10px 0px',
    fontSize: '16px'
};

const dangerAlert: React.CSSProperties = {
    color: 'red',
    textAlign: 'center',
    padding: '10px 0px',
    fontSize: '16px'
};
