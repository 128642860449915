import {combineForms} from 'react-redux-form';

const loginForm = {
    UserId: '',
    Password: ''
};

const passwordResetLinkForm = {
    userId: '',
};

const plantForm = {
    plantId: '',
    plantRegion: '',
    plantName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    facilityType: '',
    permitType: [],
    outfalls: {},
    coords: {},
    inspections: {}
};

const updatePlantForm = {
    plantRegion: '',
    plantName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    facilityType: '',
    permitType: [],
    outfalls: {},
    coords: {},
    inspections: {},
};

const userForm = {
    UserId: '',
    Password: '',
    First_Name: '',
    Last_Name: '',
    Email: '',
    Access_Level: '',
    orgId: '',
    Plants: [],
    Regions: [],
    Job_Title: '',
    Job_Desc: '',
};

const QTRVisualForm = {};

const updateUserForm = {
    First_Name: '',
    Middle_Name: '',
    Last_Name: '',
    Email: '',
    Access_Level: '',
    orgId: '',
    Plants: [],
    Regions: [],
    Job_Title: '',
    Job_Desc: '',
};

const forgotPasswordForm = {
    tempPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const orgForm = {
    orgName: ''
};

const orgFilterForm = {
    orgId: ''
};

function constructObject(length) {
    let obj = {};
    for (let index = 1; index <= length; index++) {
        Object.assign(obj, {[index]: null});
    }
    return obj;
}

const supportForm = {
    comments: ''
};

const goldenRules = {
    // rule: [],
    // priority: [],
}


const referenceForm = {
    bannerImage: '',
};

export const forms = combineForms({
    loginForm, passwordResetLinkForm, forgotPasswordForm, plantForm, userForm, updateUserForm, monthlyForms: {}, updatePlantForm, orgForm, orgFilterForm,
    dailyForm: constructObject(17), monthlySWForm: constructObject(59), monthlySPCCForm: constructObject(28),
    monthlyUSTSpill: constructObject(6), monthlySpillLogs: constructObject(4), monthlyUSTRect: constructObject(8),
    dailyPMForm: constructObject(54), QTRVisualForm: constructObject(24), shopInspectionForm: constructObject(27), commonInspectionForm: {}, supportForm,
    goldenRules, referenceForm
}, 'forms');
