import '../Maintenance/maintenance.scss';

import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';

import {UserModel} from '../../model/UserModel';
import {getAllUsers} from '../../services/userService';
import {BasePage} from '../reusableComponents/BasePage';
import {ListPage} from '../reusableComponents/ListPage';
import {UserListItem} from './UserListItem';
import {resetModels} from '../../utils/generalUtils';
import { Pagination } from '../reusableComponents/Pagination';
const queryString = require('query-string');

export interface IUserListProps {
    userInstances: UserModel[];
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
}

export interface IUserListState {
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class UserListImpl extends React.PureComponent<IUserListProps, IUserListState> {
    static defaultProps: IUserListProps;

    constructor(props: IUserListProps) {
        super(props);
        resetModels();
        let search = 'None';
        if (this.props.location.search) { 
            search = queryString.parse(this.props.location.search).search;
        }
        this.state = {
            isAsc: true,
            sortIndex: 'UserId',
            curPageNo: 1,
            searchText: search,
            totCount:0  
        };
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers(this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText);
        }
    }

    renderAddUserButton = () => {
        return <div className="add-button" onClick={() => this.props.history?.push('/user/create')}>
            Add User <i className="fa fa-plus-square" aria-hidden="true"/>
        </div>;
    }

    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText ? this.state.curPageNo : 1,
            searchText: searchText|| 'None',
        })
        const queryString = `?search=${this.state.searchText}`;

        await getAllUsers(this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken);
        this.props.history?.push(`/users${queryString}`);
        
    }
    handlePagination = async () => {
        let curPageNo = this.state.curPageNo || '1';
        await getAllUsers(this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken);
    }

    render() {

        const TotalCount = this.props.userInstances.map(user => user.props.TotalCount);
        let pageno = Math.ceil(TotalCount[0]/20 );

        return (<BasePage pageTitle="Users" className="list-page-base" sideButton={this.renderAddUserButton()}>
                <ListPage
                    // @ts-ignore
                    identifier="UsersList"
                    instances={this.props.userInstances}
                    listItemComponent={UserListItem}
                    searchBy={['UserId', 'Access_Level', 'First_Name', 'Last_Name', 'Active','Email']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#','User ID', 'First Name', 'Last Name', 'Access Level', 'Status']}
                    listItemFields={['', 'UserId', 'First_Name', 'Last_Name', 'Access_Level', 'Active']}
                    onHandleSort={this.handleSort}
                    promise={this.promise}
                    cols={[1, 2, 3, 2, 2, 2]}
                    searchPlaceHolder="Enter user id, first name, last name, access level or status to search..."
                    emptyListMessage="No Users found"
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }

}

export function mapStateToProps(state) {
    let userInstances = UserModel.list();
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');

    userInstances = userInstances.filter((model) => {
        return model.props.id !== state.login.get('UniqueId');
    });
    // userInstances(state.login.get('UniqueId')).$delete();
    return {
        userInstances, axiosPreviousToken
    };
}

export const UserList = withRouter(connect< IUserListProps, any, any>(mapStateToProps)(UserListImpl as any));
