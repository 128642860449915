import * as React from 'react';
import * as _ from 'lodash';
import {SelectDropdown} from './SelectDropdown';
import {frameSelectOptions} from '../../utils/generalUtils';
import {getUserData} from '../../services/loginService';
import {getRegionsByOrg} from '../../services/orgService';

export interface IRegionFilterProps {
    id?: string;
    onChange: (filterType, value) => void;
    className?: string;
    title?: string;
    selectedValue?: string;
    regionList?: [];
}

export interface IRegionFilterState {
    availableOptions: Array<any>;
    selectedValue: string | undefined;
}

export class RegionFilter extends React.PureComponent<IRegionFilterProps, IRegionFilterState> {
    loggedInUserInfo = getUserData();

    constructor(props: IRegionFilterProps) {
        super(props);
        this.state = {selectedValue: props.selectedValue, availableOptions: [{label: 'All', value: 'All'}]};
        // if (this.loggedInUserInfo.Access_Level !== 'L1') {
        setTimeout(() => {
            this.getRegionsList();
        }, 500);
        //  }
    }

    getRegionsList = async () => {
        const { regionList } = this.props;
        let availableOptions = [{label: 'All', value: 'All'}];
        if (this.loggedInUserInfo.Access_Level === 'L3' || this.loggedInUserInfo.Access_Level === 'L4') {
            availableOptions = _.concat(availableOptions, frameSelectOptions(this.loggedInUserInfo.Regions, 'regionId', 'regionName'));
            this.setState({
                availableOptions
            });
        } else if (this.loggedInUserInfo.orgId) {
            /* await getRegionsByOrg(this.loggedInUserInfo.orgId).then(res => {
                availableOptions = _.concat(availableOptions, frameSelectOptions(res, 'regionId', 'regionName'));
                this.setState({
                    availableOptions
                });
            }); */
            if(regionList?.length) { 
                regionList.forEach((obj: any) => {
                    availableOptions.push({label: obj.props.regionName, value: obj.props.regionId});
                });
                this.setState({
                    availableOptions
                });
            }
        }
    }

    componentWillReceiveProps(nextProps: IRegionFilterProps) {
        // if (nextProps.selectedValue !== this.state.selectedValue) {
        this.setState({
            selectedValue: nextProps.selectedValue
        });
        //    }
    }

    handleChange = (e) => {
        this.setState({
            selectedValue: e
        });
        this.props.onChange('regionFilter', e);
    }

    render() {
        return (
            <div className={this.props.className || `region-filter-inline`} key="Region">
            <div style={{textAlign: 'center'}} className="filter-title">{this.props.title || ''}</div>

                <SelectDropdown
                    id={this.props.id}
                    menuItems={this.state.availableOptions}
                    onChange={this.handleChange}
                    placeholder="Region"
                    defaultValue={this.props.selectedValue}
                />
            </div>
        );
    }
}
