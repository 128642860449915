import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory} from '../../../interfaces';
import {PlantModel} from '../../../model/PlantModel';

export interface IListItemProps {
    instance?: PlantModel;
    index?: number;
    history?: IHistory;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    static defaultProps: IListItemProps;

    render() {
        const {instance, index, history} = this.props;
        return <Row className="form-list-item">
            <Col
                className="clickable-list-item serial-no"
                sm={1} xs={1}>
                {/* {index} */}
                {instance?.props.pagedataCnt}

            </Col>
            <Col
                className="clickable-list-item" xs={2}
                sm={2}>
                {instance?.props.plantId}
            </Col>
            <Col
                className="clickable-list-item" xs={3}
                sm={3}>
                {instance?.props.plantName}
            </Col>
            <Col
                className="clickable-list-item" xs={2}
                sm={2}>
                {instance?.props.plantRegionName}
            </Col>
            <Col
                className="clickable-list-item" xs={2}
                sm={2}>
                {instance?.props.facilityType}
            </Col>
            <Col
                className="clickable-list-item" xs={2}
                sm={2}>
                {instance?.props.state}
            </Col>
        </Row>;
    }
}

export function mapStateToProps(state) {
    return { 
    };
}

export const ListItem = withRouter(connect< IListItemProps, any, any>(mapStateToProps)(ListItemImpl as any));
