import * as React from 'react'; 
import {isImageLoading} from '../../../actions/miscellaneousActions';
import {compressBase64Image, getBase64} from '../../../utils/generalUtils';
import {Async} from '../Async'; 
// const errorImage = require('../../../images/errorImage.png');
declare const require: any;
//const loaderImage = require('./imgLoader.gif');
const loaderImage = require('../../../images/loader.gif');

export interface IImgProps {
    className?: string;
    src: string;
    width?: string;
    height?: string;
    style?: React.CSSProperties;
    onClick?: (imageURL: string) => void;
}

export interface IImgState {
    imageSrc: string;
}

export class Img extends React.PureComponent<IImgProps, IImgState> {
    constructor(props) {
        super(props);
        this.state = {imageSrc: ''};
    }

    promise = async () => {
        const imgData = await getBase64(this.props.src);
        const imageBase64 = 'data:image/png;base64,' + imgData;
        compressBase64Image(imageBase64, (compressedImage) => {
            isImageLoading(false);
            this.setState({
                imageSrc: compressedImage
            });
        });
        return null;
    }

    render() {
        const {props: {className, height, width, src, style, onClick}, state: {imageSrc}} = this; 
        return <Async
            identifier="IMG"
            promise={this.promise}
            error={<img alt={'error-img'}
                width={width}
                height={height}
                style={{boxShadow: 'none', pointerEvents: 'none'}}
                src={'errorImage'}
            />}
            loader={
                <div
                    onClick={() => onClick && onClick(src)}
                    className="display-image-wrapper"
                >
                    <img alt={'Loading image..'} width={width} style={style} height={height} className={className} src={loaderImage}/>
                </div>}
            content={
                <div
                    onClick={() => {
                        onClick && onClick(src)
                    }}
                    className="display-image-wrapper"
                >
                    <img alt={'EnviroConnect'} width={width} style={style} height={height} className={className} src={imageSrc}/>
                </div>
            }
        />;
    }
}
