import './imageUpload.scss';

import * as React from 'react';
import * as _ from 'lodash';

export interface IImageUploadProps {
    label: string;
    buttonLabel?: string;
    type?: 'NeedSinglePhoto';
    multi?: boolean;
    imagesToDelete?: Array<any>;
    imagesList?: Array<any>;
}

export interface IImageUploadState {
    images: string[];
}

export class ImageUpload extends React.Component<IImageUploadProps, IImageUploadState> {
    constructor(props: IImageUploadProps) {
        super(props);
        const imagesToPush: Array<any> = [];
        _.forEach(props.imagesList, (image) => {
            imagesToPush.push(image.imageURL);
        });
        this.state = {images: imagesToPush};
    }

    get value() {
        return this.state.images;
    }

    handleImageDelete = (index) => {
        let imagesArray = this.state.images.filter((image, i) => i !== index);
        this.setState({
            images: imagesArray
        });
    }

    renderImages = () => {
        const {images} = this.state;
        return images.map((image: any, index) => {
            return <div key={index} className="image">
                <i onClick={() => this.handleImageDelete(index)} className="fa fa-close"/>
                <img src={image} width="100px" alt="imageFile" height="100px"/>
            </div>;
        });
    }

    handleImageUpload = (event) => {
        const files = event.target.files;
        let imagesArray = this.state.images.length > 0 ? this.state.images : [];
        let con = 0;
        const self = this;

        function readFile(e) {
            const name = new Date().getTime().toString();
            const imageBase64 = e.target.result;
            imagesArray.push(imageBase64);
            self.setState({
                images: imagesArray
            });
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileReader = new FileReader();
            fileReader.onload = readFile;
            fileReader.readAsDataURL(file);
        }
    }

    renderContent = () => {
        if (this.props.type === 'NeedSinglePhoto') {
            return this.state.images.length === 0 ? <div className="image-upload" ref="images">
                <div className="image-container-label">{this.props.label}</div>
                <label htmlFor="imageFile" title="Click to select images">
                    {this.props.buttonLabel || 'Select Images'}
                    <input multiple={this.props.multi} accept="image/gif, image/jpeg, image/png"
                           type="file" id="imageFile" onChange={this.handleImageUpload}/>
                </label>
                <div className="display-image">
                    {this.renderImages()}
                </div>
            </div> : <div className="image-upload" ref="images">
                <div className="image-container-label">Delete the following image to upload new one...</div>
                <div className="display-image">
                    {this.renderImages()}
                </div>
            </div>;
        } else {
            return <div className="image-upload" ref="images">
                <div className="image-container-label">{this.props.label}</div>
                <label htmlFor="imageFile" title="Click to select images">
                    {this.props.buttonLabel || 'Select Images'}
                    <input multiple={this.props.multi} accept="image/gif, image/jpeg, image/png"
                           type="file" id="imageFile" onChange={this.handleImageUpload}/>
                </label>
                <div className="display-image">
                    {this.renderImages()}
                </div>
            </div>;
        }
    }

    render() {
        return this.renderContent();
    }
}
