import './signatureInput.scss';

import * as React from 'react';
import SignaturePad from 'react-signature-pad';

import {base64ToFile} from '../../../utils/generalUtils';

export interface ISignatureInputProps {
    onSave: Function;
}

export interface ISignatureInputState {
    signature: string | null;
}

export class SignatureInput extends React.PureComponent<ISignatureInputProps, ISignatureInputState> {
    refs: {
        acceptorSignature: SignaturePad;
    };

    constructor(props: ISignatureInputProps) {
        super(props);
        this.state = {signature: null};
    }

    get value() {
        return this.state.signature;
    }

    saveSignature = async () => {
        const acceptorSignature = this
            .refs
            .acceptorSignature
            .toDataURL();
        this.props.onSave(acceptorSignature);
        const file = await base64ToFile(acceptorSignature, 'user-signature.png');
        this.setState({
            signature: acceptorSignature,
        });
    }

    render() {
        return <div className="signature-input" style={{paddingBottom: '35px'}}>
            <div className="signature-container">
                <SignaturePad
                    clearOnResize={false}
                    key="pad"
                    canvasProps={{width: 327, height: 150, className: 'sigCanvas'}}
                    ref="acceptorSignature"/>
                <div className="buttons-container">
                    <button
                        type="button"
                        onClick={() => this.refs.acceptorSignature.clear()}
                        className="clear-button"
                    >
                        Clear
                    </button>
                    <button className="save-button" onClick={this.saveSignature}>
                        Save
                    </button>
                </div>
            </div>
        </div>;
    }
}
