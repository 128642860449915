import './toggleSwitch.scss';

import * as React from 'react';

export interface IToggleSwitchProps {
    buttonLabel: string[];
    activeIndexOnLoad: number;
    onChangeTab: (index: number, formType: string) => void;
}

export interface IToggleSwitchState {
    isActive: number;
}

export class ToggleSwitch extends React.Component<IToggleSwitchProps, IToggleSwitchState> {
    constructor(props: IToggleSwitchProps) {
        super(props);
        this.state = {isActive: props.activeIndexOnLoad};
    }

    handleActiveButton = (index: number, formType: string) => {
        this.setState({
            isActive: index
        });
        this.props.onChangeTab(index, formType);
    }

    render() {
        const {buttonLabel} = this.props;
        return (
            <div className="toggle-container">
                {buttonLabel.map(
                    (label, index) => <ToggleButton
                        isActive={this.state.isActive}
                        key={index}
                        index={index}
                        onClick={() => this.handleActiveButton(index, buttonLabel[index])}
                        label={label}
                    />
                )}
            </div>
        );
    }
}

const ToggleButton = ({label, onClick, isActive, index}) => {
    return <button onClick={onClick} className={`toggle-button ${isActive === index ? 'active' : ''}`}>{label}</button>;
};
