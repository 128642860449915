import * as React from 'react';
import {Cell, Legend, Pie, PieChart as RPieChart, Tooltip} from 'recharts';

import {ResponsiveContainer} from './ResponsiveContainer';

export interface IPieChartProps {
    data: Object[];
    dataKey: string | string[];
    className?: string;
    legends?: boolean;
    customTooltip?: JSX.Element | Function;

    onClick?(data): void;
}

export class PieChart extends React.PureComponent<IPieChartProps, {}> {

    render() {
        const {data, dataKey, onClick, customTooltip} = this.props;
        const COLORS = ['#A4BF43', '#ABABAB'];
        return (
            <ResponsiveContainer className={this.props.className}>
                <RPieChart>
                    <Pie
                        data={data}
                        // @ts-ignore
                        dataKey={dataKey}
                        innerRadius={70}
                        outerRadius={100}
                        fill="#8884d8"
                        onClick={e => onClick ? onClick(e) : null}
                        paddingAngle={0}
                    >
                        {
                            data.map((entry, index) => <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                            />)
                        }
                    </Pie>
                    {// @ts-ignore
                        <Tooltip content={customTooltip}/>
                    }
                    {this.props.legends ? <Legend/> : ''}
                </RPieChart>
            </ResponsiveContainer>
        );
    }
}
