import {format} from 'date-fns';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {IHistory} from '../../../interfaces';
import {DailyFormsModel} from '../../../model/DailyFormModel';
import {MonthlyFormModel} from '../../../model/MonthlyFormModel';
import {QuarterlyFormModel} from '../../../model/QuarterlyFormModel';
import {getWaterDailyFormAnswers, markAsComplete} from '../../../services/dailyFormService';
import {toDataUrl} from '../../../utils/generalUtils';
import {Async} from '../Async';
import {BasePage} from '../BasePage';
import {ErrorPage} from '../ErrorPage';
import {Gallery} from '../ImageGallery';
import {Img} from '../ImageGallery/Img';
import {Loader} from '../Loader';
import {MarkAsCompletedPopOver} from '../MarkAsCompletePopOver';
import './detailsPageWrapper.scss';
import './image-gallery.scss';
import {getFormDetailsByType} from '../../../services/dashboardService';
import {getMonthlyFormDetails} from '../../../services/monthlyFormService';
import {getQuarterlyFormDetails} from '../../../services/quarterlyFormService';
import {startPromise} from '../../../actions/loadingActions';
import needsWorks from '../../../images/needsWork.svg';
import loadingImage from './signLoading.gif';

export interface IDetailsPageProps {
    id?: string;
    PermitType?: string;
    promise?: (...args: any) => any;
    history?: IHistory;
    instance?: DailyFormsModel | MonthlyFormModel | QuarterlyFormModel;
    pageTitle?: string;
    backTo?: string;
    type?: string;
    formType?: string;
}

export interface IDetailsPageState {
    isLoading: boolean;
    signBase64: string;
    styles: React.CSSProperties;
    quesNum: string | number | undefined | null;
}

export class DetailsPageImpl extends React.Component<IDetailsPageProps, IDetailsPageState> {

    static defaultProps: IDetailsPageProps;

     refs: {
         popOver: MarkAsCompletedPopOver;
     };

    constructor(props: IDetailsPageProps) {
        super(props);
        this.state = {
            signBase64: '', quesNum: null,
            isLoading: false, styles: {}
        };
    }

    handleUpdate = async (comments: string, date: any) => {
        const {formType, instance: {props: {id, type}, history}}: any = this.props;
        const {quesNum} = this.state;
        this.setState({
            isLoading: true
        });
        await markAsComplete(comments, id, type, quesNum!, format(date, 'MM-dd-yyyy'), this, history);
        this.setState({
            isLoading: false
        });

        switch (formType) {
            case 'daily':
                await getWaterDailyFormAnswers(id);
                break;

            case 'monthly':
                await getMonthlyFormDetails(id);
                break;

            case 'quarterly':
                await getQuarterlyFormDetails(id);
                break;

            case 'flaggedforms':
                await getFormDetailsByType(id);
                break;

            default:
                break;
        }
    }

    renderMarkAsCompleteButton = () => {
        if (!this.props.instance || !this.props.instance.props.questions) {
            return <span/>;
        }
        return <div style={{
            position: 'absolute',
            right: '20px',
        }}
                    className="button"
                    onClick={this.handlePrint}
                    id="print-button">
            <i className="fa fa-print" aria-hidden="true"/>
            &nbsp;
            <span> Print</span>
        </div>;
        // }
    }

    handlePrint = () => {
        document.getElementById('print-button')!.style.display = 'none';
        document.getElementById('back-button')!.style.display = 'none';
        document.getElementById('details-page')!.className = 'details-page-container-on-print';
        if (document.getElementById('overlay')) {
            document.getElementById('overlay')!.style.display = 'none';
        }
        let css = '@page { size: portrait; }',
            head = document.head || document.getElementsByTagName('head')[0],
            style: any = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';

        style.appendChild(document.createTextNode(css));
        head.appendChild(style);

        window.print();
        document.getElementById('back-button')!.style.display = 'block';
        document.getElementById('details-page')!.className = 'details-page-container';
        document.getElementById('print-button')!.style.display = 'block';
        if (document.getElementById('overlay')) {
            document.getElementById('overlay')!.style.display = 'block';
        }
    }

    renderImages = () => {
        const {instance}: any = this.props;
        if (instance.props.ImageUrls.length <= 0) {
            return <img className="no-image" alt={'no-img'} src={'noImage'}/>;
        }
        return instance.props.ImageUrls.map((imageData: any, index: number) => {
            return <Img
                onClick={() => showCarousel(imageData)}
                key={index}
                style={{padding: '5px'}}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });

    }

    renderDeclaration = (formType: string) => {
        const {instance}: any = this.props;

        return <PerfectScrollbar>
            <div className="right">
                <div className="images-container">
                    <div className="image-label">Attached Images:</div>
                    <PerfectScrollbar>
                        <div className="attached-images">
                            {instance.props.ImageUrls.length > 0 ? instance.props.ImageUrls.map((url: any, index: number) => {
                                return <div
                                    key={index}
                                    title="Click to view in full screen mode."
                                    className="image"
                                >
                                    <Img src={url} onClick={() => showCarousel(url)}/>
                                </div>;
                            }) : <div
                                style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                                No Image Attached to this form.
                            </div>
                            }
                        </div>
                    </PerfectScrollbar>
                </div>
                {formType === 'MonthlySW' || formType === 'ShopInspection' ?
                    <div>
                        <div style={{paddingTop: '15px', paddingBottom: '15px', fontSize: '12px'}}>
                            I certify under penalty of law that this document and all attachments were
                            prepared under my direction or supervision in accordance with a system designed to
                            assure that qualified personnel properly gathered and evaluated the information
                            submitted. Based on my inquiry of the person or persons who manage the system, or those
                            persons directly responsible for gathering the information, the information submitted
                            is, to the best of my knowledge and belief, true, accurate, and complete. I am
                            aware that there are significant penalties for submitting false information,
                            including the possibility of fine and imprisonment for knowing violations.
                        </div>

                        <div className="uploaded-signature-container">
                            <div className="image-label">Attached Signature:</div>
                            {instance.props.SignatureUrls && instance.props.SignatureUrls.length > 0 /* && imageExists(instance.props.SignatureUrls) */ ?
                                <div className="signature-image">
                                    <Async
                                        identifier="SignImage"
                                        promise={this.compressImage}
                                        loader={<img alt={'loading-img'} src={loadingImage}/>}
                                        content={<img alt={'img-content'} src={this.state.signBase64}/>}
                                        error={<img alt={'err-loading-img'} src={loadingImage}/>}
                                    />
                                </div> :
                                <div className="no-signature">No Signature Attached</div>
                            }
                        </div>
                    </div> : <div/>
                }
            </div>
        </PerfectScrollbar>;

    }

    compressImage = async () => {
        // const self = this;
        const {instance: {props: {SignatureUrls}}}: any = this.props;
        await toDataUrl(SignatureUrls[0], (imgData: any) => {
            this.setState({
                signBase64: imgData
            });
        });
    }
    handleFollowUpForm = (instance: any) => {
        const {history}: any = this.props
        history.push(`/reports/monthly/${instance.props.Associations[0]}`);
        startPromise('DetailsPageWrapper');
    }
    renderFormDetails = () => {
        const {instance, type}: any = this.props;
        let submittedByCount = 0;
        if (!instance || !instance.props.questions) {
            return <div/>;
        }
        return <div style={{padding: '20px'}} id="details-page" className="details-page-container">
            <PerfectScrollbar>
                <div className="form-details" id="formDetails">
                    {instance.props.questions.map((questionData: any, index: number) => {
                        if (questionData.question) {
                            if (questionData.question === 'Submitted By') {
                                ++submittedByCount;
                            }
                            if ((questionData.question === 'Submitted By' && submittedByCount === 2) || questionData.question === 'Date') {
                                return false;
                            }
                            if (questionData.question === 'Actions Taken') {
                                questionData.question = 'Actions Taken:';
                            }
                        }

                        return questionData ?
                            <div key={index} className="flagged-question-container">
                                {questionData.flaggedQuestion && !questionData.completed ?
                                    <MarkAsCompletedPopOver ref="popOver" onUpdate={this.handleUpdate}>
                                        <div
                                            className="mark-as-complete-col"
                                            title="Click to mark this question as Complete"
                                        >
                                            <i
                                                className="fa fa-flag incomplete-flag"
                                                onClick={() => this.setState({quesNum: questionData.quesNum})}
                                                aria-hidden="true">
                                            </i>
                                        </div>
                                    </MarkAsCompletedPopOver>
                                    : ''}
                                <div className="question-col">
                                    {!(questionData.answer && questionData.answer.indexOf('Needs work-') > -1) ? <Label
                                            icon={<i className="fa fa-check-square-o" aria-hidden="true">&nbsp;</i>}
                                            isFlagged={questionData.flaggedQuestion}
                                        >
                                            {questionData.question}
                                        </Label> :
                                        <Label
                                            icon={<img alt={'needs-work-img'} style={{height: '30px', width: '30px'}} src={needsWorks}/>}
                                            isFlagged=
                                                {questionData.answer && questionData.answer.includes('Needs work')}
                                        >
                                            &nbsp;{questionData.question}
                                        </Label>
                                    }

                                    <Value>{questionData.answer || 'Unanswered'}</Value>
                                    {(questionData.completed) ?
                                        [
                                            <Label
                                                icon={<i className="fa fa-check-circle" aria-hidden="true">&nbsp;</i>}
                                                key={1} isFlagged={1}>Completed By:
                                            </Label>,
                                            <Value key={2}>{questionData.completedBy || 'NA'}</Value>,
                                            <span key={6} style={{display: 'block', paddingBottom: '20px'}}/>,
                                            <Label
                                                icon={<i className="fa fa-comments" aria-hidden="true">&nbsp;</i>}
                                                key={4} isFlagged={1}>Follow Up Comments:
                                            </Label>,
                                            <Value key={5}>{questionData.followUpComments || 'NA'}</Value>,
                                            <span key={7} style={{display: 'block', paddingBottom: '20px'}}/>,
                                            <Label
                                                icon={<i className="fa fa-calendar" aria-hidden="true">&nbsp;</i>}
                                                key={8} isFlagged={1}>Date Completed:
                                            </Label>,
                                            <Value key={9}>{questionData.dateCompleted || 'NA'}</Value>,
                                            <span key={10} style={{display: 'block', paddingBottom: '20px'}}/>
                                        ]
                                        : ''}

                                </div>
                            </div> : '';
                    })}
                    {instance.props.Associations && instance.props.Associations.length > 0 ?
                        <div className="form-footer">
                            <button className="followUpForm-button"
                                    onClick={() => this.handleFollowUpForm(instance)}>
                                <i className="fa fa-file-text-o" aria-hidden="true"/>
                                &nbsp;    Show FollowUp Form
                            </button>
                        </div> : ''}
                </div>
            </PerfectScrollbar>
            {this.renderDeclaration(type)}
        </div>;
    }

    render() {
        const {pageTitle}: any = this.props;
        return (
            <BasePage sideButton={this.renderMarkAsCompleteButton()} pageTitle={pageTitle}>
                <Gallery images={this.props.instance ? this.props.instance.props.ImageUrls : []}/>
                <Async
                    identifier="DetailsPageWrapper"
                    promise={this.props.promise}
                    loader={<Loader type="async"/>}
                    error={<ErrorPage/>}
                    content={this.renderFormDetails()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    return state;
}

export const DetailsPageWrapper
    = withRouter((connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl as any)));

const Label = ({children, isFlagged, icon}: any) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}: any) => {
    return <div style={{fontSize: '18px'}}>{children}</div>;
};
