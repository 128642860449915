import './fileUpload.scss';

import * as React from 'react';
import * as _ from 'lodash';
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';


export interface IFileUploadProps {
    label: string;
    buttonLabel?: string;
    type?: 'NeedSinglePhoto';
    multi?: boolean;
    imagesToDelete?: Array<any>;
    imagesList?: Array<any>;
    fileList?: Array<any>;
    fileExtFunction?: any;

    // id: string;
    // multiple?: boolean;
    // style?: React.CSSProperties;
    // accept?: string;
    // model: string;
    // onChange?: (event: EventTarget) => void;
    // imagesArray?: string[];
    // defaultValue?: any;
    // // forms?: IForms | undefined;
    // displayUploadedFiles?: boolean;
    // restrictMultipleImage?: boolean;
    // uploadedImages?: string[];
    // noNeedAttachButton?: boolean | false;
    // emptyMessage?: string;
}

export interface IFileUploadState {
    images: string[];
    fileExt: string[];
    uploadedFiles: File[];
    uploadedFileNames: string[];
    oldFileNames: string[];
    deletedFileNames: string[];
    editFileNameIndex?: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[];   
    isActualCompletionDisabled: boolean; 
    actualCompletionDate: string; 
}

export class FileUpload extends React.Component<IFileUploadProps, IFileUploadState> {
    // invalidFile: number;
    constructor(props: IFileUploadProps) {
        super(props);
        const imagesToPush: Array<any> = [];
        const fileExtToPush: Array<any> = [];
        // let fileExtArray: Array<any> = [];
        _.forEach(props.imagesList, (image) => { 
            let fileDet = image.fileName.split('.');  
            const fileExtension = fileDet[fileDet.length - 1];
            // fileExtArray.push(fileDet[0] + '.' + fileExtension);

            imagesToPush.push(image.imageURL);
            fileExtToPush.push(fileDet[0] + '.' + fileExtension);
        });
        this.props.fileExtFunction(fileExtToPush)
        this.state = {images: imagesToPush,fileExt: fileExtToPush, uploadedFiles: [], uploadedFileNames: [], oldFileNames: [], deletedFileNames: [], isActualCompletionDisabled: true, actualCompletionDate: 'null'};
    }

    get value() {
        return this.state.images;
    }

    handleImageDelete = (index) => {
        let imagesArray = this.state.images.filter((image, i) => i !== index);
        let fileExtArray = this.state.fileExt.filter((image, i) => i !== index);

        this.setState({
            images: imagesArray,
            fileExt: fileExtArray
        });
    }

    renderImages = () => {
        const {images, fileExt} = this.state;

        // const fileSplit = fileName.split('.'); 
        // let imageThumb = '';
        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
        // switch(fileSplit[fileSplit.length-1].toLowerCase()) {
        //     case 'pdf': imageThumb = pdfThumb; break;
        //     case 'doc': case 'docx': imageThumb = wordThumb; break; 
        //     case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
        //     case 'txt': imageThumb = textThumb; break;
        //     case 'mp4': imageThumb = videoThumb; break; 
        //     case 'pptx': case 'ppt': imageThumb = pptThumb; break;
        //     case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ 
        //         if(oldFileNames.indexOf(fileName)!==-1) {
        //             imageThumb = S3_URL +'/attachments/' + fileName;  
        //         } else {
        //             let totalIndex = (oldFileNames.length-deletedFileNames.length);  
        //             let currentImageIndex = index - totalIndex; 
        //             if(uploadedFiles[currentImageIndex]) {
        //                 imageThumb = URL.createObjectURL(uploadedFiles[currentImageIndex]);
        //             }
        //         }
        //         break;
        //     default: imageThumb = ''; this.invalidFile++; break; 
        // } 
        if (images.length > 0) {
        return images.map((image: any, index) => {

            const answer_array = image.split(';base64,');
            if(fileExt[index] !== undefined) {
            const fileSplit = fileExt[index]?.split('.'); 

            let imageThumb = '';
            // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
            switch(fileSplit[fileSplit.length-1].toLowerCase()) {
                case 'pdf': imageThumb = pdfThumb; break;
                case 'doc': case 'docx': imageThumb = wordThumb; break; 
                case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                case 'txt': imageThumb = textThumb; break;
                case 'mp4': imageThumb = videoThumb; break; 
                case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = image; break;
                // default: imageThumb = ''; this.invalidFile++; break; 
                default: imageThumb = ''; break; 
            } 

            return <div key={index} className="image">
                <i onClick={() => this.handleImageDelete(index)} className="fa fa-close"/>
                {/* <img src={image} width="100px" alt="imageFile" height="100px"/> */}
                <img src={imageThumb} width="100px" alt="files" height="100px"/>

            </div>;
            }
        });
        }
    }
    invalidFile: number = 0;
    handleFileUpload = (event) => {

        const files = event.target.files;
        let imagesArray = this.state.images.length > 0 ? this.state.images : [];
        let fileExtArray = this.state.fileExt.length > 0 ? this.state.fileExt : [];
        let con = 0;
        const self = this;
        // if (files.length === 1) {
            this.invalidFile = 0;
        // }
        // let fileDet = files[this.invalidFile].name.split('.');  
        // const fileExtension = fileDet[fileDet.length - 1];
        // //fileNames.push(uploadedFileNames[i]);
        // fileNames.push(uploadedFiles[i].name.split('.')[0] + '~~' + uploadedFiles[i].lastModified + '.' + fileExtension);
    

        const readFile = (e) => {
            const name = new Date().getTime().toString();
            const imageBase64 = e.target.result;
            // this.invalidFile = 0;
            let fileDet = files[this.invalidFile].name.split('.');  
            const fileExtension = fileDet[fileDet.length - 1];
            
            imagesArray.push(imageBase64);
            fileExtArray.push(files[this.invalidFile].name.split('.')[0] + '~~' + files[this.invalidFile].lastModified + '.' + fileExtension);
            this.invalidFile = this.invalidFile + 1;
            self.setState({
                images: imagesArray,
                fileExt: fileExtArray
            });
            self.props.fileExtFunction(fileExtArray)
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileReader = new FileReader();
            fileReader.onload = readFile;
            fileReader.readAsDataURL(file);
        }
    }   
    
   /*  fileName: string = '';
    handleFileUpload = ({ target: { files } }: any) => {
        const { uploadedFiles, uploadedFileNames, oldFileNames, deletedFileNames } = this.state; 
        const { context: { formModel }, props: { model } } = this;
        this.invalidFile = 0;
        let filesData: File[] = [];
        let fileNames:Array<any> = [];
        if (oldFileNames.length > 0) { 
            for (let i = 0; i < oldFileNames.length; i++) { 
                let file = oldFileNames[i].split('/'); 
                if(deletedFileNames.indexOf(file[file.length-1])===-1 ) { 
                    fileNames.push(file[file.length-1]);
                }
            }
        }
        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) { 
                filesData.push(uploadedFiles[i]);
                let fileDet = uploadedFiles[i].name.split('.');  
                const fileExtension = fileDet[fileDet.length - 1];
                //fileNames.push(uploadedFileNames[i]);
                fileNames.push(uploadedFiles[i].name.split('.')[0] + '~~' + uploadedFiles[i].lastModified + '.' + fileExtension);
            }
        }
        for (let i = 0; i < files.length; i++) {
            if (uploadedFileNames.indexOf(files[i].name) < 0) {
                filesData.push(files[i]); 
                
                const name = uuid();  
                let filenameDet = files[i]['name'].split('.');
                let fileName = `${name}.${filenameDet[filenameDet.length-1].toLowerCase()}`; 
                let thiz = this; 
                fileNames.push(fileName); 
                const fileReader = new FileReader();
                //fileReader.onload = this.readFile;  
                fileReader.onload = function (e: any) {
                    dispatch(actions.push(`${formModel}${model}`, { name: fileName, file: e.target.result })); 
                }
                fileReader.readAsDataURL(files[i]); 
            }
        } 
        this.setState({
            uploadedFiles: filesData,
            uploadedFileNames: fileNames
        });
    }  */




    renderContent = () => {
        const FILESALLOWEDEXE = ['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/x-m4v', 'application/pdf,application/msword', '.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain,.doc', '.docx,.ppt', '.pptx,.txt', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        // if (this.props.type === 'NeedSinglePhoto') {
        //     return this.state.images.length === 0 ? <div className="image-upload" ref="images">
        //         <div className="image-container-label">{this.props.label}</div>
        //         <label htmlFor="imageFile" title="Click to select images">
        //             {this.props.buttonLabel || 'Select Images'}
        //             <input multiple={this.props.multi} accept="image/gif, image/jpeg, image/png"
        //                    type="file" id="imageFile" onChange={this.handleFileUpload}/>
        //         </label>
        //         <div className="display-image">
        //             {this.renderImages()}
        //         </div>
        //     </div> : <div className="image-upload" ref="images">
        //         <div className="image-container-label">Delete the following image to upload new one...</div>
        //         <div className="display-image">
        //             {this.renderImages()}
        //         </div>
        //     </div>;
        // } else {
            return <div className="image-upload" ref="images">
                <div className="image-container-label">{this.props.label}</div>
                {/* <label htmlFor="imageFile" title="Click to select images"> */}
                <label htmlFor="files" title="Click to select images">

                    {/* {this.props.buttonLabel || 'Select Images'} */}
                    {/* <input multiple={this.props.multi} accept="image/gif, image/jpeg, image/png"
                           type="file" id="imageFile" onChange={this.handleFileUpload}/> */}
                    <><input
                        id="files"
                        type="file"
                        multiple
                        style={fileUpload}
                        onChange={this.handleFileUpload} 
                        accept={FILESALLOWEDEXE.join(', ')} 
                    />
                    <label
                        htmlFor="files"
                        style={{ padding: '2px 15px', marginLeft: '15px', }}
                        className="upload-image">
                        Select File(s)
                    </label></>
                </label>
                <div className="display-image">
                    {this.renderImages()}
                </div>
            </div>;
        // }
    }

    render() {
        return this.renderContent();
    }
    
}
const fileUpload: React.CSSProperties = {
    height: '38px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    /* left: '35%', */
};

