import './monthPicker.scss';

import {format} from 'date-fns';
import * as React from 'react';
import {monthsAndYears} from '../../../constants/generals';
import moment from 'moment';

export interface IMonthPickerProps {
    years: number[] | string[];
    onClick: Function;
    defaultValue?: string;
    popover?: boolean;
    title?: string;
    className?: string;
}

export interface IMonthPickerState {
    year: number;
    showCalender: boolean;
    hideCalender: boolean;
    value: string;
    month: string;
}

export class MonthPicker extends React.Component<IMonthPickerProps, IMonthPickerState> {
    pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    };

    constructor(props: IMonthPickerProps) {
        super(props);
        // @ts-ignore
        /* let newValue: any = format(new Date(this.props.defaultValue.replace(/-/g, '/')), 'MMM yyyy');
        this.state = {
            value: newValue || format(new Date(), 'MMM yyyy'),
            month: newValue.split(' ')[0] || format(new Date(), 'mmm'),
            year: Number(newValue.split(' ')[1]) || new Date().getFullYear(), showCalender: false,
            hideCalender: true,
        }; */
        // let newValue:any = moment(this.props.defaultValue.replace(/-/g, '/')).format('MMM YYYY');
        let newValue: string | undefined = moment(this.props.defaultValue?.replace(/-/g, '/')).format('MMM YYYY');
        this.state = {
            value: newValue || moment().format('MMM YYYY'),
            month: newValue.split(' ')[0] || moment().format('MMM'),
            year: Number(newValue.split(' ')[1]) || moment().year(), showCalender: false,
            hideCalender: true,
        };

    }

   /* componentWillReceiveProps(nextProps) {
        const newValue = format(new Date(nextProps.defaultValue.replace(/-/g, '/')), 'MM yyyy');
        this.setState({
            value: newValue || format(new Date(), 'MM yyyy'),
            month: newValue.split(' ')[0] || format(new Date(), 'mmm'),
            year: Number(newValue.split(' ')[1]) || new Date().getFullYear(), showCalender: false,
        });
    }*/

    handleAMonthChange = (month, monthNum) => {
        // const today = new Date();
        const today = moment().toDate();
        if ((today.getFullYear() <= this.state.year) && (today.getMonth() < monthNum)) {
            return;
        }
        this.setState({
            value: `${month} ${this.state.year}`,
            showCalender: false,
            hideCalender: true,
            month
        });
        let selectedMonth = '';
        let selectedMonthForAPI = '';
        monthsAndYears.map((data) => {
            if (data.label === month.substring(0, 3)) {
                selectedMonth = `${this.state.year}-${data.value}-02`;
                selectedMonthForAPI = `${data.value}01${this.state.year}`;
            }
            return true;
        });
        if (isNaN(parseInt(month))) {
            const monthIndex = this.pickerLang.months.indexOf(month);
            if (monthIndex < 9) {
                month = '0' + (monthIndex + 1);

            } else {
                month = monthIndex + 1;
            }
        }
        this.props.onClick(`${month}-01-${this.state.year}`, selectedMonth, selectedMonthForAPI);
    }

    handleClickMonthBox = (e) => {
        this.setState({
            showCalender: !this.state.showCalender
        });
    }

    render() {
        let browserDetection;
        if (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            browserDetection = 'chrome';
        } else if (window.navigator.userAgent.toLowerCase().indexOf('trident') > -1) {
            browserDetection = 'ie';
        }
        return (<div
                id="container"
                className="edit month-picker"
                // onBlur={() => this.setState({ showCalender: false })}
            >
                {/* <div className='selDropdown-title'><h6>Region</h6></div> */}
            <div style={{textAlign: 'center'}} className="filter-title">{this.props.title || ''}</div>

                <div className="rmp-container rmp-table show">
                    <div className="rmp-cell">
                        <div className={this.state.showCalender ? 'rmp-popup light show' : 'rmp-popup-hide  light '}>
                            {browserDetection === 'ie' ? <div className="rmp-pad">
                                    <div><label>{this.state.year}</label>
                                        <i onMouseDown={() => {
                                            this.setState({
                                                year: this.state.year - 1,
                                            });
                                        }}

                                           className="rmp-tab rmp-btn prev "
                                           data-id="0"
                                        >
                                            &lt;
                                        </i>
                                        <i onMouseDown={() => {
                                            this.setState({
                                                year: this.state.year + 1,
                                                showCalender: true
                                            });
                                        }}

                                           className="rmp-tab rmp-btn next"
                                           data-id="0"
                                        >
                                            &gt;
                                        </i>
                                    </div>
                                    <ul>
                                        {this.pickerLang.months.map((month, index) => (
                                            <li
                                                onMouseDown={() => this.handleAMonthChange(month, index)}
                                                onClick={() => this.handleAMonthChange(month, index)}
                                                className={this.state.month === month ? 'rmp-btn active' : 'rmp-btn'}
                                                data-id={index}>
                                                {month}
                                            </li>
                                        ))}
                                    </ul>
                                </div> :
                                (<div className="rmp-pad">
                                    <div><label>{this.state.year}</label>
                                        <i onClick={() => {
                                            this.setState({year: this.state.year - 1});
                                        }}
                                           className="rmp-tab rmp-btn prev "
                                           data-id="0"
                                        >
                                            &lt;
                                        </i>
                                        <i onClick={() => {
                                            this.setState({year: this.state.year + 1});
                                        }}
                                           className="rmp-tab rmp-btn next disable"
                                           data-id="0"
                                        >
                                            &gt;
                                        </i>
                                    </div>
                                    <ul>
                                        <li
                                            onClick={() => this.handleAMonthChange('Jan', 0)}
                                            className={this.state.month === 'Jan' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:1">
                                            Jan
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Feb', 1)}
                                            className={this.state.month === 'Feb' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:2">
                                            Feb
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Mar', 2)}
                                            className={this.state.month === 'Mar' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:3">
                                            Mar
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Apr', 3)}
                                            className={this.state.month === 'Apr' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:4">
                                            Apr
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('May', 4)}
                                            className={this.state.month === 'May' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:5">
                                            May
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Jun', 5)}
                                            className={this.state.month === 'Jun' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:6">
                                            Jun
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Jul', 6)}
                                            className={this.state.month === 'Jul' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:7">
                                            Jul
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Aug', 7)}
                                            className={this.state.month === 'Aug' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:8">
                                            Aug
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Sep', 8)}
                                            className={this.state.month === 'Sep' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:9">
                                            Sep
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Oct', 9)}
                                            className={this.state.month === 'Oct' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:10"
                                        >Oct
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Nov', 10)}
                                            className={this.state.month === 'Nov' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:11"
                                        >Nov
                                        </li>
                                        <li
                                            onClick={() => this.handleAMonthChange('Dec', 11)}
                                            className={this.state.month === 'Dec' ? 'rmp-btn active' : 'rmp-btn'}
                                            data-id="0:12"
                                        >Dec
                                        </li>
                                    </ul>
                                </div>)
                            }
                        </div>
                    </div>
                    {browserDetection === 'ie' && this.props.popover ?
                        (<div className={this.props.className ? "box allfreqFlag": "box"}  onMouseDown={this.handleClickMonthBox}>
                            <label>{this.state.value}</label>
                        </div>)
                        : (<div className={this.props.className ? "box allfreqFlag": "box"} onMouseDown={this.handleClickMonthBox}>
                            <label>{this.state.value}</label>
                        </div>)}
                </div>
            </div>
        );
    }
}
