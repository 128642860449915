import {CircularProgress} from 'material-ui';
import * as React from 'react';

import {setLoading, setSuccess} from '../../actions/loadingActions';
import {sendReminder} from '../../services/dashboardService';
import {Async} from '../reusableComponents/Async';

export interface IPlantListItemProps {
    index: number;
    data: any;
    type: string;
    date: { qtr: string, year: string }; 
    onClick(plant: any): void;
}

export class PlantListItem extends React.PureComponent<IPlantListItemProps, {}> {

    static defaultProps: IPlantListItemProps;

    handleReminderClick = (plant: any) => {
        this.props.onClick(plant);
    }

    handleSendReminder = async () => {
        const {index, data, date} = this.props;
        setLoading(`ReminderButton${index}`);
        await sendReminder(data.plantUId, data.FormId, date, this);
        setSuccess(`ReminderButton${index}`);
    }

    render() {
        const {index, data} = this.props;
        return < div className="modal-list-item">
            <div onClick={() => this.handleReminderClick(data)} className="serial-no">{index + 1}</div>
            <div onClick={() => this.handleReminderClick(data)} className="plant-id">{data.plantId}</div>
            {this.props.type && this.props.type.includes('Incomplete')  ? <div className="reminder-button">
                    <button onClick={this.handleSendReminder}>
                        <Async
                            identifier={`ReminderButton${index}`}
                            promise={() => setSuccess(`ReminderButton${index}`)}
                            loader={<CircularProgress style={buttonStyle} color="white"/>}
                            content={<span>Send Reminder</span>}
                            error={<div>Please Try Again</div>}
                        />
                    </button>
                </div> :
                <div onClick={() => this.handleReminderClick(data)} className="reminder-button">
                    Completed
                </div>}
        </div>;
    }
}

const buttonStyle: React.CSSProperties = {
    width: '116.48px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center'
};
