import './form.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../../interfaces';
import moment from 'moment';
import { UserModel } from '../../../model/UserModel';

interface FormFooterProps {
    submitLabel?: string;
    cancelLabel?: string;
    onSubmit?: () => void;
    redirectTo?: string;
    onCancel?: () => void;
    history?: IHistory;
    pathName?: string;
}

export class FormFooterImpl extends React.PureComponent<FormFooterProps, {}> {
    
    userData = UserModel.getUserData();
    constructor(props: FormFooterProps | Readonly<FormFooterProps>) {
        super(props);
        this.state={};
    }

    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            const path = this.props?.pathName?.split('/');
            path?.pop();
            this.props?.history?.push(this.props.redirectTo || `/home`);
        }
    }

    render() { 
        return <div className="form-footer">
            <button type="button" onClick={this.handleCancel} className="cancel-button">
                {this.props.cancelLabel || 'Cancel'}
            </button>
            {/* { (this.userData.orgId==='705bf30b-082e-453b-8f51-0ada593e4ca2' && moment('06-30-2024').isBefore(moment(), 'day')) || this.props.redirectTo !='/inspections' ?
            <button className="submit-button" onClick={this.props.onSubmit}>
                {this.props.submitLabel || 'Submit'}
            </button> : <><br></br><p style={{marginTop: '10px'}}>You can't Add inspection forms until 06/30/2024. <br></br>Please <a target="_blank" href='https://argosems.com/'>click here</a> for old EnviroConnect App.</p></>} */
            <button className="submit-button" onClick={this.props.onSubmit}>
                {this.props.submitLabel || 'Submit'}
            </button>}
        </div>;
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        pathName: ownProps.location.pathname
    };
}
export const FormFooter: any = withRouter(connect(mapStateToProps)(FormFooterImpl as any));
