import * as React from 'react';
import {ResponsiveContainer as RResponsiveContainer} from 'recharts';

export interface IResponsiveContainerProps {
    children?: any;
    className?: string;
    height?: string | number;
}

export class ResponsiveContainer extends React.Component<IResponsiveContainerProps, {}> {

    render() {
        return (
            <div style={{
                paddingBottom: '250px',
                position: 'relative',
                height: this.props.height || 0,
            }} className={this.props.className + ' charts-responsive-container'}>
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    margin: 'auto',
                    height: '100%',
                    padding: 0
                }}
                     className="col col-xs-12 responsive-pie-chart"
                >
                    <RResponsiveContainer>
                        {this.props.children}
                    </RResponsiveContainer>
                </div>
            </div>
        );
    }
}
