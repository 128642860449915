import './customSignaturePad.scss';

import * as React from 'react';

import {AutoGenerateSignature} from '../AutoGenerateSignature';
import {SignatureInput} from '../SignatureInput';

export interface ICustomSignaturePadProps {
    userName: string;
}

export interface ICustomSignaturePadState {
    singManually: boolean;
    signSaved: boolean;
    signatureURL: string;
}

export class CustomSignaturePad extends React.PureComponent<ICustomSignaturePadProps, ICustomSignaturePadState> {
    singManuallyLabel = 'Sign Manually';
    singAutomaticallyLabel = 'Sign Automatically';

    constructor(props: ICustomSignaturePadProps) {
        super(props);
        this.state = {signSaved: false, singManually: false, signatureURL: ''};
    }

    get signature() {
        return this.state.signatureURL;
    }

    render() {
        const {state: {singManually}, singAutomaticallyLabel, singManuallyLabel} = this;
        return (
            < div style={{width: '50%;', 'paddingLeft': '28vw'}} className="custom-signature-pad">
                <div style={{fontSize: '20px', paddingBottom: '10px'}}>Signature:</div>
                {
                    this.state.signSaved ?
                        <img alt={'signature-board'}
                            className="signature-board"
                            src={this.state.signatureURL}
                        /> :
                        <div>
                            <div>
                                {this.state.singManually ?
                                    <SignatureInput
                                        ref="manualSignature"
                                        onSave={(dataURL) => this.setState({signSaved: true, signatureURL: dataURL})}
                                    /> :
                                    <AutoGenerateSignature fontSize="2vw"
                                        ref="generatedsignature"
                                        name={this.props.userName}
                                        onSave={(dataURL) => this.setState({signSaved: true, signatureURL: dataURL})}
                                    />}
                            </div>
                            <div className="change-type">
                                {this.state.signSaved ? '' :
                                    <button
                                        type="button"
                                        onClick={() => this.setState({singManually: !this.state.singManually})}
                                    >
                                        {singManually ? singAutomaticallyLabel : singManuallyLabel}
                                    </button>}
                            </div>
                        </div>
                }
            </div>
        );
    }
}
