import {
    EMPTY_PLANT_OPTIONS,
    HIDE_IMAGE,
    IS_IMAGE_LOADING,
    REMOVE_PLANT_OPTIONS,
    SAVE_ACTIVITIES_MENU_ITEMS,
    SAVE_DROPDOWN_FILTER,
    SAVE_PLANT_OPTIONS,
    SHOW_IMAGE,
    SAVE_REFERENCE_ITEMS,
    SAVE_AXIOS_PREVIOUS_TOKEN
} from '../constants/actions';
import {dispatch} from '../utils/generalUtils';
import {ISelectOptions} from '../interfaces';

export function savePlantOptions(plantOptions) {
    return dispatch({
        type: SAVE_PLANT_OPTIONS,
        plantOptions
    });
}

export function removePlantOptions(region) {
    return dispatch({
        type: REMOVE_PLANT_OPTIONS,
        region
    });
}

export function emptyPlantOptions() {
    return dispatch({
        type: EMPTY_PLANT_OPTIONS,
    });
}

export function showCarousel(imageURL: string) {
    return dispatch({
        type: SHOW_IMAGE,
        imageURL
    });
}

export function hideImage() {
    return dispatch({
        type: HIDE_IMAGE,
    });
}

export function isImageLoading(isLoading: boolean) {
    return dispatch({
        type: IS_IMAGE_LOADING,
        isLoading
    });
}

export function saveDropdownFilter(filterBy, key) {
    return dispatch({
        type: SAVE_DROPDOWN_FILTER,
        key, filterBy
    });
}

export function saveActivitiesOptions(activityOptions: ISelectOptions[]) {
    return dispatch({
        type: SAVE_ACTIVITIES_MENU_ITEMS,
        activityOptions

    });
}

export function saveReferenceFiles(referenceFiles: any) {
    return dispatch({
        type: SAVE_REFERENCE_ITEMS,
        referenceFiles
    });
}

export function shoCarousel(imageURL: string) {
    return dispatch({
        type: SHOW_IMAGE,
        imageURL
    });
}

export function saveAxiosPreviousToken(token: any) {
    return dispatch({
        type: SAVE_AXIOS_PREVIOUS_TOKEN,
        token
    });
}
