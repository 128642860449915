import {dispatch} from '../utils/generalUtils';

export function executePromiseAction(promise: Promise<{}>, identifier: string) {
    return dispatch({
        type: `${identifier}/LOADING`,
        payload: {
            promise
        },
        meta: {
            identifier
        }
    });
}

export function setLoading(identifier: string) {
    return dispatch({
        type: `${identifier}/SET_LOADING`,
        meta: {identifier}
    });
}

export function setError(identifier: string) {
    return dispatch({
        type: `${identifier}/SET_ERROR`,
        meta: {identifier}
    });
}

export function setSuccess(identifier: string) {
    return dispatch({
        type: `${identifier}/SET_SUCCESS`,
        meta: {identifier}
    });
}

export function startPromise(identifier: string) {
    return dispatch({
        type: `${identifier}/START_PROMISE`,
        meta: {identifier}
    });
}

export function stopPromise(identifier: string) {
    return dispatch({
        type: `${identifier}/STOP_PROMISE`,
        meta: {identifier}
    });
}
