import {fromJS} from 'immutable';

import {
    EMPTY_PLANT_OPTIONS,
    HIDE_IMAGE,
    IS_IMAGE_LOADING,
    REMOVE_PLANT_OPTIONS,
    SAVE_ACTIVITIES_MENU_ITEMS,
    SAVE_PLANT_OPTIONS,
    SHOW_IMAGE,
    SAVE_REFERENCE_ITEMS,
    SAVE_AXIOS_PREVIOUS_TOKEN
} from '../constants/actions';

export function miscellaneousReducer(state: any = fromJS({}), action) {
    switch (action.type) {
        case SAVE_PLANT_OPTIONS:
            let plantOptions: Array<Object> = state.get('plantOptions') || [];
            return state.set('plantOptions', plantOptions.concat(action.plantOptions));
        case REMOVE_PLANT_OPTIONS:
            let newPlantOptions: Array<any> = state.get('plantOptions') || [];
            const plants = newPlantOptions.filter(val => action.region !== val.plantRegion);
            return state.set('plantOptions', plants);
        case EMPTY_PLANT_OPTIONS:
            return state.set('plantOptions', []);
        case SHOW_IMAGE:
            return state.set('imageURL', action.imageURL);
        case HIDE_IMAGE:
            return state.set('imageURL', '');
        case IS_IMAGE_LOADING:
            return state.set('isImageLoading', action.isLoading);
        case SAVE_ACTIVITIES_MENU_ITEMS:
            return state.set('activityMenuItems', action.activityOptions);
        case SAVE_REFERENCE_ITEMS:
            return state.set('referenceFiles', action.referenceFiles);
        case SAVE_AXIOS_PREVIOUS_TOKEN:
            return state.set('axiosPreviousToken', action.token);
        default:
            return state;
    }
}
