import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {DropdownButton, Dropdown, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Col, Row, } from 'react-bootstrap'; 
import {IHistory} from '../../../interfaces'; 
import {BasePage} from '../../reusableComponents/BasePage'; 
import {parseJwt} from "../../../services/loginService";
import {Form} from '../../reusableComponents/FormComponents/Form';
import './questionSetupStyle.scss';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { questionSetUp, getCommonFormQuestions, deleteInspectionForms, updateInspectionFormName } from '../../../services/formService';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'; 
import { Async } from '../../reusableComponents/Async';
import {Loader} from '../../reusableComponents/Loader'; 
import {ErrorPage} from '../../reusableComponents/ErrorPage';
import { toast } from 'react-toastify';
import { isNumeric } from '../../../utils/generalUtils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
/* import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'; */
import { hidePrintPreviewModal } from '../../../actions/modalActions';
import { Modal } from 'react-bootstrap'; 
import Dialog from 'react-bootstrap-dialog';
import _ from 'lodash';



export interface IQuestionSetupProps {
    history?: IHistory;
    formId: string;
    formName: string;
    userAccess_Level: string;
    permitType: string;
}

export interface IQuestionSetupState {
    insQues: any;  
    originalQues: any;
    isLoading: boolean;
    quesRearrange: any;
    deletedComponents: any;
    displayView: boolean;
    inspectionName: string;
}
  
export class QuestionSetupImpl extends React.PureComponent<IQuestionSetupProps, IQuestionSetupState> {
    dialog: Dialog;

    constructor(props: IQuestionSetupProps) {
        super(props);
        this.state = { insQues: [], originalQues: [], isLoading: false, quesRearrange: { dragStart: '', dragTo: '', showPopoverOptions: '', showPopoverPosition: '' }, deletedComponents: [], displayView: false, inspectionName: ''};
    }
    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }
    currentQuesNo: number = 0;
    promise = async () => {
        const { formId } =this.props;
        await getCommonFormQuestions(formId, '3', 'question-setup').then(res => { 
            if(res) {  
                this.setState({ insQues: res, originalQues: JSON.parse(JSON.stringify(res)) });
            } 
        });
    }
    handleCancel = () => {
        this.props.history?.push('/inspections');
    } 
    onSubmitForm = async () => {  
        const {history, formId, permitType} = this.props;
        let editedQues = this.state.insQues;
        const originalQues = this.state.originalQues;
        let deletedComponents = this.state.deletedComponents;
        // console.log('originalQues => ', originalQues)
        // console.log('editedQues => ',editedQues)
        
        const checkAndInsertDeletedComponents = (origQuestions, editedQuestions, deletedComponents) => {
            const checkQuestions = (originalQues, editedQues) => {
                editedQues.forEach((ques, index) => {
                    if (ques.questionId) {
                        const origQues = _.find(originalQues, { questionId: ques.questionId });
                        
                        // Check for differences in the current question
                        if (
                            origQues &&
                            (
                                origQues.question !== ques.question ||
                                (origQues.questionType === 'ObjectiveType' && _.xor(origQues.choices || [], ques.choices || []).length > 0) ||
                                origQues.followUpOn !== ques.followUpOn
                            )
                        ) {
                            deletedComponents.push(ques.questionId);
                            editedQues[index].questionId = '';
                            if (ques.subQuestions && ques.subQuestions.length > 0) {
                                editedQues[index].needModifyParentId = true;
                            }
                        }
        
                        // Recursively process subQuestions
                        if (ques.subQuestions && ques.subQuestions.length > 0) {
                            checkQuestions(origQues ? origQues.subQuestions || [] : [], ques.subQuestions);
                        }
                    }
                });
            };
        
            checkQuestions(origQuestions, editedQuestions);
        };
        
        // Usage
        checkAndInsertDeletedComponents(originalQues, editedQues, deletedComponents);

        let data = {
            quesData: editedQues,
            permitType,
            formId: formId, deletedComponents: deletedComponents, 
        } 
        await this.setState({isLoading: true})
        await questionSetUp(data, history) 
        await this.setState({isLoading: false}) 
        return;
    }
    addNewComponent = async (type, quesTo: any = '', parentQues: any = '') => {
        const { insQues } = this.state;
        let length = insQues.length || 0

        let newques: any = {
            'index': length, 
            'questionType': type, 'question': '',
            'choices': [],
            'priority': length + 1,
            'subQuestions': [], 
            'questionId': '', 'isFlagged': false, 'followUpOn': ''
        } 
        let sub_length = length;
        if(quesTo==='') {
            let newState = insQues.concat(newques)
            await this.setState({insQues: newState});  
        } else {
            
            let subQuestions = parentQues['subQuestions'];
            let length = subQuestions.length || 0
            const arrIndex = parentQues['index'].toString()+'_'+length;
            
            // Parse the nested index path
            const indexes = parentQues['index'].toString().split('_').map(Number);
            const insQues = [...this.state.insQues];

            // Traverse to the correct nested level
            let parentQuest = insQues;
            for (let i = 0; i < indexes.length - 1; i++) {
                const index = indexes[i];
                parentQuest = parentQuest[index].subQuestions;
            }

            // Get the last index to determine where to insert in the final level
            const parentIndex = indexes[indexes.length - 1];
            const parent = parentQuest[parentIndex];

            // Define the new sub-question object
            const newques = {
                index: arrIndex, // Index of new sub-question
                questionType: type,
                question: '', // Use the index path in question for clarity
                choices: [],
                priority: parent.subQuestions.length + 1,
                subQuestions: [],
                questionId: '',
                isFlagged: false,
                followUpOn: '',
                answerSelected: quesTo
            };

            // Set answer fields for the parent question
            parent.answertoSQ = quesTo;
            // parent.answerSelected = quesTo;

            // Insert the new sub-question into the parent's subQuestions array
            parent.subQuestions.push(newques);

            // Update the state
            await this.setState({ insQues });
        } 
        /* else {
            const insQues = [...this.state.insQues];
            let subQuestions = insQues[parentQues['index']].subQuestions;
            let length = subQuestions.length || 0
            newques.index = length;
            newques.answerSelected = quesTo;
            insQues[parentQues['index']].answertoSQ = quesTo;
            insQues[parentQues['index']].answerSelected = quesTo; 
            insQues[parentQues['index']].subQuestions.push(newques);             
            await this.setState({insQues});
        } */
    }
    createNewQuestion = () => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Question Type</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Text')} ><button >Text</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Comments')} ><button >Textarea</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Date')} ><button >Date</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Time')} ><button >Time</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('ObjectiveType')} ><button >Choices</button></div>
                </div> 
            </PopoverContent>
        </Popover>;
    } 
    createNewComponent = () => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">New Component</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Title')} ><button >Title</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Subtitle')} ><button >SubTitle</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Hint')} ><button >Notes</button></div>
                    <div className='action-button'>
                        <span><OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={ this.createNewQuestion() }
                        >
                            <span><button >Question</button></span>
                        </OverlayTrigger></span> 
                        </div> 
                </div> 
            </PopoverContent>
        </Popover>;
    } 
    handleDragStart = (e, index) => { 
        let rearrange = this.state.quesRearrange; 
        if(rearrange['dragStart']==='') {
            rearrange['dragStart'] = index; 
            this.setState({quesRearrange: rearrange}); 
        }
    }; 
    handleDragEnter = (e, index) => {
        /* e.target.style.backgroundColor = "#336699"; */  
        //e.currentTarget.className += " rearrange-component-ondrag"; 
    }; 
    handleDragLeave = (e, index) => {
        /* e.target.style.backgroundColor = "black"; */  
    }; 
    handleDrop = (e, index) => {
        let rearrange = this.state.quesRearrange;
        if (rearrange['dragStart'] !== '' && index !== rearrange['dragStart']) {
            let insQues = [...this.state.insQues];
    
            // Helper function to retrieve item and parent array based on path
            const getItemAndParent = (questions, path) => {
                if (!path.length) return null;
                const currentIndex = path[0];
                if (path.length === 1) {
                    return { item: questions[currentIndex], parent: questions, itemIndex: currentIndex };
                } else if (questions[currentIndex]?.subQuestions) {
                    return getItemAndParent(questions[currentIndex].subQuestions, path.slice(1));
                }
                return null;
            };
    
            // const dragFromPath = rearrange['dragStart'].split('_').map(Number);
            // const dragToPath = index.split('_').map(Number);
            const dragFromPath = rearrange['dragStart'].toString().split('_').map(Number);
            const dragToPath = index.toString().split('_').map(Number);
    
            if (index.toString().includes('_')) {
                if (!rearrange['dragStart'].toString().includes('_')) {
                    rearrange['dragStart'] = '';
                    this.setState({ quesRearrange: rearrange });
                    toast.error('Main question can\'t be moved to sub-question. Please delete main question and add as sub-question', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'failed-toast',
                    });
                    return false;
                } else {
                    // Fetch drag item and parent arrays
                    const dragFromResult = getItemAndParent(insQues, dragFromPath);
                    const dragToResult = getItemAndParent(insQues, dragToPath);
    
                    if (dragFromResult && dragToResult) {
                        const { item: dragItem, parent: dragFromParent, itemIndex: dragFromIndex } = dragFromResult;
                        const { parent: dragToParent, itemIndex: dragToIndex } = dragToResult;
    
                        // Remove from drag start and add to drag target
                        dragFromParent.splice(dragFromIndex, 1);
                        dragToParent.splice(dragToIndex, 0, dragItem);
    
                        // Update question numbers recursively for both parents
                        const updateQuestionNumbers = (questions, prefix = '') => {
                            questions.forEach((q, i) => {
                                q.questionNum = prefix ? `${prefix}_${i}` : `${i}`;
                                if (q.subQuestions) {
                                    updateQuestionNumbers(q.subQuestions, q.questionNum);
                                }
                            });
                        };
                        updateQuestionNumbers(insQues);
                    }
                }
            } else {
                let dragItem;
                if (rearrange['dragStart'].toString().includes('_')) {
                    const dragFromResult = getItemAndParent(insQues, dragFromPath);
                    if (dragFromResult) {
                        const { item, parent: dragFromParent, itemIndex: dragFromIndex } = dragFromResult;
                        if (item && dragFromParent) {
                            dragFromParent.splice(dragFromIndex, 1);
                            item['answertoSQ'] = '';
                        }
                    }
                } else {
                    dragItem = insQues[rearrange['dragStart']];
                    insQues.splice(rearrange['dragStart'], 1);
                }
                insQues.splice(index, 0, dragItem);
    
                // Update question numbers for main questions
                insQues.forEach((q, i) => {
                    q.questionNum = `${i}`;
                    if (q.subQuestions) {
                        const updateQuestionNumbers = (questions, prefix) => {
                            questions.forEach((subQ, j) => {
                                subQ.questionNum = `${prefix}_${j}`;
                                if (subQ.subQuestions) {
                                    updateQuestionNumbers(subQ.subQuestions, subQ.questionNum);
                                }
                            });
                        };
                        updateQuestionNumbers(q.subQuestions, q.questionNum);
                    }
                });
            }
    
            rearrange['dragStart'] = '';
            this.setState({ quesRearrange: rearrange });
            this.reArrangeComponent(insQues);
            toast.success('Priority changed successfully', { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 });
        }
    };
    
    renderComponents() {  
        this.currentQuesNo = 0; 
        if(this.state.insQues.length>0) {

            return this.state.insQues.map((ques, index) => {
                return <div draggable onDragStart={(e) => this.handleDragStart(e, ques.index)}
                onDragEnter={(e) => this.handleDragEnter(e, ques.index)}
                onDragLeave={(e) => this.handleDragLeave(e, ques.index)}
                onDrop={(e) => this.handleDrop(e, ques.index, )}
                onDragOver={(e) => e.preventDefault()} ><div className="drag-icon"><i className="fa fa-bars"></i></div> { this.renderComponentFormat(ques, index) } </div>; 
            }); 
        }
    }
    renderComponentFormat = (ques, index) => {
        document.body.click();  
        ques.index = index; 
        if (ques.questionType === 'Title') {
            return this.renderTitleComponent(ques);
        } else if (ques.questionType === 'Subtitle') {
            return this.renderSubTitleComponent(ques);
        } else if (ques.questionType === 'Hint') {
            return this.renderHintTextAreaComponent(ques);
        } else if (ques.questionType === 'Text' || ques.questionType === 'BlockScore' || ques.questionType === 'TotalScore') {
            return this.renderTextComponent(ques);
        } else if (ques.questionType === 'Comments') {
            return this.renderTextAreaComponent(ques);
        } else if (ques.questionType === 'Date') {
            return this.renderDateComponent(ques);
        } else if (ques.questionType === 'Time') {
            return this.renderTimeComponent(ques);
        } else if (ques.questionType === 'ObjectiveType') {
            return this.renderChoicesComponent(ques);
        } else {
            return <span/>;
        }  
    }
    renderComponentOption = (ques) => {
        let index = ques.index;
        let position = ques.index+1;
        if (index.toString().indexOf("_") > -1) {
            let indexes = index.split("_");
            position = this.currentQuesNo + '.' + (parseInt(indexes[1])+1)
        }
        return <Popover
            id="popover-positioned-top"
            className="footer-popover position-option-component">
            <Popover.Title as='h2' className="popover-title">Position {position} <span className="closeMoveComponent" onClick={this.closeComponentOptions}><i className="fa fa-close"></i></span></Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button delete' onClick={() => this.deleteComponent(ques)} ><button >Delete</button></div> 
                    <div className='action-button'>
                        <span><OverlayTrigger 
                            show={this.state.quesRearrange.showPopoverPosition===ques.index}
                            trigger="click"
                            placement="left"
                            overlay={ this.renderComponentMove(ques) }
                        >
                            <span><button onClick={() => this.showMovePositionOverlay(ques)} >Move</button></span>
                        </OverlayTrigger></span> 
                    </div> 
                </div> 
            </PopoverContent>
        </Popover>; 
    }
    renderComponentMove = (ques) => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Enter position to move <span className="closeMoveComponent" onClick={this.closeComponentOptions}><i className="fa fa-close"></i></span></Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <RRFInput
                        model={`.movePosition~${ques.index}`}
                        type="text"
                        placeholder=""
                        id="movePosition"
                        label={null} 
                        onChange={this.onFieldValueChangeForMovePosition}
                    /> 
                     <button type="button" className="move-position-button" onClick={this.submitMovePosition}>
                        Submit
                    </button>
                </div> 
            </PopoverContent>
        </Popover>;
    }
    onFieldValueChangeForMovePosition = (data) => { 
        let moveTo = data.target.value;
        let name = data.target.name.split('.');
        let dragIndex = name && name[2].split('~')[1]; 
        let insQues = [...this.state.insQues]; 
        if(!isNumeric(moveTo) || !isNumeric(dragIndex) || moveTo<1 || moveTo>insQues.length ) {
            toast.error('Invalid data.', { position: toast.POSITION.TOP_RIGHT, className: 'failed-toast' }); 
            return false;
        }
        moveTo = data.target.value - 1;
        if(data) {  
            if(name[2]) { 
                let quesRearrange = {...this.state.quesRearrange};
                /* rearrange  = {dragStart: dragIndex, dragTo: moveTo} */
                quesRearrange['dragStart'] = dragIndex;
                quesRearrange['dragTo'] = moveTo;
                this.setState({quesRearrange});  
            }
        }
        return true;
    }
    submitMovePosition = () => {
        let rearrange = this.state.quesRearrange; 
        this.handleDrop('', (rearrange.dragTo) ) 
        this.closeComponentOptions();
    }
    closeComponentOptions = ()=> {
        let quesRearrange = {...this.state.quesRearrange};
        quesRearrange['dragStart'] = '';
        quesRearrange['dragTo'] = '';
        quesRearrange['showPopoverPosition'] = '';
        quesRearrange['showPopoverOptions'] = '';
        this.setState({quesRearrange});
    }
    deleteComponent = async (ques) => {
        let { insQues } = this.state;   
        let index = ques['index'];
    
        if (index.toString().indexOf("_") > -1) {
            // Split the index to determine the path to the nested question
            let indices = index.split("_").map(num => parseInt(num, 10));
    
            // Recursive function to navigate to the correct parent and delete the subQuestion
            const deleteNestedQuestion = (questions, indices) => {
                if (indices.length === 1) {
                    // Base case: Delete the subQuestion at the final level
                    questions.splice(indices[0], 1);
                    return;
                }
    
                // Recursive case: Traverse into subQuestions using the first index
                let currentIndex = indices.shift();
                if (questions[currentIndex] && questions[currentIndex].subQuestions) {
                    deleteNestedQuestion(questions[currentIndex].subQuestions, indices);
                }
            };
    
            deleteNestedQuestion(insQues, indices);
        } else {
            // Top-level question deletion
            insQues.splice(index, 1); 
        }
    
        // If the question has an ID, add it to deletedComponents
        if (ques && ques.questionId !== '') {
            let deletedComponents = [...this.state.deletedComponents]; 
            deletedComponents.push(ques.questionId);
            this.setState({ deletedComponents }); 
        }
    
        this.reArrangeComponent(insQues); 
        this.closeComponentOptions();
    };
    
    reArrangeComponentOld = async (insQues) => {
        let newInsQues: any = []; 
        insQues.forEach((el,index) => { 
            let currQues: any = el; 
            currQues['index'] = index;
            currQues['priority'] = index + 1; 
            if(insQues.subQuestions && insQues.subQuestions.length>0) {
                insQues.subQuestions.forEach((el,subindex) => {
                    let currSubQues: any = el; 
                    currSubQues['index'] = subindex;
                    currSubQues['priority'] = subindex + 1; 
                    currQues.subQuestions.push(currSubQues);  
                }); 
            } 
            newInsQues.push(currQues);  
        }); 
        await this.setState({insQues: newInsQues}); 
    }
    reArrangeComponent = async (insQues) => {
        // Recursive function to update index and priority
        const updateIndexesAndPriorities = (questions, parentIndex = '') => {
            return questions.map((el, index) => {
                let currQues = { ...el }; // Create a copy of the current question
                currQues['index'] = parentIndex ? `${parentIndex}_${index}` : `${index}`;
                currQues['priority'] = index + 1;
    
                if (currQues.subQuestions && currQues.subQuestions.length > 0) {
                    currQues.subQuestions = updateIndexesAndPriorities(currQues.subQuestions, currQues['index']);
                }
    
                return currQues;
            });
        };
    
        // Process the main questions
        const newInsQues = updateIndexesAndPriorities(insQues);
    
        // Update the state with the new questions
        await this.setState({ insQues: newInsQues });
    };
/*     
    handleChangeChoices = async (Choices, ques) => { 
        const insQues = [...this.state.insQues];
        let index = ques['index'];
        if (index.toString().indexOf("_") > -1) {
            let indexWithParentIndex = index.split("_");
            insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].choices = Choices; 
        } else {
            insQues[index].choices = Choices; 
        } 

        let flagged = insQues[ques['index']].isFlagged || false;
        if(flagged) {
            if(!Choices.includes(insQues[ques['index']].followUpOn) ) {
                insQues[ques['index']].followUpOn = '';
                insQues[ques['index']].isFlagged = false;
            }
        }
        await this.setState({insQues}); 
    }
        
 */
    handleChangeChoices = async (Choices, ques) => { 
        const insQues = [...this.state.insQues]; 
        // let flagged = false; 
        // let index = ques['index']; 
        const index = ques['index'].toString();
        let flagged = false;
        const indexParts = index.split("_").map(part => isNaN(part) ? part : parseInt(part));

        // Helper function to find the correct question by recursively traversing sub-questions
        // const findQuestion = (questionsArray, indexParts) => {
        let currentQuestion = insQues;
        for (let i = 0; i < indexParts.length; i++) {
            const part = indexParts[i];
            if (i === indexParts.length - 1) {
                // Reached the target question, update choices here
                currentQuestion[part].choices = Choices;
                flagged = currentQuestion[part].isFlagged;
                // Handle flagged state and followUpOn condition
                if (flagged && !Choices.includes(currentQuestion[part].followUpOn)) {
                    currentQuestion[part].followUpOn = '';
                    currentQuestion[part].isFlagged = false;
                }
            } else {
                currentQuestion = currentQuestion[part].subQuestions;
            }
        }
        await this.setState({insQues});

    }

    onFieldValueChange = async (data) => {
        if (data) {
            const insQues = [...this.state.insQues];
            const nameParts = data.target.name.split('.');
            const indexPath = nameParts[2].split('_').map(Number);
            const newValue = data.target.value;
    
            const updateNestedQuestion = (questions, path) => {
                const currentIndex = path[0];
                if (path.length === 1) {
                    // Update the question value at the last level of nesting
                    questions[currentIndex].question = newValue;
                } else if (questions[currentIndex].subQuestions) {
                    // Recursively traverse to the next nested level
                    updateNestedQuestion(questions[currentIndex].subQuestions, path.slice(1));
                }
            };
    
            updateNestedQuestion(insQues, indexPath);
    
            await this.setState({ insQues });
        }
    };
    onFieldHintValueChange = async (data, index) => {
        if(data) {
            // let name = data.target.name.split('.');
            if(index) {
                const insQues = [...this.state.insQues];
                //insQues[name[2]].question = data.target.value; 
                // let index = name[2];
                if (index.toString().indexOf("_") > -1) {
                    // let indexWithParentIndex = index.split("_");
                    insQues['forms'].subQuestions["plantForm"].question = data; 
                    /* insQues[indexWithParentIndex[0]].subQuestions.splice(indexWithParentIndex[1],1) */
                } else {
                    insQues[index].question = data; 
                }

                await this.setState({insQues}); 
            }
        }
    }
    onFlagValueChange = async (data, id) => {
        const insQues = [...this.state.insQues];
    
        // Recursive function to traverse and update
        const updateFollowUpOn = (questions, idToUpdate, newValue) => {
            for (let question of questions) {
                if (question.index.toString() === idToUpdate.toString()) {
                    // Update the matching question
                    question.followUpOn = newValue === 'No follow-up' ? '' : newValue;
                    question.isFlagged = newValue === 'No follow-up' ? false : true;
                    return true; // Stop further traversal
                }
    
                // If subQuestions exist, recurse
                if (question.subQuestions && question.subQuestions.length > 0) {
                    const updated = updateFollowUpOn(question.subQuestions, idToUpdate, newValue);
                    if (updated) return true; // Stop traversal once the update is done
                }
            }
            return false; // No match found in this branch
        };
    
        const updated = updateFollowUpOn(insQues, id, data.value);
    
        if (updated) {
            await this.setState({ insQues });
        } else {
            console.error(`No question found with index ${id}`);
        }
    };
    
    
    onFlagValueChangeOld = async (data, id) => { 
        const insQues = [...this.state.insQues];

        insQues[id].followUpOn = data.value==='No follow-up'?'':data.value; 
        insQues[id].isFlagged = data.value==='No follow-up'?false:true; 

        await this.setState({insQues}); 
    }
    showMovePositionOverlay = async (ques) => { 
        const quesRearrange = {...this.state.quesRearrange};
        quesRearrange['showPopoverPosition'] =  ques.index;
        await this.setState({quesRearrange}); 
        return;
    }
    showComponentOptionsOverlay = async (ques)=> { 
        const quesRearrange = {...this.state.quesRearrange};
        quesRearrange['showPopoverOptions'] = ques.index;
        await this.setState({quesRearrange}); 
        return;

    }
    renderChoicesComponent(ques) {
        let choices = ques.choices || []; 
        let retChoices: Array<any> = [];
        retChoices.push({label: 'No follow-up', value: 'No follow-up'});
        choices.forEach(element => {
            retChoices.push({label: element, value: element});
        });
        return <div className="choice-question-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Choice Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`}</div><span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted question]'}</span> 
                    <RRFInput
                        model={`.${ques.index}`}
                        type="textarea"
                        placeholder="Enter choice question"
                        id="plantId"
                        label={null} 
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                    <div className="choice-answer-container">
                        <div className="question">Choices</div>
                        <RRFInput
                            model=".plantId"
                            type="tagInput"
                            placeholder={choices.length < 6 ? 'Add an answer' : ''}
                            id="plantId"
                            label={null} 
                            defaultValue={choices}
                            additionalParams={ques}
                            onSelect={this.handleChangeChoices} 
                            disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                        />
                    </div>
                </Col>
                {ques && (!ques.questionStatus || (ques.questionStatus && ques.questionStatus===1))  && <Col xs={1} className="component-delete"  >
                    <span ><OverlayTrigger  
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }  
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                       <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span>  
                </Col> }
            </Row>
            <Row className='follow-up-container'>
                <Col xs={10} className="content">
                    <div className="question">Select follow-up answer:</div>
                    <RRFInput
                        model={`.${ques.index}Flag`}
                        type="dropdown"
                        placeholder="Select follow-up answer"
                        id={`${ques.index}`}
                        onSelect={this.onFlagValueChange}
                        label={null}  
                        defaultValue={(ques && (ques.followUpOn && ques.followUpOn===''?'No follow-up':ques.followUpOn) ) || 'No follow-up'} 
                        menuItems={retChoices}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                        
                    />
                </Col>
            </Row> 
           
            <div>
                {/* {ques.index.toString()} */}
                {choices && choices.length>0 && <Row className='sub-question-button-container'>
                    {/* {choices && choices.length>0 && ques.index && ques.index.toString().indexOf("_") < 0 && <Row className='sub-question-button-container'> */}
                    {ques && (!ques.questionStatus || (ques.questionStatus && ques.questionStatus===1)) && <div>
                        <OverlayTrigger
                                rootClose
                                trigger="click"
                                placement="right"
                                overlay={ this.createSubQuestionTo(ques) }
                            >
                            <button type="button" className="sub-question-button">
                            Create Sub-question
                        </button>
                        </OverlayTrigger> 
                    </div> }
                </Row>}
                { ques.subQuestions && ques.subQuestions.length>0 && 
                    <div className="sub-questions-container">
                        { this.renderSubQuestions(ques) }
                    </div>
                }
                {/* {ques.questions && ques.questions.map((ques) => (
                    ques.subQuestions && ques.subQuestions.length > 0 ? (
                        <div className="sub-questions-container">
                            {this.renderSubQuestions(ques)}
                        </div>
                    ) : (
                        this.renderSubQuestions(ques) // Render if no sub-questions exist
                    )
                ))} */}
            </div> 
        </div>
    } 
    renderSubQuestionsOld(ques) {
        //if(ques.subQuestions && ques.subQuestions.length>0) {
            return ques.subQuestions.map((subques, index) => {
                let subQuesIndex = `${ques.index}.${subques.index}`;
                let subQuesIndexForForm = `${ques.index}_${index}`;  
                /* return this.renderComponentFormat(subques, subQuesIndexForForm); */
                return <div draggable onDragStart={(e) => this.handleDragStart(e, subQuesIndexForForm)}
                onDragEnter={(e) => this.handleDragEnter(e, subQuesIndexForForm)}
                onDragLeave={(e) => this.handleDragLeave(e, subQuesIndexForForm)}
                onDrop={(e) => this.handleDrop(e, subQuesIndexForForm, )}
                onDragOver={(e) => e.preventDefault()} ><div className="drag-icon"><i className="fa fa-bars"></i></div> { this.renderComponentFormat(subques, subQuesIndexForForm) } </div>;  
            }); 
        //}
    }
    renderSubQuestions(ques) {
        if (ques.subQuestions && ques.subQuestions.length > 0) {
            return ques.subQuestions.map((subques, index) => {
                const subQuesIndex = `${ques.index}.${subques.index}`;
                const subQuesIndexForForm = `${ques.index}_${index}`;
                return (
                    <div
                        key={subques.index}
                        draggable
                        onDragStart={(e) => this.handleDragStart(e, subQuesIndexForForm)}
                        onDragEnter={(e) => this.handleDragEnter(e, subQuesIndexForForm)}
                        onDragLeave={(e) => this.handleDragLeave(e, subQuesIndexForForm)}
                        onDrop={(e) => this.handleDrop(e, subQuesIndexForForm)}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <div className="drag-icon">
                            <i className="fa fa-bars"></i>
                        </div>
                        {this.renderComponentFormat(subques, subQuesIndexForForm)}
    
                        {/* Recursive call to render nested subQuestions */}
                        {/* {this.renderSubQuestions(subques)} */}
                    </div>
                );
            });
        }
        return null;
    }
    

    createSubQuestionTo(ques) {

        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Sub Question For</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body"> 
                { 
                    (ques.choices || []).map( (item) => {
                        return <div className='action-button'>
                         <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={ this.createSubQuestionComponent(item, ques) }
                        >
                            <span><button >{item}</button></span>
                        </OverlayTrigger> 
                    </div> 
                    }) 
                } 
                </div> 
            </PopoverContent>
        </Popover>;
    }
    createSubQuestionComponent(to, ques) {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Question Type</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Text', to, ques)} ><button >Text</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Comments', to, ques)} ><button >Textarea</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Date', to, ques)} ><button >Date</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Time', to, ques)} ><button >Time</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('ObjectiveType', to, ques)} ><button >Choices</button></div>
                </div> 
            </PopoverContent>
        </Popover>; 
    }
    processQuestionLabel = (ques) => {
        let quesNoLabel: any = this.currentQuesNo;
        let questionNum = ques && ques.index;
        if(questionNum && questionNum.toString().indexOf("_") > -1) {
            /* quesNoLabel =  questionNum.replace(/_/g, "."); */
            let split = questionNum.split('_');

            // quesNoLabel = `${this.currentQuesNo}.${parseInt(split[  1])+1}`;
            const parts = questionNum.split('_').map(Number);

            // Map the parts to a dot-separated string based on the requirements
            quesNoLabel = parts
                .map((part, idx) => part + (idx > 0 ? 1 : 0))  // Add 1 to each part after the first
                .join('.');

        } else {  
            quesNoLabel = ++this.currentQuesNo
        }
        return quesNoLabel;
    }
    processQuestionLabelOld = (ques) => {
        let quesNoLabel: any = this.currentQuesNo; // Current question number label
        let questionNum = ques && ques.index;
    
        if (questionNum && questionNum.toString().indexOf("_") > -1) {
            // Split the `questionNum` into parts
            const parts = questionNum.split('_').map(Number);
    
            // Increment both parts
            const incrementedParts = parts.map(part => part + 1);
    
            // Map the parts to a dot-separated string
            quesNoLabel = incrementedParts.join('.');
    
        } else {
            quesNoLabel = ++this.currentQuesNo; // Increment for non-nested question numbers
        }
    
        return quesNoLabel;
    };
    
    renderDateComponent(ques) { 
        return <div className="date-question-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Date Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div><span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted question]'}</span> 
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter date question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> }
            </Row>
        </div>
    } 
    renderTimeComponent(ques) { 
        return <div className="time-question-container"> 
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Time Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div> <span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted question]'}</span>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter time question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> }
            </Row>
        </div>
    } 
    renderTextAreaComponent(ques) { 
        return <div className="textarea-question-container"> 
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Textarea Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`}  </div><span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted question]'}</span>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="textarea"
                        placeholder="Enter textarea question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> }
            </Row>
        </div>
    } 
    renderHintTextAreaComponent(ques) { 

        return <div className='secondary-heading-container'>
        <Row>
            <Col xs={11} className="content">
                <div className="secondary-heading">Notes</div>
                {/* <CKEditor
                    editor={ ClassicEditor } 
                    data={ques.question} 
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.onFieldHintValueChange(data, ques.index) 
                    } } 
                /> */}
            </Col>
            <Col xs={1} className="component-delete">
                {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                <span><OverlayTrigger
                        /* rootClose */
                        trigger="click"
                        placement="right"
                        overlay={ this.renderComponentOption(ques) }
                        show={this.state.quesRearrange.showPopoverOptions===ques.index}
                    >
                    <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                </OverlayTrigger></span> 
            </Col> 
        </Row>
        </div>
    }  
    renderTextComponent(ques) { 
        return <div className="text-question-container">
            <Row> 
                <Col xs={11} className="content"> 
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Text Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div> <span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted question]'}</span> 
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter text question"
                        id="plantId"
                        label={null}
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    /> 
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> }
            </Row>
        </div>
    } 
    renderSubTitleComponent(ques) {
        return <div className='secondary-heading-container'>
            <Row>
                <Col xs={11} className="content">
                    <div className="secondary-heading">Sub Title</div> <span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted title]' }</span>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter sub-title"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                </Col> }
            </Row>
        </div>
    } 
    renderTitleComponent(ques) { 
        return <div className="field-heading-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="field-heading">Title</div> <span className='question-deleted'>{ques && ques.questionStatus && ques.questionStatus===3 && '[deleted title]' } </span>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter title"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                        disabled={ques && ques.questionStatus && ques.questionStatus===3?true:false}
                    />
                </Col>
                {ques && ques.questionStatus && ques.questionStatus===3? '': <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                       <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                </Col> }
            </Row>
        </div>
    } 
    renderContent() {
        return (
            <>
                {<Dialog  ref={(el: any) => {
                        this.dialog = el;
                    }}/>}
                {this.state.isLoading ? <Loader type="submission"/> : ''}
                <Row className='inspection-setup-questions'> 
                    <Form
                        model="forms.plantForm"
                        onSubmit={this.onSubmitForm}>
                        <div className="inspection-setup-questions-body">
                            {
                                this.renderComponents()
                            }
                        </div>
                        <div className="create-new-component-container"> 
                            <span><OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="right"
                                    overlay={ this.createNewComponent() }
                                >
                                <button type="button" className="create-new-component">
                                
                                    <span>Create new component</span> 
                                </button>
                            </OverlayTrigger></span>  
                        </div> 
                    </Form>
                </Row>
                <Row className="form-footer">
                    <button type="button" onClick={this.handleCancel} className="cancel-button">
                        Cancel
                    </button>
                    <button type="button" onClick={this.onSubmitForm} className="submit-button">
                        Submit
                    </button>
                </Row>
            </>
        )
    }
    handleFormRename = () => {
        const { formId, formName} = this.props;
        this.setState({
            displayView: true, inspectionName: formName 
        }) 
    }
    renderPrintButton = () => {
        return<div>
         {this.props.userAccess_Level === 'L1' ?     
            <div style={{
            position: 'absolute',
            right: '75px',
        }} className="button d-print-none faiconSize" onClick={this.handleFormRename} id="print-button">
            <i className="fa fa-pencil" aria-hidden="true"/>
            &nbsp; 
        </div> :
        <div style={{
            position: 'absolute',
            right: '25px',
        }} className="button d-print-none faiconSize" onClick={this.handleFormRename} id="print-button">
            <i className="fa fa-pencil" aria-hidden="true"/>
            &nbsp; 
        </div> }
        {this.props.userAccess_Level === 'L1' ? 
        <div style={{
            position: 'absolute',
            right: '25px',
        }} className="button d-print-none faiconSize" onClick={this.handleInspectionDelete} id="print-button">
            <i className="fa fa-trash" aria-hidden="true"/>
            &nbsp; 
        </div>
        : ''}
        </div>;
    }
    handleInspectionDelete = () => { 
        this.dialog.show({
            body: `Are you sure about deleting ${this.props.formName} form ?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => deleteInspectionForms(this.props.formId, this.props.history))
            ]
        });
    }
    hidePreviewModal = (fname) => {
        this.setState({
            displayView: false 
        })
        hidePrintPreviewModal();
    }
    onInspectionNameChange = (data) => {
        this.setState({
            inspectionName: data.target.value
        })
    }
    handleUpdateInsptName = async () => {
        const { formId, formName, permitType} = this.props;
        await this.setState({isLoading: true})
        let res = await updateInspectionFormName(this.state.inspectionName.trim(), this.props.formId, this.props.history)
        if(res){ 
            this.setState({displayView: false, isLoading: false});
            this.props.history?.replace(`/inspection-setup/${formId}/${permitType}/${this.state.inspectionName}`);
        }
        else 
            this.setState({isLoading: false})  
    }
    render() {
        const { formId, formName} = this.props;
        const { inspectionName } = this.state;
        return (
            <BasePage pageTitle={`Inspection Set-up - ${inspectionName || formName}`} sideButton={this.renderPrintButton()}>
                <div className="plant-detail-container inspection-setup-container">
                {this.state.displayView === true ?
                        <Modal size="lg" className="print-preview-outer" show={true} onHide={() => this.setState({ displayView: false, inspectionName: '' })}> 
                        {this.state.isLoading ? <Loader type="submission"/> : ''}
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: 'white' }}>Update Inspection. </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ color: 'black', backgroundColor: 'white' }}> 
                            <RRFInput
                                model=".Inspection_Name"
                                type="text"
                                placeholder="Enter Inspection name..."
                                id="Inspection_Name"
                                label="Inspection Name :"
                                onChange={this.onInspectionNameChange} 
                                defaultValue={formName}
                            />
                            <Row className="delete-form-footer">
                                <button type="button" onClick={() => this.setState({ displayView: false, inspectionName: '' })} className="cancel-button">
                                    Cancel
                                </button>
                                <button type="button" onClick={() => this.handleUpdateInsptName()} className="submit-button">
                                    Update 
                                </button>
                            </Row>
                        </Modal.Body>
                    </Modal > : ''}
                    <Async
                        identifier="QuestionSetup"
                        promise={this.promise}
                        loader={<Loader/>}
                        error={<ErrorPage/>}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    } 
}

function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    const userAccess_Level = parseJwt(state.login.get('token')).Access_Level;
    const formId = ownProps.match.params.formId;
    const formName = ownProps.match.params.formName;
    const permitType = ownProps.match.params.permitType;
    return { userUID: state.login.get('UniqueId'), userName, formId, formName, permitType, userAccess_Level, };
}

export const QuestionSetupForm = withRouter(connect< IQuestionSetupProps, any, any>(mapStateToProps)(QuestionSetupImpl as any));
