import './referencePage.scss';

import * as React from 'react';
import Dropzone from 'react-dropzone';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Button} from '../reusableComponents/FormComponents/Button';
import {IFileType, IHistory} from '../../interfaces';
import {BasePage} from '../reusableComponents/BasePage';
// import { Title } from '../../reusableComponents/Title';
import {FileDisplayCard} from './FileDetailsCard';
import {Alert} from '../reusableComponents/Alert';
import {showAlert} from '../../utils/generalUtils';
import {saveReferences} from '../../services/miscellaniousService';
import {AlertModal} from '../../model/Alertmodel';
import {withRouter} from 'react-router-dom';
import {yellow100, yellowA200, yellowA100} from 'material-ui/styles/colors';
import {FileInput} from '../reusableComponents/FormComponents/FileInput';
import {Form} from '../reusableComponents/FormComponents/Form';
import {Label} from '../reusableComponents/FormComponents/Label';
import {SubmissionLoader} from '../reusableComponents/SubmissionLoader';
import {IForms} from '../../interfaces';
import {Async} from '../reusableComponents/Async';
import {Loader} from '../reusableComponents/Loader';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {toast} from 'react-toastify';

export interface IReferenceDropZoneProps {
    history?: IHistory;
    forms: IForms;
}

export interface IReferenceDropZoneState {
    // files: IFileType[];
    files: any[];
    URL: string;
    inputValue: string;
    URLTitle: string;
    isSubmitting: boolean;
}

export class ReferenceDropZoneImpl extends React.PureComponent<IReferenceDropZoneProps, IReferenceDropZoneState> {
    constructor(props: IReferenceDropZoneProps) {
        super(props);
        this.state = {inputValue: '', files: [], URL: '', URLTitle: '', isSubmitting: false};
    }
    reffileName: any = '';

    handleFileDrop = (acceptedFiles: any) => {
        const newFiles = this.state.files.concat(acceptedFiles);
        const fileReader = new FileReader();
        const name = new Date().getTime().toString();
        let filename = newFiles[0]['name'].split('.');
        this.reffileName = `${name}.${filename[filename.length-1].toLowerCase()}`;
        fileReader.onload = this.readFile;
        fileReader.readAsDataURL(newFiles[0]);
        this.setState({ files: newFiles });
    }
    refFileData: any = '';
    readFile = (e: any) => {
        this.refFileData = {file: e.target.result, name: this.reffileName};
    }

    handleDeleteFile = (index: number) => {
        const files = this.state.files;
        const remainingFiles = files.filter((file, fileIndex) => fileIndex !== index);
        this.setState({
            files: remainingFiles
        });
    }

    handleUploadFiles = async () => {
        let forms = this.props.forms;
        let bannerImage = (forms as any)[`referenceForm`][`bannerImage`];
        const {files, URL, URLTitle} = this.state;
        
        if (bannerImage.length > 1) {
            // showAlert('Sorry multiple banner imagae upload is not allowed.', 'reference', 'danger');
            toast.error(JSON.stringify('Sorry multiple banner imagae upload is not allowed.'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast',
                autoClose: 8000
            });
            return;
        }
        if (files.length > 1) {
            // showAlert('Sorry multiple file upload is not allowed.', 'reference', 'danger');
            toast.error(JSON.stringify('Sorry multiple file upload is not allowed.'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast',
                autoClose: 8000
            });
            return;
        }
        if (files[0] && !files[0].title || files[0].title == undefined) {
            // showAlert('Please add title to the file.', 'reference', 'danger');
            toast.error(JSON.stringify('Please add title to the file.'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast',
                autoClose: 8000
            });
            return;
        }
        this.setState({
            isSubmitting: true
        });
        if (URLTitle.length) {


            await saveReferences(URLTitle, URL, /* files */this.refFileData, bannerImage, 'URL', this.props.history);
        } else if (files[0].title) {
            await saveReferences(files[0].title, URL,/* files */this.refFileData, bannerImage, this.reffileName, this.props.history);

        }
        this.setState({
            isSubmitting: false
        });
    }

    handleFileTitleChange = (value: string, index: number) => {
        const files = this.state.files;
        files[index].title = value;
        this.setState({
            files
        });
    }

    getChildContext() {
        return {formModel: 'forms.referenceForm'};
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    renderContent = () => {
        return  <div >
                <Alert id="reference" className="danger-alert"/>
                {/*<AlertModal id="reference-upload-success" className="success-alert"/>*/}

                <Form {...SubmissionLoader} onSubmit={this.handleUploadFiles} model="forms.referenceForm">
                    <Row className="show-grid text-center">
                        <Col>
                            <Label required={false} sm={12} style={{textAlign: 'center'}} htmlFor="upload-images">Banner
                                Image:</Label>
                            <Col className="input" style={{
                                display: 'inline-block',
                                backgroundColor: '#a4bf43',
                                borderRadius: '10px',
                                color: 'white',
                                marginBottom: '45px',
                                marginTop: '25px',
                                width: 'fit-content'
                            }}>
                                <FileInput
                                    multiple={false}
                                    model=".bannerImage"
                                    id="upload-images"
                                    style={{align: 'center'}}
                                />
                            </Col>
                        </Col>
                    </Row>
                    {this.state.files.length > 0 || this.state.URL.length > 0 ? '' :
                        [

                            <div key="file" className="dropzone-container">
                                <Dropzone onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
                                    >
                                    {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div className="dropzone" style={{
                                            textAlign: 'center',
                                            fontSize: '25px',
                                            borderStyle: 'dotted',
                                            width: '500px',
                                            height: '200px',
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                            {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <i className="fa fa-download" aria-hidden="true"/>
                                            <p>Try dropping a file here or click to select a file to upload.</p>
                                        </div>
                                    </section>
                                        )}
                                </Dropzone>
                            </div>,
                            <div key="or" style={{
                                textAlign: 'center',
                                fontSize: '22px',
                                color: yellowA200,
                                paddingTop: '40px',
                                paddingBottom: '40px'
                            }}>
                                OR
                            </div>]
                    }
                </Form>
                {this.state.URL.length <= 0 ?
                    (this.state.files.length <= 0 ?

                        <div className="add-link-container"
                             style={{textAlign: 'center', color: 'white', display: 'flex', justifyContent: 'flex-end'}}>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Paste your link here..."
                                onChange={e => this.setState({inputValue: e.target.value})}
                                style={{margin: 'auto', width: '90%'}}
                            />
                            <i
                                title="Click to save URL"
                                onClick={() => this.setState({URL: this.state.inputValue})}
                                className="fa fa-check-circle fa-4x"
                                aria-hidden="true"

                                style={{margin: 'auto'}}
                            />
                            

                        </div> : '') :
                    (this.state.files.length <= 0 ?
                            <div className="url-display" style={{padding: '20px', textAlign: 'center'}}>
                                <div style={{padding: '11px', fontSize: '18px'}}>
                                    <span style={{color: 'white'}}>URL: &nbsp; </span>
                                    <a href={this.state.URL} target="_blank">
                                        {this.state.URL}
                                    </a>
                                    <i onClick={() => this.setState({
                                        URLTitle: '',
                                        URL: '',
                                        files: []
                                    })}

                                       title="Click to delete this URL"
                                       className="fa fa-trash"
                                       aria-hidden="true"
                                    />
                                </div>
                                <div>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter your Reference Title..."
                                        onChange={e => this.setState({URLTitle: e.target.value})}
                                    />
                                </div>
                            </div> : ''
                    )}

                <div className="files-display" style={{textAlign: 'center'}}>

                    {this.state.files.map((file, index) => {
                        return <FileDisplayCard
                            file={file}
                            key={index}
                            index={index}
                            onDelete={this.handleDeleteFile}
                            onTitleChange={this.handleFileTitleChange}
                        />;
                    })}
                </div>
                {


                    <Row className="show-grid form-button" style={{textAlign: 'center', display: 'block'}}> <Button style={{
                        color: 'white',
                        border: '1px solid #a4bf43',
                        backgroundColor: '#000000', width: '100px', margin: '50px',
                        borderRadius: '10px', height: '40px', fontSize: '12px'
                    }} onClick={() => this.props.history?.goBack()} type="reset"
                    >
                        <i className="fa fa-ban" aria-hidden="true"/>
                        &nbsp;
                        Cancel
                    </Button>
                        {this.state.files.length > 0 || this.state.URLTitle.length > 0 ?
                            <button style={{
                                width: '100px', margin: '50px',
                                borderRadius: '10px', height : '40px', fontSize: '12px',
                                color: 'white',
                                border: '10px solid #a4bf43',
                                backgroundColor: '#a4bf43',
                            }}
                                    onClick={this.handleUploadFiles}>Upload</button>
                            : ''} </Row>
                }
            </div>
    }
    render() {
        return <BasePage pageTitle="Add File/URL">
            
            <div className="reference-upload">
                {/* {this.state.isSubmitting ? <Loader/> : ''} */}
                {/*<Title text="Add File/URL" />*/}
                <Async
                        identifier="ReferenceUploadPage"
                        // promise={this.promise}
                        loader={this.state.isSubmitting ? <Loader type="async" /> : null}
                        error={<ErrorPage/>}
                        content={this.renderContent()}
                        // content={this.state.displayView === false ? this.renderContent() : <App />}
                    />
            </div>
        </BasePage>;
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms
    };
}

export const ReferenceDropZone = withRouter(connect< IReferenceDropZoneProps, any, any>(mapStateToProps)(ReferenceDropZoneImpl));
