import {applyMiddleware, compose, createStore, Store} from 'redux';
import {IArgosStore} from '../interfaces';
import {rootReducer} from '../reducers/index';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {fromJS} from 'immutable';
import {checkForUserExpiry} from "../services/loginService";

declare const require: any;
const objectAssign = require('object-assign');

export function configureStore(initialState = {}): Store<IArgosStore> {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const userData = localStorage.getItem('EnviroConnectUserInfo');
    const login = fromJS(JSON.parse(userData || '{}'));
   /* if (checkForUserExpiry(JSON.parse(userData || '{}'))) {
        return ;
    }*/
    return createStore(
        rootReducer,
        objectAssign(initialState, login ? {login} : {}),
        composeEnhancers(
            applyMiddleware(...getMiddlewares())
        )
    );
}

function getMiddlewares() {
    return [
        thunk,
        promiseMiddleware
    ];
}

export const store = configureStore();
