import {IHistory} from '../interfaces';
import * as React from 'react';
import {Alert} from '../components/reusableComponents/Alert';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {clearSetTimeout} from '../utils/generalUtils';
import {Modal} from 'react-bootstrap';

export interface IAlertModalProps {
    id?: string;
    history?: IHistory;
    path?: string;
    match?: {
        params: {
            id: string;
        }
    };
    style?: React.CSSProperties;
}

export class AlertModalImpl extends React.PureComponent<IAlertModalProps, {}> {
    constructor(props: IAlertModalProps | Readonly<IAlertModalProps>) {
        super(props);
    }

    render() {
        const {path, history, id} = this.props;
        if(id) {
            return (
                <Alert id={id} view={(text) => {
                    return <Modal bsSize="sm" show={true}
                                onHide={(() => {
                                    path ? clearSetTimeout(id, () => history?.push(path)) :
                                        clearSetTimeout(id, () => history?.push('/home'));
                                })}>
                        <Modal.Header style={modalHeader} closeButton>
                            <Modal.Title>
                                <i className="fa fa-3x fa-check-circle-o" aria-hidden="true"/>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={modalHeader}>
                            <p style={this.props.style || {}}>
                                {text}
                            </p>
                        </Modal.Body>
                    </Modal>;
                }}
                />
            );
        }
    }
}

export function mapStateToProps(state) {
    return { };
}

export const AlertModal = withRouter(connect< IAlertModalProps, any, any>(mapStateToProps)(AlertModalImpl));

const modalHeader: React.CSSProperties = {
    borderBottom: 'none',
    color: 'green',
    textAlign: 'center',
    padding: '10px',
    fontSize: '18px'
};
