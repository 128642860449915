import {IHistory} from '../../../interfaces';
import * as React from 'react';
import {connect} from 'react-redux';

export interface IButtonProps {
    type?: 'submit' | 'button' | 'reset' | undefined;
    label?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    history?: IHistory;
    children?: any;
    redirectTo?: string;
    className?: any;
}

export class ButtonImpl extends React.Component<IButtonProps, {}> {
    constructor(props: IButtonProps | Readonly<IButtonProps>) {
        super(props);
    }

    handleButtonClick = () => {
        const {type, onClick} = this.props;
        if (onClick) {
            return onClick();
        }
        if (type === 'reset') {
            // @ts-ignore
            this.props.history.push('/home');
        }
        if (this.props.redirectTo === 'goBack') {
            // @ts-ignore
            this.props.history.goBack();
            // this.props.history.push('/References')
        }
    }

    render() {
        const {type, style, className} = this.props;
        let typeText = className === 'risk-assessment-toggle' ? 'button' : (type ? type : 'submit');
        return <button
            type={typeText}
            className="button"
            style={style}
            onClick={this.handleButtonClick}
        >
            {this.props.children}
        </button>;
    }
}

export function mapStateToProps() {
    return {};
}

export const Button = connect<{}, {}, IButtonProps>(mapStateToProps)(ButtonImpl);
