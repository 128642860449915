import './imageGallery.scss';

import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import {connect} from 'react-redux';

import {hideImage} from '../../../actions/miscellaneousActions';

declare const require: any;
require('react-image-gallery/styles/scss/image-gallery.scss');

export interface IGalleryProps {
    imageURL?: string;
    images?: string[];
}

export class GalleryImpl extends React.PureComponent<IGalleryProps, {}> {
    constructor(props) {
        super(props);
        this.state={}
    }

    handleImage = (e) => {
        if (e.target.className === 'image-outer') {
            return hideImage();
        }
        return;
    }

    renderImage = () => {
        const {imageURL, images} = this.props;
        if (!imageURL) {
            return <div/>;
        }
        const imagesData: Array<any> = [];
        images?.forEach(element => {
            imagesData.push({
                original: element,
                thumbnail: element,
            });
        });
        const startIndex = images?.indexOf(imageURL);
        return (
            <div className="image-outer" onClick={this.handleImage}>
                <span className="close-image" onClick={() => hideImage()}>&times;</span>
                <ImageGallery
                    items={imagesData}
                    startIndex={startIndex}
                    showFullscreenButton={false}
                    showPlayButton={false}
                />
            </div>
        );
    }

    render() {
        return this.renderImage();
    }
}

export function mapStateToProps(state: any) {
    return {
        imageURL: state.miscellaneous.get('imageURL')
    };
}

export const Gallery = connect< IGalleryProps, any, any>(mapStateToProps)(GalleryImpl as any);
