import * as React from 'react';

import {RRFInput} from './RRFInput';

export interface IInputRowProps {
    label: string;
    quesNo: string | number;
    type: string;
    placeholder?: string;
    onChange?: (e: any) => void;
    model?: string;
    defaultValue?: string;
    key?: string;
}

export class InputRow extends React.PureComponent<IInputRowProps, {}> {
    constructor(props: IInputRowProps) {
        super(props);
    }

    render() {
        const {model, placeholder, onChange,  label, quesNo, type, key} = this.props;
        return <div className="question-row">
            <div className="question">
                {quesNo ? <div className="question-no">{quesNo}</div> : ''}
                <RRFInput
                    model={model || ''}
                    type={type}
                    placeholder={placeholder} 
                    id={key || (quesNo || '').toString()}
                    defaultValue={this.props.defaultValue}
                    onChange={onChange}
                    label={label}
                    key={key || quesNo}
                />
            </div>
        </div>;
    }
}
