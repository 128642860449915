import 'moment-timezone';
import * as React from 'react'; 
import 'react-datetime/css/react-datetime.css'; 
import 'rsuite/dist/rsuite.min.css';
import DatePicker from 'rsuite/DatePicker'; 
import DateRangePicker from 'rsuite/DateRangePicker';

import { isAfter } from 'date-fns';
const moment = require('moment');

export interface IDatePickerProps {
    value: any;
    format?: string;
    maxDate?: any;
    onChange: Function;
}

export interface IDatePickerState {
    value: any;
}

export class RWeeklyPicker extends React.PureComponent<IDatePickerProps, IDatePickerState> {
    constructor(props: IDatePickerProps) {
        super(props);
        this.state = {
            value: props.value
        };
    }
    parseDate = (date: any) => {
        if (typeof date === 'string') {
            const parsedDate = moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'], true);
            if (parsedDate.isValid()) {
                return parsedDate.toDate();
            } else {
                console.error('Invalid date format');
                return null;
            }
        } else if (date instanceof Date) {
            return date;
        } else {
            console.error('Unknown type');
            return null;
        }
    }
    /* handleCalChange =(date) => { 
        date = date[0];
        const {format, onChange} = this.props; 
        const newDate = format ? moment(date).format(format) : moment(date).toDate(); 
        onChange(newDate);
        this.setState({
            value: new Date(newDate)
        });
    } */
    handleChange = (date) => {  
        const {format, onChange} = this.props;
        //   const newDate = format ? moment(date).tz('America/Chicago').format(format) :
        //        moment(date).tz('America/Chicago').toDate();
        const newDate = format ? moment(date).format(format) : moment(date).toDate();
        
        // const weekNumber = moment(date).isoWeek();
        // const dateFrom = moment(date).startOf('isoWeek').toDate();
        // const dateTo = moment(date).endOf('isoWeek').toDate();
        onChange(newDate);
        this.setState({
            value: moment(date).toDate()
        });
        
    }

    /* isValidDate = (currentDate, selectedDate) => {
        if (selectedDate) {
            return selectedDate.isSameOrBefore(new Date(this.props.maxDate ? this.props.maxDate : new Date()))
        } else {
            return currentDate.isSameOrBefore(new Date(this.props.maxDate ? this.props.maxDate : new Date()))
        }

    } */

    renderValue = (date) => { 
        // let currDate = date[0];
        const weekNumber = moment(date).isoWeek();
        const year = moment(date).year();

        // const weekNumber = moment(currDate).isoWeek();
        // const year = moment(currDate).year();
        return `W${weekNumber}, ${year}`;
    }

    render() {
        const {value} = this.props;  
        return (
            <div className='WeekPicker'> 
                <DatePicker 
                    placeholder='Week picker'
                    isoWeek
                    oneTap
                    editable
                    showWeekNumbers
                    preventOverflow  
                    // shouldDisableDate={date => isAfter(date, new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() - 1) + 6)))}
                    shouldDisableDate={date => moment(date).isAfter(moment().startOf('week').add(7, 'days'))}
                    value={this.state.value}
                    onChangeCalendarDate={this.handleChange}
                    renderValue={this.renderValue}  

                />
                {/* <DateRangePicker 
                    oneTap 
                    isoWeek 
                    showOneCalendar 
                    editable 
                    showWeekNumbers 
                    // hoverRange="week" 
                    preventOverflow 
                    placeholder='Week picker' 
                    shouldDisableDate={date => isAfter(date, new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() - 1) + 6)))} 
                    value={[this.state.value, this.state.value]} 
                    onChange={this.handleCalChange} 
                    renderValue={this.renderValue} 
                    ranges={[]} 
                /> */}
            </div>
 
        );
    }
}
