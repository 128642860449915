import * as React from 'react';
import {DispatchProp} from 'react-redux';
import {toast} from 'react-toastify';

import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {isFormEmpty} from '../../utils/generalUtils';
import {Form} from '../reusableComponents/FormComponents/Form';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {Loader} from '../reusableComponents/Loader';
import {OrgModel} from '../../model/OrgModel';
import {ImageUpload} from '../reusableComponents/ImageUpload';
import {uploadImages} from '../../services/formService';
import './organisations.scss'

export interface IOrgFormProps {
    formModelName: string;
    id: string;
    orgInstance?: OrgModel;
    onSubmit: (values) => void;
}

export interface IOrgFormState {
    isLoading: boolean;
}

export class OrgFormImpl extends React.Component<IOrgFormProps, IOrgFormState> {
    static defaultProps: IOrgFormProps;
    refs: {
        images: ImageUpload;
    };
    imagesList: Array<any> = [];

    constructor(props: IOrgFormProps) {
        super(props);
        this.imagesList = props.orgInstance ? props.orgInstance.props.orgLogoDetails : [];
        this.state = {
            isLoading: false
        };
    }

    handleFormSubmit = async (value) => {
        if (isFormEmpty(value)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        const dataToSave = JSON.parse(JSON.stringify(value));
        dataToSave.orgLogoDetails = await uploadImages(this.refs.images.value, 'companyLogos');
        return this.props.onSubmit(dataToSave);
    }

    render() {
        const {orgInstance} = this.props;
        return (
            <React.Fragment>
                <Form className="organisation-form"
                    model={`${this.props.formModelName}`}         
                    onSubmit={this.handleFormSubmit}>
                    {this.state.isLoading ? <Loader type="submission"/> : ''}
                    <RRFInput
                        model=".orgName"
                        type="text"
                        placeholder="Enter organisation name..."
                        id="OrgName"
                        label="Name:"
                        defaultValue={orgInstance && orgInstance.props.orgName}
                    />
                    {<ImageUpload
                        ref="images"
                        multi={true}
                        type="NeedSinglePhoto"
                        imagesList={orgInstance && orgInstance.props.orgLogoDetails || []}
                        label="Select Logo to Upload:"
                    />}
                    {
                        <FormFooter redirectTo="/org"/>}
                </Form>
            </React.Fragment>
        );
    }
}
