import * as React from 'react';
import {BaseModel} from './BaseModel';

interface IChecklistModelProps {
    id?: string;
    formId?: string;
    type?: string;
    flagged?: boolean;
    answers?: {
        data: {
            date?: string;
            quesNum?: number;
            answer?: {
                answer: string
                date: string
            };
        }[]
    }[];
}

export class ChecklistModel extends BaseModel<IChecklistModelProps> {
    static resource = 'checklist';

    constructor(props: IChecklistModelProps) {
        super(props);
    }

    static getDailyFormQuestions() {
        const questions = [
            'Has it rained in the past 24 hours?',
            'Is the plant operating today?',
            'Housekeeping was performed (includes trash pick-up, trip hazards)',
            'Oil spills and leaks are cleaned up (under 25 gallons).',
            'Dust collectors were inspected for excess emissions.',
            'Bags in the dust collectors were replaced.',
            'Inspected cement and flyash fill pipes - account for all caps, locks & keys.',
            'Plant property was swept today (by 3rd party sweeping company).',
            'Plant property was swept today (with in-house sweeper truck).',
            'Plant property was washed down (using hose, truck, loader,…).',
            'Aggregate stockpiles were watered.',
            'Burners on the boilers were checked.',
            'Did you receive a visit from a government agency today (EPA, OSHA, TCEQ, ADEQ, City, County, etc ….)?',
            'Comments:',
            'Are there any conditions identified that need immediate correction?',
            'Comments:',
            'Rain Amount'
        ];
        return questions;
    }
}
