import * as React from 'react';
import {BaseModel} from './BaseModel';
import {IQuestions} from '../interfaces';

interface IQuarterlyForm {
    PlantId: string;
    Description: string;
    ActionTaken: string;
    UserId: number;
    DatePlant: string;
    Date: string;
}

export interface IQuarterlyFormModelProps {
    id?: string;
    UserName?: string;
    UserId?: string;
    type?: 'QuarterVisual' | 'ShopInspection' | 'all';
    PlantId?: string;
    DatePlant?: string;
    ActualDate?: string;
    QuarterVisual?: IQuarterlyForm[];
    ShopInspection?: IQuarterlyForm[];
    questions?: IQuestions[];
    QuestionsData?: IQuestions[];
    FlaggedQuestion?: boolean;
    ImageUrls?: string[];
    Completed?: boolean;
    SignatureUrls?: string[];
    Flagged?: boolean;
    CompletedBy?: string;
    DateCompleted?: string;
    FollowUpComments?: string;
    Associations?: string;
}

export class QuarterlyFormModel extends BaseModel<IQuarterlyFormModelProps> {
    static resource = 'quarterly-form';

    constructor(props: IQuarterlyFormModelProps) {
        super(props);
    }
}
