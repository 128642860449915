import * as React from 'react';
import {CircularProgress} from 'material-ui';
import {MuiThemeProvider} from 'material-ui/styles';
import { Modal } from 'react-bootstrap'; 
import { connect } from 'react-redux';
import './printPreviewModel.scss';
import { hidePrintPreviewModal } from '../../../actions/modalActions'; 
import { SubmissionLoader } from '../SubmissionLoader';
import { Async } from '../Async';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
declare var require: any;
const pdfmake = require('pdfmake/build/pdfmake.js');
const { jsPDF } = require("jspdf"); 
// import { jsPDF } from "jspdf";
// var htmlToPdfmake = require("html-to-pdfmake");

export interface IPrintPreviewModalProps {
    id?: string;
    modalID?: string;
    onClickYes?: (ref: any) => void;
    ref?: string;
    className?: string;
    style?: React.CSSProperties;
    isImageLoading?: boolean;
    isDetailsPage?: boolean;
    fileName?: string;
    children: any;
    onSelectPrint?: any;
    onSelectPrintStyle?: any;
}

export interface IPrintPreviewModalState {
    isPreviewLoading: boolean;
    isPageDisplay : boolean;
}

export class PrintPreviewModalImpl extends React.Component<IPrintPreviewModalProps, IPrintPreviewModalState> {
    constructor(props: IPrintPreviewModalProps ) {
    // constructor(props: IPrintPreviewModalProps | Readonly<IPrintPreviewModalProps>) {
    
        super(props);
        this.state = { isPageDisplay : false, isPreviewLoading : false};
    }

    static defaultProps = {
        isDetailsPage: true
    };

    /**
     * TODO: Remove this DOM manipulation if any other way to assign the scaled child's height to it's parent.
     */
    componentDidUpdate() {
        const { isDetailsPage } = this.props;
        const height = document.getElementById('print-outer');

        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            let printOuter = document.getElementById('print-outer');
            if (isDetailsPage && height && printOuter) {
                printOuter.style.height =
                    (height.getBoundingClientRect().height) + 'px';
            }
            if (!isDetailsPage && height && printOuter) {
                printOuter.style.height =
                    (height.getBoundingClientRect().height) + 250 + 'px';
            }
        }
    }

    refVal: any;

    printOuter: any;

    static Filter: any;

    getFileName = () => {
        const { fileName } = this.props;
        if (window.navigator.userAgent.indexOf('Edge') > -1) {
            return `${fileName}.pdf`;
        }
        return fileName;
    }

    handlePrint = async () => {
        // this.componentDidUpdate()
        const { getFileName } = this;
        const { id, isImageLoading, modalID, className, style, isDetailsPage } = this.props;

        //const ref = document.getElementById('print');
        this.props.onSelectPrintStyle(true);
        let ref: any = document.querySelector('#print-outer');
        let filename = getFileName();

        if(!ref) { 
            setSuccess('PrintPreviewLoader');
            hidePrintPreviewModal();
            return;
        }
        await setLoading('PrintPreviewLoader'); 
        // setSuccess('PrintPreviewLoader');
/* 
        let css = '@page { size: portrait; }',
            head = document.head || document.getElementsByTagName('head')[0],
            style: any = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';

        style.appendChild(document.createTextNode(css));
        head.appendChild(style);

        window.print(); */
        
        // window.print();
        let newDate = new Date()
        const date = `${newDate.getDate()}/${newDate.getMonth()+1}/${newDate.getFullYear()}`;

        if (filename === 'dashboard') {
            
            let pagebreak = { mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' };
            await html2pdf().from(ref).set({
                filename: 'dashboard--'+date+'.pdf',
                // html2canvas:  { width: 920},
                pagebreak: pagebreak,
                jsPDF: { orientation: 'portrait', unit: 'in', format: 'letter', compressPDF: true}
                // image: {type: 'jpeg', quality: 1,width: width,height: height}
            }).save();
            
            setSuccess('PrintPreviewLoader');
            this.props.onSelectPrint(false); 
            hidePrintPreviewModal();

        } else {
            let filesname = 'maintenance--'+date+'.pdf';
            html2canvas(ref, {
                allowTaint: false,
                useCORS:true,
            }).then( (canvas) => { 
                const dataURL = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                const docDefinition = {
                    pageSize: { width: ref.clientWidth, height: ref.clientHeight },
                    content: [ 
                        {
                            image: dataURL,
                            fit: [ref.clientWidth - (ref.clientWidth/10) , ref.clientHeight - (ref.clientWidth/10)],
                            //fit: [480, (ref.clientHeight - 200)], 
                        },
                    ],
                };
                
                pdfmake.createPdf(docDefinition).download(filesname);
                setSuccess('PrintPreviewLoader');
                this.props.onSelectPrint(false); 
                hidePrintPreviewModal();
            }).catch(function (error) {
                console.error(error);
            });
        }
/* 
        html2canvas(ref).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 90;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            doc.save('download.pdf');
            // setLoader(false);
        }); */
        

        
    }
    // updateDisplayStatus = (flag) => {
    //     this.setState({
    //         isPageDisplay: true,
    //     })
    // }
    hidePreviewModal = () => {
        this.props.onSelectPrint(false); 
        hidePrintPreviewModal();
    }
    renderModal = (showLoader: boolean) => {
        const { id, isImageLoading, modalID, className, style, isDetailsPage } = this.props;

        if (modalID !== id) {
            // this.props.onSelectPrint('false'); 
            return <div ref="print"></div>;
        }

        const newStyle = Object.assign({
            margin: 'auto',
            padding: '0px 10px'
        }, (style ? style : ''));


        return (
            <div className='PrintpreviewDiv'>
                <Modal size="lg" 
                    className="print-preview-outer" show={true} onHide={() => this.hidePreviewModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: 'white' }}>Export Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ color: 'black', backgroundColor: 'white' }}>
                        <div
                            className={`print-preview ${className}`}
                            style={newStyle}
                            id="print-outer"
                        >
                            {this.props.children}
                        </div>
                        <div className="button-container" >
                            <button key={6} className="print-button" onClick={this.handlePrint} >
                                Export
                            </button>
                        </div>
                    </Modal.Body>
                    {showLoader ? <SubmissionLoader /> : ''}
                </Modal >
            </div>
        );
    
    }

    render() {
        return <Async
            identifier="PrintPreviewLoader"
            promise={() => setSuccess('PrintPreviewLoader')}
            error={< div ></div>}
            loader={this.renderModal(true)}
            content={this.renderModal(false)}
        />;
    }
}

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('previewModalID'),
        isImageLoading: state.miscellaneous.get('isImageLoading')
    };
}

export const PrintPreviewModal = connect<{}, {}, IPrintPreviewModalProps>(mapStateToProps)(PrintPreviewModalImpl);
