import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';

import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';

import {OrgModel} from '../../model/OrgModel';
import {getOrgInfo, updateOrgInfo} from '../../services/orgService';
import {OrgFormImpl} from './organisationsForm';

export interface IUpdateOrgProps {
    id: string;
    orgInstance: OrgModel;
    history?: IHistory;
}

export class UpdateOrgImpl extends React.Component<IUpdateOrgProps, {}> {
    static defaultProps: IUpdateOrgProps;

    constructor(props: IUpdateOrgProps) {
        super(props);
    }

    handleFormSubmit = async (values) => {
        const dataToSend = JSON.parse(JSON.stringify(values));
        if (!dataToSend.orgLogoDetails) {
            dataToSend.orgLogoDetails = {};
        } else {
            dataToSend.orgLogoDetails = dataToSend.orgLogoDetails[0];
        }
        dataToSend.status = 1;
        return await updateOrgInfo(dataToSend, this.props.orgInstance.props.id, this.props.history);
    }

    renderOrgForm = () => {
        return <OrgFormImpl
            id="Update Form"
            orgInstance={this.props.orgInstance}
            onSubmit={this.handleFormSubmit}
            formModelName="forms.orgForm"
        />;
    }

    promise = async () => {
        if (!this.props.orgInstance) {
            await getOrgInfo(this.props.id);
        }
    }

    render() {
        return (
            < BasePage pageTitle="Update Organisation">
                <Async
                    identifier="UpdateOrgForm"
                    promise={this.promise}
                    error={<ErrorPage/>}
                    loader={<Loader type="async"/>}
                    content={this.renderOrgForm()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    const orgInstance = OrgModel.get(ownProps.match.params.id);
    return {
        orgInstance,
        id: ownProps.match.params.id
    };
}

export const UpdateOrgForm = withRouter(connect< IUpdateOrgProps, any, any>(mapStateToProps)(UpdateOrgImpl));
