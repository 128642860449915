import {format, getQuarter} from 'date-fns';
import {toast} from 'react-toastify';
import {showPlantListModal, updateModalData} from '../actions/modalActions';
import {FORM_SUBMISSION_SUCCESS, SUBMISSION_FAILED} from '../constants/generals';
import {IHistory} from '../interfaces';
import {DailyFormsModel, IDailyFormsModelProps} from '../model/DailyFormModel';
import {store} from '../store';
import {getCurrentUserUUID, marshallFormDetails, objectToArray, splitDate} from '../utils/generalUtils';
import {getDev, postDev, putDev} from '../utils/HTTPDev';
import {getFlaggedForms} from './dashboardService';
import {deleteImages, uploadImages} from './formService';
import {getMonthlyFormList} from './monthlyFormService';
import {getAllPlantsByRegion} from './plantService';
import {getQuarterlyFormList} from './quarterlyFormService';
import { QuestionModel} from '../model/QuestionModel';



export async function getWaterDailyFormAnswers(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IDailyFormsModelProps = {questions: []};
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.UserName,
            question: 'Submitted By',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: format(response?.data.data.ActualDate, 'MM/dd/yyyy'),
            question: 'Submission Date',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.PlantId,
            question: 'Plant Id',
            flaggedQuestion: false
        });
        objectToArray(response?.data.data.Answers).forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        if (response?.data.data.Completed === 1) {
            questionsData.CompletedBy = response?.data.data.CompletedBy;
            questionsData.DateCompleted = format(response?.data.data.DateCompleted, 'MM/dd/yyyy');
            questionsData.FollowUpComments = response?.data.data.FlaggedComments;
        }
        questionsData.id = formId;
        questionsData.ImageUrls = response?.data.data.ImageUrls;
        questionsData.SignatureUrls = response?.data.data.SignatureUrls;
        questionsData.Completed = response?.data.data.Completed !== 0;
        questionsData.Flagged = response?.data.data.Flagged !== 0;
        questionsData.type = response?.data.data.FormType;
        new DailyFormsModel(questionsData).$save();
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getDailyFormData(mm: string, dd: string, yyyy: string, region: string, isAsc = false, sortIndex = 'submittedDate', curPageNo = 1, searchText = 'None', axiosPreviousToken = null) {
    try {
        const dataToSend = {
            'region': region || 'All',
            'user': getCurrentUserUUID(),
            'isAsc':isAsc,
            'sortIndex': sortIndex,
            'curPageNo': curPageNo,
            'searchText':searchText.replace(/[^a-zA-Z0-9 ]/g, '')
        };
        return await postDev(`/forms-w/${mm}${dd}${yyyy}`, dataToSend, axiosPreviousToken);
    } catch (error) {
        throw error;
    }
}

export async function saveFlagCompletions(inspectionId: string, questionId: string, comments: string, date: any, history?: IHistory) {
    try {
        const dataToSend = {
            inspectionId,
            questionId,
            comments,
            date,
            userId: getCurrentUserUUID(),
            path: history?.location.pathname
        };
        return await putDev('/markflagged', dataToSend);
    } catch (e) {
        throw e;
    }
}

export async function getFormSummaryQuestions(formId: string) {
    try {
        const response = await getDev('/getquestionsforformsummary/' + formId);
        return response && response?.data && response?.data.data.length ? response?.data.data : [];
    } catch (e) {
        throw e;
    }
}

export async function getDailyChecklistData(freq: string, plantId: string, date: string, formId?: string, outfall?: string) {
    try {
        //let url = freq === 'Daily' ? '/getdailyformsonmonth/' : freq === 'Monthly' ? '/getmonthlyformsonmonth/' : '/getquarterlyformsonyear/';
        let url = '';
        switch(freq) {
            case 'Daily': url = '/getdailyformsonmonth/'; break;
            case 'Weekly': url = '/getweeklyformsonweek/'; break;
            case 'Monthly': url = '/getmonthlyformsonmonth/'; break;
            case 'Quarterly': url = '/getquarterlyformsonyear/'; break;
            case 'Yearly': url = '/getyearlyformsonyear/'; break;
        }
        url += `${plantId}/${date}/${formId}`;
        if (outfall) {
            url += '?pointSource=' + outfall;
        }
        const response = await getDev(url);
        if(response) {
            return [response.data.data, response.data.omittedQues || [], response.data.deletedQues || [] ]
        } 
        return [[], [], []]
        //return response?.data.data;
    } catch (error) {
        throw error;
    }
}

export async function submitDailyForm(data: any, history: IHistory, images: any, formId: string) {
    try {
        QuestionModel.deleteAll();
        let response: any = null;
        data.ImageUrls = await uploadImages(images, 'images');
        if (formId) {
            const updateData = {
                Answers: data.Answers,
                UserId: getCurrentUserUUID(),
                InspectionID: data.InspectionID,
                Date: data.Date,
                ImageUrls: data.ImageUrls,
                SignatureUrls: data.SignatureUrls,
                FormType: 'DailyForm',
                PermitTYpe: data.PermitType,
                FacilityType: data.FacilityType
            };
            response = await putDev(`/updateform/${formId}`, updateData);
        } else {
            response = await postDev('/form', data);
        }
        toast.success(FORM_SUBMISSION_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            autoClose: 1000,
            onClose: () => history.push('/inspections')
        });

        await getAllPlantsByRegion(getCurrentUserUUID(), 'All');
        return response;
    } catch (error) {
        await deleteImages(images, 'images');
        toast.error('Something went wrong please try again later...!!', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast',
            autoClose: 1000
        });
        throw error;
    }
}

export async function markAsComplete(comments: string, formId: string | undefined, formType: string, quesNum: any,
                                     completedDate: string, instance: InstanceType<any>) {

    const dailyFilter = store.getState().dashboard.get('dailyFilter');
    const monthlyFilter = store.getState().dashboard.get('monthlyFilter');
    const quarterlyFilter = store.getState().dashboard.get('quarterlyFilter');
    let type = '';
    let month = '';
    let date = '';
    let year = '';
    let region = '';

    if ((formType || '').toLowerCase() === 'dailyform') {
        const {mm, dd, yyyy} = splitDate(format(dailyFilter.date, 'yyyy-MM-dd'));
        month = mm;
        date = dd;
        year = yyyy;
        region = dailyFilter.region;
        type = 'dailyForm';
    } else if ((formType || '').toLowerCase() === 'quarterly') {
        const {mm, dd, yyyy} = splitDate(format(quarterlyFilter.date, 'yyyy-MM-dd'));
        month = mm;
        date = dd;
        year = yyyy;
        region = quarterlyFilter.region;
        type = 'ShopInspection';
    } else {
        const {mm, dd, yyyy} = splitDate(format(monthlyFilter.date, 'yyyy-MM-dd'));
        month = mm;
        date = dd;
        year = yyyy;
        region = monthlyFilter.region;
        type = 'monthlyForm';
    }
    try {
        const response = await putDev(`/markflagged`, {
            formId,
            QuestionNum: quesNum,
            userId: getCurrentUserUUID(), comments, date: completedDate
        });
        toast.success(response?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast'
        });
        const flaggedForms: any = await getFlaggedForms(type, month, date, year, region);

        const newInstance: Array<any> = [];
        (flaggedForms.data.data[formType] || []).forEach((dailyFormData: any) => {
            if (dailyFormData.UniqueId) {
                newInstance.push({
                    UserName: dailyFormData.UserName,
                    Region: dailyFormData.Region,
                    PlantUId: dailyFormData.PlantUId,
                    UserId: dailyFormData.UserId,
                    FormId: dailyFormData.FormId,
                    State: dailyFormData.State,
                    UniqueId: dailyFormData.UniqueId,
                    PlantId: dailyFormData.PlantId,
                    Date: dailyFormData.Date,
                    Type: formType,
                    Completed: dailyFormData.Completed,
                });
            }
        });

        updateModalData(newInstance);
        if ((formType || '').toLowerCase() === 'dailyform') {
            await getDailyFormData(month, date, year, region);
            showPlantListModal('flagged-forms', newInstance,
                'Daily Flagged Forms', formType);
        } else if ((formType || '').toLowerCase() === 'monthly') {
            await getMonthlyFormList(month, year, 'All', region);
            showPlantListModal('flagged-forms', newInstance,
                'Monthly Flagged Forms', 'monthly');
        } else if ((formType || '').toLowerCase() === 'quarterly') {
            const quarter = getQuarter(new Date(`${year}-${month}-${date}`));
            await getQuarterlyFormList(quarter.toString(), year, 'All', region);
            showPlantListModal('flagged-forms', newInstance,
                'Quarterly Flagged Forms', 'quarterly');
        }
    } catch (error) {
        instance.setState({
            isLoading: false
        });
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}
