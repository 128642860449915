import {toast} from 'react-toastify';

import {setSuccess} from '../actions/loadingActions';
import {logout, saveLoginDetails} from '../actions/loginActions';
import {FORGETPASSWORD_SUCCESS, FORM_SUBMISSION_SUCCESS, LOCAL_STORAGE_KEY, LOGIN_FAILED} from '../constants/generals';
import {IHistory} from '../interfaces';
import {UserModel} from '../model/UserModel';
import {getDev, postDev, putDev} from '../utils/HTTPDev';
import {showAlert} from '../utils/generalUtils';

export async function loginUser(UserId, Password, history, pathName, orgId) {
    try {
        const response = await postDev('login', {
            Email: (UserId), Password, OrganizationId : (orgId)
        });
        if (response && response?.data && response?.data.data && response?.data.message === "Organization Info" && response?.data.status === 'valid') {
            setSuccess('LoginButton');
            return response?.data.data;
        }
        setSuccess('LoginButton');
        if (response && response?.data && response?.data.token && response?.data.status === 'valid') {
            const userData = parseJwt(response?.data.token);
            saveUserData(response?.data.token || '', UserId, userData.UniqueId, userData.orgId || null, userData.orgName || null, userData.Regions || [], userData.Access_Level);
            userData.id = userData.UniqueId;
            new UserModel(userData).$save();
            saveLoginDetails({
                token: response?.data.token,
                UniqueId: userData.UniqueId,
                Access_Level: userData.Access_Level,
                orgId: userData.orgId || null,
                orgName: userData.orgName || null
            });
            if (!userData.orgId) {
                localStorage.setItem('L1OrgId', '');
                localStorage.setItem('L1OrgLogo', '');
            } else {
                localStorage.setItem('L1OrgId', userData.orgId);
                localStorage.setItem('L1OrgLogo', userData.orgLogoURL);
            }
            setSuccess('LoginButton');
            history.push(pathName);
        }
    } catch (error: any) {
        toast.error(error.toString().split(':')[1], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        setSuccess('LoginButton');
        throw error;
    }
}

export function checkForUserExpiry(userData) {
    if (!userData || !userData.isLoggedIn) {
        return false;
    }
    const expiryDate = userData && parseJwt(userData.token);
    if (expiryDate && (Date.now() > Number(`${expiryDate.exp}000`))) {
        removeUserData();
        document.location.href = '/';
        return true;
    }
    return false;
}

export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function logoutUser(history?: IHistory) {
    removeUserData();
    logout();
    if (history)
        history.push('/');
}

export function saveUserData(token, userId, UniqueId, orgId, orgName, Regions, Access_Level) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        token, userId, isLoggedIn: true, UniqueId, orgId, orgName, Regions, Access_Level
    }));
}

export function getUserData() {
    return JSON.parse(localStorage.getItem('EnviroConnectUserInfo') || '{}');
}

export function removeUserData() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    sessionStorage.clear();
}

export async function submitForgotPassword(data, history: IHistory) {
    try {
        const response = await postDev('/forgetpassword', data);

        if (response && response?.data && response?.data.data && response?.data.message === "Organization Info" && response?.data.status === 'valid') {
            setSuccess('LoginButton');
            return response?.data.data;
        }

        if (response && response?.data && response?.data.status === 'valid') {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                isLoggedIn: false,
                userUID: response?.data.userUID
            }));

            let orgId ='None'
            if (data.OrganizationId !=='') {
                orgId = data.OrganizationId; 
            }
            // tslint:disable-next-line:max-line-length
            showAlert('Password updated. Temporary password sent to your registered mail.', 'login-form-modal', 'success', () => {
                history.push('/forgot-password/' + data.email+'/'+ orgId);
            });
        }else {
            history.push('/');
            return false;

        }
        return true;
    } catch (error: any) {
        setSuccess('forgot-password');
        showAlert(error.data.message ? error.data.message : error.data, 'forgot-password', 'danger');
        // throw error;
    }
}

export async function resetPassword(data, history: IHistory) {
    try {
        const response = await putDev(`/resetpassword`, data);
        if (response && response?.data && response?.data.status === 'valid') {
            setSuccess('forgot-password');
            removeUserData();
           /*  showAlert('Password updated.', 'forgot-password-form-modal', 'success', () => {
                history.push('/');
            }); */
            toast.success(FORGETPASSWORD_SUCCESS, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                className: 'success-blacktoast',
                onClose: () => history?.push('/')
            });
        }
    } catch (error) {
        // setSuccess('forgot-password');
        toast.error('Please enter valid inputs', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function getCompaniesByEmail(UserId) {
    try {
        await getDev(`/getuserByUserId/${UserId}`);
        return UserId;
    } catch (error) {
        throw error;
    }
}
