import {isEmpty, keys} from '../utils/generalUtils';
import {removeInstance, saveInstance, updateInstance} from '../actions/modelActions';
import {store} from '../store';

export class BaseModel<P> {
    static resource: any;
    static constraint: Object;
    static defaultProps;

    constructor(public props: P & { id?: string }, public resource?: string) {
        this.resource = resource;
        this.props = props;
    }

    static get(id: string, state = store.getState()) {
        let modelState = state.models;
        if (!modelState) {
            return;
        }
        let storeKey: string = `${this.resource}${id}`;

        return modelState.toJS()
            ? modelState.get(storeKey)
            : modelState[storeKey];
    }

    /* static getAllFormIds(): string[] {
         const instances = this.list();
         let ids = [];
         instances.forEach(instance => {
             ids.push(instance.props.id);
         });
         return ids;
     }*/

    static getBy(reference: string, value: string) {
        const instances = this.list();
        return instances.find(instance => {
            if (instance.props[reference] === value) {
                return instance;
            }
            return true;
        });
    }

    static getAllBy(reference: string, value: string) {
        const instances = this.list();
        return instances.filter(instance => {
            return instance.props[reference] === value;
        });
    }

    static getFiltered(filterBy: string, state = store.getState()) {
        return state
            .models
            .filter(instance => instance.props.filterBy === filterBy)
            .toArray();
    }

    // $saveFiltered(key: string): BaseModel<P> {
    //     if (!this.validate()) {
    //         throw Error;
    //     }
    //     saveFilteredInstance(this, `${key}${this.props.id}`, key);
    //     return this;
    // }

    static list(state = store.getState()) {
        let data = state.models.filter((instance: { resource: string; }) => instance.resource === this.resource).toArray();
        let resultSet: any = [];
        data.map((instance: any, key: any) => {
            resultSet.push(instance[1])
            return true;
        });
        return resultSet;
    }

    static getAllByType(type, state = store.getState()) {
        const instances = this.list();
        return instances.filter(instance => {
            return instance.props.type === type;
        });
    }

    static saveAll<T extends BaseModel<{}>>(instances: T[]): void {
        for (let instance of instances) {
            if (!validateObject(instance, this.constraint)) {
                throw Error;
            }
        }
        instances.map((instance, value) => {
            saveInstance(instance, instance.getStoreKey());
            return true;
        });
    }

    static deleteAll(instances = this.list()) {
        instances.map(instance => removeInstance(instance.getStoreKey()));
    }

    static deleteAllFiltered<T extends BaseModel<{}>>(instances: T[], filterBy: string): void {
        instances.map(instance => removeInstance(`${filterBy}${instance.props.id}`));
    }


    private validate() {
        //let constraints = this.constructor.constraints;
        let constraints = '';
        if (isEmpty(constraints)) {
            return true;
        }
        validateObject(this.props, constraints);
    }

    getStoreKey(): string {
        return `${this.resource}${this.props.id}`;
    }

    $save(identifier: string = ''): BaseModel<P> {
        if (!this.validate()) {
            throw Error;
        }
        saveInstance(this, this.getStoreKey());
        return this;
    }

    $update(key: string = ''): BaseModel<P> {
        updateInstance(`${key
            ? `${this.resource}${key}`
            : this.getStoreKey()}`, this);
        return this;
    }

    $delete(casecade: boolean = true): void {
        removeInstance(this.getStoreKey());
    }
}

function generateInstanceMap(instances: BaseModel<{}>[]) {
    if (isEmpty(instances)) {
        return;
    }
    let instanceMap = {};

    instances.forEach(instance => {
        instanceMap[instance.getStoreKey()] = instance;
    });
    return instanceMap;
}

function validateObject(obj: Object, rules: Object): boolean {
    for (let prop in rules) {
        if (rules.hasOwnProperty(prop)) {
            let constraint = rules[prop];
            if (!constraint(obj[prop])) {
                return false;
            }
        }
    }
    return true;
}
