import * as _ from 'lodash';

import {getAllDashboardInspections, getCompletedInspectionsData, getCompletedOrIncompletedInspectionsList, getFlaggedFormQuestions, getFlaggedFormsList, getFormSummaryData} from '../../services/dashboardService';
import {frameSelectOptions} from '../../utils/generalUtils';

export const yearsList = [
    {
        'label': '2020',
        'value': '2020'
    },
    {
        'label': '2021',
        'value': '2021'
    }
]; 

export async function fetchYearDropdownList() {
    let CurrYear = new Date().getFullYear();
    const yearsLists = [{}];
    for (let i = 0; i <5; i++) {
        let Year = '';
        Year = (CurrYear - i).toString();
        yearsLists.push({label: Year, value: Year})
    }

    return yearsLists;
}

export async function fetchCompletedInspectionsData(orgId: string, instance: InstanceType<any>) {
    instance.setState({
        isLoading: true
    });

    const response = await getCompletedInspectionsData(orgId, instance);
    instance.setState({
        isLoading: false
    });
    return response;
}

export async function getAllInspectionByOrgFac(instance: InstanceType<any>, orgId: string) {
    const response = await getAllDashboardInspections(orgId, 'All', instance);

    const inspectionForms = {Daily: [{label: 'All', value: 'All'}], Weekly: [{label: 'All', value: 'All'}], Monthly: [{label: 'All', value: 'All'}], Quarterly: [{label: 'All', value: 'All'}], Yearly: [{label: 'All', value: 'All'}] };
    
    if (response && response?.data && response?.data.data) {
        const inspectionsList = response?.data.data;
        if (!_.isEmpty(inspectionsList)) {
            ['Water', 'Air', 'Waste', 'Others'].map((permitType, i) => {
                inspectionForms.Daily = _.concat(inspectionForms.Daily, frameSelectOptions(inspectionsList[permitType].Daily, 'ParentId', 'FormName'));
                inspectionForms.Weekly = _.concat(inspectionForms.Weekly, frameSelectOptions(inspectionsList[permitType].Weekly, 'ParentId', 'FormName'));
                inspectionForms.Monthly = _.concat(inspectionForms.Monthly, frameSelectOptions(inspectionsList[permitType].Monthly, 'ParentId', 'FormName'));
                inspectionForms.Quarterly = _.concat(inspectionForms.Quarterly, frameSelectOptions(inspectionsList[permitType].Quarterly, 'ParentId', 'FormName'));
                inspectionForms.Yearly = _.concat(inspectionForms.Yearly, frameSelectOptions(inspectionsList[permitType].Yearly, 'ParentId', 'FormName'));
           return true;
            });
        }
    }
    return inspectionForms;
}

export async function fetchFormSummaryData(orgId: string, type: string, instance: InstanceType<any>) {
    /* instance.setState({
        isLoading: true
    }); */
    const response = await getFormSummaryData(orgId, type, instance);
    /* instance.setState({
        isLoading: false
    }); */
    return response;
}

export async function fetchFlaggedFormsList(type: string, orgId: string, instance: InstanceType<any>) {
    instance.setState({
        isLoading: true
    });
    const response = await getFlaggedFormsList(type, orgId, instance);
    instance.setState({
        isLoading: false
    });
    return response;
}

export async function fetchCompleteOrIncompleteInspections(type: string, freq: string, parentId: string, instance: InstanceType<any>) {
    instance.setState({
        isLoading: true
    });
    const response = await getCompletedOrIncompletedInspectionsList(type, freq, parentId, instance);
    instance.setState({
        isLoading: false
    });
    return response;
}

export async function fetchFlaggedQuestions(inspectionInfo: any, instance: InstanceType<any>) {
    instance.setState({
        isLoading: true
    });
    const response = await getFlaggedFormQuestions(inspectionInfo, instance);
    instance.setState({
        isLoading: false
    });
    return response;
}
