import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {IHistory} from '../../interfaces';
import {BasePage} from '../reusableComponents/BasePage';
import {OrgFormImpl} from './organisationsForm';
import {createOrg} from '../../services/orgService';

export interface ICreateFormProps {
    history?: IHistory;
}

export class CreateOrgFormImpl extends React.PureComponent<ICreateFormProps, {}> {
    constructor(props: ICreateFormProps) {
        super(props);
    }

    handleFormSubmit = async (values) => {
        const dataToSend = JSON.parse(JSON.stringify(values));
        if (!dataToSend.orgLogoDetails) {
            dataToSend.orgLogoDetails = {};
        } else {
            dataToSend.orgLogoDetails = dataToSend.orgLogoDetails[0];
        }
        dataToSend.status = 1;
        return await createOrg(dataToSend, this.props.history);
    }

    render() {
        return < BasePage key={3} pageTitle="Add Organisation">
            <OrgFormImpl
                id="CreateForm"
                onSubmit={this.handleFormSubmit}
                formModelName="forms.orgForm"
            />
        </BasePage>;
    }
}

export function mapStateToProps() {
    return {};
}

export const CreateOrgForm = withRouter(connect< ICreateFormProps, any, any>(mapStateToProps)(CreateOrgFormImpl as any));
