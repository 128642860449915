import {format} from 'date-fns';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {monthsAndYears} from '../../../constants/generals';
import {IFilters, IHistory, IMonthlyForms} from '../../../interfaces';
import {getMonthlyFormList} from '../../../services/monthlyFormService';
import {getCurentDate, splitDateIE} from '../../../utils/generalUtils';
import {BasePage} from '../../reusableComponents/BasePage';
import {ListPage} from '../../reusableComponents/ListPage';
import {MonthPicker} from '../../reusableComponents/MonthPicker';
import {RegionFilter} from '../../reusableComponents/RegionFilter';
import {ListItem} from '../InspectionReportDetailer/ListItem';
import { getRegionsByOrg } from '../../../services/orgService';
import { getUserData } from '../../../services/loginService';
import { RegionModel } from '../../../model/OrgModel';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { Pagination } from '../../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IMonthlyReportsProps {
    monthlyForms?: IMonthlyForms;
    filters?: IFilters;
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
    regionList: any;
}

export interface IMonthlyReportsState {
    data: Array<any>;
    filterBy: string;
    regionName: string;
    date: Date;
    month: string; 
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class MonthlyReportsImpl extends React.Component<IMonthlyReportsProps, IMonthlyReportsState> { 
    loggedInUserInfo = getUserData();
    constructor(props: IMonthlyReportsProps) {
        super(props);
        //let date = new Date();
        let date = moment();
        let region = 'All';
        let search = '';

        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
            search = queryString.parse(this.props.location.search).search;
        }

        /* this.state = {
            data: [],
            filterBy: region, date, regionName: region,
            month: format(new Date(date.toString().replace(/-/g, '/')), 'MM-dd-yyyy'),  
        }; */ 
        
        this.state = { 
            data: [], 
            filterBy: region, 
            date: getCurentDate(date, 'MM-DD-yyyy', true), 
            regionName: region, 
            month: getCurentDate(date, 'MM-DD-yyyy'),
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            searchText:search,
            totCount:0  
        };
    }

    promise = async () => {
        //let date = format(new Date(), 'MM-dd-yyyy');
        let date = getCurentDate(moment(), 'MM-DD-yyyy');
        let search = '';
        let region = 'All';
        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
        }
        const {mm, yyyy} = splitDateIE(date);
        await getMonthlyFormList(mm, yyyy, 'All', region, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        }); 
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
    }

    handleMonthFilter = async (e) => {
        await this.setState({
            month: e,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
        });
        const {yyyy} = splitDateIE(e);
        let mm = '';
        let selectedMonth = '';
        monthsAndYears.map((data) => {
            if (data.value === e.substr(0, 2)) {
                selectedMonth = `${data.value}-01-${yyyy}`;
                mm = data.value;
            }
            return true;
        });
        setLoading('MonthlyFormList');
        const queryString = `?date=${selectedMonth}&&region=${this.state.regionName}`;
        await getMonthlyFormList(mm, yyyy, '', this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/monthly${queryString}`);
        setSuccess('MonthlyFormList');
    }

    getMonthlyDateSplit = () => {
        let selectedMonth = '';
        let trialMonth = this.state.month;
        let finalMonth = '';
        if (isNaN(Number(trialMonth[0]))) {
            finalMonth = this.state.month;
        } else {
            const {mm, dd, yyyy} = splitDateIE(trialMonth);
            monthsAndYears.map((data) => {
                if (data.value === mm) {
                    finalMonth = `${data.label} ${yyyy}`;
                }
                return true;
            });
        }
        monthsAndYears.map((data) => {
            if (data.label === finalMonth.substring(0, 3)) {
                selectedMonth = `${data.value}-01-${finalMonth.substring(finalMonth.length - 4)}`;
            }
            return true;
        });
        return selectedMonth;
    }

    handleRegionFilter = async (filterName, regionName) => {
        let selectedMonth = '';
        await this.setState({
            regionName,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
        });
        let trialMonth = this.state.month;
        let finalMonth = '';
        if (isNaN(Number(trialMonth[0]))) {
            finalMonth = this.state.month;
        } else {
            const {mm, dd, yyyy} = splitDateIE(trialMonth);
            monthsAndYears.map((data) => {
                if (data.value === mm) {
                    finalMonth = `${data.label} ${yyyy}`;
                }
                return true;
            });
        }
        monthsAndYears.map((data) => {
            if (data.label === finalMonth.substring(0, 3)) {
                selectedMonth = `${data.value}-01-${finalMonth.substring(finalMonth.length - 4)}`;
            }
            return true;
        });
        const {mm, dd, yyyy} = splitDateIE(selectedMonth);
        setLoading('MonthlyFormList');
        const queryString = `?date=${selectedMonth}&&region=${regionName}`;
        await getMonthlyFormList(mm, yyyy, '', regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/monthly${queryString}`);
        setSuccess('MonthlyFormList');
    }

    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText ? this.state.curPageNo : 1,
            sortIndex: index,
            searchText: searchText,
        })

        let selectedMonth = '';
        let trialMonth = this.state.month;
        let finalMonth = '';
        if (isNaN(Number(trialMonth[0]))) {
            finalMonth = this.state.month;
        } else {
            const {mm, dd, yyyy} = splitDateIE(trialMonth);
            monthsAndYears.map((data) => {
                if (data.value === mm) {
                    finalMonth = `${data.label} ${yyyy}`;
                }
                return true;
            });
        }
        monthsAndYears.map((data) => {
            if (data.label === finalMonth.substring(0, 3)) {
                selectedMonth = `${data.value}-01-${finalMonth.substring(finalMonth.length - 4)}`;
            }
            return true;
        });
        let selectedMonths = this.getMonthlyDateSplit();
        const {mm, dd, yyyy} = splitDateIE(selectedMonth);
        setLoading('MonthlyFormList');
        const queryString = `?date=${selectedMonth}&&region=${this.state.regionName}&&search=${this.state.searchText}`;

        await getMonthlyFormList(mm, yyyy, '', this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/monthly${queryString}`);
        setSuccess('MonthlyFormList');
    }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        let selectedMonth = this.getMonthlyDateSplit();
        const {mm, dd, yyyy} = splitDateIE(selectedMonth);

        setLoading('MonthlyFormList');
        await getMonthlyFormList(mm, yyyy, '', this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content
            });
        });
        setSuccess('MonthlyFormList');

    }

    render() {
        const { data, month, regionName, totCount} = this.state;
        let pageno = Math.ceil(totCount/20 );

        return (
            <BasePage className="list-page-base monthly-reports" pageTitle="Monthly Reports">
                <ListPage
                    // @ts-ignore
                    identifier="MonthlyFormList"
                    instances={data}
                    listItemComponent={ListItem}
                    frequency={'Monthly'}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'permitType', 'inspectionName']}
                    instanceOf={'plantInfo'}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    pageHeadings={['#', 'PlantId', 'Region', 'Facility', 'Permit', 'Form', 'Submitted', 'Last Updated']}
                    listItemFields={['', 'plantId', 'plantRegionName', 'facilityType', 'permitType', 'inspectionName', 'submittedDate', 'lastUpdatedDate']}
                    cols={[1, 1, 2, 1, 1, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Monthly Reports found"
                    chartFilter={<div className="filter-container row"> 
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} > 
                            <MonthPicker
                                defaultValue={month}
                                years={[2013, 2015, 2016]}
                                onClick={this.handleMonthFilter}
                                popover={true}
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} > 
                            <RegionFilter
                                selectedValue={regionName}
                                onChange={this.handleRegionFilter}
                                regionList={this.props.regionList}
                            />
                        </Col>
                    </div>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const regionList = RegionModel.list();
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');
    return { regionList, axiosPreviousToken };
}

export const MonthlyReports =
    withRouter(connect< IMonthlyReportsProps, any, any>(mapStateToProps)(MonthlyReportsImpl as any));
