import {format} from 'date-fns'; 
import {setSuccess} from '../actions/loadingActions'; 
import {ChecklistModel} from '../model/ChecklistModel';
import {IYearlyFormModelProps, YearlyFormModel} from '../model/YearlyFormModel'; 
import {getCurrentUserUUID, marshallChecklistData, marshallFormDetails, objectToArray} from '../utils/generalUtils';
import {getDev, postDev, putDev} from '../utils/HTTPDev'; 

export async function getYearlyFormList(region: string, year: any, isAsc = false, sortIndex = 'submittedDate', curPageNo = 1, searchText = 'None', axiosPreviousToken = null) {
    const allInstances = YearlyFormModel.list();
    allInstances.forEach((instance: InstanceType<any>) => {
        new YearlyFormModel(instance.props).$delete();
    });
    try {
        const dataToSend = {
            'region': region || 'All',
            'year': year || '',
            'user': getCurrentUserUUID(),
            'isAsc':isAsc,
            'sortIndex': sortIndex,
            'curPageNo': curPageNo,
            'searchText':searchText.replace(/[^a-zA-Z0-9 ]/g, '')
        };
        const response = await postDev(`/forms-y`, dataToSend, axiosPreviousToken);
        setSuccess('YearlyFormList');
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getMonthlyFormDetails(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IYearlyFormModelProps = {id: '', questions: [], ImageUrls: [], Completed: false};
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.UserName,
            question: 'Submitted By',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: format(response?.data.data.ActualDate, 'MM/dd/yyyy'),
            question: 'Submission Date',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.PlantId,
            question: 'Plant Id',
            flaggedQuestion: false
        });
        objectToArray(response?.data.data.Answers).forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        if (response?.data.data.Completed === 1) {
            questionsData.CompletedBy = response?.data.data.CompletedBy;
            questionsData.DateCompleted = format(response?.data.data.DateCompleted, 'MM/dd/yyyy');
            questionsData.FollowUpComments = response?.data.data.FlaggedComments;

        }
        questionsData.Associations = response?.data.data.Associations;
        questionsData.id = formId;
        questionsData.Completed = response?.data.data.Completed !== 0;
        questionsData.ImageUrls = response?.data.data.ImageUrls;
        questionsData.SignatureUrls = response?.data.data.SignatureUrls;
        questionsData.Flagged = response?.data.data.Flagged !== 0;
        questionsData.type = response?.data.data.FormType;
        new YearlyFormModel(questionsData).$save();
        return questionsData;
    } catch (error) {
        throw error;
    }
}

export async function getMonthlyFormAnswers(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IYearlyFormModelProps = {id: '', questions: [], ImageUrls: response?.data.data.ImageUrls, Completed: false, ActualDate: response?.data.data.ActualDate};
        objectToArray(response?.data.data.Answers).reverse().forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        questionsData.id = formId;
        return questionsData;
    } catch (error) {
        throw error;
    }
} 

export async function getMonthlyChecklistData(plantId: string, yyyy = format(new Date(), 'yyyy'), formType: string) {
    try {
        const response = await getDev(`/getmonthlyforms/${plantId}/${yyyy}/${formType}`);
        let flag = true;
        const answersData = marshallChecklistData(response?.data.data);
        answersData.sort((a: any, b: any) => {
            return a.month - b.month;
        });
        new ChecklistModel({id: plantId, answers: answersData, type: formType}).$save();
    } catch (error) {
        throw error;
    }
}
