import {
    DELETE_PLANT_DETAILS,
    REMOVE_FILTER_DATA,
    RESET_DASHBOARD_FILTERS,
    SAVE_DAILY_FORM_DATA,
    SAVE_DAILY_FORM_FILTER,
    SAVE_FLAGGED_DAILY_FORM_DATA,
    SAVE_FLAGGED_FORM_DETAILS,
    SAVE_FLAGGED_MONTHLY_FORM_DATA,
    SAVE_FLAGGED_QUARTERLY_FORM_DATA,
    SAVE_FORM_COUNTS,
    SAVE_LINE_CHART_DATA,
    SAVE_LINE_CHART_FILTER,
    SAVE_MONTHLY_FORM_DATA,
    SAVE_MONTHLY_FORM_FILTER,
    SAVE_PLANT_DETAILS,
    SAVE_QUARTERLY_FORM_DATA,
    SAVE_QUARTERLY_FORM_FILTER,
} from '../constants/actions';
import {dispatch} from '../utils/generalUtils';

export function saveDailyFormData(instance) {
    return dispatch({
        type: SAVE_DAILY_FORM_DATA,
        instance
    });
}

export function saveMonthlyFormData(instance) {
    return dispatch({
        type: SAVE_MONTHLY_FORM_DATA,
        instance
    });
}

export function saveQuarterlyFormData(instance) {
    return dispatch({
        type: SAVE_QUARTERLY_FORM_DATA,
        instance
    });
}

export function saveFormCounts(instance) {
    return dispatch({
        type: SAVE_FORM_COUNTS,
        instance
    });
}

export function saveLineChartData(instance) {
    return dispatch({
        type: SAVE_LINE_CHART_DATA,
        instance
    });
}

export function saveLineChartFilter(date, region) {
    return dispatch({
        type: SAVE_LINE_CHART_FILTER,
        date, region
    });
}

export function saveDailyFormFilter(date, region) {
    return dispatch({
        type: SAVE_DAILY_FORM_FILTER,
        date, region
    });
}

export function saveMonthlyFormFilter(date, region) {
    return dispatch({
        type: SAVE_MONTHLY_FORM_FILTER,
        date, region
    });
}

export function saveQuarterlyFormFilter(date, region) {
    return dispatch({
        type: SAVE_QUARTERLY_FORM_FILTER,
        date, region
    });
}

export function removeFilterData() {
    return dispatch({
        type: REMOVE_FILTER_DATA
    });
}

export function savePlantDetails(plantDetails) {
    return dispatch({
        type: SAVE_PLANT_DETAILS,
        plantDetails
    });
}

export function deletePlantDetails() {
    return dispatch({
        type: DELETE_PLANT_DETAILS
    });
}

export function saveFlaggedMonthlyFormsData(instance, monthlyCount) {
    return dispatch({
        type: SAVE_FLAGGED_MONTHLY_FORM_DATA,
        instance, monthlyCount: monthlyCount
    });
}

export function saveFlaggedQuarterlyFormsData(instance, quarterlyCount) {
    return dispatch({
        type: SAVE_FLAGGED_QUARTERLY_FORM_DATA,
        instance, quarterlyCount: quarterlyCount
    });
}

export function saveFlaggedDailyFormsData(instance, dailyCount) {
    return dispatch({
        type: SAVE_FLAGGED_DAILY_FORM_DATA,
        instance, dailyCount: dailyCount
    });
}

export function saveFlaggedFormDetails(formDetails) {
    return dispatch({
        type: SAVE_FLAGGED_FORM_DETAILS,
        formDetails
    });
}

export function resetDashboardFilters() {
    return dispatch({
        type: RESET_DASHBOARD_FILTERS
    });
}
