import * as React from 'react';
import { IFileType } from '../../interfaces';
import * as ReactDOM from 'react-dom';

export interface IFileDisplayCardProps {
    file: IFileType;
    index: number;
    onDelete: (index: number) => void;
    onTitleChange: (value: string, index: number) => void;
}

export interface IFileDisplayCardState {
    divText: string;
}

export class FileDisplayCard extends React.PureComponent<IFileDisplayCardProps, IFileDisplayCardState> {
    constructor(props: IFileDisplayCardProps) {
        super(props);
        this.state = { divText: 'Click to add Title' };
    }

    handleTitleChange = (index: number) => {
        this.setState({
            divText: ''
        });
        const innerHTML: any = ReactDOM.findDOMNode(this.titleRef);
        this.props.onTitleChange(innerHTML['innerHTML'], index);
    }

    titleRef: any = null;

    render() {
        const { index, file, onDelete, onTitleChange } = this.props;
        return <div key={index} className="file-details">
            <i
    onClick={() => onDelete(index)}
    title="Click to delete this file"
    className="fa fa-times"
    aria-hidden="true"
    />
            <div className="row-one" >
                <i className="fa fa-file" aria-hidden="true"/>
                <span
                    style={{ backgroundColor : '#a4bf43', color: 'white' }}
                    contentEditable={true}
                    onClick={() => this.setState({ divText: '' })}
                    onInput={(e) => this.handleTitleChange(index)}
                    className="file-name"
                    ref={val => this.titleRef = val}
                >
                    {this.state.divText}
                </span>
            </div>
            <div className="row-two" >
                <span className="file-name">Name: &nbsp; {file.name}</span>
                <br />
                <span className="file-size" >
                    Size: &nbsp;
                    <span className="size" >
                        {`${Math.round(file.size / 1024).toFixed(2)}KB`}
                    </span>
                </span>
                <br />
                <a title="Click to view the file" target="_blank" href={file.preview}>View File</a>
            </div>
        </div>;
    }
}
