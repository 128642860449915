import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {FlaggedFormModel} from '../../model/FlaggedFormModel';
import {inspectionType} from '../../constants/generals';

const queryString = require('query-string');

export interface IListItemProps {
    instance?: FlaggedFormModel;
    index?: number;
    location?: any;
    history?: IHistory;
    state?: any
}

export class ListItemImpl extends React.Component<IListItemProps, {}> {

    static defaultProps: IListItemProps;

    render() {
        const category = queryString.parse(this.props.location.search).formType;
        const {instance, index, history} = this.props;
        if(!instance )
            return;
        return  <Row className="form-list-item">
            <ListItem category={category} sm={2} label={instance.props.PlantId}  {...this.props} />
            <ListItem category={category} sm={3} label={instance.props.Region}  {...this.props} />
            <ListItem category={category} sm={3} label={inspectionType(instance.props.type)}  {...this.props} />
            <ListItem
                category={category}
                sm={instance.props.CompletedBy || instance.props.CompletedBy === null
                    ? 2 : 4} label={instance.props.Date}  {...this.props} />
            {instance.props.CompletedBy || instance.props.CompletedBy === null ?
                <ListItem category={category}
                          sm={2}
                          label={instance.props.DateCompleted === 'None' ? 'NA' : instance.props.DateCompleted || 'NA'}
                          {...this.props}
                /> : ''}
        </Row>;
    }
}


const ListItem = ({category, sm, label, history, instance}: any) => {
    return <Col

        onClick={() => history.push(`/flagged-forms/${category}/${instance.props.id}`)}
        className="clickable-list-item"
        xs={2}
        sm={sm}
    >
        {label || 'NA'}
    </Col>;
};

export function mapStateToProps(state: any) {
    return {
        state
    };
}
export const FlaggedListItem =
    withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl as any));
