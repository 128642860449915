import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {submitPlantForm} from '../../services/plantService';
import {BasePage} from '../reusableComponents/BasePage';
import {PlantForm, PlantFormImpl} from './PlantForm';
import {parseJwt} from "../../services/loginService";
import { Loader } from '../reusableComponents/Loader';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';

export interface IAddPlantFormProps {
    history?: IHistory;
    userUID: any;
    userName: any
}

export class AddPlantFormImpl extends React.PureComponent<IAddPlantFormProps, {}> {

    constructor(props: IAddPlantFormProps) {
        super(props);
    }

    handlePlantFormSubmit = (values) => {
       return submitPlantForm(values, this.props.history);
    }

    render() {
        return <BasePage key={3} pageTitle="Add Plant.." className='plant-page'>
            <PlantFormImpl
                model="forms.plantForm"
                onSubmit={this.handlePlantFormSubmit}
                // loader={<Loader type="async"/>}
                history={this.props.history}
            />
        </BasePage>;
    }
    /* renderContent = () => {
        if (!this.props.plantInstance) {
            return <span/>;
        }
        return <PlantForm
            model="forms.plantForm"
            onSubmit={this.handlePlantFormSubmit}
            // plantInstance={this.props.plantInstance}
            history={this.props.history}
        />;
    }

    render() {
        return <BasePage pageTitle={`Add Plant.`} className="plant-page">
            <Async
                identifier="PlantDetailsPage"
                // promise={this.promise}
                loader={<Loader type="async"/>}
                content={this.renderContent()}
                error={<ErrorPage/>}
            />
        </BasePage>;
    } */
}



function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    return {
        userUID: state.login.get('UniqueId'),
        userName
    };
}

export const AddPlantForm = withRouter(connect< IAddPlantFormProps, any, any>(mapStateToProps)(AddPlantFormImpl as any));
