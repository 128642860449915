import './dashboard.scss';
import '../reusableComponents/FormComponents/form.scss';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {BasePage} from '../reusableComponents/BasePage';
import {PrintPreviewModal} from '../reusableComponents/PrintPreview';
import {Async} from '../reusableComponents/Async';
import {Loader} from '../reusableComponents/Loader';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {RegionFilter} from '../reusableComponents/RegionFilter';
import {ToggleSwitch} from '../reusableComponents/ToggleSwitch.tsx';
import {LineChart} from '../reusableComponents/ChartComponents/LineChart';
import {RWeeklyPicker} from '../reusableComponents/WeekPicker';
import * as _ from 'lodash'; 
import {
    fetchCompletedInspectionsData,
    fetchCompleteOrIncompleteInspections,
    fetchFlaggedFormsList,
    fetchFormSummaryData,
    getAllInspectionByOrgFac,
    fetchYearDropdownList,
    yearsList
} from './dashboardDataFetcher';
import {SelectDropdown} from '../reusableComponents/SelectDropdown';
import {MonthPicker} from '../reusableComponents/MonthPicker';
import {format} from 'date-fns';
import {getUserData, parseJwt} from '../../services/loginService';
import {IHistory} from '../../interfaces';
import {BarChart} from '../reusableComponents/ChartComponents/BarChart';
import {CustomToolTip} from '../reusableComponents/CustomToolTip';
import {RDatePicker} from '../reusableComponents/DatePicker';
import {findCurrentQuarter, frameSelectOptions, getCurentDate, printDetails} from '../../utils/generalUtils';
import {DetailsModal} from '../reusableComponents/ListModal/DetailsModal';
import {FlaggedFormListItem} from './FlaggedFormListItem';
import {ListModal} from '../reusableComponents/ListModal';
import {PlantListItem} from './PlantListItem';
import {ChangeEvent} from "react";
import loaderImage from '../../images/loader.gif';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { getRegionsByOrg } from '../../services/orgService';
import { getCompletedLineData } from '../../services/dashboardService';
import { RegionModel } from '../../model/OrgModel';
import moment from 'moment';
import {getQuarter} from 'date-fns';


const queryString = require('query-string');


export interface IChartContainerProps {
    [x: string]: any;
    loggedInUserInfo: any;
    history?: IHistory;
    regionList?: [];
}

export interface IChartContainerState {
    isLoading: boolean;
    regionToFilter: string;
    optionsForInspectionTypeDD: Array<object>;
    selInspType: string;
    activeFreqTabIndex: number;
    inspectionsFormList: Object;
    optionsForInspectionsDD: Array<object>;
    linearGraphDate: any;
    currentLinearInspectionForm: Array<string>;
    linearGraphData: Object[];
    dailySummaryGraphDate: Date;
    dailySummaryGraphData: any;
    weeklySummaryGraphDate: any;
    weeklySummaryGraphData: any;
    monthlySummaryGraphDate: any;
    monthlySummaryGraphData: any;
    quarterlySummaryGraphDate: { year: number, qtr: string };
    quarterlySummaryGraphData: any;
    yearlySummaryGraphData: any;
    yearlySummaryGraphDate: any;
    flaggedFormsList: Array<Object>;
    showFlaggedFormLister: boolean;
    completeInCompleteForms: Array<Object>;
    showCompleteIncompleteList: boolean;
    completeOrIncompleteModalTitle: string | null;
    currentCompOrIncompFreq: string;
    yearsList: [];
    YearDropdownList: Array<Object>;
    apiLoading: {Daily: boolean, Weekly: boolean, Monthly: boolean, Quarterly: boolean, Yearly: boolean };
    printFlag: boolean;
    availableOptions: Array<any>;
    regionSelectedFilter: string;
    printStyle: boolean;
    lineChartData : Array<Object>;
    // urlFilterDate : Array<Object>;
    urlFilterDate : { key: string; value: any }[];
    filteredInspType: any;
    inspTypePrintVal : boolean;
}

export class ChartsContainerImpl extends React.Component<IChartContainerProps, IChartContainerState> {
    loggedInUserInfo = getUserData();
    constructor(props: IChartContainerProps) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        let urlArray: ({ [key: string]: string } | { key: string; value:any })[] = [];
        const skipArr = ['lineActiveTabIndex','lineActiveTabLabel','qtryearDiv','qtryear']
        urlParams.forEach((value, key) => {
            const isCurrArrInSkipArr = skipArr.includes(key);
            if(!isCurrArrInSkipArr){
                urlArray.push({ [key]: value });
            }
          });

        const activeIndexString: string | null = urlParams.get('lineActiveTabIndex');
        const activeIndex: number = activeIndexString !== null ? parseInt(activeIndexString, 10) : 0;
        const dailyDate: string | null = urlParams.get('dailyDate');
        // Use optional chaining and nullish coalescing to handle null value
        const dateObject: Date | null = dailyDate ? new Date(dailyDate) : null;
        // Assuming dailySummaryGraphDate is of type Date
        const dailySummaryGraphDate: Date = dateObject || new Date(); // Provide a default date if dateObject is null

        const qtrYearDiv = urlParams.get('qtryearDiv');
        const qtrYear = urlParams.get('qtryear');

        //const quarterlySummaryGraphDate: { qtr: string; year: number } = (qtrYearDiv && qtrYear) ? { 'qtr': qtrYearDiv, 'year': parseInt(qtrYear, 10) } : findCurrentQuarter(new Date());
        const quarterlySummaryGraphDate: { qtr: string; year: number } = (qtrYearDiv && qtrYear) ? { 'qtr': qtrYearDiv, 'year': parseInt(qtrYear, 10) } : {'qtr': getQuarter(getCurentDate(moment(), 'MM/DD/yyyy', true)).toString(), 'year': getCurentDate(moment(), 'yyyy')};
        const yearlySummaryGraphDate =  urlParams.get('yearlyDate') ? urlParams.get('yearlyDate') : getCurentDate(moment(), 'yyyy');

        const lineActiveTabIndex = urlParams.get('lineActiveTabIndex') ?? 0;
        const lineActiveTabLabel = urlParams.get('lineActiveTabLabel') ?? 'Daily';
        const lineActiveTab = [lineActiveTabIndex, lineActiveTabLabel];

        if (qtrYearDiv && qtrYear) {
            let myObject = {
                key: "quarterlyDate",
                value: quarterlySummaryGraphDate
            };
            urlArray.push(myObject);
        }
        if (lineActiveTab){
            let myObject1 = {
                key: "lineActiveTab",
                value: lineActiveTab
            };
            urlArray.push(myObject1);
        }
        let urlFilterDate: { key: string; value: any; }[] = [];

        urlFilterDate = urlArray.map(item => {
            
                if ('key' in item && 'value' in item) {
                    // If it's of the expected structure, return it as is
                    return item as { key: string; value: any; };
                } else {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    return { key, value };
                }
            
          });
        const inspectionFormValue: string = urlParams.get('inspectionForm') ?? '';
        const resultArray: string[] = inspectionFormValue.split(',');
        const regionFilterDD: string = urlParams.get('regionFilter') ?? 'All';
        const inspTypeFilterDD: string = urlParams.get('inspType') ?? 'All';


        this.state = {
            isLoading: false,
            regionToFilter: regionFilterDD,
            optionsForInspectionTypeDD: [{label:'All',value:"All"},{label:"Env'l Inspection",value:"Env'l Inspection"},{label:'HouseKeeping', value:'HouseKeeping'}],
            selInspType:inspTypeFilterDD,
            activeFreqTabIndex: activeIndex,
            inspectionsFormList: {},
            optionsForInspectionsDD: [],
            currentLinearInspectionForm: resultArray ? resultArray : ['All'],
            linearGraphDate: urlParams.get('linearGraphDate') ? urlParams.get('linearGraphDate') : format(new Date(), 'MM/dd/yyyy'),
            linearGraphData: [],
            dailySummaryGraphDate: dailySummaryGraphDate,
            dailySummaryGraphData: {data: [], flaggedFormsCount: 0},
            weeklySummaryGraphDate: urlParams.get('weeklyDate') ? urlParams.get('weeklyDate') : format(new Date(), 'MM/dd/yyyy'),
            weeklySummaryGraphData: {data: [], flaggedFormsCount: 0},
            monthlySummaryGraphDate: urlParams.get('monthlyDate') ? urlParams.get('monthlyDate') : format(new Date(), 'MM/dd/yyyy'),
            monthlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            quarterlySummaryGraphDate: quarterlySummaryGraphDate,
            quarterlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            yearlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            yearlySummaryGraphDate: yearlySummaryGraphDate,
            flaggedFormsList: [],
            showFlaggedFormLister: false,
            completeInCompleteForms: [],
            showCompleteIncompleteList: false,
            completeOrIncompleteModalTitle: null,
            currentCompOrIncompFreq: '',
            yearsList: [],
            YearDropdownList: [],
            apiLoading: {Daily: true, Weekly: true, Monthly: true, Quarterly: true,Yearly: true },
            printFlag: false,
            availableOptions: [{label: 'All', value: 'All'}],
            regionSelectedFilter: 'All',
            printStyle: false,
            lineChartData: [],
            urlFilterDate: urlFilterDate.length> 0 ? urlFilterDate : [],
            filteredInspType:[],
            inspTypePrintVal: false
        };
    }
  
    promise  = async () => {
        const {orgId} = this.props.loggedInUserInfo;

        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
        // Loading the list of all inspection for the organisation
        const inspectionsFormList = await getAllInspectionByOrgFac(this, orgId);

        // Getting linearGraphData for daily frequency
        const linearGraphData = await fetchCompletedInspectionsData(orgId, this); 

        const YearsDropdownList = await fetchYearDropdownList(); 
        const urlParams = new URLSearchParams(this.props.location.search);
        
        const inspectionFormValue: string = urlParams.get('inspectionForm') ?? 'All';
        const resultArray: string[] = inspectionFormValue.split(',');

        this.setState({
            linearGraphData: (linearGraphData && linearGraphData.chartData) || [],
            inspectionsFormList,
            optionsForInspectionsDD : urlParams.get('lineActiveTabLabel') === 'Daily' ? inspectionsFormList.Daily 
                                    : urlParams.get('lineActiveTabLabel') === 'Weekly' ? inspectionsFormList.Weekly 
                                    : urlParams.get('lineActiveTabLabel') === 'Monthly' ? inspectionsFormList.Monthly
                                    : urlParams.get('lineActiveTabLabel') === 'Quarterly' ? inspectionsFormList.Quarterly 
                                    : urlParams.get('lineActiveTabLabel') === 'Yearly' ? inspectionsFormList.Yearly 
                                    : inspectionsFormList.Daily, 
            YearDropdownList: YearsDropdownList,
            currentLinearInspectionForm : resultArray ? resultArray : ['All']

        });
        this.loadPromiseData();
    }

    loadPromiseData = async () => { 
        const {orgId} = this.props.loggedInUserInfo;
        
        // Loading daily form summary data
        const dailyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Daily', this);
        this.setState({  
            dailySummaryGraphData: dailyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            apiLoading: {Daily: false, Weekly: true, Monthly: true, Quarterly: true, Yearly: true }
        }, async () => {
            await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
        });

        // Loading weekly form summary data
        const weeklyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Weekly', this);
        this.setState({  
            weeklySummaryGraphData: weeklyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            apiLoading: {Daily: false, Weekly: false, Monthly: true, Quarterly: true, Yearly: true }
        }, async () => {
            await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
        });

        // Loading monthly form summary data
        const monthlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Monthly', this);
        this.setState({  
            monthlySummaryGraphData: monthlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: true, Yearly: true }
        }, async () => {
            await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
        });

        // Loading quarterly form summary data
        const quarterlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Quarterly', this);
        this.setState({  
            quarterlySummaryGraphData: quarterlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: true }
        }, async () => {
            await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
        });

        // Loading quarterly form summary data
        const yearlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Yearly', this);
        this.setState({  
            yearlySummaryGraphData: yearlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }
        }, async () => {
            await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
        }); 

    }
    reloadInspectionList = async () => {
        const {orgId} = this.props.loggedInUserInfo;
        const inspectionsFormList = await getAllInspectionByOrgFac(this, orgId); 
       let freq = 'Daily'
       switch(this.state.activeFreqTabIndex ) {
        case 0: freq = 'Daily'; break;
        case 1: freq = 'Weekly'; break;
        case 2: freq = 'Monthly'; break;
        case 3: freq = 'Quarterly'; break;
        case 4: freq = 'Yearly'; break;
       }
        this.setState({
            inspectionsFormList,
            optionsForInspectionsDD: inspectionsFormList[freq]
            // currentLinearInspectionForm : resultArray ? resultArray : ['All']
        });
    }

    reloadLinearGraphData = async () => {
        const {orgId} = this.props.loggedInUserInfo;
        let linearGraphData = await fetchCompletedInspectionsData(orgId, this);
        this.setState({
            linearGraphData: (linearGraphData && linearGraphData.chartData) || [],
        });
        await this.updateKeyValuePair('linearGraphDate', this.state.linearGraphDate)

    }

    updateKeyValuePair = async (key, value) => {

        if (key === 'Promise') {
            this.props.history?.push(`/dashboard${value}`);
        } else {
            if (value !== undefined) {
                if (( key !== 'lineActiveTab') && (key!== 'inspectionForm') && (key !== 'regionFilter') && (key !== 'inspType')) {
                    if (key !== 'quarterlyDate') {
                        value = format(new Date(value.replace(/-/g, '/')), 'MM/dd/yyyy')
                    }
                    if (key === 'yearlyDate') {
                        value = new Date(value).getFullYear()
                    }
                }
                // Check if the key already exists in the array
                const existingIndex = this.state.urlFilterDate.findIndex(pair => pair.key === key);
                if (existingIndex !== -1) {
                    // If key exists, update the value
                    const updatedKeyValuePairs = [...this.state.urlFilterDate];
                    updatedKeyValuePairs[existingIndex].value = value;
                    await this.setState({urlFilterDate:updatedKeyValuePairs});
                } else {
                    // If key doesn't exist, add a new key-value pair
                    await this.setState({urlFilterDate:[...this.state.urlFilterDate, { key, value }]});
                }
                let queryString = this.state.urlFilterDate.map(pair => pair.key==='quarterlyDate' ? 
                                                                `${'qtryearDiv'}=${pair.value.qtr}&&${'qtryear'}=${pair.value.year}` 
                                                                : pair.key==='lineActiveTab' ? `${'lineActiveTabIndex'}=${pair.value[0]}&&${'lineActiveTabLabel'}=${pair.value[1]}`
                                                                :`${pair.key}=${pair.value}`).join('&&');
                queryString = '?'+queryString
                this.props.history?.push(`/dashboard${queryString}`);
            }
        }
      };

    reloadDailyFormSummaryGraph = async (date?: any) => {
        const {orgId} = this.props.loggedInUserInfo;

        if (date) {
            this.setState({
                dailySummaryGraphDate: date,
                apiLoading: {Daily: true, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }

            }, async () => {
                const dailyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Daily', this);
                this.setState({
                    dailySummaryGraphData: dailyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    // filteredInspType:[]
                    apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }

                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                });
            });
        // const queryString = `?dailyDate=${format(date, 'yyyy-MM-dd')}&&region=${this.state.regionName}`;

        // this.props.history.push(`/reports/daily${queryString}`);
        } else {
            const dailyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Daily', this);
            this.setState({
                dailySummaryGraphData: dailyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                // filteredInspType:[]
            });
        }
        
        if (date !== undefined) {
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            await this.updateKeyValuePair('dailyDate',`${month}-${day}-${year}`)
        }
    }

    reloadWeeklyFormSummaryGraph = async (date?: any) => { 
        const {orgId} = this.props.loggedInUserInfo;
        if (date) { //format(new Date(), 'MM/dd/yyyy')
            this.setState({
                weeklySummaryGraphDate: format(new Date(date.replace(/-/g, '/')), 'MM/dd/yyyy'),
                apiLoading: {Daily: false, Weekly: true, Monthly: false, Quarterly: false, Yearly: false }
            }, async () => {
                const weeklyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Weekly', this);
                this.setState({
                    weeklySummaryGraphData: weeklyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }
                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                });
            });
        } else {
            const weeklyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Weekly', this);
            this.setState({
                weeklySummaryGraphData: weeklyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            });
        }
        await this.updateKeyValuePair('weeklyDate',date)
    } 

    reloadMonthlyFormSummaryGraph = async (date?: any) => {
        const {orgId} = this.props.loggedInUserInfo;
        if (date) {
            this.setState({
                monthlySummaryGraphDate: format(new Date(date.replace(/-/g, '/')), 'MM/dd/yyyy'),
                apiLoading: {Daily: false, Weekly: false, Monthly: true, Quarterly: false, Yearly: false }
            }, async () => {
                const monthlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Monthly', this);
                this.setState({
                    monthlySummaryGraphData: monthlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }
                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                });
            });
        } else {
            const monthlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Monthly', this);
            this.setState({
                monthlySummaryGraphData: monthlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            });
        }
        await this.updateKeyValuePair('monthlyDate',date)
    }

    reloadQtrFormSummaryGraph = async (year?: any, qtr?: any) => {
        const {orgId} = this.props.loggedInUserInfo;
        let dateStr = ''
        if (year || qtr) {
            const date = this.state.quarterlySummaryGraphDate;
            if (year) {
                date.year = year;
            }
            if (qtr) {
                date.qtr = qtr;
            }
            this.setState({
                quarterlySummaryGraphDate: date,
                apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: true, Yearly: false }
            }, async () => {
                const qtrFormSummaryGraph = await fetchFormSummaryData(orgId, 'Quarterly', this);
                this.setState({
                    quarterlySummaryGraphData: qtrFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }
                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                });
            });
            await this.updateKeyValuePair('quarterlyDate',date)

        } else {
            const qtrFormSummaryGraph = await fetchFormSummaryData(orgId, 'Quarterly', this);
            this.setState({
                quarterlySummaryGraphData: qtrFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            });
        }
    }

    reloadYearlyFormSummaryGraph = async (date?: any) => {
        const {orgId} = this.props.loggedInUserInfo;
        if (date) {
            await this.setState({
                //yearlySummaryGraphDate: format(new Date(date.replace(/-/g, '/')), 'yyyy'),
                yearlySummaryGraphDate: getCurentDate(date, 'yyyy'),
                apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: true }
            }, async () => {
                const yearlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Yearly', this);
                this.setState({
                    yearlySummaryGraphData: yearlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    apiLoading: {Daily: false, Weekly: false, Monthly: false, Quarterly: false, Yearly: false }
                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                });
            });
        } else {
            const yearlyFormSummaryGraph = await fetchFormSummaryData(orgId, 'Yearly', this);
            this.setState({
                yearlySummaryGraphData: yearlyFormSummaryGraph || {data: [], flaggedFormsCount: 0},
            });
        }
        await this.updateKeyValuePair('yearlyDate',date)
    }

    handleGlobalFilterReset = (region?: string) => {
        const {inspectionsFormList, urlFilterDate}: any = this.state;
        this.setState({
            selInspType: 'All',
            regionToFilter: region || 'All',
            currentLinearInspectionForm: ['All'],
            optionsForInspectionsDD: inspectionsFormList['Daily'],
            linearGraphDate: format(new Date(), 'MM/dd/yyyy'),
            dailySummaryGraphDate: new Date(),
            dailySummaryGraphData: {data: [], flaggedFormsCount: 0},
            weeklySummaryGraphDate: format(new Date(), 'MM/dd/yyyy'),
            weeklySummaryGraphData: {data: [], flaggedFormsCount: 0},
            monthlySummaryGraphDate: format(new Date(), 'MM/dd/yyyy'),
            monthlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            //quarterlySummaryGraphDate: findCurrentQuarter(new Date()),
            quarterlySummaryGraphDate: {'qtr': getQuarter(getCurentDate(moment(), 'MM/DD/yyyy', true)).toString(), 'year': getCurentDate(moment(), 'yyyy')},
            quarterlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            /* yearlySummaryGraphDate: format(new Date(), 'yyyy'), */
            yearlySummaryGraphDate: getCurentDate(moment(), 'yyyy'),
            yearlySummaryGraphData: {data: [], flaggedFormsCount: 0},
            urlFilterDate: [],
            filteredInspType:[],
        }, () => {
            this.reloadInspectionList();
            this.reloadLinearGraphData();
            this.reloadDailyFormSummaryGraph();
            this.reloadWeeklyFormSummaryGraph();
            this.reloadMonthlyFormSummaryGraph();
            this.reloadQtrFormSummaryGraph(); 
            this.reloadYearlyFormSummaryGraph();
            this.props.history?.push(`/dashboard`);
        });
    }
    handleInspectionTypeFormChange = async (key: any) => {
        await this.setState({
            selInspType:key || 'All',
        }
        , () => {
            this.reloadInspectionList();
            this.reloadLinearGraphData();
            this.reloadDailyFormSummaryGraph();
            this.reloadWeeklyFormSummaryGraph();
            this.reloadMonthlyFormSummaryGraph();
            this.reloadQtrFormSummaryGraph(); 
            this.reloadYearlyFormSummaryGraph();
        }
        );
        await this.updateKeyValuePair('inspType', key)
    }
    handleRegionFilterSelection = async (key: any, value: any) => {
            // this.handleGlobalFilterReset(value);
            await this.setState({
                regionToFilter: value || 'All',
            }, () => {
                this.reloadInspectionList();
                this.reloadLinearGraphData();
                this.reloadDailyFormSummaryGraph();
                this.reloadWeeklyFormSummaryGraph();
                this.reloadMonthlyFormSummaryGraph();
                this.reloadQtrFormSummaryGraph(); 
                this.reloadYearlyFormSummaryGraph();
                // this.props.history.push(`/dashboard`);
            });
            await this.updateKeyValuePair('regionFilter', value)
    }

    onChangeFrequencyTab = async (index: any, label: any) => {
        if (this.state.printFlag === false) {
            const allInspectionsList: any = this.state.inspectionsFormList;

            this.setState({
                activeFreqTabIndex: index, currentLinearInspectionForm: ['All'],
                optionsForInspectionsDD: allInspectionsList[label]
            }, () => {
                this.reloadLinearGraphData();
            });
            const keyToRemove = 'inspectionForm';
            let newArray = this.state.urlFilterDate.filter(item => item.key !== keyToRemove);

            await this.setState({
                urlFilterDate:newArray,
                filteredInspType:[]
            })
            await this.updateKeyValuePair('lineActiveTab', [index,label])
            // await this.updateKeyValuePair('lineActiveTabLabel', label)
        }
    }

    handleInspectionFormChange = (form: any) => {
        const lastValue = form[form.length - 1];
        if (lastValue === 'All' && lastValue !== undefined) {
            form = ['All']
        } else {

            if (form.length > 1) {
                _.remove(form, (w) => {
                    return w === 'All';
                });
            }
        }
        this.setState({
            currentLinearInspectionForm: form,
        });

        this.handleInspectionDDFilter(form)
        this.updateKeyValuePair('inspectionForm', form)

    }

    handleInspectionDDFilter = (form : any) => {

        let mainArray;
        let value = this.state.activeFreqTabIndex;

        switch (value) {
            case 0:
                mainArray = this.state.dailySummaryGraphData;
                break;
            case 1:
                mainArray = this.state.weeklySummaryGraphData;
                break;
            case 2:
                mainArray = this.state.monthlySummaryGraphData;
                break;
            case 3:
                mainArray = this.state.quarterlySummaryGraphData;
                break;
            case 4:
                mainArray = this.state.yearlySummaryGraphData;
                break;
            default:
                mainArray = this.state.dailySummaryGraphData;
                break;
        }
        let filteredDataArr;

        if (form != 'All' && form.length > 0) {
            const filteredData = mainArray['data'].filter(item => form.includes(item.parentid));
            let incr_variable = 0;

            mainArray['data'].forEach(item => {
                if (item.flagged !== 0 && form.includes(item.parentid)) {
                    incr_variable++;
                }
            });

            filteredDataArr = {data: filteredData, flaggedFormsCount: incr_variable, status: mainArray['status'], StatusCode: mainArray['StatusCode']}
        } else {
            filteredDataArr = [];
        }
        
        this.setState({
            filteredInspType :filteredDataArr
        });

    }

    handleLinearChartDateFilter = (value: any) => {
        /* let date = format(new Date(value), 'MM/dd/yyyy'); */ 
        let date = moment(value.replace(/-/g, '/')).format('MM/DD/yyyy'); 
        this.setState({
            linearGraphDate: date
        }, () => {
            this.reloadLinearGraphData();
        });
    }

    inspectionFormFilterOnChange = () => {
        const allData = this.state.linearGraphData;
        const currentInspectionForms = this.state.currentLinearInspectionForm;

        if (typeof currentInspectionForms === 'object' && currentInspectionForms.length > 0 && currentInspectionForms[0] !== 'All') {
            const parentIdsIdToShow = _.concat(['xName'], this.state.currentLinearInspectionForm);
            const formNames: Array<any> = [];
            parentIdsIdToShow.forEach((parentId) => {
                if (parentId === 'xName') {
                    formNames.push(parentId);
                } else {
                    const data: any = _.find(this.state.optionsForInspectionsDD, {value: parentId});
                    if (data) {
                        formNames.push(data.label);
                    }
                }
            });
            return _.map(allData, o => _.pick(o, formNames));
        }
        return allData;
    }

    renderCustomToolTip = (e: any) => {
        return <CustomToolTip eventObject={e}/>;
    }

    handleClickOnFormSummaryBar = async (e: any, i: any, freq: string) => {
        if (this.state.printFlag === false) {
           /*  const color = i.target && i.target.getAttribute && i.target.getAttribute('fill');
            const parentId = e.activePayload[0].payload.parentid; */
            const parentId = e.parentid;
            const color = e.tooltipPayload[0].color;
            const inspectionName =  e.xName;
            let formsList: any = null;
            if (color === '#63AB66') {
                formsList = await fetchCompleteOrIncompleteInspections('complete', freq, parentId, this);
                this.setState({
                    //completeOrIncompleteModalTitle: 'Completed Plants',
                    completeOrIncompleteModalTitle: `Completed Plants -  ${inspectionName}` ,
                    completeInCompleteForms: formsList,
                    showCompleteIncompleteList: true,
                    currentCompOrIncompFreq: freq
                });

            } else if (color === '#eaa866') {
                formsList = await fetchCompleteOrIncompleteInspections('Incomplete', freq, parentId, this);
                this.setState({
                    completeOrIncompleteModalTitle: `Incompleted Plants -  ${inspectionName}` ,
                    completeInCompleteForms: formsList,
                    showCompleteIncompleteList: true,
                    currentCompOrIncompFreq: freq
                });
            }
        }
    }

    loadFlaggedFormsList = async (freq: string) => {
        const {orgId} = this.props.loggedInUserInfo;
        const flaggedInspectionsFormList = await fetchFlaggedFormsList(freq, orgId, this);
        this.setState({
            flaggedFormsList: flaggedInspectionsFormList.flaggedForms,
            showFlaggedFormLister: true
        });
    }
    renderEmptyChart() {
        return (
            <div style={{textAlign: 'center', padding: '60px', fontSize: '14px'}}><i className="fa fa-exclamation-circle color-danger"></i> No Details found</div>
        )
    }
    renderLoadingChart() {
        return (
            <div style={{textAlign: 'center', padding: '60px', fontSize: '14px'}}> <img alt={'loader'} src={loaderImage} width="60px"/> </div>
        )
    }
    getRegionsList = async () => {
        let availableOptions = [{label: 'All', value: 'All'}];
        if (this.loggedInUserInfo.Access_Level === 'L3' || this.loggedInUserInfo.Access_Level === 'L4') {
            availableOptions = _.concat(availableOptions, frameSelectOptions(this.loggedInUserInfo.Regions, 'regionId', 'regionName'));
            this.setState({
                availableOptions
            });
        } else if (this.loggedInUserInfo.orgId) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId).then(res => {
                availableOptions = _.concat(availableOptions, frameSelectOptions(res, 'regionId', 'regionName'));
                this.setState({
                    availableOptions
                });
            });
        }
        for (let i = 0; i <availableOptions.length; i++) {
            let reglabel = availableOptions[i].label
            if (availableOptions[i].value === this.state.regionToFilter){
                this.setState({regionSelectedFilter:reglabel})
            }
           
        }
    }
    exportLineChart = async () => {
        let buttonLabel=['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']

        const allInspectionsList: any = this.state.inspectionsFormList;
        const {orgId} = this.props.loggedInUserInfo;
        let {linearGraphDate, activeFreqTabIndex, regionToFilter, selInspType} = this.state;
        // let LineChartArr = []
        const LineChartArr: Array<Object> = [{'Daily' : []},{'Weekly':[]},{'Monthly' : []},{'Quarterly':[]},{'Yearly':[]}];
        let labelarr = buttonLabel
        labelarr = buttonLabel;
        for (let i = 0; i < buttonLabel.length; i++) {
           
            let response = await getCompletedLineData(orgId, linearGraphDate, i, regionToFilter, selInspType);
            let labels = buttonLabel[i];
            LineChartArr[labels] = response.chartData
        }
        await this.setState({
            lineChartData: LineChartArr,
            printFlag : true,
            isLoading : false
        })
    }
    renderContent = () => { 
        let inspectionFormData = this.inspectionFormFilterOnChange();
        let currFreqTabIndex = this.state.activeFreqTabIndex;
        let DCnt = this.state.dailySummaryGraphData['data'].length
        let WCnt = this.state.weeklySummaryGraphData['data'].length
        let MCnt = this.state.monthlySummaryGraphData['data'].length
        let QCnt = this.state.quarterlySummaryGraphData['data'].length;
        let YCnt = this.state.yearlySummaryGraphData['data'].length
        
        return <div key={1} className={this.state.printFlag === true ? `dashboard-print` : `dashboard`} >
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            {this.state.showCompleteIncompleteList && <ListModal
                className='dashboard-inspection-status'
                id="dashboard"
                listTitle={this.state.completeOrIncompleteModalTitle}
                data={this.state.completeInCompleteForms}
                parentInstance={this}
                listHeadings={['#', 'Plant Id', 'Remind']}
                listItemComponent={PlantListItem}
                closePopup={() => {
                    this.setState({
                        completeOrIncompleteModalTitle: '',
                        completeInCompleteForms: [],
                        showCompleteIncompleteList: false,
                        currentCompOrIncompFreq: ''
                    });
                }}
            />}
            {this.state.showFlaggedFormLister && <DetailsModal
                id="flagged-forms"
                className="dashboard-flagged-form-list"
                data={this.state.flaggedFormsList}
                style={styles.flaggedFormsListStyle}
                modalTitle="Flagged Forms"
                listHeadings={['Plant Name', 'Inspection Name', 'Region', 'Facility Type / Permit Type', 'Completed']}
                history={this.props.history}
                listItemComponent={FlaggedFormListItem}
                onClose={() => {
                    this.setState({
                        flaggedFormsList: [],
                        showFlaggedFormLister: false
                    });
                }}
            />}
             {this.state.printFlag === true ? <div className="print-title-row">
             <Row>
                <Col sm={3} md={3} lg={3}></Col>
                <Col sm={6} md={6} lg={6}>
                    <div className="print-title">Dashboard
                    </div>    
                </Col>
                <Col sm={3} md={3} lg={3}>
                    {/* <div className="pdf-pagebreak-before"></div> */}
                </Col>
            </Row>
            </div> : ''}
            {/* <div className="html2pdf__page-break" /> */}
            <Row>
                <Col sm={12} md={2} lg={2}>
                    <div className={this.state.printFlag === true ? "region-filter-title inspType-filter-title" : "region-filter-title inspTypeDD"} ><DropdownTitle>Inspection Type</DropdownTitle></div>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    {/* <div className={this.state.printFlag === true ? `print-disabled`:``}> */}
                    {/* {this.state.printFlag === true ?  */}
                        <div className={this.state.printFlag === true ? 'region-filter region-align print-disabled' : 'region-filter'}>
                        {/* {this.state.inspTypePrintVal === true ?  <DropdownTitle className='inspTypePrintValue'>{this.state.selInspType}</DropdownTitle> : ''}  */}
                        <SelectDropdown
                            id={'inspection-list-dropdown'}
                            menuItems={this.state.optionsForInspectionTypeDD} 
                            onChange={this.handleInspectionTypeFormChange}
                            isMultiSelect={false}
                            placeholder={this.state.printFlag === true ? " " : ' '}
                            defaultValue={this.state.selInspType}
                        />
                        </div>
                </Col>
                <Col sm={12} md={2} lg={2}>
                    <div className={this.state.printFlag === true ? "region-filter-title reg-print-filter-title" : "region-filter-title regionDDTitle"}><DropdownTitle>Region</DropdownTitle></div>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    {/* <div className={this.state.printFlag === true ? `print-disabled`:``}> */}
                    {/* {this.state.printFlag === true ?  */}
                        <div className={this.state.printFlag === true ? 'region-filter region-align' : ''}>
                        {/* {this.state.printFlag === true && this.state.regionSelectedFilter !== 'All' ?  <DropdownTitle>{this.state.regionSelectedFilter}</DropdownTitle> : ''} */}
                        <RegionFilter
                            id={'region-filter'}
                            selectedValue={this.state.regionToFilter}
                            // className={`region-filter`}
                            className={this.state.printFlag === true ? `region-filter prints-disabled` : `region-filter`}
                            key="region"
                            onChange={this.handleRegionFilterSelection}
                            regionList={this.props.regionList}
                        />
                        </div>
                </Col>
                <Col sm={12} md={2} lg={2}>
                    {this.state.printFlag === false ?
                    <div className="reset-filters">
                        <button className="reset-button" style={{padding: '0 5px'}} onClick={() => {
                            this.handleGlobalFilterReset();
                        }}>
                            Remove Filters
                        </button>
                    </div>
                    : ''}
                </Col>
            </Row>
            <Row>
                <div className={this.state.printFlag === true ? `toggle-container print-disabled lineChart-export`: `toggle-container`} >
                    <ToggleSwitch
                        buttonLabel={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
                        onChangeTab={this.onChangeFrequencyTab}
                        activeIndexOnLoad={this.state.activeFreqTabIndex}
                    />
                </div> 
                {/* <div className={this.state.printFlag === true ? `toggle-container print-disabled`: `toggle-container`} >
                    <ToggleSwitch
                        buttonLabel={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
                        onChangeTab={this.onChangeFrequencyTab}
                        activeIndexOnLoad={this.state.activeFreqTabIndex}
                    />
                </div> */}
            </Row>
            <Row>
                <Col sm={12} md={1} lg={1}>
                    <div className="inspection-filter-title"><DropdownTitle>Inspection</DropdownTitle></div>
                </Col>
                <Col sm={12} md={7} lg={7}>
                    <div className={this.state.printFlag === false ? `region-filter-inline inspection-form-filter `: `region-filter-inline inspection-form-filter print-disabled`} key="inspection-form">
                        <SelectDropdown
                            id={'inspection-list-dropdown'}
                            menuItems={this.state.optionsForInspectionsDD}
                            // menuItems={this.state.YearDropdownList}
                            onChange={this.handleInspectionFormChange}
                            isMultiSelect={true}
                            placeholder="Inspection"
                            defaultValue={this.state.currentLinearInspectionForm}
                        />
                    </div>
                </Col>
                <Col sm={12} md={4} lg={4} className='dashboard-filter-text-center'>
                    <div className={this.state.printFlag === false ? "filter-container select-dropdown" : "filter-container prints-disabled select-dropdown"} >
                        { (this.state.activeFreqTabIndex === 0 || this.state.activeFreqTabIndex === 1) && <MonthPicker
                            defaultValue={this.state.linearGraphDate}
                            years={[]}
                            onClick={this.handleLinearChartDateFilter}
                            popover={true}
                        />}
                        {this.state.activeFreqTabIndex !== 0 && this.state.activeFreqTabIndex !== 1 && this.state.activeFreqTabIndex !== 4 && <SelectDropdown
                            id={'linear-graph-year-selector'}
                            menuItems={this.state.YearDropdownList}
                            onChange={this.handleLinearChartDateFilter}
                            placeholder="Select"
                            defaultValue={format(new Date(this.state.linearGraphDate), 'yyyy')}
                        />}
                    </div>
                </Col>
            </Row>
            {/* <Row className={this.state.printFlag === true ? "show-grid line-chart-container line-chart-height" : "show-grid line-chart-container"}>
                <Col sm={12} xs={12} style={inspectionFormData.length>0 ? linearChartStyles.colStyle : {} }>
                    {inspectionFormData.length>0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive"
                            data={inspectionFormData}
                        /> : 
                        this.renderEmptyChart() }
                </Col>
            </Row> */}
            <Row className={this.state.printFlag === true ? "show-grid line-chart-container lineChart-export line-chart-height" : "show-grid line-chart-container"}>
                <Col sm={12} xs={12} style={inspectionFormData.length>0 ? linearChartStyles.colStyle : {} }>
                    {inspectionFormData.length > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={inspectionFormData}
                        /> : 
                        this.renderEmptyChart() }
                </Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid pt20 lineChart-export" : "show-grid pt20"}>
                <Col sm={8} xs={8}> <LineChartTitle>Daily Line Chart</LineChartTitle></Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container lineChart-export" : "show-grid line-chart-container"}>
            {this.state.printFlag === true ?
                <Col sm={12} xs={12} className='line-Chart-export' style={this.state.lineChartData.length>0 ? linearChartStyles.colStyle : {} }>
                    { DCnt > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={this.state.activeFreqTabIndex === 0 ? inspectionFormData : this.state.lineChartData['Daily']}
                        /> : 
                        this.renderEmptyChart()}
                </Col>
                : ''}
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid pt20 lineChart-export" : "show-grid pt20"}>
                <Col sm={8} xs={8}> <LineChartTitle>Weekly Line Chart</LineChartTitle></Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container lineChart-export" : "show-grid line-chart-container"}>
            {this.state.printFlag === true ?
                <Col sm={12} xs={12} className='line-Chart-export legend-style' style={this.state.lineChartData.length>0 ? linearChartStyles.colStyle : {} }>
                    { WCnt > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={this.state.activeFreqTabIndex === 1 ? inspectionFormData : this.state.lineChartData['Weekly']}
                        /> : 
                        this.renderEmptyChart() }
                </Col> : '' }
            </Row>
            <p className="break-before"></p>
            <Row className={this.state.printFlag === false ? "show-grid pt20 lineChart-export" : "show-grid pt20"}>
                <Col sm={8} xs={8}> <LineChartTitle>Monthly Line Chart</LineChartTitle></Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container lineChart-export" : "show-grid line-chart-container"}>
            {this.state.printFlag === true ?
                <Col sm={12} xs={12} className='line-Chart-export' style={this.state.lineChartData.length>0 ? linearChartStyles.colStyle : {} }>
                    { MCnt > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={this.state.activeFreqTabIndex === 2 ? inspectionFormData : this.state.lineChartData['Monthly']}
                        /> : 
                        this.renderEmptyChart() }
                </Col> : ''}
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid pt20 lineChart-export" : "show-grid pt20"}>
                <Col sm={8} xs={8}> <LineChartTitle>Quarterly Line Chart</LineChartTitle></Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container lineChart-export" : "show-grid line-chart-container"}>
            {this.state.printFlag === true ?
                <Col sm={12} xs={12} className='line-Chart-export' style={this.state.lineChartData.length>0 ? linearChartStyles.colStyle : {} }>
                    { QCnt > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={this.state.activeFreqTabIndex === 3 ? inspectionFormData : this.state.lineChartData['Quarterly']}
                        /> : 
                        this.renderEmptyChart() }
                </Col> : ''} 
            </Row>
            <p className="break-before"></p>
            <Row className={this.state.printFlag === false ? "show-grid pt20 lineChart-export" : "show-grid pt20"}>
                <Col sm={8} xs={8}> <LineChartTitle>Yearly Line Chart</LineChartTitle></Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container lineChart-export" : "show-grid line-chart-container"}>
            {this.state.printFlag === true ?
                <Col sm={12} xs={12} className='line-Chart-export' style={this.state.lineChartData.length>0 ? linearChartStyles.colStyle : {} }>
                    { YCnt > 0 ?
                        <LineChart
                            onClick={() => {
                                return false;
                            }}
                            className="line-chart-responsive" 
                            data={this.state.activeFreqTabIndex == 4 ? inspectionFormData : this.state.lineChartData['Yearly']}
                        /> : 
                        this.renderEmptyChart() }
                </Col> : ''}
            </Row>
            {/* <p>{this.state.dailySummaryGraphData['data'].length}</p>
            <p>{this.state.weeklySummaryGraphData['data'].length}</p>
            <p>{this.state.monthlySummaryGraphData['data'].length}</p>
            <p>{this.state.quarterlySummaryGraphData['data'].length}</p>
            <p>{this.state.yearlySummaryGraphData['data'].length}</p> */}
            {/* <div className="html2pdf__page-break" id='html2pdf__page-break' /> */}
            {/* {(DCnt) > 20 ? <p className="break-before"></p>  : ''} */}
            
            <Row className="show-grid pt20 closingParagraph" >
                <Col /* sm={8} xs={8} */ xs={12} sm={8} md={8} lg={8} xl={8} > <ChartTitle>Daily Form Summary</ChartTitle>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                    <div className={this.state.printFlag === false ? "filter-container dashboard-filter-text-center" : "filter-container prints-disabled"} >
                        <RDatePicker
                            value={this.state.dailySummaryGraphDate}
                            onChange={this.reloadDailyFormSummaryGraph}/>
                    </div>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={12} xs={12} style={barChartStyles.colStyle}>
                    {this.state.apiLoading.Daily ? this.renderLoadingChart(): 
                        <BarChart
                            customTooltip={(e: any) => this.renderCustomToolTip(e)}
                            onClick={(e, i) => {
                                this.handleClickOnFormSummaryBar(e, i, 'Daily');
                            }}
                            dataKey={['Complete', 'InComplete']}
                            data={this.state.filteredInspType.length === undefined && currFreqTabIndex === 0 ? this.state.filteredInspType['data'] : this.state.dailySummaryGraphData['data']}
                        />  }
                </Col>
            </Row>
            {/* this.state.dailySummaryGraphData['data'].length>0 &&  */ 
                <Row className={this.state.printFlag === false ? "show-grid line-chart-container" : "show-grid line-chart-container prints-disabled"} >
                    <Col xs={12} sm={3} md={3} lg={3} xl={3} />
                    <Col xs={6} sm={3} md={3} lg={3} xl={3} >
                    {this.state.printFlag === false ?
                        <div
                            className="footer-button"
                            style={{display: 'inline-block', margin: '5px 10px'}}
                            title={`Click to View all Daily Forms`}
                        >
                            <button className='view-details-button'
                                onClick={() => {
                                    this.props.history?.push('reports/daily?date=' + format(new Date(this.state.dailySummaryGraphDate), 'yyyy-MM-dd') + '&&region=' + this.state.regionToFilter);
                                }}
                                style={styles.viewAllFormsButtonStyle}
                            >
                                View All Daily Forms
                            </button>
                        </div> : ''}
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3} xl={3} >
                        <div style={{display: 'inline-block', margin: '5px 10px'}}
                            title={`Click to View Flagged Daily Forms`}
                            className={this.state.printFlag === true ? 'FlaggedBtnCenter' : ''}
                        >
                            <button className='view-details-button'
                                onClick={() => this.loadFlaggedFormsList('Daily')}
                                style={styles.viewFlaggedForms as React.CSSProperties}
                            >
                                Flagged Daily Forms : {this.state.filteredInspType.length === undefined && currFreqTabIndex === 0 ? ( this.state.filteredInspType['flaggedFormsCount'] || 0) : ( this.state.dailySummaryGraphData['flaggedFormsCount'] || 0) }
                            </button>
                        </div>

                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3} />
                </Row> 
            }
            <p className="break-before"></p>

            {/* <div className="html2pdf__page-break" /> */}
            {/* <p className="break-before">Break before</p> */}
            {/* <p className="break-after">Break after</p> */}

            {/*Weekly form summary*/}
            <Row className="show-grid pt20">
                <Col xs={12} sm={8} md={8} lg={8} xl={8}> <ChartTitle>Weekly Form Summary</ChartTitle></Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={this.state.printFlag === false ? "filter-container dashboard-filter-text-center" : "filter-container prints-disabled"}> 
                        <RWeeklyPicker
                            format="MM-DD-YYYY"
                            maxDate={new Date()}
                            value={new Date(this.state.weeklySummaryGraphDate)}
                            onChange={this.reloadWeeklyFormSummaryGraph}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={12} xs={12} style={barChartStyles.colStyle}>
                {this.state.apiLoading.Weekly ? this.renderLoadingChart(): <BarChart
                        customTooltip={(e: any) => this.renderCustomToolTip(e)}
                        onClick={(e, i) => {
                            this.handleClickOnFormSummaryBar(e, i, 'Weekly');
                        }}
                        dataKey={['Complete', 'InComplete']}
                        data={this.state.filteredInspType.length === undefined && currFreqTabIndex === 1 ? this.state.filteredInspType['data'] : this.state.weeklySummaryGraphData['data']}
                    /> }
                </Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container" : "show-grid line-chart-container prints-disabled"}>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} />
                <Col xs={6} sm={3} md={3} lg={3} xl={3} >
                {this.state.printFlag === false ?
                    <div
                        className="footer-button"
                        style={{display: 'inline-block', margin: '5px 10px'}}
                        title={`Click to View all Weekly Forms`}
                    >
                        <button className='view-details-button'
                            onClick={() => {
                                this.props.history?.push('reports/weekly?date=' + format(new Date(this.state.weeklySummaryGraphDate), 'yyyy-MM-dd') + '&&region=' + this.state.regionToFilter);
                            }}
                            style={styles.viewAllFormsButtonStyle}
                        >
                            View All Weekly Forms
                        </button>
                    </div> : ''}
                </Col>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    <div style={{display: 'inline-block', margin: '5px 10px'}}
                         title={`Click to View Flagged Weekly Forms`}
                         className={this.state.printFlag === true ? 'FlaggedBtnCenter' : ''}
                    >
                        <button className='view-details-button'
                            onClick={() => this.loadFlaggedFormsList('Weekly')}
                            style={styles.viewFlaggedForms as React.CSSProperties}
                        >
                            Flagged Weekly Forms : {this.state.filteredInspType.length === undefined && currFreqTabIndex === 1 ? ( this.state.filteredInspType['flaggedFormsCount'] || 0 ) : ( this.state.weeklySummaryGraphData['flaggedFormsCount'] || 0 )}
                        </button>
                    </div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
            </Row> 

            {(WCnt + MCnt) > 20 ? <p className="break-before"></p>  : ''}
            
            {/*Monthly form summary*/}
            <Row className="show-grid pt20">
                <Col xs={12} sm={8} md={8} lg={8} xl={8}> <ChartTitle>Monthly Form Summary</ChartTitle></Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={this.state.printFlag === false ? "filter-container select-dropdown__DEL dashboard-filter-text-center" : "filter-container prints-disabled select-dropdown"}>
                        <MonthPicker
                            defaultValue={this.state.monthlySummaryGraphDate}
                            years={[]}
                            onClick={this.reloadMonthlyFormSummaryGraph}
                            popover={true}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={12} xs={12} style={barChartStyles.colStyle}>
                    { this.state.apiLoading.Monthly ? this.renderLoadingChart(): <BarChart
                        customTooltip={(e: any) => this.renderCustomToolTip(e)}
                        onClick={(e, i) => {
                            this.handleClickOnFormSummaryBar(e, i, 'Monthly');
                        }}
                        dataKey={['Complete', 'InComplete']}
                        data={this.state.filteredInspType.length === undefined && currFreqTabIndex === 2 ? this.state.filteredInspType['data'] : this.state.monthlySummaryGraphData['data']}
                    /> }
                </Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container" : "show-grid line-chart-container prints-disabled"}>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    {this.state.printFlag === false ?
                    <div
                        className="footer-button"
                        style={{display: 'inline-block', margin: '5px 10px'}}
                        title={`Click to View all Monthly Forms`}
                    >
                        <button className='view-details-button'
                            onClick={() => {
                                this.props.history?.push('reports/monthly?date=' + format(new Date(this.state.monthlySummaryGraphDate), 'MM-01-yyyy') + '&&region=' + this.state.regionToFilter);
                            }}
                            style={styles.viewAllFormsButtonStyle}
                        >
                            View All Monthly Forms
                        </button>
                    </div> : ''}
                </Col>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    <div style={{display: 'inline-block', margin: '5px 10px'}}
                         title={`Click to View Flagged Monthly Forms`}
                         className={this.state.printFlag === true ? 'FlaggedBtnCenter' : ''}
                    >
                        <button className='view-details-button'
                            onClick={() => this.loadFlaggedFormsList('Monthly')}
                            style={styles.viewFlaggedForms as React.CSSProperties}
                        >
                            Flagged Monthly Forms : {this.state.filteredInspType.length === undefined && currFreqTabIndex === 2 ? ( this.state.filteredInspType['flaggedFormsCount'] || 0 ) : ( this.state.monthlySummaryGraphData['flaggedFormsCount'] || 0 ) }
                        </button>
                    </div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
            </Row>
            <p className="break-before"></p>

            {/*Quarterly form summary*/}
            <Row className="show-grid quarterly-form-summary pt20">
                <Col sm={this.state.printFlag === false ? 6 : 6} xs={this.state.printFlag === false ? 12 : 12}> <ChartTitle>Quarterly Form Summary</ChartTitle></Col>
                <Col sm={this.state.printFlag === false ? 3 : 4} xs={this.state.printFlag === false ? 6 : 6}>
                    <div className={this.state.printFlag === false ? "filter-container" : "filter-container prints-disabled"}>
                        <SelectDropdown className={'quarterly-form-filter'}
                            // menuItems={yearsList}
                            menuItems={this.state.YearDropdownList}
                            onChange={(e: ChangeEvent) => this.reloadQtrFormSummaryGraph(e, null)}
                            placeholder="Select"
                            defaultValue={this.state.quarterlySummaryGraphDate.year.toString()}
                        />
                    </div>
                </Col>
                <Col sm={this.state.printFlag === false ? 3 : 2} xs={this.state.printFlag === false ? 6 : 6}>
                    <div className={this.state.printFlag === false ? "filter-container" : "filter-container prints-disabled"}>
                        <SelectDropdown className={'quarterly-form-filter'}
                           /*  menuItems={[{label: 'First', value: '1st'}, {
                                label: 'Second',
                                value: '2nd'
                            }, {label: 'Third', value: '3rd'}, {label: 'Fourth', value: '4th'}]} */
                            menuItems={[{label: 'First', value: '1'}, { label: 'Second', value: '2' }, {label: 'Third', value: '3'}, {label: 'Fourth', value: '4'}]}
                            onChange={(e: any) => this.reloadQtrFormSummaryGraph(null, e)}
                            placeholder="Select"
                            defaultValue={this.state.quarterlySummaryGraphDate.qtr}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={12} xs={12} style={barChartStyles.colStyle}>
                    { this.state.apiLoading.Quarterly ? this.renderLoadingChart(): <BarChart
                        customTooltip={(e: any) => {
                            return this.renderCustomToolTip(e);
                        }}
                        onClick={(e: any, i: any) => {
                            this.handleClickOnFormSummaryBar(e, i, 'Quarterly');
                        }}
                        dataKey={['Complete', 'InComplete']}
                        data={this.state.filteredInspType.length === undefined && currFreqTabIndex === 3 ? this.state.filteredInspType['data'] : this.state.quarterlySummaryGraphData['data']}
                    /> }
                </Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container" : "show-grid line-chart-container prints-disabled"}>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                {this.state.printFlag === false ?
                    <div
                        className="footer-button"
                        style={{display: 'inline-block', margin: '5px 10px'}}
                        title={`Click to View all Quarterly Forms`}
                    >
                        <button className='view-details-button'
                            onClick={() => {
                                /* const qtr = this.state.quarterlySummaryGraphDate.qtr === '1st' ? '1' : this.state.quarterlySummaryGraphDate.qtr === '2nd' ? '2' : this.state.quarterlySummaryGraphDate.qtr === '3rd' ? '3' : '4'; */
                                const qtr = this.state.quarterlySummaryGraphDate.qtr;
                                this.props.history?.push('reports/quarterly?year=' + this.state.quarterlySummaryGraphDate.year + '&&quarter=' + qtr + '&&region=' + this.state.regionToFilter);
                            }}
                            style={styles.viewAllFormsButtonStyle}
                        >
                            View All Quarterly Forms
                        </button>
                    </div> : ''}
                </Col>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    <div style={{display: 'inline-block', margin: '5px 10px'}}
                         title={`Click to View Flagged Quarterly Forms`}
                         className={this.state.printFlag === true ? 'FlaggedBtnCenter' : ''}
                    >
                        <button className='view-details-button'
                            onClick={() => this.loadFlaggedFormsList('Quarterly')}
                            style={styles.viewFlaggedForms as React.CSSProperties}
                        >
                            Flagged Quarterly Forms : {this.state.filteredInspType.length === undefined && currFreqTabIndex === 3 ? ( this.state.filteredInspType['flaggedFormsCount'] || 0 ) : ( this.state.quarterlySummaryGraphData['flaggedFormsCount'] || 0 ) }
                        </button>
                    </div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
            </Row> 
            {(QCnt + YCnt) > 20 ? <p className="break-before"></p>  : ''}

            {/*Yearly form summary*/}
            <Row className="show-grid quarterly-form-summary pt20">
                <Col xs={12} sm={8} md={8} lg={8} xl={8}> <ChartTitle>Yearly Form Summary</ChartTitle></Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={this.state.printFlag === false ? "filter-container dashboard-filter-text-center" : "filter-container prints-disabled"} >
                        <SelectDropdown className={'quarterly-form-filter'} 
                            menuItems={this.state.YearDropdownList}
                            onChange={(e: ChangeEvent) => this.reloadYearlyFormSummaryGraph(e)}
                            placeholder="Select"
                            defaultValue={this.state.yearlySummaryGraphDate.toString()}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={12} xs={12} style={barChartStyles.colStyle}>
                    { this.state.apiLoading.Yearly ? this.renderLoadingChart(): <BarChart
                        customTooltip={(e: any) => this.renderCustomToolTip(e)}
                        onClick={(e, i) => {
                            this.handleClickOnFormSummaryBar(e, i, 'Yearly');
                        }}
                        dataKey={['Complete', 'InComplete']}
                        data={this.state.filteredInspType.length === undefined && currFreqTabIndex === 4 ? this.state.filteredInspType['data'] : this.state.yearlySummaryGraphData['data']}
                    /> }
                </Col>
            </Row>
            <Row className={this.state.printFlag === false ? "show-grid line-chart-container" : "show-grid line-chart-container prints-disabled"}>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    {this.state.printFlag === false ?
                    <div
                        className="footer-button"
                        style={{display: 'inline-block', margin: '5px 10px'}}
                        title={`Click to View all yearly Forms`}
                    >
                        <button className='view-details-button'
                            onClick={() => {
                                this.props.history?.push('reports/yearly?region=' + this.state.regionToFilter + '&&year=' + this.state.yearlySummaryGraphDate);
                            }}
                            style={styles.viewAllFormsButtonStyle}
                        >
                            View All Yearly Forms
                        </button>
                    </div> : '' }
                </Col>
                <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                    <div style={{display: 'inline-block', margin: '5px 10px'}}
                         title={`Click to View Flagged Yearly Forms`}
                         className={this.state.printFlag === true ? 'FlaggedBtnCenter' : ''}
                    >
                        <button className='view-details-button'
                            onClick={() => this.loadFlaggedFormsList('Yearly')}
                            style={styles.viewFlaggedForms as React.CSSProperties}
                        >
                            Flagged Yearly Forms : {this.state.filteredInspType.length === undefined && currFreqTabIndex === 4 ? ( this.state.filteredInspType['flaggedFormsCount'] || 0 ) : ( this.state.yearlySummaryGraphData['flaggedFormsCount'] || 0 ) }
                        </button>
                    </div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}/>
            </Row>

        </div>;
    }
    ref: any;
    exportChart = (ref: any) => {
        printDetails(this.ref, 'dashboard');
    }
    printAction = async () => {
        await this.setState({
            isLoading : true,
            // inspTypePrintVal: true
            // lineChartData : listLineChart
        })
        // window.print()
        this.getRegionsList();
        this.exportLineChart()        
        await showPrintPreviewModal('dashboard');

        
    }

    renderPrintButton = () => {
        return <div style={{
            position: 'absolute',
            right: '20px',
        }} className="button d-print-none" onClick={this.printAction} id="print-button">
            
            &nbsp;
            <span>Export as PDF </span>&nbsp;&nbsp;
            <i className="fa fa-file-pdf-o" aria-hidden="true"/>
        </div>;
    }
    handlePrint = async (childData) => {
        this.setState({printFlag: childData,isLoading :true}
        , async () => {
            await this.renderContent()
        });
        // await this.renderContent()
        this.setState({isLoading : false, inspTypePrintVal: false});

    }
    handlePrintStyle= (childData) => {
        this.setState({inspTypePrintVal: true});
        // this.renderContent()
    }
    render() {
        return (
            <BasePage sideButton={this.renderPrintButton()} pageTitle="Dashboard" className='dashoard-content'>
                {this.state.printFlag === true ?
                <PrintPreviewModal
                    id="dashboard"
                    ref="printPreview"
                    onClickYes={this.exportChart}
                    style={{ boxShadow: 'none' }}
                    fileName="dashboard"
                    className="dashboard"
                    onSelectPrint={this.handlePrint}
                    onSelectPrintStyle={this.handlePrintStyle}
                >
                    {this.renderContent()}
                </PrintPreviewModal> : ''}
                <Async
                    identifier={'Dashboard'}
                    promise={this.promise}
                    loader={<Loader type="async"/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }

}

export function mapStateToProps(state: any) {
    const regionList = RegionModel.list();
    return {
        loggedInUserInfo: parseJwt(state.login.get('token')), regionList
    };
}

export const ChartsContainer = withRouter(connect<IChartContainerProps, any, any>(mapStateToProps)(ChartsContainerImpl as any));

const linearChartStyles: any = {
    rowStyle: {
        margin: 0
    },
    colStyle: {
        float: 'none',
        display: 'inline-block',
        height: '330px'
    },
};

const barChartStyles: any = {
    rowStyle: {
        margin: 0
    },
    colStyle: {
        float: 'none',
        display: 'inline-block',
        marginLeft: '5vw'
    },
};

const ChartTitle = ({children}: any) => {
    return <div className="chart-title"
                style={{fontSize: '20px', margin: 'auto', textAlignLast: 'center'}}>{children}</div>;
};
const LineChartTitle = ({children}: any) => {
    return <div className="chart-title"
                style={{fontSize: '20px', margin: 'auto', textAlignLast: 'left'}}>{children}</div>;
};

const DropdownTitle = ({children}: any) => {
    return <div className="dropdown-title"
                style={{fontSize: '15px', margin: 'auto', textAlignLast: 'center'}}>{children}</div>;
};

const styles = {
    viewAllFormsButtonStyle: {
        border: 'none',
        padding: '0 15px',
        height: '55px',
        outline: 'none',
        background: '#a4bf43',
        borderRadius: '5px',
    },
    viewAllFormsButtonContainer: {
        position: 'absolute',
        bottom: '4%',
        width: '90%',
        textAlign: 'center',
    },
    viewFlaggedForms: {
        border: 'none',
        padding: '0 15px',
        height: '55px',
        outline: 'none',
        background: '#a4bf43',
        position: 'relative',
        borderRadius: '5px',
        textAlign: 'center',
    },
    flaggedFormCount: {
        height: '10px',
        width: '10px',
        padding: '8px',
        borderLeft: '1px solid',
        marginLeft: '8px'
    },
    flaggedFormsListStyle: {
        zIndex: 9999999
    }
};
