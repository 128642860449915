import * as React from 'react';
import {BaseModel} from './BaseModel';
import {IQuestions} from '../interfaces';

export interface IQuestionModelProps {
    question?: string;
    quesNum?: string;
    type?: string;
    id?: string;
    quesName?: string;
    answer?: boolean;
    Questiontype?: string;
    questions?: IQuestions[];
}

export class QuestionModel extends BaseModel<IQuestionModelProps> {
    static resource = 'form-question';

    constructor(props: IQuestionModelProps) {
        super(props);
    }
}
