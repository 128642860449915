import * as React from 'react';
import {OverlayTrigger, Popover, PopoverContent} from 'react-bootstrap';
import {CircularProgress} from 'material-ui';
import DatePicker from 'react-date-picker';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import { formatDate } from '../../utils/generalUtils';

export interface IMarkAsCompletedPopOverProps {
    onUpdate: (comments, date, questionId, inspectionId?) => void;
    questionId?: string;
    formSubmittedDate?: any;
    defaultValue?: any;
    inspectionId?: string;
}

export interface IMarkAsCompletedPopOverState {
    isLoading: boolean;
    date: any;
    comments: string;
}

export class MarkAsCompletedPopOver extends React.PureComponent<IMarkAsCompletedPopOverProps, IMarkAsCompletedPopOverState> {

    constructor(props: IMarkAsCompletedPopOverProps) {
        super(props); 
        this.state = {isLoading: false, date: (props.defaultValue && props.defaultValue.followUpDate && formatDate(props.defaultValue.followUpDate, '' , true)) || new Date(), comments: (props.defaultValue && props.defaultValue.followUpRemark) || ''};
    }

    get comments() {
        return this.state.comments;
    }

    get date() {
        return this.state.date;
    }

    public handleMarkAsComplete = () => {
        const comments = this.state.comments;
        if (!comments || (comments && !comments.trim())) {
            toast.warn('Please enter followup comments to close this flag.', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        const date = this.state.date;
        this.setState({
            isLoading: true
        });
        /* this.setState({
            isLoading: false, date: new Date(), comments: ''
        }); */
        this.props.onUpdate(comments, date, this.props.questionId, this.props.inspectionId);
    }

    renderPopOver = () => {
        const popoverBottom = (
            <Popover id="popover-positioned-bottom" className="footer-popover" style={{zIndex: 99999999}}>
                <Popover.Title as='h2' className="popover-title">Comments:</Popover.Title>
               <PopoverContent className="popover-content">
                   {this.state.isLoading ? <div style={loaderStyle}>
                       <CircularProgress size={70} color="#A4BF43" thickness={5}/>
                   </div> : ''}
                   <div className={"date-container"}>
                       <span style={{color: '#b7b7b7', fontWeight: 'bold'}}>Select Date:</span>
                       <DatePicker
                           maxDate={new Date()}
                           minDate={new Date(this.props.formSubmittedDate)}
                           value={this.state.date}
                           onChange={date => this.setState({date})}
                           locale="en-US"
                       />
                   </div>
                   <textarea
                       rows={3}
                       placeholder="Add your comments..."
                       onChange={e => this.setState({comments: e.target.value})}
                       value={this.state.comments}
                   />
                   {
                       this.state.comments.length > 0 ?
                           <div className="popover-submit">
                               <button onClick={this.handleMarkAsComplete} className="submit-comments">
                                   Done
                               </button>
                           </div>
                           : ''
                   }
               </PopoverContent>
            </Popover>
        );
        return <OverlayTrigger key="overlay" rootClose trigger="click" placement="bottom"
                               overlay={popoverBottom}>
            {/* @ts-ignore */}
            {this.props.children}
        </OverlayTrigger>;

    }

    render() {
        return this.renderPopOver();
    }
}

const loaderStyle: React.CSSProperties = {
    position: 'absolute',
    height: '85%',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5490196078431373)',
    zIndex: 999999
};
