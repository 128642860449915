import * as React from 'react';
import {Bar, BarChart as RBarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';

import {ResponsiveContainer} from './ResponsiveContainer';

export interface IBarChartProps {
    data: Object[];
    isAnimationActive?: boolean;
    barColors?: string[];
    dataKey: string | string[];
    customTooltip?: JSX.Element | Function; 
    onClick?(e, i): void;
}

export class BarChart extends React.PureComponent<IBarChartProps, {}> {
    renderEmptyChart() {
        return (
            <div style={{textAlign: 'center', padding: '60px', fontSize: '14px'}}><i className="fa fa-exclamation-circle color-danger"></i> No Details found</div>
        )
    }
    
    handleClick = (data, index) => { 
        this.props.onClick && this.props.onClick(data, data);
    }
    render() {
        const {data, onClick, customTooltip} = this.props;
        const height = data.length * 35;
        if(!data || data.length<1) {
           return this.renderEmptyChart();
        } 
        return (
            <div className="bar-chart">
                <ResponsiveContainer height={height}>
                    <RBarChart data={data} barGap={20} barCategoryGap={'25%'}
                               layout={'vertical'} 
                               /* onClick={() =>(e, i, c) => e ? (onClick ? onClick(e, i) : null) : null} */
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <YAxis type="category" interval={0} width={200} dataKey="xName"/>
                        <XAxis type="number" allowDecimals={false}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        {// @ts-ignore
                            <Tooltip content={customTooltip}/>}
                        <Legend/>
                        {['#63AB66', '#eaa866'].map((dataVal, index) => {
                            return <Bar onClick={this.handleClick}
                                key={index}
                                stackId={'a'}
                                dataKey={index === 0 ? 'Complete' : 'InComplete'}
                                fill={dataVal}
                            />;
                        })}
                    </RBarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
