import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './ArgosGeorgiaYearlyMaintenanceReport.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate, convertTime, getAnswerWithFlag} from '../../../utils/generalUtils'; 
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';
import {IHistory} from '../../../interfaces';

export interface IArgosGeorgiaYearlyMaintenanceReportDetailerState {
    formData: any;
    history?: IHistory;
    signBase64: string;
}

export interface IArgosGeorgiaYearlyMaintenanceReportDetailerProps {
    formData: any;
    onFlagUpdate: () => void;
    onopenInNewViewTab: Function;
}

export class ArgosGeorgiaYearlyMaintenanceReportDetailerImpl extends React.Component<IArgosGeorgiaYearlyMaintenanceReportDetailerProps, IArgosGeorgiaYearlyMaintenanceReportDetailerState> { 
    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');
    listOfNWQuestions: Array<any> = [];
    questionCount = 0;

    constructor(props: IArgosGeorgiaYearlyMaintenanceReportDetailerProps) {
        super(props); 
        this.state = {
            signBase64: '',
            formData: props.formData || {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
    }

    renderTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="main-title">
                <td colSpan={4}>{quest.question}</td>
            </tr>
        );
    } 
    renderSecondaryTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="secondary-title">
                <td colSpan={4}> {quest.question}</td> 
            </tr>
        );
    }
    renderParagraph = (quest: any) => {
        let ques = quest.question.split('~')
        return (
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p dangerouslySetInnerHTML={{__html: item}}
                            />
                        })
                    }
                </div> 
            </div>
        )
    }  
    getQuestionCount = (questData) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle')) ? this.questionCount += 1 : null;
    } 
    renderObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        const questionNo = this.getQuestionCount(quest);
        if (quest.answer === 'Needs Work') {
            this.listOfNWQuestions.push(questionNo + ') Needs work - ' + quest.subQuestions[0].answer);
        }
        return (
            <React.Fragment key={quest.questionId}>
                <tr className="question-row">
                    <td className="question">
                        {questionNo + ') ' + quest.question}
                    </td>
                    <td className="answer">
                        <span className="pp-printable-data">{quest.answer === 'Needs Work' ? <span className="fa fa-check"/> : <span/>}</span>
                        <span className="d-print-none">{quest.answer === 'Needs Work' ? quest.subQuestions[0].answer : <span/>}</span>
                    </td>
                    <td>
                        {quest.answer === 'Ok' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                    <td>
                        {quest.answer === 'N/A' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                </tr> 
            </React.Fragment>
        );
    } 
    renderComments = (quest ) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    } 
    renderDate = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    } 
    generateQuestionAnswer = (quest, questNo, isSubQuest?: boolean) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': case 'Hint': {
                return this.renderSecondaryTitles(quest);
            }
            case 'Paragraph':
                return this.renderParagraph(quest);
            case 'Comments':
                return this.renderComments(quest);
            case 'ObjectiveType': { 
                return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest); 
            } 
        }
    }
    renderReports() {
        const { parentInspectionName } = this.reportsMetaData 
        const {questionAndAnswer } = this.props.formData;
        if(questionAndAnswer) {
            switch(parentInspectionName) {
                case 'Maintenace Record263': 
                case 'Improvements & CAPEX708' :
                    return this.renderYearlyMaintenace();   
            }
        }
    } 
    renderYearlyMaintenace() {
        const {questionAndAnswer, plantId, submittedDate, submittedBy } = this.props.formData; 
        return (
            <>
            <div className='argos-yearly-report-table-container' style={{paddingTop: '15px'}}> 
                <div className='inspection-basic-details'>
                    <Row>
                        <Col sm={12} lg={12}>Plant Name/#: {plantId} <span style={{paddingLeft: '20px'}}>Date: {submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span> <span style={{paddingLeft: '20px'}}>Inspected By: {submittedBy || 'NA'}</span></Col> 
                    </Row>
                </div>
                {this.renderYearlyMaintenaceAnswers(1)} 
            </div></>
        );
    } 
    renderYearlyMaintenaceAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        const { parentInspectionName } = this.reportsMetaData 
        const distinctModuleIndexes: string[] = Array.from(new Set(questionAndAnswer.map(item => item.moduleIndex)));
        const filteredArray = questionAndAnswer.filter(item => item.questionType !== 'Title'); 
        return (
            <div>
                <table className='argos-maintenace-record '>
                    <thead> 
                        {parentInspectionName === 'Maintenace Record263' ? 
                        <>
                            <tr>
                                <th colSpan={12} className="table-col-tabtitle">CURRENT YEAR'S MAINTENANCE RECORD</th>
                            </tr> 
                            <tr> 
                                <th className="table-col-unit">DATE</th>
                                <th className="table-col-unit">REPAIR <br />ORDER #</th>
                                <th className="table-col-comment">EQUIPMENT REPAIRED</th> 
                                <th className="table-col-unit">UNIT #<br/>(if applicable)</th>
                                <th className="table-col-comment">DESCRIPTION OF REPAIR</th>
                                <th className="table-col-unit">REPAIRED BY</th> 
                                <th className="table-col-unit">COST</th>
                            </tr>
                        </>
                        :
                        <>
                            <tr>
                                <th colSpan={12} className="table-col-tabtitle">FUTURE REPAIRS & CAPITAL PROJECTS - BUDGETING SHEET</th>
                            </tr> 
                            <tr> 
                                <th className="table-col-capax-desc">DESCRIBE PROPOSED SCOPE OF WORK<br/>(REPAIR REQUIRED OR CAPITAL PROJECT)</th>
                                <th className="table-col-capax-desc">JUSTIFICATION<br/>( REASONS WHY IT IS NECESSARY )</th>
                                <th className="table-col-capax-cost">COST<br/>( ESTIMATE )</th> 
                            </tr>
                        </>
                        } 
                    </thead>
                    <tbody>
                            
                        {distinctModuleIndexes.map((item, index) => (
                            <tr key={index} className='ADDModuless'>
                            {filteredArray.map((quesData, dataIndex) => (
                                <>
                                {quesData.moduleIndex === String(item) ?
                                    <td key={dataIndex} className="table-col-units">{quesData.answer}</td>
                                :''}
                                </>
                            ))}
                            </tr>
                        ))}                                   
                </tbody>
            </table>
        </div>
        )
    }
    
    renderImages = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData;
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()}>
            {/* <hr className="line-break"/> */}
            <div className="images-container">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {
                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat === "PDF" ?
                                    <span onClick={() => onopenInNewViewTab(url.imageURL)} >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                        </div>;
                        // return <div
                        //     key={index}
                        //     title="Click to view in full screen mode.."
                        //     className="image">
                        //     <Img src={url.imageURL} onClick={() => showCarousel(url.imageURL)}/>
                        // </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div> 
        </div>;
    }

    renderAnswer(index) { 
        const {questionAndAnswer } = this.props.formData;
        let quest = questionAndAnswer[index];
        if(questionAndAnswer.length>index) { 
            let answer = questionAndAnswer[index]['answer'];
            let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(quest);
            return (
                quest.isFlagged && quest.followUpOn===quest.answer ? <div className="flagged-answer"> <MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue}>
                        <div className={`${followUpClass} mark-as-complete-col`}
                            title="Click to enter the follow-up comment">
                            <i className={followUpLogo} aria-hidden="true"> </i> {answer}
                        </div> 
                </MarkAsCompletedPopOver> </div> : answer
            )
        }   
    }
    handleFlagAnswerUpdate = async (comments, date, questionId) => {
        let inspId = this.props.formData.inspectionUId;

        await saveFlagCompletions(inspId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'ArgosGeorgiaYearlyMaintenanceReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }
    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent,inspectionDate} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData; 
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <div className="pp-printable-data">
                <div className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Permanent:</span><span className="answer">{new Date(inspectionDate) <= new Date('2024-11-30') ? isPermanent : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>
                <div className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span></div>
                <div className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </div>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{new Date(inspectionDate) <= new Date('2024-11-30') ? isPermanent : 'NA'}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                {this.renderReports()}
            </Row>
            {this.renderImages()} 
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const ArgosGeorgiaYearlyMaintenanceReportDetailer = connect<{}, IArgosGeorgiaYearlyMaintenanceReportDetailerState, IArgosGeorgiaYearlyMaintenanceReportDetailerProps>(mapStateToProps)(ArgosGeorgiaYearlyMaintenanceReportDetailerImpl as any);
 
