import * as React from 'react';

export interface IErrorPageProps {
}

export class ErrorPage extends React.PureComponent<IErrorPageProps, {}> {
    constructor(props: IErrorPageProps) {
        super(props);
    }

    render() {
        return <div style={container}>
            <i style={{fontSize: '20vh', color: 'gray'}}
               className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <div style={{
                paddingTop: '50px',
                fontSize: '3vh',
                color: 'gray',
                textAlign: 'center'
            }}>Oops Something went wrong...!!
            </div>
        </div>;
    }
}

const container: React.CSSProperties = {
    minHeight: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};
