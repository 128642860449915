import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {actions} from 'react-redux-form';
import {IForms} from '../../../interfaces';
import {dispatch, showAlert} from '../../../utils/generalUtils';
import './fileinput.scss';
import {IMAGEALLOWEDEXE} from '../../../constants/generals';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Alert} from '../Alert';
import {shoCarousel} from '../../../actions/miscellaneousActions';

declare var require;
const val = require('../../../images/spi.png');

export interface IFileInputProps {
    id: string;
    multiple?: boolean;
    //style?: React.CSSProperties;
    style?: any;
    accept?: string;
    model: string;
    onChange?: (event: EventTarget) => void;
    imagesArray?: string[];
    defaultValue?: any;
    forms?: IForms | undefined;
    displayUploadedFiles?: boolean;
    restrictMultipleImage?: boolean;
}

export interface IFileInputState {
    imagesArray: string[];
    oldUploads: string[];
    uploadedImage?: any;
}

export class FileInputImpl extends React.Component<IFileInputProps, IFileInputState> {
    constructor(props: IFileInputProps | Readonly<IFileInputProps>) {
        super(props);
        const {defaultValue} = this.props;
        this.state = {imagesArray: [], oldUploads: defaultValue};
    }

    componentDidMount() {
        const {props: {model}, context: {formModel}} = this;
        if ((formModel || '').split('.')[1] === 'incidentDetails') {
            return;
        }
        dispatch(actions.change(`${formModel}${model}`, ''));
    }

    componentWillReceiveProps(nextProps: any) {
        const {defaultValue, forms} = this.props;
        this.setState({oldUploads: defaultValue});
    }

    static defaultProps = {
        displayUploadedFiles: true
    };

    static contextTypes = {
        formModel: PropTypes.string
    };

    handleImageDelete = (index: number) => {
        const {props: {model, forms}} = this;
        let formModel = this.context.formModel;
        dispatch(actions.remove(`${formModel}${model}`, index));
    }

    renderUploadedImages = () => {
        const {props: {model, forms, restrictMultipleImage}, context: {formModel}} = this;
        const formName = (formModel || '').split('.');
        const modelName = model.split('.');
        let imageData = (forms as any)[`${formName[1]}`][`${modelName[1]}`];
        if (!imageData) {
            return <div/>;
        }
        return imageData.map((imageObj: { image: string; }, index: number) => {
            return <div key={index} style={displayImage} className="image-wrapper">
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.handleImageDelete(index)}
                />
                <img
                    height="75px"
                    width="90px"
                    style={{padding: '5px'}}
                    className="thumbnail-image"
                    src={imageObj.image}
                    // onError={this.onError(index)}
                    alt="thumbnail"/>
            </div>;
        });
    }
    onError = () => {
        return;
    }
    renderOldUploads = () => {
        const {defaultValue} = this.props;
        return this.state.oldUploads.map((imageObj, index) => {
            let file = imageObj.split('/')[imageObj.split('/').length - 1].split('.');
            return <div key={index} style={displayImage} className="image-wrapper">
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.removeOldUpload(index, imageObj)}
                />
                <OverlayTrigger key="top" placement="top" overlay={<Tooltip id={file[0] + '.' + file[1]}> <strong>{file[0] + '.' + file[1]}</strong> </Tooltip>}>
                    <img
                        height="75px"
                        width="90px"
                        style={{padding: '5px'}}
                        className="thumbnail-image"
                        src={imageObj}
                        onClick={() => shoCarousel(imageObj)}
                        alt="thumbnail"
                    />
                </OverlayTrigger>
            </div>;
        });

    }
    openFile = (imageObj: string | undefined) => {
        window.open(imageObj, '_blank');
    }
    removeOldUpload = (index: number, image: string) => {
        const {context: {formModel}} = this;
        const array = [...this.state.oldUploads]; // make a separate copy of the array
        const arrayIndex = array.indexOf(image);
        if (arrayIndex !== -1) {
            array.splice(index, 1);
            this.setState({oldUploads: array});
        }
        dispatch(actions.push(`${formModel}.deletedImages`, image));
    }

    handleChange = (event: { target: { files: any; }; }) => {
        const {context: {formModel}, props: {model, forms, restrictMultipleImage}} = this;
        const files = event.target.files;
        if (files && restrictMultipleImage && this.state.oldUploads) {
            const array = [...this.state.oldUploads]; // make a separate copy of the array
            array.splice(0, 1);
            this.setState({oldUploads: array});
            dispatch(actions.change(`${formModel}${model}`, ''));
        }
        // let imagesArray = [];
        // let con = 0;
        let invalid = 0;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileContent = file['name'].split('.');
            if (IMAGEALLOWEDEXE.indexOf('image/' + fileContent[fileContent.length - 1].toLowerCase()) === -1) {
                invalid++;
            } else {
                const fileReader = new FileReader();
                fileReader.onload = this.readFile;
                /* function readFile(e: any) {
                    const name = new Date().getTime().toString();
                    dispatch(actions.push(`${formModel}${model}`, { name: `${name}.png`, image: e.target.result }));
                } */
                fileReader.readAsDataURL(file);
            }
        }
        if (invalid > 0) {
            showAlert(`Only ${IMAGEALLOWEDEXE.join(', ')} files allowed. ${invalid} file(s) CANNOT be upload.`, 'image-danger-alert-invalidexe', 'danger', undefined, true, 4500);
        }
    }
    readFile = (e: any) => {
        const {context: {formModel}, props: {model}} = this;
        const name = new Date().getTime().toString();
        dispatch(actions.push(`${formModel}${model}`, {name: `${name}.png`, image: e.target.result}));
    }

    render() {
        const {accept, multiple, style, displayUploadedFiles, defaultValue} = this.props;
        return (
            <div className="file-upload">
                <input
                    type="file"
                    multiple={multiple}
                    style={fileUpload}
                    accept={IMAGEALLOWEDEXE.join(', ')}
                    onChange={this.handleChange}
                />
                <span className="upload-image">Choose Image(s)</span>
                <div className="images">
                    {this.state.oldUploads ? this.renderOldUploads() : ''}
                    {displayUploadedFiles ? this.renderUploadedImages() : ''}
                </div>

                <span className="imageDangerAlert"> <Alert className="danger-alert" id="image-danger-alert-invalidexe"/> </span>
            </div>
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms
    };
}

export const FileInput = connect<{}, {}, IFileInputProps>(mapStateToProps)(FileInputImpl);

const fileUpload: React.CSSProperties = {
    height: '38px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    // left: '35%',
};

const displayImage: React.CSSProperties = {
    width: '90px',
    height: '75px',
    margin: '0 5px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)', cursor: 'pointer',
};
