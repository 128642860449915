import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory} from '../../interfaces';
import {PlantModel} from '../../model/PlantModel';

interface IListItemProps {
    instance?: PlantModel;
    onClick?: Function;
    index?: number;
    history?: IHistory;
}

interface IListItemState {

}

export class ListItemImpl extends React.Component<IListItemProps, IListItemState> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
        this.state = {};
    }

    static defaultProps: IListItemProps;
    render() {
        let {instance, index, history} = this.props;
        if (!instance) {
            return '';
        }
        return <Row className="form-list-item">
            <Col
                className="clickable-list-item serial-no"
                sm={1} xs={1}
            > 
                {/* {index} */}
                {instance?.props.pagedataCnt}

            </Col>
            <Col
                onClick={() => history?.push(`/maintenance/${instance?.props.id}/${instance?.props.plantId}`)}
                className="clickable-list-item" xs={2}
                sm={2}
            >
                {instance.props.plantId}

            </Col>
            <Col
                onClick={() => history?.push(`/maintenance/${instance?.props.id}/${instance?.props.plantId}`)}
                className="clickable-list-item" xs={3}
                sm={3}
            >
                {instance.props.plantName}
            </Col>
            <Col
                onClick={() => history?.push(`/maintenance/${instance?.props.id}/${instance?.props.plantId}`)}
                className="clickable-list-item" xs={3}
                sm={2}
            >
                {instance.props.plantRegionName}
            </Col>
            <Col
                onClick={() => history?.push(`/maintenance/${instance?.props.id}/${instance?.props.plantId}`)}
                className="clickable-list-item" xs={2}
                sm={2}
            >
                {instance.props.state}
            </Col>
            <Col
                onClick={() => history?.push(`/maintenance/${instance?.props.id}/${instance?.props.plantId}`)}
                className="clickable-list-item" xs={2}
                sm={2}
            >
                {instance.props.Active}
            </Col>
        </Row>;
    }
}

export function mapStateToProps(state) {
    return { };
}

export const ListItem = withRouter(connect< IListItemProps, any, any >(mapStateToProps)(ListItemImpl));
