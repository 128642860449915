import * as React from 'react';
import { Col } from 'react-bootstrap';

export interface ILabelProps {
    htmlFor?: string;
    className?: string;
    style?: React.CSSProperties;
    sm?: number;
    required?: boolean;
}

export class Label extends React.PureComponent<ILabelProps> {
    constructor(props: ILabelProps | Readonly<ILabelProps>) {
        super(props);
    }

    static defaultProps = {
        required: false
    };

    render() {
        let title = {
            color: 'yellow',
            paddingTop: '5px',
            display: 'inline-block'
        };
        let { required, htmlFor, sm, className, children, style } = this.props;
        return (
            <Col className={`input-label ${className}`} sm={sm ? sm : 4} /* style={style? (Object.assign((style || {}), title)) : title} */ style={style ? (Object.assign((title || {}), style)) : title}>

                <label htmlFor={this.props.htmlFor}><span style={{ color: 'red' }} >{required ? '*' : ''}</span>
                    &nbsp;{children}</label>
            </Col>
        );
    }
}
