import * as React from 'react';
import {BaseModel} from './BaseModel';

interface IOrgModelProps {
    orgId: string;
    orgName: string;
    id: string;
    orgRegion: Array<{ regionId: string, regionName: string }>;
    orgLogoDetails: Array<{ dirPath: string; fileName: string; imageURL: string }>;
}

export class OrgModel extends BaseModel<IOrgModelProps> {
    static resource = 'OrgInfo';

    constructor(props: IOrgModelProps) {
        super(props, 'OrgInfo');
    }
}

interface IRegionModelProps {
    id: string;
    regionName: string;
    regionId: string;
}
export class RegionModel extends BaseModel<IRegionModelProps> {
    static resource = 'RegionInfo';

    constructor(props: IRegionModelProps) {
        super(props, 'RegionInfo');
    }
}
