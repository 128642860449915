import './commons.scss';
import './mediaQueries.scss';
// import './responsiveQueriesNew.scss';


import * as React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
/* import {Route, Switch} from 'react-router';
import {HashRouter} from 'react-router-dom'; */

import { createBrowserHistory } from 'history';
import {Router, Switch, Route, HashRouter} from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import {store} from './store';
import {AuthRoute} from './components/reusableComponents/AuthRoute';
import {LoginPage} from './components/LoginPage';
import {HomePage} from './components/HomePage';
import {Inspections} from './components/Inspections/Inspections';
import {Maintenance} from './components/Maintenance';
import {TechnicalSupportPage} from './components/TechnicalSupportPage';
import {UpdatePlantForm} from './components/Maintenance/UpdatePlantForm';
import {AddPlantForm} from './components/Maintenance/AddPlantForm';
import {SummaryReportsSlider} from './components/Summary/ListPage/SummaryReportsSlider';
import { FlaggedReports } from './components/FlaggedReport/FlaggedReports';

import {UserList} from './components/Users/UserList';
import {UpdatePassword} from './components/Profile/UpdatePassword';
import {DailyReports} from './components/Reports/Daily/DailyReports';
import {FlaggedFormDetails} from './components/FlaggedForm/FlaggedFormDetails';
import {FlaggedFormList} from './components/FlaggedForm/FlaggedFormList';
import {DailyChecklistReport} from './components/Summary/ReportsPage/DailyChecklistReport';
import {MonthlyReports} from './components/Reports/Monthly/MonthlyReports';
import {WeeklyReports} from './components/Reports/Weekly/WeeklyReports';
import {QuarterlyReports} from './components/Reports/Quarterly/QuarterlyReports';
import {DetailsPage as PlantDetails} from './components/Maintenance/DetailsPage';
import {Profile} from './components/Profile';
import {CreateForm} from './components/Users/CreateForm';
import {UpdateForm} from './components/Users/UpdateForm';
import {ReportsDetailsPage} from './components/Reports/InspectionReportDetailer/ReportsDetailsPage';
import {ForgotPasswordForm} from './components/ForgotPassword';
import {OrgsList} from './components/Organisations/organisationsList';
import {CreateOrgForm} from './components/Organisations/createOrganisation';
import {UpdateOrgForm} from './components/Organisations/updateOrganisation';
import {OrgDetailsPage} from './components/Organisations/organisationDetails';
import {CommonInspectionForm} from './components/Forms/commonInspectionForm';
import { MaintenanceInspectionForm } from './components/Forms/maintenanceReport';
import {ChartsContainer} from './components/Dashboard/chartsContainer';
import {GoldenRulesPage} from './components/References/GoldenRules';
import {ReferencePage} from './components/References/Reference';
import { ReferenceDropZone } from './components/References/ReferenceDropZone';

import {QuestionSetupForm} from './components/Inspections/Questions/QuestionSetup';
import {InspectionTypesForm } from './components/Maintenance/InspectionTypes';
import { AddInspectionTypeForm} from './components/Maintenance/AddInspectionType';
import { YearlyReports } from './components/Reports/Yearly/YearlyReports';

/* declare const require: any; */
require('react-perfect-scrollbar/dist/css/styles.css');
// require('react-select/dist/react-select.css');

require('font-awesome/css/font-awesome.min.css'); 

const newHistory = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={newHistory}>
      <Switch>
        <Route exact path="/" component={LoginPage}/>
        <Route exact path="/forgot-password/:userId/:orgId" component={ForgotPasswordForm}/>
        <AuthRoute exact path="/home" component={HomePage}/>

        <AuthRoute exact path="/inspections" component={Inspections}/>
        <AuthRoute exact path="/housekeeping" component={Inspections}/>

        <AuthRoute exact path="/maintenance" component={Maintenance}/>
        <AuthRoute exact path="/maintenance/update/:id" component={UpdatePlantForm}/>
        <AuthRoute exact path="/maintenance/create" component={AddPlantForm}/>
        <AuthRoute exact path="/maintenance/:id/:plantId" component={PlantDetails}/>
        
        <AuthRoute exact path="/technical-support" component={TechnicalSupportPage}/>
        <AuthRoute exact path="/inspection-types" component={InspectionTypesForm}/>
        <AuthRoute exact path="/inspection-type/create" component={AddInspectionTypeForm}/>
        <AuthRoute path="/profile/:id" component={Profile}/>
        <AuthRoute exact path="/update-password" component={UpdatePassword}/>

        <AuthRoute exact path="/users" component={UserList}/>
        <AuthRoute path="/users/:id" component={Profile}/>
        <AuthRoute exact path="/user/create" component={CreateForm}/>
        <AuthRoute exact path="/user/update/:id" component={UpdateForm}/>
        
        <AuthRoute exact path="/org" component={OrgsList}/>
        <AuthRoute exact path="/org/create" component={CreateOrgForm}/>
        <AuthRoute exact path="/org/details/:id" component={OrgDetailsPage}/>
        <AuthRoute exact path="/org/update/:id" component={UpdateOrgForm}/>

        <AuthRoute exact path="/inspections/common/:parentId" component={CommonInspectionForm}/>
        <AuthRoute exact path="/inspections/maintenance/:parentId" component={MaintenanceInspectionForm}/>
        <AuthRoute exact path="/inspection-setup/:formId/:permitType/:formName" component={QuestionSetupForm}/>

        <AuthRoute exact path="/summary-reports" component={SummaryReportsSlider}/>
        <AuthRoute exact path="/summary-reports/:frequency" component={DailyChecklistReport}/>

        <AuthRoute exact path="/reports/Daily" component={DailyReports}/>
        <AuthRoute exact path="/reports/Weekly" component={WeeklyReports}/>
        <AuthRoute exact path="/reports/Monthly" component={MonthlyReports}/>
        <AuthRoute exact path="/reports/Quarterly" component={QuarterlyReports}/>
        <AuthRoute exact path="/reports/Yearly" component={YearlyReports}/>
        <AuthRoute exact path="/reports/:frequency/:inspectionId" component={ReportsDetailsPage}/>
        <AuthRoute exact path="/reports/:frequency/:inspectionId/:formId" component={ReportsDetailsPage}/>

        <AuthRoute exact path="/flagged-reports" component={FlaggedReports}/>

        <AuthRoute exact path="/dashboard" component={ChartsContainer}/>

        <AuthRoute exact path="/golden-rules" component={GoldenRulesPage}  />
        <AuthRoute exact path="/references/" component={ReferencePage}  />
        <AuthRoute exact path="/references/edit" component={ReferenceDropZone}  />

        <AuthRoute exact path="/flagged-forms/:category/:id" component={FlaggedFormDetails}/>
        <AuthRoute exact  path="/flagged-forms" component={FlaggedFormList}/>

      </Switch> 
    </HashRouter>
  </Provider>, 
  document.getElementById('argos')
);
registerServiceWorker();
