import * as React from 'react';
import {BaseModel} from './BaseModel';
import {IQuestions} from '../interfaces';

export interface IDailyFormsModelProps {
    id?: string;
    type?: 'dailyForm';
    InCompleteFormIds?: string[];
    plantInfo?: {
        name?: string;
        zip?: string;
        country?: string;
        region?: string;
        state?: string;
        street?: string;
        address?: string;
        id?: string;
        userId?: string;
        plantID?: string;
    };
    questions?: IQuestions[];
    ImageUrls?: string[];
    UserName?: string;
    InspectionId?: string;
    PlantId?: string;
    Region?: string;
    UserId?: string;
    FlaggedQuestion?: boolean;
    InspectionUId?: string;
    PlantUID?: string;
    Date?: string;
    SignatureUrls?: string[];
    Completed?: boolean;
    Flagged?: boolean;
    CompletedBy?: string;
    DateCompleted?: string;
    FollowUpComments?: string;
    Associations?: string[];
}

export class DailyFormsModel extends BaseModel<IDailyFormsModelProps> {
    static resource = 'daily-forms';

    constructor(props: IDailyFormsModelProps) {
        super(props);
    }
}
