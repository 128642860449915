import {format, getQuarter} from 'date-fns';
import {CircularProgress} from 'material-ui';
import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../../interfaces';
import {PlantModel} from '../../../model/PlantModel';
import {Row, Col} from 'react-bootstrap';
import {SettledFormModel} from '../../../model/SettledFormModel';
import {getSettledForms} from '../../../services/plantService';
import {Async} from '../../reusableComponents/Async';
import {ModalError} from '../../reusableComponents/ModalError';
import {ModalLoader} from '../../reusableComponents/ModalLoader';
import {FooterCard} from './FooterCard';
import {getCurentDate, isMobileDevice, optimizeInspections} from '../../../utils/generalUtils';
import '../../Inspections/inspections.scss';
import {connect} from "react-redux";

const queryString = require('query-string');

export interface IListFooterProps {
    instances?: any;
    index?: number;
    id?: string;
    onClose?: Function;
    history?: IHistory;
    location?: {
        search: string;
    };
    pageNum?: any;
    searchInput?: string;
}

export interface IListFooterState {
    date: Date;
    settledFormInstance: any;
}

export class ListFooterImpl extends React.Component<IListFooterProps, IListFooterState> {

    static defaultProps: IListFooterProps;
    identifier = 'InspectionFooter';

    constructor(props: IListFooterProps) {
        super(props);
        let date = new Date();
        let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`;
        if (this.props.location?.search) {
            const {search} = this.props.location;
            // date = new Date(queryString.parse(search).date);
            // let resDate = queryString.parse(search).date.toString().replace(/-/g, '/');
 
            // date = getCurentDate(resDate, 'MM/DD/yyyy', true);
            if(!isMobileDevice()) {
                date = new Date(queryString.parse(search).date);
            }
            quarter = queryString.parse(search).quarter ? queryString.parse(search).quarter : quarter;
        }
        this.state = {
            date, settledFormInstance: '',
        };
    }

    promise = async () => {
        if(this.props.id) {
            await getSettledForms(format(this.state.date, 'MMddyyyy'), this.props.id); 
            const settledFormInstance = SettledFormModel.get(this.props.id);
            this.setState({settledFormInstance})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.index !== nextProps.index) {
            this.setState({
                date: new Date()
            });
            const settledFormInstance = SettledFormModel.get(nextProps.id);
            this.setState({settledFormInstance})
        }
    }

    onDateChange() {
        if(this.props.id) { 
            const settledFormInstance = SettledFormModel.get(this.props.id);
            this.setState({settledFormInstance})
        }
    }
    renderCards = () => { 
        if(this.props.id) {
            //const settledFormInstance = SettledFormModel.get(this.props.id);
            let settledFormInstance = this.state.settledFormInstance;
            const plantInfo = PlantModel.get(this.props.id);
            if (!plantInfo || !settledFormInstance) {
                return <div style={{
                    minHeight: '25vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress size={150} color="#A4BF43" thickness={5}/>
                </div>;
            }

            const inspections = optimizeInspections(settledFormInstance.props.inspections);

            return <div className="inspections-list-parent-container">
                {Object.keys(inspections).map((permitType: string, index: number) => {
                    return <Row className="permit-type-container" key={permitType + index}>
                        <div className="permit-type-header">{permitType}</div>
                        {Object.keys(inspections[permitType]).map((freq, i) => {
                            return <Col className="inspections-list" key={permitType + freq + i}>
                                <div className="freq-title">{freq}</div>
                                <FooterCard
                                    // @ts-ignore
                                    date={this.state.date}
                                    permitType={permitType}
                                    freq={freq}
                                    plantInfo={plantInfo}
                                    inspections={inspections[permitType][freq]}
                                    pageNum={this.props.pageNum}
                                    searchInput={this.props.searchInput}
                                    onDateChange={() => {
                                        this.onDateChange();
                                    }}>
                                </FooterCard>
                            </Col>;
                        })}
                    </Row>;
                })}
            </div>;
        } else {
            return <></>;
        }
    }

    renderContent = () => {
        if(this.props.id) {
            const instances = PlantModel.get(this.props.id);
            if (!instances) {
                return <span/>;
            }
            return (
                <Async
                    identifier={this.identifier}
                    promise={this.promise}
                    loader={<ModalLoader/>}
                    content={this.renderCards()}
                    error={<ModalError/>}
                />
            );
        } else {
            return <></>;
        }
    }

    render() {
        return this.renderContent();
    }
}

export function mapStateToProps(state) {
    return { 
    };
}

export const ListFooter = withRouter(connect< IListFooterProps, any, any>(mapStateToProps)(ListFooterImpl as any));
