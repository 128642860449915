import './form.scss';

import * as React from 'react';
import * as _ from 'lodash';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {actions, Control} from 'react-redux-form';
import Select from 'react-select';

import {dispatch, ModelContext} from '../../../utils/generalUtils';
import {white} from "material-ui/styles/colors";
import {ISelectOptions} from "../../../interfaces";
import {dropdownStyles} from "../SelectDropdown";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

export interface IRRFInputProps {
    id: string;
    type: string;
    model: string;
    placeholder?: string;
    label?: string | null;
    defaultValue?: any;
    onChange?: (value) => void;
    className?: string;
    emptyFieldModel?: string;
    disabled?: boolean;
    formmodel?: string;
    menuItems?: {
        label: string;
        value: string;
    }[];
    multi?: boolean;
    onSelect?: (obj: any, fieldId: any) => void;
    onBlur?: (obj, fieldId) => void;
    additionalParams?: any;
    pattern?: any

}

export interface IRRFInputState {
    selectedOption: any;
    modelName: string;
    value: any;
    menuItems?: {
        label: string;
        value: string;
    }[];
}

export class RRFInputImpl extends React.Component<IRRFInputProps, IRRFInputState> {
    constructor(props: IRRFInputProps) {
        super(props);
        const modelName = `${this.props.formmodel}${this.props.model}`;
        this.state = {
            value: (props.defaultValue || ''),
            selectedOption: props.type === 'dropdown' ? this.attachValueObjects(props.defaultValue) : null,
            modelName, menuItems: props.menuItems || []
        };
        dispatch(actions.change(modelName, (props.defaultValue || '')));
    }

    componentWillReceiveProps(nextProps: IRRFInputProps) {
        /*   if (nextProps.menuItems !== this.props.menuItems) {
               this.setState({
                   menuItems: nextProps.menuItems,
                   selectedOption: nextProps.type === 'dropdown' ? this.attachValueObjects(nextProps.defaultValue) : null
               })
           }*/
        if (nextProps.type === 'dropdown' && nextProps.defaultValue &&
            (nextProps.defaultValue !== this.props.defaultValue) || (nextProps.menuItems !== this.props.menuItems)) {
            this.setState({
                menuItems: nextProps.menuItems,
            }, () => {
                this.setState({
                    selectedOption: nextProps.type === 'dropdown' ? this.attachValueObjects(nextProps.defaultValue) : null
                })
            });
        } else if (nextProps.defaultValue && !this.state.value) {
            this.setState({
                value: nextProps.defaultValue
            });
            dispatch(actions.change(this.state.modelName, (nextProps.defaultValue || '')));
        } else if (nextProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                value: nextProps.defaultValue || '',
            });
            dispatch(actions.change(this.state.modelName, (nextProps.defaultValue || '')));
        }
    }

    handleOnChange = (e) => {
        this.setState({
            value: e.target.value
        });
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
    handleOnBlur = (e) => {
        this.setState({
            value: e.target.value
        });
        if (this.props.onBlur) {
            this.props.onBlur(null, null);
        }
    }

    attachValueObjects(value) {
        if (!value)
            return
        if (!this.props.multi && this.props.menuItems?.length) {
            let valueToSingleSelect: any = {}
            valueToSingleSelect = this.props.menuItems?.find((obj: ISelectOptions) => {
                return obj.value === value
            });
            return valueToSingleSelect || {}
        } else {
            const valueToMultiSelect: Array<any> = [];
            if (this.props.menuItems?.length) {
                _.forEach(value, (v) => {
                    const item = _.find(this.props.menuItems, {value: v})
                    if (item?.value) {
                        valueToMultiSelect.push(item)
                    }
                })
            }
            return valueToMultiSelect.length ? valueToMultiSelect : []
        }
    }

    handleChange = (obj, modelName, id) => {
        const {multi, onSelect} = this.props; 
        if (obj && !multi) {
            this.setState({
                selectedOption: obj
            });
            dispatch(actions.change(modelName, obj.value));
        }
        if (obj instanceof Array) {
            this.setState({
                selectedOption: obj
            });
            const valueToPush = _.map(obj, 'value');
            setTimeout(() => {
                dispatch(actions.change(modelName, JSON.parse(JSON.stringify(valueToPush)) || []));
            }, 300);
        }
        if (onSelect) {
            onSelect(obj, id);
        }
    }
    
    handleChangeChoices = (Choices) => { 
        const {onSelect, additionalParams} = this.props;
        if(onSelect) { 
            onSelect(Choices, additionalParams); 
        }
    }

    renderInputComponent = (type, modelName) => {
        if (this.props.model === '.Regions') {
        }
        const {
            id, label, className, disabled, placeholder,
            model, emptyFieldModel, menuItems, multi
        } = this.props; 
        switch (type) {
            case 'text':
                return <Row className="show-grid input-container">
                    {label && 
                        <Col className="input-label">
                            <label key={1} htmlFor={id}>{label}</label>
                        </Col>
                    }
                    <Col className="input-field">
                        {/* eslint-disable-next-line */}
                        <Control.text
                            key={2}
                            id={id}
                            model={`${modelName}${model}`}
                            className={`form-control ${emptyFieldModel === model ? 'empty-field' : ''}  ${className}`}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            placeholder={placeholder}
                            disabled={disabled}
                            autoComplete="off"
                            value={this.state.value}
                        />
                    </Col>
                </Row>;
            case 'textarea':
                return <Row className="show-grid input-container">
                    { label && 
                        <Col className="input-label">
                            <label key={1} htmlFor={id}>{label}</label>
                        </Col>
                    }
                    <Col className="input-field">
                        {/* eslint-disable-next-line */}
                        <Control.textarea
                            key={2}
                            rows={3}
                            model={`${modelName}${model}`}
                            className={`form-control ${emptyFieldModel === model ? 'empty-field' : ''}  ${className}`}
                            onChange={this.handleOnChange}
                            placeholder={placeholder}
                            value={this.state.value}
                            disabled={disabled}
                        />
                    </Col>
                </Row>;
            case 'checkbox-slider': 
                return <label className="switch">
                    <Control.checkbox
                        key={2}
                        id={id}
                        model={`${modelName}${model}`}
                        className={`form-control ${emptyFieldModel === model ? 'empty-field' : ''} ${className}`}
                        onChange={this.handleOnChange} 
                        value={this.state.value}
                    />
                    <span className="slider round"/>
                </label>;
            case 'dropdown':
                return <Row className="show-grid input-container">
                    {label && <Col className="input-label">
                        <label key={1} htmlFor={id}>{label}</label>
                    </Col> }
                    <Col className="input-field">
                        <Select
                            id={id}
                            className={className}
                            value={this.state.selectedOption}
                            onChange={obj => this.handleChange(obj, `${modelName}${model}`, id)}
                            options={this.state.menuItems}
                            isMulti={multi}
                            styles={dropdownStyles}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#a4bf43',
                                    primary: '#a4bf43',
                                },
                            })}
                            isDisabled={disabled}
                            clearable={false}
                            placeholder={placeholder}
                        />
                    </Col>
                </Row>;
            case 'tagInput': 
                return <TagsInput value={this.state.value} inputProps={placeholder} addKeys={[9, 13]} onChange={this.handleChangeChoices} onlyUnique={true} maxTags={6} disabled={disabled} />

            default:
                return <Row className="show-grid input-container">
                    { label && <Col className="input-label">
                        <label key={1} htmlFor={id}>{label}</label>
                    </Col> }
                    <Col className="input-field">
                        {/* eslint-disable-next-line */}
                        <Control.text
                            key={2}
                            type={type}
                            model={model}
                            id={id}
                            autoComplete="off"
                            className={`form-control ${emptyFieldModel === model ? 'empty-field' : ''}  ${className}`}
                            onChange={this.handleOnChange}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={this.state.value}
                        />
                    </Col>
                </Row>;
        }
    }

    render() {
        return <ModelContext.Consumer>
            {modelName => this.renderInputComponent(this.props.type, modelName)}
        </ModelContext.Consumer>;
    }
}

export function mapStateToProps(state) {
    return {
        emptyFieldModel: state.alert.get('emptyFieldModel')
    };
}

export const RRFInput = connect<{}, {}, IRRFInputProps>(mapStateToProps)(RRFInputImpl as any);
