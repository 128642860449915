import './listModal.scss';

import * as React from 'react';
import {Modal} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect} from 'react-redux';

import {deletePlantDetails} from '../../../actions/dashboardAction';
import {hidePlantListModal} from '../../../actions/modalActions';
import {IPlantDetails} from '../../../interfaces';
import {sendReminder} from '../../../services/dashboardService';
import {Alert} from '../Alert';
import {getCurrentUserAccessLevel} from '../../../utils/generalUtils';
import {format, parseISO, parse} from 'date-fns';
import moment from 'moment';

export interface IListModalProps {
    id: string;
    modalID?: string; 
    modalTitle?: string;
    listHeadings: string[];
    listItemComponent: React.ComponentClass<{
        index: number, data: any, type: string, 
        onClick(plantID: any): void, date: any
    }>;
    data?: any;
    className?: string;
    style?: React.CSSProperties; 
    listTitle?: any;

    /* 
    modalTitle: string; 
    listItemComponent: React.ComponentClass<{
        index: number, data: any, type: string,
        onClick(plantID): void, date: string | { qtr: string, year: string }
    }>; 
    listTitle?: string; */
    initialPromise?: any;
    plantDetails?: IPlantDetails;
    formType?: string;

    promise?(plantId): any;

    parentInstance: any;
    closePopup?: Function;
}

export interface IListModalState {
    showDetails: boolean;
    showProgressBar: boolean;
    plantID: string | null;
    plantDetail: any;
}

export class ListModalImpl extends React.PureComponent<IListModalProps, IListModalState> {
    static defaultProps: IListModalProps;

    constructor(props: IListModalProps) {
        super(props);
        this.state = {showDetails: false, plantID: null, showProgressBar: false, plantDetail: {}};
    }

    handleSendReminder = async (data) => {
        this.setState({
            showProgressBar: true
        });
        const dateToSend = this.getDateForReminder();
        await sendReminder(data.plantUId, data.FormId, dateToSend, this);
    }

    renderPlantDetails = () => { 
        const {plantDetail} = this.state;
        /* if (!plantDetail) {
             return <Loader type="async"/>;
         }*/
        /*  if (!plantDetail.plantId) {
              return <div style={{fontSize: '20px', color: 'gray', padding: '20px 0px', textAlign: 'center'}}>
                  No Details Found
              </div>;
          }*/
        return <div style={{textAlign: 'center'}} className="plant-details">
            {/*{this.state.showProgressBar ? <div className="progress-bar">
                <CircularProgress size={150} color="#A4BF43" thickness={5}/>
            </div> : ''}*/}
            <div className="details-row">
                <div
                    className="details-label">PlantId:
                </div>
                &nbsp;
                <div className="details-value"> {plantDetail.plantId || 'NA'}</div>
            </div>
            <div className="details-row">
                <div
                    className="details-label">Plant Region:
                </div>
                &nbsp;
                <div className="details-value"> {plantDetail.regionName || 'NA'}</div>
            </div>
            <div className="details-row">
                <div
                    className="details-label">Plant Name:
                </div>
                &nbsp;
                <div className="details-value"> {plantDetail.plantName || 'NA'}</div>
            </div>
            <div className="details-row">
                <div
                    className="details-label">Facility Type:
                </div>
                &nbsp;
                <div className="details-value"> {plantDetail.facilityType || 'NA'}</div>
            </div>
            <div className="details-row">
                <div
                    className="details-label">Permit Type:
                </div>
                &nbsp;
                <div className="details-value"> {plantDetail.permitType || 'NA'}</div>
            </div>
            {this.props.listTitle === 'Incomplete Plants' ?
                <div className="modal-button-container">
                    <button onClick={() => {
                        this.setState({showDetails: false});
                    }} className="back-button">Back
                    </button>
                    <span style={{padding: '0px 10px'}}/>
                    {getCurrentUserAccessLevel() === 'L1' || getCurrentUserAccessLevel() === 'L2' ?
                        <button
                            onClick={() => {
                                this.handleSendReminder(plantDetail);
                            }
                            }
                            className="reminder-button"
                        >
                            Send Reminder
                        </button> : ''}
                </div> : ''}
        </div>;
    }

    handleOnListItemClick = async (plantDetail) => {
        this.setState({
            showDetails: true,
            plantDetail
        });
        return;
    }

    getDateForReminder = () => {
        const parentValues = this.props.parentInstance.state;
        let date: any = null;
        if (parentValues.currentCompOrIncompFreq === 'Daily') {
            date = format(parentValues.dailySummaryGraphDate, 'MM-dd-yyyy');
        } else if (parentValues.currentCompOrIncompFreq === 'Weekly') {
            const parsedDate = parse(parentValues.weeklySummaryGraphDate, 'MM/dd/yyyy', new Date());
            date = format(parsedDate, 'MM-dd-yyyy');            
        } else if (parentValues.currentCompOrIncompFreq === 'Monthly') {
            date = format(moment(parentValues.monthlySummaryGraphDate).toDate(), 'MM-yyyy');
        } else if (parentValues.currentCompOrIncompFreq === 'Quarterly') {
            const qtr = parentValues.quarterlySummaryGraphDate.qtr === '1st' ? 1 : parentValues.quarterlySummaryGraphDate.qtr === '2nd' ? 2 : parentValues.quarterlySummaryGraphDate.qtr === '3rd' ? 3 : 4;
            date = {year: parentValues.quarterlySummaryGraphDate.year.toString(), qtr: qtr.toString()};
        } else if (parentValues.currentCompOrIncompFreq === 'Yearly') {
            date = format(moment(parentValues.yearlySummaryGraphDate).toDate(), 'yyyy');
        }
        return date;
    }

    showPlantList = () => { 
        const {data, listItemComponent, listTitle} = this.props;
        const ListItem = listItemComponent;
        const dateToSend = this.getDateForReminder();
        return data && data.length > 0 ? data.map((ins, index) => {
            return <ListItem
                index={index}
                key={index}
                data={ins}
                date={dateToSend}
                type={this.props.listTitle ? this.props.listTitle : ''}
                onClick={(plant) => this.handleOnListItemClick(plant)}
            />;
        }) : <div style={{fontSize: '16px', color: 'gray', textAlign: 'center'}}>No {listTitle} found. </div>;
    }

    hideModal = () => {
        hidePlantListModal();
        deletePlantDetails();
        if (this.props.closePopup)
            this.props.closePopup();
        this.setState({
            showDetails: false
        });
    }

    renderContent = () => {
        const {listHeadings} = this.props;
        return <Modal.Body style={{backgroundColor: 'black', border: '1px solid #757575'}}>
            <Alert id="incomplete-list-update"/>
            {this.state.showDetails ? '' :
                <div className="heading-container">{listHeadings.map((heading, index) => {
                    return heading === '#' ?
                        <div
                            key={index}
                            className="heading" style={{width: '10%'}}
                        >
                            #
                        </div> :
                        <div
                            key={index}
                            className="heading"
                            style={{width: `${100 / (listHeadings.length - 1)}%`}}
                        >
                            {this.props.formType === 'Complete' ? 'Form Type' : heading}
                        </div>;
                })}</div>
            }
            {this.state.showDetails ?
                this.renderPlantDetails() :
                <div className="list-items">
                    <PerfectScrollbar>
                        {this.showPlantList()}
                    </PerfectScrollbar>
                </div>}
        </Modal.Body>;
    }

    renderModalBody = () => {
        return this.renderContent();
    }

    renderListItems = () => {
        const {
            style, listTitle, className
        } = this.props;
        return <Modal
            show={true}
            className={`${className} list-modal`}
            style={style}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton style={modalHeader}>
                <Modal.Title id="contained-modal-title" style={{color: 'white'}}>
                    {this.state.showDetails ?
                        <div className="back-button" onClick={() => this.setState({showDetails: false})}>
                            <i style={{
                                fontSize: '22px', paddingRight: '10px'
                            }} className="fa fa-arrow-circle-left" aria-hidden="true"></i>
                            {`Details Of Plant ${this.state.plantDetail.plantId}`}
                        </div> : listTitle}
                </Modal.Title>
            </Modal.Header>
            {this.renderModalBody()}
        </Modal>;
    }

    render() {
        return this.renderListItems();
    }

}

export function mapStateToProps(state) {
    return {};
}

export const ListModal = connect<{}, {}, IListModalProps>(mapStateToProps)(ListModalImpl as any);

const modalHeader: React.CSSProperties = {
    backgroundColor: '#A4BF43',
    border: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
};
