import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {DropdownButton, Dropdown, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Col, Row, } from 'react-bootstrap';  
import {IHistory} from '../../interfaces';
import {submitPlantForm} from '../../services/plantService';
import {BasePage} from '../reusableComponents/BasePage'; 
import {parseJwt} from "../../services/loginService"; 
import {BoxInput} from '../reusableComponents/FormComponents/BoxInput';
import * as _ from 'lodash';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Async } from '../reusableComponents/Async';
import {getAllInspectionsByComp} from '../../services/plantService';
import { UserModel } from '../../model/UserModel';
import {toast} from 'react-toastify';
import { Form } from '../reusableComponents/FormComponents/Form';
import './InspectionType.scss';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
 
export interface IInspectionTypesProps {
    history?: IHistory;
    userUID: any;
    userName: any;
}
export interface IInspectionTypesState {
    inspectionInfo: any;
    isLoading: boolean;
    facilityType: string;
}

export class InspectionTypesImpl extends React.PureComponent<IInspectionTypesProps, IInspectionTypesState> {
     
    userData = UserModel.getUserData();
    constructor(props: IInspectionTypesProps) {
        super(props);
        this.state = { isLoading: false, inspectionInfo: [], facilityType: 'Ready Mix'}
    }
    promise = async () => {
        this.setState({ 
            isLoading: true
        });
        await this.loadInspectionData();
        return
    }
    loadInspectionData = async () => {
        await getAllInspectionsByComp(this.userData.orgId, this.state.facilityType).then(res => { 
            this.setState({ 
                isLoading: false,  inspectionInfo: res?.data?.data || []
            });
            let message = res?.data?.message;
            if(message==='inspection not found') {
                toast.error('No inspection form found', {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'failed-toast'
                });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            });
        });
    }
    onSubmitForm = () => {
        return;
    }
    handlePlantFormSubmit = (values) => {
       return submitPlantForm(values, this.props.history);
    }
    renderHtmlContent = () => {
       /*  if (this.state.isLoading) {
            return <span className="facility-loader-image">
                <CircularProgress size={150} color="#A4BF43" thickness={5}/>
            </span>
        } */
        if (!_.isEmpty(this.state.inspectionInfo)) {
            return (['Water', 'Air', 'Waste', 'Others'].map((items, index) => (
                [
                    this.state.inspectionInfo[items] && (this.state.inspectionInfo[items].Daily.length || this.state.inspectionInfo[items].Monthly.length || this.state.inspectionInfo[items].Quarterly.length || this.state.inspectionInfo[items].Yearly.length) ?
                        <Row key={index}>
                            <div className="permit-type-box col-12">Permit Type - {items}</div>
                            <BoxInput
                                label="Inspections"
                                permitType={items}
                                formsList={this.state.inspectionInfo[items]}
                                type="Inspections"
                                id="Insnew"
                                onClick={this.handleInspectionFormChange}
                            />
                        </Row> : null
                ]
            )));
        }
    }
    handleInspectionFormChange = (insData, freq, permitType, inputType) => { 
        this.props.history?.push(`/inspection-setup/${insData.FormId}/${permitType}/${insData.FormName}`);
        return true; 
        return <div ><OverlayTrigger  
                trigger="click"
                placement="right"
                overlay={ this.renderComponentOption() }  
                show={true}
            >
        <i className="fa fa-ellipsis-v" aria-hidden="true"  ></i>
        </OverlayTrigger></div>  
    }
    renderComponentOption = () => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover position-option-component">
            <Popover.Title as='h2' className="popover-title">Position   <span className="closeMoveComponent"  ><i className="fa fa-close"></i></span></Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button delete'  ><button >Delete</button></div> 
                    
                </div> 
            </PopoverContent>
        </Popover>; 
    }
    renderContent() {
        return  <div className="plant-detail-container">            
                 {this.state.isLoading ? <Loader type="submission"/> : ''}
                <Row>
                <Col className="plant-basic-datas">
                    <Form
                        model='forms.plantForm'
                        onSubmit={this.onSubmitForm}>
                        <Row>
                            <Col sm={{span: 6, offset: 3}}>
                                <RRFInput
                                    model=".facilityType"
                                    type="dropdown"
                                    placeholder="Select a Facility Type"
                                    id="facilityType"
                                    onSelect={this.onFieldValueChange}
                                    menuItems={[{
                                            label: 'Ready Mix', value: 'Ready Mix'
                                        },
                                        {
                                            label: 'Quarry', value: 'Quarry'
                                        },
                                        {
                                            label: 'Asphalt', value: 'Asphalt'
                                        },
                                        {
                                            label: 'Maintenance Shop', value: 'Maintenance Shop'
                                        },
                                        {
                                            label: 'Others', value: 'Others'
                                        }
                                    ]}
                                    label="Facility Type" 
                                    defaultValue={this.state.facilityType}
                                />
                            </Col>
                        </Row>
                        <Row className="inspections-form-selector">{this.renderHtmlContent()}</Row>
                    </Form>
                </Col>
            </Row>
        </div> 
    }
    onFieldValueChange = async (val)=> {
        await this.setState({
            isLoading: true, facilityType: val.value
        });
        await this.loadInspectionData(); 
    }
    renderAddInspectionTypeButton = () => {
        return <> 
        <div className="add-button" onClick={() => this.props.history?.push('/inspection-type/create')}>
            Add Inspection <i className="fa fa-plus-square" aria-hidden="true"/>
        </div></>;
    }
    render() {
        return <BasePage key={3} className="plant-detail-container inspection-type-base" pageTitle="Inspection Types" sideButton={this.renderAddInspectionTypeButton()}>
                <Async
                    identifier="InspectioNTypes"
                    promise={this.promise}
                    loader={<Loader/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                /> 
        </BasePage>;
    }
}

function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    return {
        userUID: state.login.get('UniqueId'),
        userName
    };
}

export const InspectionTypesForm = withRouter(connect<IInspectionTypesProps, any, any>(mapStateToProps)(InspectionTypesImpl as any));
