import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {IHistory} from '../../interfaces';
import {submitTechnicalFeedback} from '../../services/formService';
import {isFormEmpty} from '../../utils/generalUtils';
import {BasePage} from '../reusableComponents/BasePage';
import {Form} from '../reusableComponents/FormComponents/Form';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import './technicalSupportPage.scss';

export interface ITechnicalSupportPageProps {
    history?: IHistory;
}

export class TechnicalSupportPage extends React.PureComponent<ITechnicalSupportPageProps, {}> {
    constructor(props: ITechnicalSupportPageProps) {
        super(props);
    }

    handleSubmit = async (values) => {
        if (isFormEmpty(values)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        await submitTechnicalFeedback(values, this.props.history);
    }

    render() {
        return <BasePage pageTitle="How are we doing...?">
            <div className={"technical-support-page"}>
                <Form model="forms.supportForm" onSubmit={this.handleSubmit}>
                    <div className="info">
                        We hope you're enjoying using the new <strong>EnviroConnect Data System.</strong> Please drop a
                        comment below if there is something you liked or didn't like about the web
                        application. When you hit the submit button, your comment will be forwarded to the project
                        manager over at SPI and we will do our best to make it even better!
                    </div>
                    <RRFInput
                        model=".comments"
                        type="textarea"
                        placeholder="Enter your comments..."
                        id="Comments"
                        label="Comments:"
                    />
                    <FormFooter/>
                </Form>
            </div>
        </BasePage>;
    }
}

// export const TechnicalSupportPage = withRouter(TechnicalSupportPageImpl);
