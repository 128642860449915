import './navbar.scss';

import * as React from 'react';
import {Container, Nav, Navbar, NavDropdown, NavItem, NavLink} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import {Modal} from 'react-bootstrap';
import {logoutUser, parseJwt} from '../../../services/loginService';
import {RRFInput} from '../FormComponents/RRFInput';
import {getAllOrgs, changeOrgDetails} from '../../../services/orgService';
import {Form} from '../FormComponents/Form';
import {resetModels, refreshWindow, isMobileDevice} from '../../../utils/generalUtils';
import {IHistory} from "../../../interfaces";
import logo from '../../../images/logo_.png';
import ArgosGeorgia from '../../../images/Argos-Georgia.png';
import spiIcon from '../../../images/spi.png';
import orgLoader from '../../../../src/images/loader.gif';
import {OrgModel} from '../../../model/OrgModel';


export interface INavbarProps {
    orgInstances: OrgModel[];
    history?: IHistory;
    userId?: string;
    UniqueId?: string;
    Access_Level?: string;
    First_Name?: string;
}

export interface INavbarState {
    orgsList: Array<any>;
    isOrgsListLoading: boolean;
    currentGlobalOrgLogo: string | null;
    currentGlobalOrg: string | null;
    showOrgsLister: boolean;
}

export class NavbarImpl extends React.PureComponent<INavbarProps, INavbarState> {
    static defaultProps: INavbarProps;

    constructor(props: INavbarProps) {
        super(props);
        this.state = {
            isOrgsListLoading: false,
            currentGlobalOrgLogo: localStorage.getItem('L1OrgLogo'),
            currentGlobalOrg: localStorage.getItem('L1OrgId'),
            orgsList: [{label: 'All', value: 'All', orgLogoDetails: []}],
            showOrgsLister: false
        };

        if (OrgModel.list().length === 0 && props.Access_Level === 'L1') {
            this.loadOrgsList();
        }
    }

    isSamePath = (path: string) => {
        const {history: {location: {pathname}}}: any = this.props;
        return path === pathname;
    }

    onOrgSelection = async (org, id: string) => {
        if (this.state.currentGlobalOrg !== org.value) {
            const imageURL = org.orgLogoDetails.length ? org.orgLogoDetails[0].imageURL : '';
            await this.setState({
                currentGlobalOrg: org.value,
                currentGlobalOrgLogo: imageURL
            });
            resetModels();
            localStorage.setItem('L1OrgId', org.value);
            localStorage.setItem('L1OrgLogo', imageURL);
            await changeOrgDetails(org.value)

            this.props?.history?.push('/home');
        }
    }

    loadOrgsList = () => {
        this.setState({showOrgsLister: true, isOrgsListLoading: true});
        const orgsList = [{label: 'All', value: 'All', orgLogoDetails: []}];
        getAllOrgs().then(res => {
            res.forEach(org => {
                orgsList.push({label: org.orgName, value: org.orgId, orgLogoDetails: org.orgLogoDetails});
            });
            this.setState({
                isOrgsListLoading: false,
                orgsList
            });
        });

    }

    loadOrgSelector = () => {
        return <Modal
            className="list-item-modal modal-scrollable"
            show={this.state.showOrgsLister}
            onHide={() => {
                this.setState({
                    showOrgsLister: false,
                    currentGlobalOrg: localStorage.getItem('L1OrgId'),
                    currentGlobalOrgLogo: localStorage.getItem('L1OrgLogo')
                });
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Select an organisation...
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.isOrgsListLoading ?
                    <div className="comp-selector-container"><img alt="org-Loader" src={orgLoader}/></div> :
                    <div className="comp-selector-container">
                        <Form
                            model="forms.orgFilterForm"
                            onSubmit={() => {
                                return false;
                            }}
                            className="">
                            <RRFInput
                                model=".orgId"
                                type="dropdown"
                                placeholder="Select Organisation..."
                                id="orgId"
                                className="org-lister"
                                onSelect={this.onOrgSelection}
                                menuItems={this.state.orgsList}
                                label=""
                                defaultValue={this.state.currentGlobalOrg}
                            />
                        </Form>
                        <div className="buttons-container">
                            <button onClick={() => {
                                this.setState({
                                    showOrgsLister: false,
                                    currentGlobalOrg: sessionStorage.getItem('L1OrgId'),
                                    currentGlobalOrgLogo: sessionStorage.getItem('L1OrgLogo')
                                });
                            }
                            } className="comp-selection-btn">Close
                            </button>
                        </div>
                    </div>}
            </Modal.Body>
        </Modal>;
    }
    loadOrgList = () => {
        if (OrgModel.list().length > 0 ) {
            const transformedArray = OrgModel.list().map(obj => ({
                label: obj.props.orgName,
                value: obj.props.orgId,
                orgLogoDetails: obj.props.orgLogoDetails.map(detail => ({
                  imageURL: detail.imageURL,
                //   dirPath: detail.dirPath,
                //   fileName: detail.fileName
                })),
            }));
            transformedArray.unshift({ label: 'All', value: 'All', orgLogoDetails: [] });
            this.setState({orgsList :transformedArray})
        }
    }

    render() {
        return [
            <div id="print-header-container">
                <div className="print-header-bar-left">
                    <img className="org-print-header-logo" src={this.state.currentGlobalOrgLogo || undefined} alt="org"/>
                </div>
                <div className="print-header-bar-right">
                    <img className="spi-print-header-logo" src={spiIcon} alt=""/>
                </div>
            </div>,
            <Navbar bg="light" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand>
                        {this.loadOrgSelector()}
                        <img className="nav-logo"
                             onClick={() => this.props.Access_Level === 'L1' ? (this.setState({showOrgsLister: true}), this.loadOrgList()) : this.props.history?.push('/home')}
                             alt="EnviroConnect" src={logo}/>
                        {this.state.currentGlobalOrgLogo && this.state.currentGlobalOrgLogo !== '' &&
                        <img className="nav-logo org-logo" onClick={() => this.props.history?.push('/home') } src={this.state.currentGlobalOrgLogo} alt="Org Logo"/>}
                        { this.state.currentGlobalOrg === '705bf30b-082e-453b-8f51-0ada593e4ca3' ? <div className='reg-logo'><p>Georgia <br></br>Carolinas</p></div>: '' }

                        {/* { this.state.currentGlobalOrg === '705bf30b-082e-453b-8f51-0ada593e4ca2' ? <img className="nav-logo additional-org-logo" onClick={() => this.props.history?.push('/home')}
                        alt="OrgLogo" src='https://enviroconnect-beta.s3.amazonaws.com/companyLogos/55bcfd7d-50bf-4ce5-9dba-6a8ce0e2336b_5NTpph764Lk9DH.png' />: '' } */}
                        
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/> 
                    <Navbar.Collapse id="basic-navbar-nav" className='responsive-navbar-nav'>
                        
                        <Nav className="ms-auto responsive-navbar-links">
                            <LinkContainer replace={this.isSamePath('/home')} to={`/home`}>
                                <NavLink eventKey={1}>Home</NavLink>
                            </LinkContainer>
                            {this.state.currentGlobalOrg ==='705bf30b-082e-453b-8f51-0ada593e4ca2'?<LinkContainer replace={this.isSamePath('/inspections')} to={`/inspections`}>
                                <NavLink eventKey={2}>Inspections</NavLink>
                            </LinkContainer> : ''}
                            {this.state.currentGlobalOrg !=='705bf30b-082e-453b-8f51-0ada593e4ca2'?<NavDropdown title="Inspections" id="basic-nav-dropdown" className='insp-Drpdown'>
                                <LinkContainer replace={this.isSamePath('/inspections')} to={`/inspections`}>
                                    <NavDropdown.Item>Env'l Inspection</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer replace={this.isSamePath('/housekeeping')} to={`/housekeeping`}>
                                    <NavDropdown.Item>HouseKeeping</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>: '' }
                            <LinkContainer replace={this.isSamePath('/summary-reports')} to={`/summary-reports`}>
                                <NavLink eventKey={3}>Form Summary</NavLink>
                            </LinkContainer>
                            <NavDropdown title="Reports" id="basic-nav-dropdown" className='report-Drpdown'>
                                <LinkContainer replace={this.isSamePath('/reports/daily')}
                                                to={`/reports/daily`}><NavDropdown.Item>Daily Reports</NavDropdown.Item></LinkContainer>
                                <LinkContainer replace={this.isSamePath('/reports/monthly')}
                                                to={`/reports/weekly`}><NavDropdown.Item>Weekly Reports</NavDropdown.Item></LinkContainer>
                                <LinkContainer replace={this.isSamePath('/reports/monthly')}
                                                to={`/reports/monthly`}><NavDropdown.Item>Monthly Reports</NavDropdown.Item></LinkContainer>
                                <LinkContainer replace={this.isSamePath('/reports/quarterly')}
                                                to={`/reports/quarterly`}><NavDropdown.Item>Quarterly Reports</NavDropdown.Item></LinkContainer>
                                <LinkContainer replace={this.isSamePath('/reports/yearly')}
                                                to={`/reports/yearly`}><NavDropdown.Item>Yearly Reports</NavDropdown.Item></LinkContainer>
                                <LinkContainer replace={this.isSamePath('/flagged-reports')}
                                                to={`/flagged-reports`}><NavDropdown.Item>Flagged Reports</NavDropdown.Item></LinkContainer>
                            </NavDropdown> 
                            {this.props.Access_Level !== 'L1' &&
                            <LinkContainer replace={this.isSamePath('/dashboard')} to={`/dashboard`}>
                                <NavLink eventKey={4}>Dashboard</NavLink>
                            </LinkContainer>}
                            <LinkContainer replace={this.isSamePath('/maintenance')} to={`/maintenance`}>
                                <NavLink eventKey={5}>Maintenance</NavLink>
                            </LinkContainer>
                            <NavDropdown title="References" id="basic-nav-dropdown">
                                <LinkContainer replace={this.isSamePath('/golden-rules')} to={`/golden-rules`}>
                                    <NavLink eventKey={7.1}>
                                        Golden Rules
                                    </NavLink>
                                </LinkContainer>
                                <LinkContainer replace={this.isSamePath('/references')} to={`/references`}>
                                    <NavLink eventKey={7.2}>
                                        Reference Documents
                                    </NavLink>
                                </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title={this.props.First_Name} id="basic-nav-dropdown-two " className='user-profile-dropdown'>
                                <LinkContainer eventKey={8.1}
                                    replace={this.isSamePath(`/profile/${this.props.UniqueId}`)}
                                    to={`/profile/${this.props.UniqueId}`}><NavDropdown.Item>
                                    <i className="fa fa-user-circle-o"/>
                                    &nbsp;
                                    User Profile</NavDropdown.Item></LinkContainer>
                                {this.props.Access_Level !== 'L4' &&
                                <LinkContainer eventKey={8.2} replace={this.isSamePath(`/users`)}
                                               to={`/users`}><NavDropdown.Item> <i className="fa fa-user-plus"/>
                                    &nbsp;
                                    Users</NavDropdown.Item></LinkContainer>}
                                {(this.props.Access_Level === 'L2' || this.props.Access_Level === 'L1') ?
                                <LinkContainer eventKey={8.21} replace={this.isSamePath(`/inspection-types`)}
                                            to={`/inspection-types`}><NavDropdown.Item> <i className="fa fa-bookmark"/>
                                &nbsp;
                                Add/Edit Insp Forms</NavDropdown.Item></LinkContainer> : ''}
                                {this.props.Access_Level !== 'L1' ? '' :
                                    <LinkContainer eventKey={8.3} replace={this.isSamePath(`/org`)}
                                                   to={`/org`}><NavDropdown.Item> <i className="fa fa-building"/>
                                        &nbsp;
                                        Companies</NavDropdown.Item></LinkContainer>}
                                <LinkContainer eventKey={8.4} replace={this.isSamePath(`/update-password`)}
                                               to={`/update-password`}><NavDropdown.Item> <i className="fa fa-unlock"/>
                                    &nbsp;
                                    Change Password</NavDropdown.Item></LinkContainer>
                                <LinkContainer eventKey={8.5} replace={this.isSamePath('/technical-support')}
                                               to={'/technical-support'}><NavDropdown.Item>
                                    <i style={{fontSize: '22px'}} className="fa fa-question-circle-o"/>
                                    &nbsp;
                                    Technical Support
                                </NavDropdown.Item></LinkContainer>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => logoutUser(this.props.history!)}>
                                    <i className="fa fa-sign-out"/>&nbsp;Sign Out</NavDropdown.Item>
                                {isMobileDevice() && <div>
                                    <div className='refresh-window' onClick={() => refreshWindow() }>
                                        <i className="fa fa-refresh" aria-hidden="true"></i> {`${ window.innerWidth}, ${ window.innerHeight}`}
                                    </div>
                                </div> }
                            </NavDropdown>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>,
            <div className="footer-bar-container d-print-none">
                <div className="footer-bar-left">
                    <img className="spi-footer-logo" src={spiIcon} alt=""/>
                </div>
                <div className="footer-bar-right">
                    <div className="footer-right-link">Contact us</div>
                    <div className="footer-right-link">Privacy Policy</div>
                </div>
            </div>]
    }
}

export function mapStateToProps(state) {
    let Access_Level = '';
    let First_Name = '';
    const orgInstances = OrgModel.list();

    if (state.login.get('token')) { 
        Access_Level = parseJwt(state.login.get('token')).Access_Level;
        First_Name = parseJwt(state.login.get('token')).First_Name;
    }
    return {
        userId: state.login.get('userId'),
        UniqueId: state.login.get('UniqueId'),
        Access_Level, First_Name,
        orgInstances
    };
}

export const NavbarComp = withRouter(connect< INavbarProps, any, any>(mapStateToProps)(NavbarImpl as any));



