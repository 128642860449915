export const SAVE_DAILY_FORM_DATA = 'SAVE_DAILY_FORM_DATA';
export const SAVE_FORM_COUNTS = 'SAVE_FORM_COUNTS';
export const SAVE_MONTHLY_FORM_DATA = 'SAVE_MONTHLY_FORM_DATA';
export const SAVE_QUARTERLY_FORM_DATA = 'SAVE_QUATERLY_FORM_DATA';
export const SAVE_LINE_CHART_DATA = 'SAVE_LINE_CHART_DATA';
export const SAVE_INSTANCE = 'SAVE_INSTANCE';
export const REMOVE_INSTANCE = 'REMOVE_INSTANCE';
export const UPDATE_INSTANCE = 'UPDATE_INSTANCE';
export const SAVE_DAILY_FORM_FILTER = 'SAVE_DAILY_FORM_FILTER';
export const SAVE_MONTHLY_FORM_FILTER = 'SAVE_MONTHLY_FORM_FILTER';
export const SAVE_QUARTERLY_FORM_FILTER = 'SAVE_QUARTERLY_FORM_FILTER';
export const REMOVE_FILTER_DATA = 'REMOVE_FILTER_DATA';
export const SHOW_INCOMPLETE_PLANTS_MODAL = 'SHOW_INCOMPLETE_PLANTS_MODAL';
export const HIDE_INCOMPLETE_PLANTS_MODAL = 'HIDE_INCOMPLETE_PLANTS_MODAL';
export const SAVE_PLANT_DETAILS = 'SAVE_PLANT_DETAILS';
export const DELETE_PLANT_DETAILS = 'DELETE_PLANT_DETAILS';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SAVE_FLAGGED_DAILY_FORM_DATA = 'SAVE_FLAGGED_DAILY_FORM_DATA';
export const SAVE_FLAGGED_MONTHLY_FORM_DATA = 'SAVE_FLAGGED_MONTHLY_FORM_DATA';
export const SAVE_FLAGGED_FORM_DATA = 'SAVE_FLAGGED_FORM_DATA';
export const SAVE_LOGIN_DATA = 'SAVE_LOGIN_DATA';
export const REMOVE_LOGIN_DATA = 'REMOVE_LOGIN_DATA';
export const LOGOUT = 'LOGOUT';
export const HIGHLIGHT_EMPTY_FIELD = 'HIGHLIGHT_EMPTY_FIELD';
export const REMOVE_EMPTY_FIELD = 'REMOVE_EMPTY_FIELD';
export const SAVE_FLAGGED_FORM_DETAILS = 'SAVE_FLAGGED_FORM_DETAILS';
export const SAVE_PLANT_OPTIONS = 'SAVE_PLANT_OPTIONS';
export const REMOVE_PLANT_OPTIONS = 'REMOVE_PLANT_OPTIONS';
export const EMPTY_PLANT_OPTIONS = 'EMPTY_PLANT_OPTIONS';
export const SAVE_MONTHLY_FORM_QUESTIONS = 'SAVE_MONTHLY_FORM_QUESTIONS';
export const HIDE_UST_MODAL = 'HIDE_UST_MODAL';
export const SHOW_UST_MODAL = 'SHOW_UST_MODAL';
export const SHOW_IMAGE = 'SHOW_IMAGE';
export const HIDE_IMAGE = 'HIDE_IMAGE';
export const IS_IMAGE_LOADING = 'IS_IMAGE_LOADING';
export const RESET_DASHBOARD_FILTERS = 'RESET_DASHBOARD_FILTERS';
export const SAVE_FLAGGED_QUARTERLY_FORM_DATA = 'SAVE_FLAGGED_QUARTERLY_FORM_DATA';
export const SAVE_LINE_CHART_FILTER = 'SAVE_LINE_CHART_FILTER';
export const SAVE_DROPDOWN_FILTER = 'SAVE_DROPDOWN_FILTER';
export const UPDATE_MODAL_DATA = 'UPDATE_MODAL_DATA';
export const PUSH_ALERT = 'PUSH_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_TIMEOUT_ID = 'SET_TIMEOUT_ID';
export const SAVE_ACTIVITIES_MENU_ITEMS = 'SAVE_ACTIVITIES_MENU_ITEMS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SAVE_REFERENCE_ITEMS = 'SAVE_REFERENCE_ITEMS';
export const HIDE_MODAL = 'HIDE_MODAL';
export const IMAGEALLOWEDEXE = 'IMAGEALLOWEDEXE';
export const SHOW_PRINT_PREVIEW_MODAL = 'SHOW_PRINT_PREVIEW_MODAL';
export const HIDE_PRINT_PREVIEW_MODAL = 'HIDE_PRINT_PREVIEW_MODAL';
export const SAVE_AXIOS_PREVIOUS_TOKEN = 'SAVE_AXIOS_PREVIOUS_TOKEN';


