import {toast} from 'react-toastify';
import {IHistory} from '../interfaces';

import {setLoading, setSuccess} from '../actions/loadingActions';
import {FORM_SUBMISSION_SUCCESS, SUBMISSION_FAILED} from '../constants/generals';
import {PlantModel} from '../model/PlantModel';
import {IOutfall, ISettledFormModelProps, SettledFormModel} from '../model/SettledFormModel';
import {getCurrentUserUUID} from '../utils/generalUtils';
import {delDev, getDev, postDev, putDev} from '../utils/HTTPDev';
import {UserModel} from '../model/UserModel';

/*export async function getAllRegionsByOrg(org) {
    try {
        return await getDev('/region');
    } catch (error) {
        throw error;
    }
}*/

export async function getAllPlantsByRegion(userId = getCurrentUserUUID(), region = 'All', isAsc = true, sortIndex = 'plantId', curPageNo = 1, searchText = 'None', axiosPreviousToken = null) {
    PlantModel.deleteAll();
    setLoading('PlantListPage');
    try {
        searchText = searchText || 'None'
        searchText = searchText.replace(/[^a-zA-Z0-9 ]/g, '');

        const response = await getDev(`/plantsbyregion/${region || 'All'}/${userId}/${isAsc}/${sortIndex}/${curPageNo}/${searchText}`,[],axiosPreviousToken);
        if (response?.data.data instanceof Array) {
            PlantModel.deleteAll();
        }

        (response?.data.data instanceof Array ? response?.data.data : []).forEach(instance => {

            const newInstance = marshallPlantInstance(instance);
            new PlantModel(newInstance).$save();
        });
        setSuccess('PlantListPage');

    } catch (error) {
        // setSuccess('PlantListPage');
        throw error;
    }
}

export async function getAllPlantsByMultipleRegions(orgId, regionsList) {
    try {
        return postDev('regionplants/' + orgId, regionsList);
    } catch (error) {
        throw error;
    }
}

export async function getAllInspectionsByComp(compId: string, facilityType: string) {
    try {
        const response = await getDev('/inspectionstype/' + compId + '/' + facilityType);
        setSuccess('inspectionsFormsList');
        return response;
    } catch (error) {
        setSuccess('PlantListPage');
        throw error;
    }
}

export async function getPlantDetails(plantId: string) {
    PlantModel.deleteAll();
    setLoading('PlantDetailsPage');
    try {
        const response = await getDev(`/plant/${plantId}`, {});
        const newInstance = marshallPlantInstance(response?.data.data);
        new PlantModel(newInstance).$save();
        setSuccess('PlantDetailsPage');
    } catch (error) {
        setSuccess('PlantDetailsPage');
        throw error;
    }
}

function marshallPlantInstance(instance) {
    return {
        id: instance && instance.uniqueId || '',
        street: instance && instance.street || '',
        country: instance && instance.country || '',
        state: instance && instance.state || '',
        plantId: instance && instance.plantId || '',
        orgId: instance && instance.orgId || '',
        /* plantRegion: instance && instance.plantRegion || '', */
        plantRegion: (instance && instance.plantRegion) || (instance && instance.plantRegionId) || '',
        plantRegionId: instance && instance.plantRegion || '',
        plantRegionName: instance && instance.plantRegionName || '',
        zip: instance && instance.zipCode || '',
        Active: instance && instance.Active || '',
        TotalCount: instance && instance.TotalCount || '',
        pagedataCnt: instance && instance.pagedataCnt || '',
        city: instance && instance.city || '',
        plantName: instance && instance.plantName || '',
        facilityType: instance && instance.facilityType || '',
        coords: instance && instance.coords || {},
        inspections: instance && instance.inspections || [],
        shapesList: instance && instance.shapesList || [],
        outfallsList: instance && instance.outfallsList || [],
        coordinatesPointerList: instance && instance.coordinatesPointerList || [],
        userdetailsList: instance && instance.userdetails || [],
    };
}

export async function submitPlantForm(plantInfo, history?: IHistory) {
    try {
        const response = await postDev('/addplant', plantInfo);
        history?.push('/maintenance');
        toast.success(FORM_SUBMISSION_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => history?.push('/maintenance'),
            className: 'success-toast',
            autoClose: 2000
        });
        await getAllPlantsByRegion(getCurrentUserUUID());
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function updatePlant(plantId: string, data: Object, history?: IHistory) {
    setLoading('PlantDetailsPage');
    try {
        const response = await postDev(`/updateplant/${plantId}`, data);

        toast.success(response?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            onClose: () => history?.push('/maintenance'),
            autoClose: 1000
        });
        setSuccess('PlantDetailsPage');
        await getAllPlantsByRegion(getCurrentUserUUID());
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function getSettledForms(date: string, plantUUID: string) {
    try {
        const userData = UserModel.getUserData();
        const plantInfo = PlantModel.get(plantUUID);

        const response = await getDev(`/inspectionforms/${userData.orgId}/${plantUUID}/${plantInfo.props.facilityType}/${date}`);

        const instance: ISettledFormModelProps = {
            id: plantUUID,
            inspections: response?.data.data
        };
        new SettledFormModel(instance).$save();
        return true;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function deleteForm(date: string, plantUID: string, formType: string, instance, outfall?: string) {
    try {
        const response: any = await delDev(`/deleteform/${date}/${plantUID}/${formType}/${outfall || 'na'}`);
        toast.error((response?.data.message || 'Form deleted successfully...!!!'), {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast'
        });
        setLoading('InspectionFooter');
        await getSettledForms(date, plantUID);
        setSuccess('InspectionFooter');
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        document.body.click();
        throw error;
    }
}

export async function deletePlant(plantUID: string, history?: IHistory) {
    setLoading('PlantDetailsPage');
    try {
        const response: any = await delDev(`/plant/${plantUID}`);
        const plantInstance = PlantModel.get(plantUID);
        new PlantModel(plantInstance.props).$delete();
        await getAllPlantsByRegion('All');
        toast.success((response?.data.message || 'Plant deleted successfully...!!!'), {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            autoClose: 2000,
            onClose: () => history?.push('/maintenance')
        });
        setSuccess('PlantDetailsPage`');
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        document.body.click();
        setSuccess('PlantDetailsPage`');
        throw error;
    }
}

export async function deActivatePlant(plantUID: string, history?: IHistory) {
    setLoading('PlantDetailsPage');
    try {
        const response: any = await putDev(`/plantDeActivate/${plantUID}`,plantUID);
        /* const plantInstance = PlantModel.get(plantUID);
        new PlantModel(plantInstance.props).$delete();
        await getAllPlantsByRegion('All'); */
        toast.success((response?.data.message || 'Plant deleted successfully...!!!'), {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            autoClose: 2000,
            onClose: () => history?.push('/maintenance')
        });
        setSuccess('PlantDetailsPage`');
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        document.body.click();
        setSuccess('PlantDetailsPage`');
        throw error;
    }
}
