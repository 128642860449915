import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {setSuccess} from '../actions/loadingActions';
import {FORM_SUBMISSION_SUCCESS} from '../constants/generals';
import {IHistory} from '../interfaces';
import {ChecklistModel} from '../model/ChecklistModel';
import {IMonthlyFormModelProps, MonthlyFormModel} from '../model/MonthlyFormModel';
import {QuestionModel} from '../model/QuestionModel';
import {getCurrentUserUUID, marshallChecklistData, marshallFormDetails, objectToArray} from '../utils/generalUtils';
import {getDev, postDev, putDev} from '../utils/HTTPDev';
import {uploadImages} from './formService';
import {getAllPlantsByRegion} from './plantService';

export async function getMonthlyFormList(mm: string, yyyy: string, type: string, region: string, isAsc = false, sortIndex = 'submittedDate', curPageNo = 1, searchText = 'None', axiosPreviousToken = null) {
    const allInstances = MonthlyFormModel.list();
    allInstances.forEach((instance: InstanceType<any>) => {
        new MonthlyFormModel(instance.props).$delete();
    });
    try {
        const dataToSend = {
            'region': region || 'All',
            'user': getCurrentUserUUID(),
            'isAsc':isAsc,
            'sortIndex': sortIndex,
            'curPageNo': curPageNo,
            'searchText':searchText.replace(/[^a-zA-Z0-9 ]/g, '')
        };
        const response = await postDev(`/forms-m/${mm}/${yyyy}`, dataToSend, axiosPreviousToken);
        setSuccess('MonthlyFormList');
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getFlaggedFormList(freq: string, linearDate: string, region: string, status: string, pageno: number, allfreqFlag: boolean, isAsc: boolean, sortIndex: string, searchText: string = 'None', axiosPreviousToken = null) {
    const allInstances = MonthlyFormModel.list();
    allInstances.forEach((instance: InstanceType<any>) => {
        new MonthlyFormModel(instance.props).$delete();
    });
    try {
        const dataToSend = {
            'freq': freq,
            'Date':linearDate,
            'region': region || 'All',
            'status':status,
            'user': getCurrentUserUUID()
        };
        const response = await getDev(`/flagged-report/${freq}/${linearDate}/${region}/${status}/${pageno}/${allfreqFlag}/${isAsc}/${sortIndex}/${searchText}`, dataToSend, axiosPreviousToken);
        setSuccess('MonthlyFormList');
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getMonthlyFormDetails(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IMonthlyFormModelProps = {id: '', questions: [], ImageUrls: [], Completed: false};
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.UserName,
            question: 'Submitted By',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: format(response?.data.data.ActualDate, 'MM/dd/yyyy'),
            question: 'Submission Date',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.PlantId,
            question: 'Plant Id',
            flaggedQuestion: false
        });
        objectToArray(response?.data.data.Answers).forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        if (response?.data.data.Completed === 1) {
            questionsData.CompletedBy = response?.data.data.CompletedBy;
            questionsData.DateCompleted = format(response?.data.data.DateCompleted, 'MM/dd/yyyy');
            questionsData.FollowUpComments = response?.data.data.FlaggedComments;

        }
        questionsData.Associations = response?.data.data.Associations;
        questionsData.id = formId;
        questionsData.Completed = response?.data.data.Completed !== 0;
        questionsData.ImageUrls = response?.data.data.ImageUrls;
        questionsData.SignatureUrls = response?.data.data.SignatureUrls;
        questionsData.Flagged = response?.data.data.Flagged !== 0;
        questionsData.type = response?.data.data.FormType;
        new MonthlyFormModel(questionsData).$save();
        return questionsData;
    } catch (error) {
        throw error;
    }
}

export async function getMonthlyFormAnswers(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IMonthlyFormModelProps = {id: '', questions: [], ImageUrls: response?.data.data.ImageUrls, Completed: false, ActualDate: response?.data.data.ActualDate};
        objectToArray(response?.data.data.Answers).reverse().forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        questionsData.id = formId;
        return questionsData;
    } catch (error) {
        throw error;
    }
}

function marshallQuestionAndAnswers(instance: any, answers: any, index: number, type: any) {
    /* if (!answers) {
         return false;
     }*/
    const newInstance: any = {
        question: instance.Question,
        quesNum: instance.Question_Num,
        type: type,
        id: index,
        quesName: `q${instance.Question_Num}`,
        answer: null
    };
    const skipForBooleanAnswer = ['weather', 'comments:', 'time', 'acknowledged by', 'actual date',
        'if applicable, explain corrective actions taken since the last inspection:',
        'if any incidents of non-compliance were identified during the inspection, discuss corrective actions needed:',
        'actions taken', 'volts', 'amps', 'fine', 'coarse', 'description:', 'preventative actions taken'
    ];

    const questionToCheck = answers.filter((quest: any) => {
        return quest.question === instance.Question;
    });

    if (questionToCheck && questionToCheck.length && questionToCheck[0].question === instance.Question) {
        if (skipForBooleanAnswer.indexOf(instance.Question.toLowerCase()) >= 0) {
            newInstance.answer = questionToCheck[0].answer;
        } else if (instance.Question.toLowerCase() === 'date') {
            newInstance.answer = new Date(questionToCheck[0].answer);
        } else {
            newInstance.answer = questionToCheck[0].answer;
        }
    } else {
        newInstance.answer = null;
    }
    return newInstance;

}

/*export async function getMonthlyFormQuestions(type: string, permitType: string, formId?: string) {
    try {
        const response = await getDev(`/getquestions/${permitType}/${type}`);
        let formInstance: any = null;
        if (formId) {
            formInstance = await getMonthlyFormAnswers(formId);
        }
        if (response && response?.data && response?.data.status === 'valid') {
            if (type === 'MonthlySPCC' && formId) {
                response?.data.data.pop();
                response?.data.data.pop();
            }
            (response?.data.data || []).reverse().forEach((instance: any, index: number) => {
                if (instance.Question.toLowerCase() === 'time') {
                    return;
                }
                if (type === 'MonthlySPCC' && formId) {
                    if (instance.Question.toLowerCase() === 'acknowledged by' || instance.Question.toLowerCase() === 'actual date') {
                        return;
                    }
                }
                if (formId) {
                    const modelInstance = marshallQuestionAndAnswers(instance, formInstance.questions, index, type);
                    new QuestionModel(modelInstance).$save();
                } else {
                    const newInstance = {
                        question: instance.Question,
                        quesNum: instance.Question_Num,
                        type: type,
                        id: index,
                        quesName: `q${instance.Question_Num}`,
                    };
                    new QuestionModel(newInstance).$save();
                }
            });
            return formInstance;
        }
    } catch (error) {
        toast.error('Something went wrong please try again later...!!', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}*/

/*export async function submitMonthlyForm(data, history: IHistory, images: string[], formId: string) {
    try {
        let response = null;
        data.ImageUrls = await uploadImages(images, 'images');
        if (formId) {
            const updateData = {
                Answers: data.Answers,
                UserId: getCurrentUserUUID(),
                InspectionID: data.InspectionID,
                Date: data.Date,
                ImageUrls: data.ImageUrls,
                SignatureUrls: data.SignatureUrls
            };
            response = await putDev(`/updateform/${formId}`, updateData);
        } else {
            response = await postDev('/form', data);
        }
        toast.success(FORM_SUBMISSION_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast',
            autoClose: 1000,
            onClose: () => history.push('/inspections')
        });
        await getAllPlantsByRegion(getCurrentUserUUID(), 'All');
        return response;
    } catch (error) {
        toast.error('Something went wrong please try again later...!!', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast',
            autoClose: 1000
        });
        throw error;
    }
}*/

export async function getMonthlyChecklistData(plantId: string, yyyy = format(new Date(), 'yyyy'), formType: string) {
    try {
        const response = await getDev(`/getmonthlyforms/${plantId}/${yyyy}/${formType}`);
        let flag = true;
        const answersData = marshallChecklistData(response?.data.data);
        answersData.sort((a: any, b: any) => {
            return a.month - b.month;
        });
        new ChecklistModel({id: plantId, answers: answersData, type: formType}).$save();
    } catch (error) {
        throw error;
    }
}
