import * as React from 'react';
import {BaseModel} from './BaseModel';
import {IQuestions} from '../interfaces';

interface ISpillLogs {
    PlantId: string;
    Description: string;
    ActionTaken: string;
    UserId: number;
    DatePlant: string;
    Date: string;
}

interface ISPCC {
    PlantId: string;
    Weather: string;
    UserId: number;
    DatePlant: string;
    Time: string;
    Date: string;
    Questions: IQuestions;
}

interface IPPM {
    PlantId: string;
    UserId: number;
    DatePlant: string;
    Time: string;
    Date: string;
    Questions: IQuestions;
}

interface IUSTSpill {
    PlantId: string;
    FreeDebris: string;
    FreeCrack: string;
    FollowUpForm: string;
    UserId: number;
    DatePlant: string;
    ActionTaken: string;
    Date: string;
    Id: string;
    LindIntact: string;
}

interface IUSTRect {
    PlantId: string;
    Fine: string;
    Amps: string;
    Volts: string;
    RectOn: string;
    UserId: number;
    DatePlant: string;
    Caorse: string;
    Date: string;
}

interface ISW {
    PlantId: string;
    Weather: string;
    Questions: IQuestions;
    UserId: string;
    DatePlant: string;
    Time: string;
    Date: string;
}

export interface IMonthlyFormModelProps {
    id?: string;
    UserId?: string;
    type?: 'SpillLogs' | 'SPCC' | 'SW' | 'IPPM' | 'USTRect' | 'USTSpill' | 'all' | 'MonthlyUSTSpill' | 'UST Spill Container';
    PlantId?: string;
    DatePlant?: string;
    SpillLogs?: ISpillLogs[];
    SPCC?: ISPCC[];
    PPM?: IPPM[];
    USTSpill?: IUSTSpill[];
    USTRect?: IUSTRect[];
    SW?: ISW[];
    questions?: IQuestions[];
    QuestionsData?: IQuestions[];
    UserName?: string;
    InspectionId?: string;
    ActualDate?: string;
    InspectionUId?: string;
    PlantUID?: string;
    Date?: string;
    Region?: string;
    Completed?: boolean;
    ImageUrls?: string[];
    Associations?: string[];
    FlaggedQuestion?: boolean;
    SignatureUrls?: string[];
    Flagged?: boolean;
    CompletedBy?: string;
    DateCompleted?: string;
    FollowUpComments?: string;
}

export class MonthlyFormModel extends BaseModel<IMonthlyFormModelProps> {
    static resource = 'monthly-form';

    constructor(props: IMonthlyFormModelProps) {
        super(props);
    }
}
