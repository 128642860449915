import * as React from 'react';
import {Col} from 'react-bootstrap';

export interface IImageCardContainerProps {
    href: string;
    fext: string;
    src: string;
    index: number;
    activeIndex: number;
    text: string;
    onMouseOver: any;
    onMouseLeave: any;
    addEditClass: boolean;
    onSingleFileDelete: Function;
    selectedIndexes: number[];
    onFileSelect: Function;
    onFileDeselect: Function;
    isDeletable: boolean;
    onopenInNewViewTab: Function;
    // history: any;
}
export interface IImageCardContainerState {

}

export class ImageCardContainer extends React.Component<IImageCardContainerProps, IImageCardContainerState> {
    constructor(props: IImageCardContainerProps) {
        super(props);
        this.state = {};
    }

    
    render() {
        const {
            href, fext, src, index, activeIndex, text, onMouseOver,
            onMouseLeave, addEditClass, onSingleFileDelete, selectedIndexes,
            onFileSelect, onFileDeselect, isDeletable, onopenInNewViewTab
        } = this.props;

        return <div
            className="card-container col-sm-6"
            onMouseOver={onMouseOver as any}
            onTouchStart={onMouseOver}
            onTouchEnd={onMouseLeave}
            onMouseLeave={onMouseLeave as any}
        >
            <Col className="card" sm={11} xs={11} style= {{ backgroundColor: 'black' }} >
                {(addEditClass && index === activeIndex) || selectedIndexes.length > 0 ?
                    <div className="show-edit-options">
                        {selectedIndexes.length <= 0 && fext === 'pdf' ?
                            [
                            <i className={"fa fa-file"} onClick={() => onopenInNewViewTab(href)} aria-hidden="true">
                                <span>View File</span>
                            </i>,
                            <a
                                key={index + 121}
                                onClick={() => onSingleFileDelete(index)}
                                title="Click to delete this file" className="fa fa-trash" aria-hidden="true"/>]
                        : selectedIndexes.length <= 0 && fext !== 'pdf' ?
                            [<a
                                key={index + 11}
                                href={href}
                                target="_blank"
                                title="Click to view this file"
                                className="fa fa-file"
                                aria-hidden="true"
                            >
                                <span>View File</span>
                            </a>,
                            <a
                                key={index + 121}
                                onClick={() => onSingleFileDelete(index)}
                                title="Click to delete this file" className="fa fa-trash" aria-hidden="true"/>]
                        : ''}
                        <br/>
                        {!isDeletable ? selectedIndexes.length > 0 && selectedIndexes.indexOf(index) >= 0 ?
                            <i
                                title="Click to remove this file from selected items."
                                onClick={() => onFileDeselect(index)}
                                className="fa fa-check-square-o"
                                aria-hidden="true"/> :
                            <i
                                title="Click to select Multiple Files"
                                onClick={() => onFileSelect(index)}
                                className="fa fa-square-o"
                                aria-hidden="true"
                            /> : ''

                        }
                    </div> : ''}
                {/* <a href={href} target="_blank"> */}
                        {/* <div className="show-edit-options"> */}

                        <i  onClick={() => onopenInNewViewTab(href)} aria-hidden="true">
                                {/* <span>View File</span> */}
                        {/* </div> */}

                    <div>
                        <img style={{ width: '70%' }} src={src} alt="reference-text"/>
                    </div>
                    <div className="reference-text">
                        {text}
                    </div>
                    </i>

                {/* </a> */}
            </Col>
        </div>;
    }

}
function base64toBlob(base64Pdf: any) {
    throw new Error('Function not implemented.');
}

