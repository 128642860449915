import * as React from 'react';

import {FlaggedFormModel} from '../../../model/FlaggedFormModel';
import './pagination.scss';

export interface IPaginationProps {
    instances?: FlaggedFormModel[];
    currentPage?: number;
    handlePageNumbers?: Function;
}

export class Pagination extends React.PureComponent<IPaginationProps, {}> {
    constructor(props: IPaginationProps) {
        super(props);
        this.state = {};
    }

    render() {
        const {instances, currentPage}: any = this.props;
        let pageNumberArray: Array<any> = [];
        const pageNumbers = instances[0] && instances[0].props.PagesCount;
        for (let i = 1; i <= pageNumbers; i++) {
            pageNumberArray[i - 1] = i;
        }
        return (
            <ul className="pagination">
                {pageNumberArray.map((page, index) => (
                    <li key={index} className={(index + 1) === currentPage ?
                        'active-pagination-item' : 'inactive-pagination-item'}
                        onClick={() => {
                            if (this.props.handlePageNumbers) {
                                this.props.handlePageNumbers(index + 1)
                            }
                        }}>
                        {page}
                    </li>
                ))}
            </ul>
        );
    }
}
