import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory} from '../../interfaces';
import {createNewUser} from '../../services/userService';
import {BasePage} from '../reusableComponents/BasePage';
import {UserForm} from './UserForm';

export interface ICreateFormProps {
    history?: IHistory;
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, {}> {
    constructor(props: ICreateFormProps) {
        super(props);
        this.state = {}
    }

    handleFormSubmit = async (values) => {
        return await createNewUser(values, this.props.history);
    }

    render() {
        return < BasePage key={3} pageTitle="Add User">
            <UserForm
                // @ts-ignore
                id="CreateForm"
                onSubmit={this.handleFormSubmit}
                formModelName="forms.userForm"
            />
        </BasePage>;
    }
}

export function mapStateToProps() {
    return {};
}

export const CreateForm = withRouter(connect< ICreateFormProps, any, any>(mapStateToProps)(CreateFormImpl as any));
