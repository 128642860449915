import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import 'es6-promise/auto';
import {getUserData, removeUserData} from '../services/loginService';
import {toast} from 'react-toastify';
import { APPVERSION } from '../constants/generals';
import { SERVER_URL } from '../constants/url';
import { logout } from '../actions/loginActions';
import { saveAxiosPreviousToken } from '../actions/miscellaneousActions';
 
// baseURL = 'http://127.0.0.1:5000/',
//const baseURL = 'https://stagepy.enviroconnect.net';
//const baseURL = 'https://devpy.enviroconnect.net';
// const baseURL = 'http://ec2-34-214-78-239.us-west-2.compute.amazonaws.com/';

const baseURL = SERVER_URL;
async function getUpgradeStatus(currVersion) {
    let result = APPVERSION && parseInt(APPVERSION) < parseInt(currVersion) ? true : false;
    if(result) {
        if('caches' in window){
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
        }
        window.location.reload()
        return;
    }
    return true;
}

const checkOrgFilter = (url: string) => {
        const currentOrg = sessionStorage.getItem('L1OrgId');
        const loggedInUserData = getUserData();
        if (currentOrg && currentOrg !== 'All' && loggedInUserData && loggedInUserData.Access_Level === 'L1') {
            return url = url + '?orgId=' + currentOrg;
        } else {
            return url;
        }
    };

/* export async function getDev(url: string, data: Object = {}): Promise<AxiosResponse| null | void>{
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });
    if (url !== '/org' || url.indexOf('/region') === -1) {
        url = checkOrgFilter(url);
    }
    const response = await axiosInstance.get(url, {}); 
    if(response.data.message === 'Session time-out') {
        removeUserData();
        logout();
    }
    response.headers.expires && await getUpgradeStatus(response.headers.expires) 
    if (response.data.status === 'valid') {
        return response;
    } else {
        toast.error(JSON.stringify(response.data), {
            position: toast.POSITION.TOP_CENTER,
            className: 'failed-toast'
        });
        // throw new Error(JSON.stringify(response.data));
    }
} */

export async function getDev(url: string, data: Object = {}, axiosPreviousToken?: any): Promise<AxiosResponse | null | void> {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });
    const CancelToken = axios.CancelToken.source(); 

    if (url !== '/org' || url.indexOf('/region') === -1) {
        url = checkOrgFilter(url);
    }

    try {

        if(axiosPreviousToken) {
            axiosPreviousToken.cancel('Operation canceled by the user.');  
        }
        saveAxiosPreviousToken(CancelToken)
        
        const response = await axiosInstance.get(url,{
            cancelToken: CancelToken.token
          }); 
        if (response.data.message === 'Session time-out') {
            removeUserData();
            logout();
        }
        response.headers.expires && await getUpgradeStatus(response.headers.expires);
        if (response.data.status === 'valid') {
            return response;
        } else {
            toast.error(JSON.stringify(response.data), {
                position: toast.POSITION.TOP_CENTER,
                className: 'failed-toast'
            });
            // throw new Error(JSON.stringify(response.data)); 
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // Handle Axios errors (network error, HTTP errors, etc.)
            if (!error.response) {
                // Handle network error (server not reachable, connection refused, etc.)                
                removeUserData();
                logout();
                toast.error('Network error: Unable to reach the backend server.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'failed-toast',
                    autoClose: 7000
                });
                // return null;
            } else {
                // Handle HTTP errors (error.response is available)
                throw error;
            }
        } else {
            // Handle non-Axios errors (unexpected errors)
            throw error;
        }
    }
} 

export async function postDev(url: string, data: Object = {}, axiosPreviousToken?: any): Promise<AxiosResponse| null | void> {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });

    try {
        const CancelToken = axios.CancelToken.source(); 

        if(axiosPreviousToken) {
            axiosPreviousToken.cancel('Operation canceled by the user.');  
        }
        saveAxiosPreviousToken(CancelToken)

        const response = await axiosInstance.post(checkOrgFilter(url), data,{
            cancelToken: CancelToken.token
          });

        if(response.data.message === 'Session time-out') {
            removeUserData();
            logout();
        }
        response.headers.expires && await getUpgradeStatus(response.headers.expires) 
        if (response.data.status === 'valid') {
            return response;
        } else {
            toast.error(JSON.stringify(response.data.message), {
                position: toast.POSITION.TOP_CENTER,
                className: 'failed-toast',
                autoClose: 14000
            });
            return response
            //  throw new Error(JSON.stringify(response.data.message));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                removeUserData();
                logout();
                toast.error('Network error: Unable to reach the backend server.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'failed-toast',
                    autoClose: 7000
                });
                // return null;
            } else {
                throw error;
            }
        } else {
            throw error;
        }
    }
}

export async function putDev(url, data) {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });

    try {
        const response = await axiosInstance.put(checkOrgFilter(url), data);
        if(response.data.message === 'Session time-out') {
            removeUserData();
            logout();
        }
        response.headers.expires && await getUpgradeStatus(response.headers.expires) 
        if (response.data.status === 'valid') {
            return response;
        } else {
            toast.error(JSON.stringify(response.data), {
                position: toast.POSITION.TOP_CENTER,
                className: 'failed-toast'
            });
            // throw new Error(JSON.stringify(response.data));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                removeUserData();
                logout();
                toast.error('Network error: Unable to reach the backend server.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'failed-toast',
                    autoClose: 7000
                });
                // return null;
            } else {
                throw error;
            }
        } else {
            throw error;
        }
    }
}

export async function delDev(url: string) {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });
    try {
        const response = await axiosInstance.delete(checkOrgFilter(url));
        if(response.data.message === 'Session time-out') {
            removeUserData();
            logout();
        }
        response.headers.expires && await getUpgradeStatus(response.headers.expires) 
        if (response.data.status === 'valid') {
            return response;
        } else {
            toast.error(JSON.stringify(response.data), {
                position: toast.POSITION.TOP_CENTER,
                className: 'failed-toast'
            });
            //   throw new Error(JSON.stringify(response.data));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                removeUserData();
                logout();
                toast.error('Network error: Unable to reach the backend server.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'failed-toast',
                    autoClose: 7000
                });
                // return null;
            } else {
                throw error;
            }
        } else {
            throw error;
        }
    }
}

// writing this method separately for reference delete alone
export async function deleteDev(url: string , data) {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });
    try {
        const response = await axiosInstance.delete(checkOrgFilter(url), {data});
        response.headers.expires && await getUpgradeStatus(response.headers.expires) 
        if(response.data.message === 'Session time-out') {
            removeUserData();
            logout();
        }
        if (response.data.status === 'valid') {
            return response;
        } else {
            toast.error(JSON.stringify(response.data), {
                position: toast.POSITION.TOP_CENTER,
                className: 'failed-toast'
            });
            //   throw new Error(JSON.stringify(response.data));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                removeUserData();
                logout();
                toast.error('Network error: Unable to reach the backend server.', {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'failed-toast',
                    autoClose: 7000
                });
                // return null;
            } else {
                throw error;
            }
        } else {
            throw error;
        }
    }
}

export async function updatePasswordDev(url: string) {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Auth: (getUserData() || '').token,
        },
    });
    const response = await axiosInstance.put(checkOrgFilter(url));
    response.headers.expires && await getUpgradeStatus(response.headers.expires) 
    if (response.data.status === 'valid') {
        return response;
    } else {
        toast.error(JSON.stringify(response.data), {
            position: toast.POSITION.TOP_CENTER,
            className: 'failed-toast'
        });
        //    throw new Error(JSON.stringify(response.data));
    }
}
