import {LOGOUT, PUSH_ALERT, REMOVE_ALERT, SAVE_LOGIN_DATA, SET_TIMEOUT_ID} from '../constants/actions';
import {dispatch} from '../utils/generalUtils';

export function saveLoginDetails(loginData) {
    return dispatch({
        type: SAVE_LOGIN_DATA,
        loginData
    });
}

export function logout() {
    return dispatch({
        type: LOGOUT
    });
}

export function pushAlert(text: string, id: string, alertType: string) {
    return dispatch({
        type: PUSH_ALERT,
        text,
        id,
        alertType
    });
}

export function removeAlert(id?: string) {
    return dispatch({
        type: REMOVE_ALERT,
        id
    });
}

export function setTimeOutID(timeOutID: string) {
    return dispatch({
        type: SET_TIMEOUT_ID,
        timeOutID
    });
}
