import {IHistory, IRules} from '../interfaces';
import {showAlert} from '../utils/generalUtils';
import {CREATED_REFERENCE_SUCCESS, SUBMISSION_FAILED} from '../constants/generals';
import {setLoading, setSuccess} from '../actions/loadingActions';
import {saveReferenceFiles} from '../actions/miscellaneousActions';
import {UserModel} from '../model/UserModel';
import {getUserData} from './loginService';
import {getDev, postDev , deleteDev} from '../utils/HTTPDev';
import {toast} from 'react-toastify';
declare var require: any;
const { v1: uuidv1 } = require('uuid');


export async function getGoldenRules(companyName: string = 'spi') {
    try {
        const response = await getDev(`/GoldenRules`);
        let rulesArray: IRules[] = [];
        response?.data?.data.map((rules: IRules) => {
            rulesArray.push(rules);
        });
        return rulesArray;
    } catch (error) {
        throw error;
    }
}

export async function submitGoldenRules(datas: any, history?: IHistory) {
    let hasError: boolean = false;
    let response
    if (hasError) {
        showAlert('Priorities should be in positive number', 'golden-rules-failed', 'danger');
        return {response: null, updatedRules: null};
    }

    const requestBody = {rules: datas};
    try {
        response = await postDev('/GoldenRules', requestBody);
        return response
    } catch (error) {
        showAlert(SUBMISSION_FAILED, 'golden-rules-failed', 'danger');
        throw error;
    }
    finally {
        let updatedRules = await getGoldenRules();
        return {response, updatedRules};
    }
}

export async function saveReferences(refTitle: string, URL: string, file: any, bannerURL: string | any[], refFilename: string, history?: IHistory) {
    let data = {
        company: '',
        referenceTitle: '',
        referenceLink: '',
        referenceFileData: '',
        bannerImage: '',
        referenceFileName:''
    };
    try {
        data = {
            company: getUserData().orgId,
            referenceTitle: refTitle,
            referenceLink: URL,
            referenceFileData: !URL ? file.file: null,
            bannerImage: (bannerURL && bannerURL[0].image) || '',
            referenceFileName: refFilename
        };
        const response = await postDev('/References', data);
        if (response?.data.StatusCode === '200') {
            showAlert('File/URL Uploaded Successfully..!!', 'reference-upload-success', 'success', () => {
                history?.push(`/references`);
            });
        }
        toast.success(CREATED_REFERENCE_SUCCESS, {
            position: toast.POSITION.TOP_CENTER,
            className: 'success-toast',
            autoClose: 1000,
            onClose: () => history?.push(`/references`)
        });

    } catch (error) {
        throw error;
    }
}

export async function getAllReferences(companyName?: string) {
    try {
        const response = await getDev(`/References`);
        const id1 = uuidv1();
        const id2 = uuidv1();
        // const ref1 = {
        //     referenceId: id1,
        //     isDeletable: true,
        //     referenceTitle: 'EnviroConnect Description',
        //     referenceLink: 'https://www.youtube.com/watch?reload=9&v=PQOPLkkL4OA&t=9s'
        // };
        // const ref2 = {
        //     referenceId: id2,
        //     isDeletable: true,
        //     referenceTitle: 'EnviroConnect User Instructions',
        //     referenceLink: 'https://www.youtube.com/watch?v=0krjcRCmhwg&t=4s'
        // };
        const data = response?.data.data || [];
        // (data || []).push(ref1);
        // (data || []).push(ref2);
        saveReferenceFiles(data || []);
        
        return response?.data;
    } catch (error) {
        throw error;
    }
}



export async function deleteReferences(referenceIDs: any[]) {
    if (!(referenceIDs instanceof Array)) {
        referenceIDs = [referenceIDs];
    }
    setLoading('ReferencePage');
    try {
        const company = UserModel.getCompanyName();
        const response = await deleteDev(`/References`, {referenceIds: referenceIDs, company: company});
        showAlert('File Deleted Successfully..!!', 'reference-upload-success', 'success', () => {
        });
        await getAllReferences();
        setSuccess('ReferencePage');
        return response?.data;
    } catch (error) {
        throw error;
    }
}
