import * as React from 'react';
import * as _ from 'lodash';
import {format} from 'date-fns';
import {InputRow} from './InputRow';
import {RDatePicker} from '../DatePicker';

import './AdvancedQuestionsRenderer.scss';

export interface IAdvancedMaintenaceQuestRendererProps {
    quest: any;
    localQuestNo: number | null;
    onDataUpdate?: Function;
    moduleIndex: number;
    handlemoduleDelete?: Function;
    quesCount: number;
}

export interface IAdvancedMaintenaceQuestRendererState {
    dateFieldValue: string | null;
    quest: any;
    moduleQCnt : number;
}

export class AdvancedMaintenaceQuestRenderer extends React.PureComponent<IAdvancedMaintenaceQuestRendererProps, IAdvancedMaintenaceQuestRendererState> {

    constructor(props: IAdvancedMaintenaceQuestRendererProps) {
        super(props);
        // this.state = {dateFieldValue: null, quest: []};
        this.state = {dateFieldValue: null, quest: props.quest, moduleQCnt:0};
    }

    handleOptionSelection = (quest: any, data: any, fieldType: string, isSubQuest: boolean) => {
        quest.answer = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
        let dateFieldValue = JSON.parse(JSON.stringify(this.state.dateFieldValue));
        let questionsList = JSON.parse(JSON.stringify(this.state.quest));
        if (!isSubQuest && questionsList.subQuestions && questionsList.subQuestions.length && questionsList.answer !== questionsList.answertoSQ) {
            dateFieldValue = null;
            questionsList.subQuestions.forEach((sq: any) => {
                sq.answer = null;
            });
        }
        if (isSubQuest) {
            const indexOfQuest = _.findIndex(this.state.quest.subQuestions, {questionId: quest.questionId});
            if (indexOfQuest > -1) {
                questionsList.subQuestions[indexOfQuest] = quest;
            }
        } else if (fieldType !== 'ObjectiveType') {
            questionsList = quest;
        }

        this.setState({
            dateFieldValue: fieldType === 'Date' ? data : null,
            quest: questionsList
        });

        if (this.props.onDataUpdate) {
            this.props.onDataUpdate(questionsList, isSubQuest);
        }
    }

    deletemodule = (index) =>{
        if (this.props.handlemoduleDelete) {
            this.props.handlemoduleDelete(index);
        }
    }

    renderTitles = (quest: any) => {
        return (<div className='mainTitleDiv'> 
            <div className="moduleIndex-ques">
                        <div className="moduleIndex-no side-by-side">{this.props.moduleIndex}</div>
                        {/* {quest.question} */}
                        </div>
            <div key={quest.title} className="field-heading heading-inline">{quest.question}</div>
            {this.props.moduleIndex !== 1 ? <div className="moduleIndex-ques"><i className="fa fa-trash-o moduleDelIcon" aria-hidden="true" onClick={() => this.deletemodule(this.props.moduleIndex-1)} /></div>:''}
            </div>

 
        );
    }

    renderSecondaryTitles = (quest: any) => {
        return (
            <div key={quest.title} className="secondary-heading">{quest.question}</div>
        );
    }

    renderHintTitles = (quest: any) => {
        return (
            // <div key={quest.title} className="secondary-heading">{quest.question}</div>
            <div key={quest.title} className="hint-heading" dangerouslySetInnerHTML={{ __html: quest.question }} />
        );
    }

    renderParagraph = (quest: any) => {
        let ques = quest.question.split('~')
        return (<div className={'maintenaceRecord'}>
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p dangerouslySetInnerHTML={{__html: item}}
                            />
                        })
                    }
                </div> 
            </div>
            </div>
        )
    }

    renderObjectiveTypeQuestions = (quest: any, localQuestNo: number | string, isSubQuest: boolean) => {
        return (
            <React.Fragment>
                <div className={'maintenaceRecord'}>
                <div className="question-row">
                    <div className="question">
                        {/* <div className="question-no">{localQuestNo}</div> */}
                        {quest.question}
                    </div>
                    <div className={`buttons`}>
                        {quest.choices && quest.choices.length > 0 && quest.choices.map((btn: string, index: number) => (
                            <button
                                key={index}
                                onClick={() =>
                                    this.handleOptionSelection(quest, btn, 'ObjectiveType', isSubQuest)}
                                type="button"
                                className={(quest.answer === btn) ? 'button-yes'
                                    : 'button-no'}
                            >{btn}</button>
                        ))}
                    </div>
                </div>
                
                {/* {quest.subQuestions && quest.subQuestions.length > 0 && quest.answer === quest.answertoSQ && quest.subQuestions.map((subQuest: any, index: number) => {
                        return (<div key={localQuestNo + '.' + (index + 1)} className={'levelSubQuestIndent question-row'}>{this.generateQuestion(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                })} */}
                {quest.subQuestions && quest.subQuestions.length > 0 && quest.subQuestions.map((subQuest: any, index: number) => {
                        if(subQuest.answerSelected===quest.answer) {
                            return (<div key={localQuestNo + '.' + (index + 1)} className={'levelSubQuestIndent question-row'}>{this.generateQuestion(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                        }
                })}
                </div>
            </React.Fragment>
        );
    }

    renderComments = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        return (<div className={this.props.quesCount===4 ? 'maintenaceRecord':'maintenaceRecords'}>
            <div className={'question'}>
                <InputRow
                    label={quest.question}
                    quesNo={''}
                    type={quest.questionType==='Text'?'text':"textarea"}
                    placeholder=""
                    onChange={(e) => this.handleOptionSelection(quest, e.target.value, 'Comments', isSubQuest)}
                    defaultValue={quest.answer}
                    key={quest.questionId+quest.moduleIndex}
                />
            </div>
        </div>);
    } 

    renderTime = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        return ( 
            <div className="question"> 
                <InputRow
                    label={quest.question}
                    quesNo={questNo}
                    type="time" 
                    onChange={(e) => this.handleOptionSelection(quest, e.target.value, 'Comments', isSubQuest)}
                    defaultValue={quest.answer}
                    key={quest.questionId}
                />
            </div> 
        );
    }

    renderDate = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        return (
            <div className={'maintenaceRecord'}>
            <div>
                <div className="question-row">
                    <div className="question">
                        {/* {questNo ? <div className="question-no">{questNo}</div> : ''} */}
                        <div className="show-grid input-container row">
                            <div className="input-label col"><label key="">
                                {quest.question}</label>
                            </div>
                            <div className="input-label col">
                                <RDatePicker
                                    value={quest.answer ? new Date(quest.answer || new Date()): ''}
                                    maxDate={new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000))}
                                    onChange={(e: any) => {
                                        this.handleOptionSelection(quest, e, 'Date', isSubQuest);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }

    generateQuestion = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        if (quest.moduleIndex === this.props.moduleIndex-1) {
            switch (quest.questionType) {
                case 'Title': {
                    return this.renderTitles(quest);
                }
                case 'Subtitle': {
                    return this.renderSecondaryTitles(quest);
                }
                case 'Hint': {
                    return this.renderHintTitles(quest);
                }
                case 'Paragraph': {
                    return this.renderParagraph(quest);
                }
                case 'ObjectiveType': {
                    return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest);
                }
                case 'Comments':  
                case 'Text': 
                    return this.renderComments(quest, questNo, isSubQuest); 
                case 'Date': {
                    return this.renderDate(quest, questNo, isSubQuest);
                }
                case 'Time': {
                    return this.renderTime(quest, questNo, isSubQuest);
                }
                default: {
                    return <span/>;
                }
            }
        }
        else{
            return <span/>;
        }
    }

    render() {
        const {localQuestNo}: any = this.props;
        return this.generateQuestion(this.props.quest, localQuestNo, false);
    }
}
