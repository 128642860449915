import {format} from 'date-fns';
import {toast} from 'react-toastify';
import { inspectionType, SUBMISSION_FAILED} from '../constants/generals'; 
import {
    saveDailyFormData,
    saveFlaggedDailyFormsData,
    saveFlaggedFormDetails,
    saveFlaggedMonthlyFormsData,
    saveFlaggedQuarterlyFormsData,
    savePlantDetails,
    saveLineChartData,
    saveMonthlyFormData,
    saveQuarterlyFormData
} from '../actions/dashboardAction';
import {FlaggedFormModel, IFlaggedFormModelProps} from '../model/FlaggedFormModel';
import {store} from '../store';
import {getCurrentUserUUID, marshallFormDetails, objectToArray, splitDate} from '../utils/generalUtils';
import {getDev, postDev} from '../utils/HTTPDev';
import {setSuccess} from '../actions/loadingActions';
import moment from 'moment';

export async function getAllDashboardInspections(compId: string, facilityType: string, instance: InstanceType<any>) {
    try {
        const {regionToFilter, selInspType} = instance.state;
        let selInspTypes: string = '';
        if(selInspType === "Env'l Inspection" ) {
            selInspTypes = "Inspections";
        }else {
            selInspTypes = selInspType
        }
        const response = await getDev('/dashboardinspectionstype/' + compId + '/' + facilityType + '/' + regionToFilter + '/' + selInspTypes);
        setSuccess('inspectionsFormsList');
        return response;
    } catch (error) {
        setSuccess('PlantListPage');
        throw error;
    }
}

export async function getCompletedInspectionsData(orgId: string, instance: InstanceType<any>) {
    try {
        let response: any;
        let date: string = '';

        const {linearGraphDate, activeFreqTabIndex, regionToFilter, selInspType} = instance.state;
        let selInspTypes: string = '';
        if(selInspType === "Env'l Inspection" ) {
            selInspTypes = "Inspections";
        }else {
            selInspTypes = selInspType
        }
        switch(activeFreqTabIndex) {
            case 0:
                date = format(new Date(linearGraphDate), 'MMyyyy');
                response = await getDev('/dailyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspTypes);
                break;
            case 1:
                date = format(new Date(linearGraphDate), 'MMyyyy');
                response = await getDev('/weeklyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspTypes);
                break;
            case 2:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/monthlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspTypes);
                break;
            case 3:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/quarterlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspTypes);
                break;
            case 4:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/yearlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspTypes);
                break;
        }
        /* if (activeFreqTabIndex === 0) {
            date = format(new Date(linearGraphDate), 'MMyyyy');
            response = await getDev('/dailyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } else if (activeFreqTabIndex === 1) {
            date = format(new Date(linearGraphDate), 'yyyy');
            response = await getDev('/monthlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } else {
            date = format(new Date(linearGraphDate), 'yyyy');
            response = await getDev('/quarterlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } */
        return response?.data;
    } catch (e) {
        throw e;
    }
}


export async function getCompletedLineData(orgId, linearGraphDate, activeFreqTabIndex, regionToFilter, selInspType) {
    try {
        let response: any;
        let date: string = '';
        // const {linearGraphDate, activeFreqTabIndex, regionToFilter} = instance.state;
        switch(activeFreqTabIndex) {
            case 0:
                date = format(new Date(linearGraphDate), 'MMyyyy');
                response = await getDev('/dailyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspType);
                break;
            case 1:
                date = format(new Date(linearGraphDate), 'MMyyyy');
                response = await getDev('/weeklyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspType);
                break;
            case 2:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/monthlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspType);
                break;
            case 3:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/quarterlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspType);
                break;
            case 4:
                date = format(new Date(linearGraphDate), 'yyyy');
                response = await getDev('/yearlyformscummulative/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID() + '/' + selInspType);
                break;
        }
        return response?.data;
    } catch (e) {
        throw e;
    }
}

export async function getFormSummaryData(orgId: string, type: string, instance: InstanceType<any>) {
    try {
        let response: any;
        const {dailySummaryGraphDate, weeklySummaryGraphDate, monthlySummaryGraphDate, quarterlySummaryGraphDate, yearlySummaryGraphDate, regionToFilter,selInspType} = instance.state;
        let date: string = '';
        let selInspTypes: string = '';
        if(selInspType === "Env'l Inspection" ) {
            selInspTypes = "Inspections";
        }else {
            selInspTypes = selInspType
        }
        switch(type) {
            case 'Daily':
                date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
                response = await getDev('/getsubmitteddailyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None' + '/' + selInspTypes);
                break;
            case 'Weekly':
                date = format(new Date(weeklySummaryGraphDate), 'MMddyyyy');
                response = await getDev('/getsubmittedweeklyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None' + '/' + selInspTypes);
                break;
            case 'Monthly':
                date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
                response = await getDev('/getsubmittedmonthlyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None' + '/' + selInspTypes);
                break;
            case 'Quarterly':
                //const qtr = quarterlySummaryGraphDate.qtr === '1st' ? '01' : quarterlySummaryGraphDate.qtr === '2nd' ? '02' : quarterlySummaryGraphDate.qtr === '3rd' ? '03' : '04';
                const qtr = `0${quarterlySummaryGraphDate.qtr}`;
                date = quarterlySummaryGraphDate.year + '/' + qtr;
                response = await getDev('/getsubmittedquarterlyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None' + '/' + selInspTypes);
                break;
            case 'Yearly': 
                // date = format(new Date(yearlySummaryGraphDate), 'yyyy');
                date = moment(yearlySummaryGraphDate).format('YYYY');
                response = await getDev('/getsubmittedyearlyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None' + '/' + selInspTypes);
                break;
        }
        /* if (type === 'Daily') {
            date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
            response = await getDev('/getsubmitteddailyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None');
        } else if (type === 'Monthly') {
            date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
            response = await getDev('/getsubmittedmonthlyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None');
        } else {
            const qtr = quarterlySummaryGraphDate.qtr === '1st' ? '01' : quarterlySummaryGraphDate.qtr === '2nd' ? '02' : quarterlySummaryGraphDate.qtr === '3rd' ? '03' : '04';
            date = quarterlySummaryGraphDate.year + '/' + qtr;
            response = await getDev('/getsubmittedquarterlyformscount/' + date + '/' + orgId + '/' + regionToFilter + '/' + getCurrentUserUUID()+'/None/None');
        } */
        return response?.data;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        throw error;
    }
}

export async function getFlaggedFormsList(type: string, orgId: string, instance: InstanceType<any>) {
    try {
        let response: any = null;
        let date: string = '';
        const {dailySummaryGraphDate, weeklySummaryGraphDate, monthlySummaryGraphDate, quarterlySummaryGraphDate, yearlySummaryGraphDate, regionToFilter} = instance.state;
        switch(type) {
            case 'Daily':
                date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
                response = await getDev('getdailyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
                break;
            case 'Weekly':
                date = format(new Date(weeklySummaryGraphDate), 'MMddyyyy');
                response = await getDev('getweeklyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
                break;
            case 'Monthly':
                date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
                response = await getDev('getmonthlyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
                break;
            case 'Quarterly':
                const qtr = quarterlySummaryGraphDate.qtr === '1st' ? '01' : quarterlySummaryGraphDate.qtr === '2nd' ? '02' : quarterlySummaryGraphDate.qtr === '3rd' ? '03' : '04';
                response = await getDev('getquarterlyflaggedforms/' + quarterlySummaryGraphDate.year + '/' + qtr + '/' + regionToFilter + '/' + getCurrentUserUUID());
                break;
            case 'Yearly':
                date = format(new Date(yearlySummaryGraphDate), 'yyyy');
                response = await getDev('getyearlyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
                break;
        }
        /* if (type === 'Daily') {
            date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
            response = await getDev('getdailyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } else if (type === 'Monthly') {
            date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
            response = await getDev('getmonthlyflaggedforms/' + date + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } else {
            const qtr = quarterlySummaryGraphDate.qtr === '1st' ? '01' : quarterlySummaryGraphDate.qtr === '2nd' ? '02' : quarterlySummaryGraphDate.qtr === '3rd' ? '03' : '04';
            response = await getDev('getquarterlyflaggedforms/' + quarterlySummaryGraphDate.year + '/' + qtr + '/' + regionToFilter + '/' + getCurrentUserUUID());
        } */
        return response?.data.data;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        throw error;
    }
}

export async function getCompletedOrIncompletedInspectionsList(type: string, freq: string, parentId: string, instance: InstanceType<any>) {
    try {
        let response: any = null;
        let date: string = '';
        const {dailySummaryGraphDate, weeklySummaryGraphDate, monthlySummaryGraphDate, quarterlySummaryGraphDate, yearlySummaryGraphDate} = instance.state;
        const term = type === 'complete' ? 'getcompletedformsdetails' : 'getincompletedformsdetails';
        switch(freq) {
            case 'Daily':
                date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
                response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
                break;
            case 'Weekly':
                date = format(new Date(weeklySummaryGraphDate), 'MMddyyyy');
                response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
                break;
            case 'Monthly':
                date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
                response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
                break;
            case 'Quarterly':
                const qtr = quarterlySummaryGraphDate.qtr === '1' ? '01' : quarterlySummaryGraphDate.qtr === '2' ? '02' : quarterlySummaryGraphDate.qtr === '3' ? '03' : '04';
                response = await getDev(term + '/' + quarterlySummaryGraphDate.year + '/' + qtr + '/' + parentId + '/' + freq);
                break;
            case 'Yearly': 
                // date = format(new Date(yearlySummaryGraphDate), 'yyyy');
                date = moment(yearlySummaryGraphDate).format('YYYY');
                response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
                break; 
        }
        /* if (freq === 'Daily') {
            date = format(new Date(dailySummaryGraphDate), 'MMddyyyy');
            response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
        } else if (freq === 'Monthly') {
            date = format(new Date(monthlySummaryGraphDate), 'MMyyyy');
            response = await getDev(term + '/' + date + '/' + parentId + '/' + freq);
        } else {
            const qtr = quarterlySummaryGraphDate.qtr === '1st' ? '01' : quarterlySummaryGraphDate.qtr === '2nd' ? '02' : quarterlySummaryGraphDate.qtr === '3rd' ? '03' : '04';
            response = await getDev(term + '/' + quarterlySummaryGraphDate.year + '/' + qtr + '/' + parentId + '/' + freq);
        } */
        return response?.data.data.formList;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        throw error;
    }
}

export async function getFlaggedFormQuestions(inspectionInfo: any, instance: InstanceType<any>) {
    try { 
        let response = await getDev('getflaggedanswerdetails/' + inspectionInfo.FormId + '/' + inspectionInfo.UniqueId+ '/' + inspectionInfo.Date);
        return response?.data.data;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        instance.setState({
            isLoading: false
        });
        throw error;
    }
}

/****************************************************************************************************************************************************/

export function marshalDailyForms(dailyForms: any, mm: string, form: string) {
    if (mm.substring(0, 1) === '0') {
        mm = mm.substring(1, 2);
    }
    const data: any = {
        dailyFormsData: [],
        complete: 0,
        inComplete: 0,
        InCompletePlantIds: 0,
        CompletePlantIds: 0
    };
    for (let key in dailyForms[form][mm]) {
        data.dailyFormsData.push({
            [key]: dailyForms[form][mm][key]
        });
    }
    data.complete = dailyForms[form]['Complete'];
    data.inComplete = dailyForms[form]['InComplete'];
    data.InCompletePlantIds = dailyForms[form]['InCompletePlantIds'];
    data.CompletePlantIds = dailyForms[form]['CompletePlantIds'];
    return data;
}

export async function getPlantDetails(plantId: string) {
    try {
        const response = await getDev(`plant/${plantId}`);
        savePlantDetails(response?.data.data);
    } catch (error) {
        throw error;
    }
}

function getFilteredDate(formType: string) {
    if (formType.toLowerCase().indexOf('daily') > -1 && store.getState().dashboard.get('dailyFilter')) {
        const {date} = store.getState().dashboard.get('dailyFilter');
        return format(new Date(date), 'MM-dd-yyyy');
    } else if (formType.toLowerCase().indexOf('monthly') > -1 && store.getState().dashboard.get('monthlyFilter')) {
        const {date} = store.getState().dashboard.get('monthlyFilter');
        return format(new Date(date), 'MM-dd-yyyy');
    } else if ((formType === 'QTRVisual' || formType === 'ShopInspection')
        && store.getState().dashboard.get('quarterlyFilter')) {
        const {date} = store.getState().dashboard.get('quarterlyFilter');
        return format(date, 'MM-dd-yyyy');
    }
    return format(new Date(), 'MM-dd-yyyy');
}

export async function sendReminder(plantId: string, formType: string, date: any, instance: InstanceType<any>) {
    try {
        await postDev(`/remindemail`, {
            plantId,
            formType,
            date
        });
        toast.success('Email Sent Successfully.', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast'
        });
        if (instance) {
            instance.setState({
                showProgressBar: false,
                showDetails: false
            });
        }
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

export async function getFlaggedForms(type: string, mm: string, dd: string, yyyy: string,
                                      region: string, instance?: InstanceType<any>) {
    try {
        const response = await getDev(`/getflaggedforms/${mm}${dd}${yyyy}/${region || 'All'}/${getCurrentUserUUID()}`);
        const {data: {data: respData}}: any = response;
        if (type === 'dailyForm') {
            const {dailyForms} = marshallFlaggedFormInstance(respData);
            saveFlaggedDailyFormsData(dailyForms, (`${respData.DailyCount}` || `${respData.totalCount}`));
        } else if (type === 'monthlyForm') {
            const {monthlyForms} = marshallFlaggedFormInstance(respData);
            saveFlaggedMonthlyFormsData(monthlyForms, (`${respData.MonthlyCount}` || `${respData.totalCount}`));
        } else if (type === 'ShopInspection') {
            const {quarterlyForms} = marshallFlaggedFormInstance(respData);
            saveFlaggedQuarterlyFormsData(quarterlyForms, (`${respData.QuarterlyCount}` || `${respData.totalCount}`));
        } else {
            const {dailyForms, monthlyForms, quarterlyForms} = marshallFlaggedFormInstance(respData);
            saveFlaggedMonthlyFormsData(monthlyForms, `${respData.MonthlyCount}`);
            saveFlaggedDailyFormsData(dailyForms, `${respData.DailyCount}`);
            saveFlaggedQuarterlyFormsData(quarterlyForms, `${respData.QuarterlyCount}`);
        }
        return response;
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        if(instance){
            instance.setState({
                isLoading: false
            });
        }
        throw error;
    }
}

function marshallFlaggedFormInstance(instance: InstanceType<any>) {
    const newInstance: any = {
        dailyForms: [],
        monthlyForms: [],
        quarterlyForms: [],
    };
    for (const key in instance) {
        if (key === 'DailyForm') {
            instance[key].forEach((dailyFormData: any) => {
                if (dailyFormData.PlantUId) {
                    newInstance.dailyForms.push({
                        UserName: dailyFormData.UserName,
                        Region: dailyFormData.Region,
                        PlantUId: dailyFormData.PlantUId,
                        UserId: dailyFormData.UserId,
                        FormId: dailyFormData.FormId,
                        State: dailyFormData.State,
                        UniqueId: dailyFormData.UniqueId,
                        PlantId: dailyFormData.PlantId,
                        Date: dailyFormData.Date,
                        Type: key,
                        Completed: dailyFormData.Completed,
                    });
                }
            });
        } else if (key === 'Quarterly') {
            (instance[key] instanceof Array ? instance[key] : []).forEach((quarterlyFormData: any) => {
                if (quarterlyFormData.PlantUId) {
                    newInstance.quarterlyForms.push({
                        UserName: quarterlyFormData.UserName,
                        Type: key,
                        Region: quarterlyFormData.Region,
                        PlantUId: quarterlyFormData.PlantUId,
                        UserId: quarterlyFormData.UserId,
                        FormId: quarterlyFormData.FormId,
                        State: quarterlyFormData.State,
                        UniqueId: quarterlyFormData.UniqueId,
                        PlantId: quarterlyFormData.PlantId,
                        Date: quarterlyFormData.Date,
                        Completed: quarterlyFormData.Completed,
                    });
                }
            });
        } else {
            (instance[key] instanceof Array ? instance[key] : []).forEach((monthlyFormData: any) => {
                if (monthlyFormData.PlantUId) {
                    newInstance.monthlyForms.push({
                        UserName: monthlyFormData.UserName,
                        Type: key,
                        Region: monthlyFormData.Region,
                        PlantUId: monthlyFormData.PlantUId,
                        UserId: monthlyFormData.UserId,
                        FormId: monthlyFormData.FormId,
                        State: monthlyFormData.State,
                        UniqueId: monthlyFormData.UniqueId,
                        PlantId: monthlyFormData.PlantId,
                        Date: monthlyFormData.Date,
                        Completed: monthlyFormData.Completed,
                    });
                }
            });
        }
    }
    return {
        dailyForms: newInstance.dailyForms,
        monthlyForms: newInstance.monthlyForms,
        quarterlyForms: newInstance.quarterlyForms
    };
}

/*export async function getFormDetails(UniqueId: string, type: string) {
    try {
        const response = await getDev(`flaggedanswers/${UniqueId}`);
        const questionsData: IFlaggedFormModelProps = {questions: []};
        questionsData.questions.push({
            quesvalue: '',
            answer: response?.data.data.PlantId,
            question: 'Plant Id',
            flaggedQuestion: false
        });
        questionsData.questions.push({
            quesvalue: '',
            answer: response?.data.data.ActualDate && format(response?.data.data.ActualDate, 'MM/dd/yyyy'),
            question: 'Submission Date',
            flaggedQuestion: false
        });
        questionsData.questions.push({
            quesvalue: '',
            answer: response?.data.data.UserName,
            question: 'Submitted By',
            flaggedQuestion: false
        });
        questionsData.questions.push({
            quesvalue: '',
            answer: response?.data.data.FormType,
            question: 'Form Type',
            flaggedQuestion: false
        });

        for (const key in response?.data.data.Answers) {
            const instance = marshallFormDetails(response?.data.data.Answers[key], Number(key) - 1);
            questionsData.questions.push(instance);
        }
        saveFlaggedFormDetails(questionsData.questions);
        return response;
    } catch (error) {
        throw error;
    }
}*/

export async function getAllCompletedFlaggedForms(userId: string, formType: string,
                                                  searchFor: string, category?: string, pageNumber?: number) {
    const previousInstances = FlaggedFormModel.list();
    previousInstances.forEach((element: any) => {
        new FlaggedFormModel(element.props).$delete();
    });
    const url = searchFor === 'complete' ? 'flaggedcompleted' : 'flaggedincomplete';
    try {
        const response = await getDev(`${url}/${userId}/${formType}/${pageNumber}`);
        const newInstance: any = [];

        for (const key in (response?.data.data && response?.data.data.Forms)) {
            (response?.data.data.Forms || []).forEach((flaggedFormData: any) => {
                if (flaggedFormData.FormId) {
                    newInstance.push({
                        UserId: flaggedFormData.UserId,
                        Date: flaggedFormData.ActualDate === 'None' ? 'NA' :
                            format(new Date(flaggedFormData.ActualDate), 'MM/dd/yyyy'),
                        DateCompleted: flaggedFormData.DateCompleted === 'None' ? 'NA' :
                            format(new Date(flaggedFormData.DateCompleted), 'MM/dd/yyyy'),
                        DatePlant: flaggedFormData.DatePlant,
                        id: flaggedFormData.FormId,
                        type: flaggedFormData.FormType,
                        key: 'ListPageData',
                        CompletedBy: flaggedFormData.CompletedBy,
                        Region: flaggedFormData.Region,
                        FollowUpComments: flaggedFormData.FollowUpComments,
                        PlantId: flaggedFormData.PlantId,
                        category,
                        PagesCount: response?.data.data.PagesCount
                    });
                }
            });
        }
        newInstance.forEach((instance: any) => {
            new FlaggedFormModel(instance).$save();
        });
    } catch (error) {
        throw error;
    }
}

export async function getFormDetailsByType(formId: string) {
    try {
        const response = await getDev(`/getanswers/${formId}`);
        const questionsData: IFlaggedFormModelProps = {questions: []};
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.UserName,
            question: 'Submitted By',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: format(new Date(response?.data.data.ActualDate), 'MM/dd/yyyy'),
            question: 'Submission Date',
            flaggedQuestion: false
        });
        questionsData?.questions?.push({
            quesNum: questionsData.questions.length + 1,
            quesvalue: '',
            answer: response?.data.data.PlantId,
            question: 'Plant Id',
            flaggedQuestion: false
        });
        objectToArray(response?.data.data.Answers).forEach((formDetails, index) => {
            const instance = marshallFormDetails(formDetails, index, formId);
            questionsData?.questions?.push(instance);
        });
        questionsData.id = formId;
        questionsData.ImageUrls = response?.data.data.ImageUrls;
        questionsData.SignatureUrls = response?.data.data.SignatureUrls;
        questionsData.Completed = response?.data.data.Completed !== 0;
        questionsData.Flagged = response?.data.data.Flagged !== 0;
        questionsData.type = response?.data.data.FormType;
        new FlaggedFormModel(questionsData).$save();
    } catch (error) {
        throw error;
    }
}
