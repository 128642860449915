import {format} from 'date-fns';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {IDailyForms, IFilters, IHistory} from '../../../interfaces';
import {getDailyFormData} from '../../../services/dailyFormService';
import {formatDate, splitDate, getCurentDate} from '../../../utils/generalUtils';
import {BasePage} from '../../reusableComponents/BasePage';
import {RDatePicker} from '../../reusableComponents/DatePicker';
import {ListPage} from '../../reusableComponents/ListPage';
import {RegionFilter} from '../../reusableComponents/RegionFilter';
import {ListItem} from '../InspectionReportDetailer/ListItem';
import { RegionModel } from '../../../model/OrgModel';
import { getUserData } from '../../../services/loginService';
import { getRegionsByOrg } from '../../../services/orgService';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { Pagination } from '../../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IDailyReportsProps {
    dailyForms?: IDailyForms; 
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
    regionList: [];
}

export interface IDailyReportsState {
    date: Date;
    data: Array<any>;
    regionName: string;
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class DailyReportsImpl extends React.Component<IDailyReportsProps, IDailyReportsState> {
    loggedInUserInfo = getUserData();
    constructor(props: IDailyReportsProps) {
        super(props);
        //let date = new Date();
        let date = moment();
        let region = 'All';
        let search = ''
        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
            search = queryString.parse(this.props.location.search).search;
        }  
        //this.state = {data: [], date: new Date(date.toString().replace(/-/g, '/')), regionName: region};
        this.state = {
            data: [],
            date: getCurentDate(date, 'MM/DD/yyyy', true), 
            regionName: region,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            searchText:search,
            totCount:0
        };
    }

    promise = async () => { 
        //let date = format(new Date(), 'yyyy-MM-dd');
        let date = getCurentDate(moment(), 'yyyy-MM-DD');
        let region = 'All';
        let search = ''

        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
            search = queryString.parse(this.props.location.search).search;
        } 
        const {mm, dd, yyyy} = splitDate(date); 
        
        await getDailyFormData(mm, dd, yyyy, region, false,'submittedDate',1,search,null).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                regionName: region,
                totCount: res?.data.TotalCount
            });
        }); 
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
    }

    handleDateFilter = async (e: any) => {
        await this.setState({
            date: e,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            // searchText:''
        });
        const {mm, dd, yyyy} = splitDate(format(new Date(e), 'yyyy-MM-dd'));
        setLoading('DailyReports');
        const queryString = `?date=${format(e, 'yyyy-MM-dd')}&&region=${this.state.regionName}&&search=${this.state.searchText}`;
        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/daily${queryString}`);
        setSuccess('DailyReports');
    }

    handleRegionFilter = async (filterName: string, regionName: string) => {
        this.setState({
            regionName,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            // searchText:''
        });
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        setLoading('DailyReports');
        const queryString = `?date=${format(this.state.date, 'yyyy-MM-dd')}&&region=${regionName}&&search=${this.state.searchText}`;
        await getDailyFormData(mm, dd, yyyy, regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/daily${queryString}`);
        setSuccess('DailyReports');
    }
    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            curPageNo: searchText.length > 0 && this.state.searchText == searchText ? this.state.curPageNo : 1,
            sortIndex: index,
            searchText: searchText,
        })
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        setLoading('DailyReports');
        const queryString = `?date=${format(this.state.date, 'yyyy-MM-dd')}&&region=${this.state.regionName}&&search=${this.state.searchText}`;
        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/daily${queryString}`);
        setSuccess('DailyReports');

        // await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken);
    }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        setLoading('DailyReports');
        // const queryString = `?date=${format(this.state.date, 'yyyy-MM-dd')}&&region=${this.state.regionName}`;
        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content
            });
        });
        setSuccess('DailyReports');

    }

    render() {
        let pageno = Math.ceil(this.state.totCount/20 );

        return (
            <BasePage className="list-page-base daily-reports" pageTitle="Daily Reports">
                <ListPage
                    // @ts-ignore
                    identifier="DailyReports"
                    instances={this.state.data}
                    frequency={'Daily'}
                    listItemComponent={ListItem}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'permitType', 'inspectionName' ]}
                    instanceOf={'plantInfo'}
                    promise={this.promise}
                    pageHeadings={['#', 'PlantId', 'Region', 'Facility', 'Permit', 'Inspection', 'Submitted', 'Last Updated']}
                    listItemFields={['', 'plantId', 'plantRegionName', 'facilityType', 'permitType', 'inspectionName', 'submittedDate', 'lastUpdatedDate']}
                    cols={[1, 1, 2, 1, 1, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Daily Reports found"
                    listItemProp={format(this.state.date, 'MM/dd/yyyy')}
                    onHandleSort={this.handleSort}
                    chartFilter={<div className="filter-container row">
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <RDatePicker
                                value={this.state.date}
                                onChange={this.handleDateFilter}
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <RegionFilter
                                selectedValue={this.state.regionName}
                                onChange={this.handleRegionFilter}
                                regionList={this.props.regionList}
                            />
                        </Col>
                    </div>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const regionList = RegionModel.list();
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');

    return { regionList, axiosPreviousToken };
}

export const DailyReports = withRouter(connect< IDailyReportsProps, any, any>(mapStateToProps)(DailyReportsImpl as any));
