import './listPage.scss';
import {format, getQuarter} from 'date-fns';
import * as React from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as _ from 'lodash';
import {setSuccess} from '../../../actions/loadingActions';
import {DailyFormsModel} from '../../../model/DailyFormModel';
import {MonthlyFormModel} from '../../../model/MonthlyFormModel';
import {PlantModel} from '../../../model/PlantModel';
import {UserModel} from '../../../model/UserModel';
import {Async} from '../Async';
import {ErrorPage} from '../ErrorPage';
import {Loader} from '../Loader';
import {IHistory} from '../../../interfaces';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { RRFInput } from '../FormComponents/RRFInput'; 
import moment from 'moment';
import { years } from '../../../constants/generals';

const queryString = require('query-string');
type ModelInstances = DailyFormsModel | MonthlyFormModel | PlantModel | UserModel | any;

export interface IListPageProps {
    identifier?: string;
    instances?: ModelInstances[];
    frequency?: string;
    listItemComponent?: React.ComponentClass<{
        instance?: ModelInstances, index?: number, frequency?: string,
        listItemProp?: string, onClick?: Function, history?: IHistory,
        className?: string;
    }> | any;
    renderPages?: JSX.Element;
    searchBy?: string[];
    sort?: {
        heading?: string;
        sortBy?: string;
    }[];
    history?: IHistory;
    pageHeadings?: string[];
    promise?: (...args: any[]) => any | undefined;
    cols?: number[];
    searchPlaceHolder?: string;
    instanceOf?: string;
    emptyListMessage?: string;
    style?: React.CSSProperties;
    chartComponents?: JSX.Element;
    height?: string;
    chartTitle?: string;
    chartFilter?: JSX.Element | JSX.Element[];
    flaggedCount?: JSX.Element | JSX.Element[];
    filterBy?: string;
    listItemProp?: string;
    data?: any;
    onFilter?: Function;
    onFlagSubmitParents?: any;
    onHandleSort?: any;
    listFooter?: React.ComponentClass<{ instances?: ModelInstances[], index: any, onClose?: Function, id?: string, modalType?: string, pageNum?: any, searchInput?: string, }>;
    listItemFields?: string[];
    pageNum?: any;
}

export interface IListPageState {
    searchInput: string;
    id: string;
    urlPlantId: string | undefined;
    index?: number;
    showFooter: boolean;
    instance: any;
    filteredInstances: ModelInstances[] | any;
    showFooterModal: boolean;
    isScrolled: boolean;
    isAscending: boolean;
    sortFor: number | null; 
}

export class ListPageImpl extends React.PureComponent<IListPageProps, IListPageState> {
    constructor(props: IListPageProps) {
        super(props);
        const {identifier} = this.props
        /* let isAsc = true;
        if (this.props.identifier === 'FlaggedFormList') {
            isAsc = false;
        } */
        let sortFlag = false;
        let skipPropsIndentifier = ['DailyReports', 'MonthlyFormList', 'QuarterlyFormList', 'WeeklyReports', 'YearlyFormList', 'FlaggedFormList'];
            if(!identifier || (identifier && !skipPropsIndentifier.includes(identifier)) ) { 
                sortFlag = true;
            }
        this.state = {
            id: '', isScrolled: false,
            searchInput: '', filteredInstances: [], showFooter: false, showFooterModal: false,
            isAscending: sortFlag, instance: null, urlPlantId: '',  sortFor: null
        };
    }
    promise = async () => {
        // const params = new URLSearchParams(this.props.history?.location['search']);
        let searchParams: string = "";
        if (typeof this.props.history?.location['search'] === 'string') {
            searchParams = this.props.history?.location['search'];
        }
        const params = new URLSearchParams(searchParams);
        const date = params.get('date');
        const plantId = params.get('plantId'); 
        const search = params.get('search') || '';  

        await this.setState({ searchInput:search });

        if (this.props && this.props.promise) {
            await this.props.promise();
        } 
        setTimeout(async () => {
            if (plantId) {
                await this.handleBottomSlider(0, plantId, '')
                this.setState({ urlPlantId: params.get('plant')?.toString()})
            }
        }, 2000);  
    }
    handleScroll = (e) => {
        this.setState({
            isScrolled: true
        });
    } 
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.instance !== this.props.instances ) { 
            this.state.sortFor && this.sortList(this.state.sortFor);
        }
    }
    handleBottomSlider = async (index: number, id, instance) => { 
        const currentPlantInstance = PlantModel.get(id); 
        if (this.state.index === index) {
            this.setState({
                showFooter: !this.state.showFooter,
                showFooterModal: true,
                index,
                id,
                instance: currentPlantInstance, 
            });
            return;
        }
        this.setState({
            showFooter: true,
            showFooterModal: true,
            index,
            id,
            instance: currentPlantInstance, 
        });
    }

    renderListItems = () => {
        let { instances, listItemComponent, frequency, searchBy, emptyListMessage, listItemProp, identifier }: any = this.props;
        const {searchInput} = this.state;
        const ListItem = listItemComponent;
        const filteredInstances = this.state.filteredInstances;
        const searchedInstances: Array<any> = [];

        // instances = _.orderBy(instances, ['props.plantId'],['asc'])
        const EmptyList = () => <div className="empty-list">
            {`${emptyListMessage} ${searchInput ? 'for ' + searchInput : ''}`}</div>;
        /* if (searchInput.length > 0 && instances.length>0) {
            instances.forEach((instance: any) => { 
                searchBy.forEach(searchText => {
                    let currentInstance = identifier==='DailyReports' || identifier==='WeeklyReports' || identifier==='MonthlyFormList' || identifier==='QuarterlyFormList' || identifier==='YearlyFormList' || identifier==='FlaggedFormList' ? instance:instance.props; 
                        if (currentInstance &&
                            (JSON.stringify(currentInstance).toLowerCase())
                                .indexOf(`"${searchText.toLowerCase()}":"${searchInput.toLowerCase() }`) > -1
                        ) { 
                            if (searchedInstances.indexOf(instance) < 0) {
                                searchedInstances.push(instance);
                                return;
                            }
                        } 
                    }
                );  
            });
        } */
        /* if (searchedInstances.length > 0 || searchInput.length > 0) {
            return <div> {searchedInstances.length > 0 ? searchedInstances.map((instance, index) => {
                return <div key={index}
                            onClick={() => this.handleBottomSlider(index, instance.props && instance.props.id, instance)}>
                    <ListItem
                        className="form-list-item"
                        frequency={frequency}
                        index={index + 1}
                        listItemProp={listItemProp}
                        instance={instance}
                        onFlagSubmitParent={this.handleParentCall}
                    />
                </div>;
            }) : <EmptyList/>}</div>;
        } */
        /* if (filteredInstances.length > 0 || searchInput.length > 0) {
            return <div> {filteredInstances.length > 0 ? filteredInstances.map((instance, index) => {
                return <div key={index}
                            onClick={() => this.handleBottomSlider(index, instance.props && instance.props.id, instance)}>
                    <ListItem
                        className="form-list-item"
                        frequency={frequency}
                        index={index + 1}
                        listItemProp={listItemProp}
                        instance={instance}
                    />
                </div>;
            }) : <EmptyList/>}</div>;
        } */
        // await this.props.onHandleSort(true,'plantId',searchInput)

        return <div>{instances.length > 0 ? instances.map((instance, index) => {
            return <div key={index}
                        onClick={() => this.handleBottomSlider(index, instance.props && instance.props.id, instance)}>
                <ListItem
                    className="form-list-item"
                    frequency={frequency}
                    index={index + 1}
                    listItemProp={listItemProp}
                    instance={instance}
                    onFlagSubmitParent={this.handleParentCall}

                />
            </div>;
        }) : <EmptyList/>}</div>;
    }
    handleParentCall= (childData) => {
    this.props.onFlagSubmitParents(childData);
    }
    prepareSortList = async (sortFor: number) => {
        const { listItemFields, identifier } = this.props;
        var fieldToFind = listItemFields?listItemFields[sortFor]:''; 
        await this.setState({isAscending: !this.state.isAscending});
        // this.sortList(sortFor)
        await this.props.onHandleSort(this.state.isAscending,fieldToFind, this.state.searchInput)
    }
    sortList = (sortFor: number) => { 
        const { listItemFields, identifier } = this.props;
        let sortedListItems: Array<any> | undefined = [] ; 
        let isAscendingState = this.state.isAscending;  
        sortedListItems = this.props.instances?.sort(function(a, b) {
            let skipPropsIndentifier = ['DailyReports', 'MonthlyFormList', 'QuarterlyFormList', 'WeeklyReports', 'YearlyFormList', 'FlaggedFormList'];
            if(!identifier || (identifier && !skipPropsIndentifier.includes(identifier)) ) { 
                a = a.props; b = b.props;
            }
            var fieldToFind = listItemFields?listItemFields[sortFor]:''; 
            var dateFields = ['submittedDate', 'lastUpdatedDate', 'formDate']; 
            var x = isAscendingState?a[fieldToFind]:b[fieldToFind]; var y = isAscendingState?b[fieldToFind]:a[fieldToFind];
            if(dateFields.includes(fieldToFind)) { 
                x = moment(x).format('YYYY-DD-MM');
                y = moment(y).format('YYYY-DD-MM'); 
                return x.split("-").reverse().join('').localeCompare(y.split("-").reverse().join(''));
            } else { 
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
        }); 
        this.setState({
            filteredInstances: sortedListItems, sortFor: sortFor
        });

    }

    renderPageHeadings = () => {
        const {pageHeadings, cols, sort, listItemFields} = this.props;
        const {sortFor, isAscending} = this.state;

        if(cols) {
            return <Row
                id="listHeadings"
                className={this.state.isScrolled ? 'list-headings list-headings-scrolled' : 'list-headings'}
            >
                {pageHeadings?.map((heading: string, index: number) => {
                    const sortKey = (sort || [{heading: 'Date', sortBy: 'Date'}]).find(data => data.heading === heading);
                    if (heading.toLowerCase() === '#') {
                        return <Col key={index} className="heading" xs={cols[index]} sm={cols[index]}>{heading}</Col>;
                    } else {
                        return <Col
                            key={index}
                            className="heading"
                            sm={cols[index]}
                            xs={cols[index]} 
                            onClick={() => listItemFields && this.prepareSortList(index)}> 
                            <span key={index + 50}>
                                {heading}
                                &nbsp;
                            </span>
                            { listItemFields && listItemFields[index]!='' && <i
                                key={index + 20}
                                className={`fa ${!sortFor || sortFor!==index?`fa-sort`:(isAscending?`fa-sort-down`:`fa-sort-up`)}` }
                                aria-hidden="true"
                            /> }
                        </Col>;
                    }
                })}
            </Row>;
        } else {
            return;
        }
    } 
     
    renderFooterModal = () => {
        const ListFooter = this.props.listFooter;
        const {instances} = this.props;
        const {history: {push}}: any = this.props;
        
        return ListFooter && this.props.instances && this.props.instances.length > 0 ? <Modal
            className="list-item-modal inspection-list"
            show={this.state.showFooterModal}
            onHide={() => this.setState({showFooterModal: false})}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title" className='col-11'>
                    Plant Info for Plant Id {this.state.instance ? this.state.instance.props.plantId : this.state.urlPlantId ? this.state.urlPlantId :''} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <ListFooter id={this.state.id} index={this.state.index} pageNum={this.props.pageNum} searchInput={this.state.searchInput}  />}
            </Modal.Body>
        </Modal> : '';
    }

    renderListPage = () => {
        const {chartTitle, chartFilter, filterBy, flaggedCount, identifier} = this.props;
        let sortFlag = true;
        let skipPropsIndentifier = ['DailyReports', 'MonthlyFormList', 'QuarterlyFormList', 'WeeklyReports', 'YearlyFormList', 'FlaggedFormList'];
            if(!identifier || (identifier && !skipPropsIndentifier.includes(identifier)) ) { 
                sortFlag = false;
            }

        return <div className="list-page" style={this.props.style}>
            {this.renderFooterModal()} 
            <div style={{fontSize: '20px', textAlign: 'center'}} className="chart-title">
                {`${chartTitle || ''}  ${filterBy ? 'for ' + filterBy : ''}` || ''}
            </div>
            <div style={{textAlign: 'center'}} className="chart-title">{chartFilter || ''}</div>
            <div style={{textAlign: 'center'}} className="chart-title">{flaggedCount || ''}</div>
            {this.props.chartComponents}
            <div className="search-container">
                <i className="fa fa-search" aria-hidden="true"/>
                <input
                    className="search-input"
                    type="text"
                    value= {this.state.searchInput}
                    // onChange={e => this.setState({searchInput: e.target.value})}
                    onChange={async (e) => {
                        let sortcol = identifier === 'FlaggedFormList' ? 'formDate' : identifier === 'UsersList' ? 'UserId' : sortFlag ? 'submittedDate':'plantId'
                        let isAsc = identifier === 'FlaggedFormList' ? false : sortFlag ? false : true
                        await this.setState({searchInput: e.target.value})
                        await this.props.onHandleSort(isAsc,sortcol,e.target.value)
                    }}  
                    placeholder={this.state.searchInput ? this.state.searchInput : this.props.searchPlaceHolder}
                />
            </div>
            {this.renderPageHeadings()}
            <div onScroll={this.handleScroll} style={{height: this.props.height || '71vh'}}>
                <PerfectScrollbar id="scrollbar">
                    <Async
                        identifier={this.props.identifier}
                        promise={() => setSuccess(this.props.identifier ? this.props.identifier : '')}
                        loader={<Loader type="async"/>}
                        error={<ErrorPage/>}
                        content={this.renderListItems()}
                    />
                </PerfectScrollbar>
            </div>

        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <Async
                    identifier={'ListPageAll'}
                    promise={this.promise}
                    /* promise={() => {
                        // Assuming this.props.promise and this.promise() are asynchronous functions
                        return Promise.all([this.props.promise, this.promise]);
                      }} */
                    loader={< Loader type="async"/>}
                    error={< ErrorPage/>}
                    content={this.renderListPage()}
                />
                {this.props.renderPages}
            </React.Fragment>);
    }
}

export function mapStateToProps(state) {
    return { };
}

export const ListPage = withRouter(connect<IListPageProps, any, any>(mapStateToProps)(ListPageImpl as any));
