import {getDev, postDev, putDev} from '../utils/HTTPDev';
import {OrgModel, RegionModel} from '../model/OrgModel';
import {toast} from 'react-toastify';
import {UserModel} from '../model/UserModel';
import {logout, saveLoginDetails} from '../actions/loginActions';
import { parseJwt, saveUserData } from './loginService';
import { IHistory } from '../interfaces'; 

export async function getAllOrgs() {
    try {
        const response = await getDev('/org');
        const orgInstance = response?.data.data;
        orgInstance.forEach((org, index) => {
            org.id = org.orgId;
            org.key = org.orgId;
            new OrgModel(org).$save();
        });
        return orgInstance;
    } catch (err) {
        throw err;
    }
}

export async function changeOrgDetails(orgId) {
    try {
        const response = await getDev('/orgDetailsChange/'+orgId);
        if (response && response?.data && response?.data.token) {
            const userData = parseJwt(response?.data.token);
            saveUserData(response?.data.token || '', userData.UserId, userData.UniqueId, userData.orgId || null, userData.orgName || null, userData.Regions || [], userData.Access_Level);
            userData.id = userData.UniqueId;
            new UserModel(userData).$save();
            saveLoginDetails({
                token: response?.data.token,
                UniqueId: userData.UniqueId,
                Access_Level: userData.Access_Level,
                orgId: userData.orgId || null,
                orgName: userData.orgName || null
            });
        }
        const orgInstance = response?.data.data;
        return orgInstance;
    } catch (err) {
        throw err;
    }
}

export async function getRegionsByOrg(orgId) {
    try {
        let regionList= [];
        const response = await getDev('/org/' + orgId + '/regions');
        (response?.data.data instanceof Array ? response?.data.data : []).forEach(instance => {
            regionList = response?.data.data;
            regionList.forEach((region: any, index: any) => {
                region.id = region.regionId;
                region.key = region.regionId;
                new RegionModel(region).$save();
            });
            
        });
        return regionList;
    } catch (err) {
        throw err;
    }
}

export async function createOrg(data, history?: IHistory) {
    try {
        const response = await postDev('/org', data);
        history?.push('/org');
        toast.success('Organisation Created Successfully.', {
            position: toast.POSITION.TOP_CENTER,
            className: 'success-toast',
            //onClose: history?.push('/org'),
            autoClose: 1000
        });
        await getAllOrgs();
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getOrgInfo(id) {
    try {
        const response = await getDev('orgdetails/' + id);
        const org = response?.data.data;
        org.id = org.orgId;
        new OrgModel(org).$save();
    } catch (e) {
        throw e;
    }
}

export async function updateOrgInfo(data, id, history?: IHistory) {
    try {
        const response = await putDev('updateorg/' + id, data);
        history?.push('/org');
        toast.success('Organisation updated Successfully.', {
            position: toast.POSITION.TOP_CENTER,
            className: 'success-toast',
            //onClose: history?.push('/org'),
            autoClose: 1000
        });
        await getAllOrgs();
        return response;
    } catch (error) {
        throw error;
    }
}
