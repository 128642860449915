import {format, getQuarter} from 'date-fns';
import {toast} from 'react-toastify';
import * as React from 'react';
import {DropdownButton, Dropdown, OverlayTrigger, Popover, PopoverTitle, PopoverContent} from 'react-bootstrap';
import {quarterMonths} from '../../../constants/generals';
import {IHistory} from '../../../interfaces';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import moment from "moment";
import '../../Reports/reports.scss'
const queryString = require('query-string');

export interface IPointSource {
    PsId: string;
    PsName: string;
    InspectionId: string;
    IsSelected?: boolean;
}

export interface IFooterCardProps {
    date?: Date;
    permitType?: string;
    inspections?: Array<any>;
    freq?: string;
    history?: IHistory;
    plantInfo?: any;
    location?: {
        search: string;
    };
    pageNum?: any;
    searchInput?: string;
}

export interface IFooterCardState {
    isLoading: boolean;
    date: Date;
    month: string;
    year: string;
    quarter: string;
    selectedPS: any;
    selectedInspectionId: string | null;
    popoverMonth: Date;
    monthSelect: string;
    quarterString: string;
}

export class SummaryFooterCardImpl extends React.PureComponent<IFooterCardProps, IFooterCardState> {

    constructor(props: IFooterCardProps) {
        super(props);

        sessionStorage.setItem('inspectionDetails', '');

        let date = new Date();
        let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`;
        let monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
        let quarterString;
        quarterMonths(this.props.location).map((year, index) => {
            if (year.value === quarter) {
                quarterString = year.label;
            }
            return true;
        });
        monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
        if (this.props?.location?.search) {
            const {search} = this.props.location;
            const dateSelected = (queryString.parse(search).date);
            date = moment(dateSelected).tz('America/Chicago').toDate();
            quarter = queryString.parse(search).quarter ? queryString.parse(search).quarter : quarter;
            monthSelect = ((Number(quarter) - 1) * 3 + 1).toString();
            monthSelect = monthSelect.length > 1 ? monthSelect : '0' + monthSelect;
            quarterMonths(this.props.location).map((year, index) => {
                if (year.value === quarter) {
                    quarterString = year.label;
                }
                return true;
            });
        }
        this.state = {
            selectedInspectionId: null,
            date: date, isLoading: false, month: format(date, 'MM-dd-yyyy'),
            year: date.getFullYear().toString(), quarter,
            selectedPS: {PsName: 'Select', PsId: null, InspectionId: null}, popoverMonth: new Date(),
            monthSelect, quarterString
        };
    }

    // Quarterly inspections popover
    renderPointSourcePopOver = (inspection) => { 
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <PopoverTitle as={'h2'} className={'popover-title'}>Point Source</PopoverTitle>
            <PopoverContent className="popover-content">
                <div className="list-footer-quarterly">
                    <Dropdown
                        id="dropdown"
                        className="bootstrap-dropdown">
                        <DropdownToggle variant="" id={'dropdown-basic'}>{this.state.selectedPS ? this.state.selectedPS.PsName : 'Select'}</DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem 
                                key={0}
                                eventKey="4"
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({selectedPS: '-1', selectedInspectionId: inspection.InspectionId});
                                }}>
                                {`No Outfall`} 
                            </DropdownItem> */}
                            {inspection.PointSources.map((ps, index) => {
                                const className = ps.InspectionId ? 'completed' : '';
                                return <DropdownItem
                                    className={className}
                                    key={index}
                                    eventKey="4"
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.setState({selectedPS: ps, selectedInspectionId: ps.InspectionId});
                                    }}>
                                    {ps.PsName}
                                    &nbsp;
                                    {className && <i className="fa fa-check" aria-hidden="true"/>}
                                </DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="popover-footer-button">
                    <button
                        onClick={() => this.loadInspectionForm(inspection)}
                    >{'Ok'}</button>
                </div>
            </PopoverContent>
        </Popover>;
    }

    loadInspectionForm = (inspection) => {
        if ((inspection.PointSources && inspection.PointSources.length > 0) && !this.state.selectedPS.PsId) {
            toast.error('Please Select an Outfall / Point source first.', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        inspection.frequency = this.props.freq;
        const {history: {push}}: any = this.props;
        sessionStorage.setItem('inspectionDetails', JSON.stringify({
            ...inspection,
            selectedInspectionId: (inspection.PointSources && inspection.PointSources.length > 0) && this.state.selectedPS.PsId ? this.state.selectedPS.InspectionId : inspection.InspectionId,
            selectedPS: this.state.selectedPS,
            frequency: this.props.freq,
            plantInfo: this.props.plantInfo.props,
            date: format(this.state.date, 'MM-dd-yyyy'),
            permitType: this.props.permitType
        }));
        let urls: string = '/summary-reports'+'?date='+format(this.state.date, 'MM-dd-yyyy')+'&&plantId='+this.props.plantInfo.props.id+'&&plant='+this.props.plantInfo.props.plantId+'&&pagenum='+this.props.pageNum+'&&search='+this.props.searchInput;
        push(urls);
        let url: string = '/summary-reports/' + this.props.freq;
        return push(url);
    }

    resetSelectedItems = () => {
        setTimeout(() => {
            this.setState({
                selectedInspectionId: null,
                selectedPS: {PsName: 'Select', PsId: null, InspectionId: null}
            });
        }, 200);
    }

    render() {
        return this.props.inspections?.map((inspection, index) => {
            return inspection.PointSources && inspection.PointSources.length === 0 ? <div className="inspection-box" onClick={() => this.loadInspectionForm(inspection)}>
                <div key={inspection.ParentId} className="form-title">{inspection.FormName}</div>
            </div> : <div key={inspection.ParentId}><OverlayTrigger
                rootClose
                trigger="click"
                placement="top"
                overlay={
                    this.renderPointSourcePopOver(inspection)
                }>
                <div className="inspection-box">
                    <div className="form-title">{inspection.FormName}</div>
                </div>
            </OverlayTrigger></div>;
        });
    }
}


export function mapStateToProps(state) {
    return { };
}

/*export const FooterCard = withRouter(FooterCardImpl);*/
export const FooterCard = withRouter(connect< IFooterCardProps, any, any>(mapStateToProps)(SummaryFooterCardImpl as any))
