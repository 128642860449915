import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory} from '../../interfaces';
import {OrgModel} from '../../model/OrgModel';

export interface IOrgListItemProps {
    instance?: OrgModel;
    index?: number;
    history?: IHistory; 
    onClick?(val): string;
}

export class OrgsListItemImpl extends React.PureComponent<IOrgListItemProps, {}> {
    static defaultProps: IOrgListItemProps;

    constructor(props: IOrgListItemProps) {
        super(props);
    }

    render() {
        const {instance, history} = this.props;
        return <Row className="form-list-item">
            <Col
                onClick={() => history?.push(`/org/details/${instance?.props.orgId}`)}
                className="clickable-list-item serial-no"
                sm={6} xs={6}
            >
                {instance?.props.orgName}
            </Col>
        </Row>;
    }
}

export function mapStateToProps(state) {
    return { };
}

export const OrganisationsListItem = withRouter(connect< IOrgListItemProps, any, any>(mapStateToProps)(OrgsListItemImpl as any));
