import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory} from '../../interfaces';
import {PlantModel} from '../../model/PlantModel';
import {getPlantDetails, updatePlant} from '../../services/plantService';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {PlantForm, PlantFormImpl} from './PlantForm';

export interface IUpdatePlantFormProps {
    plantInstance: PlantModel;
    history?: IHistory;
    id: string;
    userId: string;
}

export class UpdatePlantFormImpl extends React.PureComponent<IUpdatePlantFormProps, {}> {

    constructor(props: IUpdatePlantFormProps) {
        super(props);
    }

    promise = async () => {
        if (!this.props.plantInstance) {
            await getPlantDetails(this.props.id);
        }
    }

    handleSubmit = async (values) => {
        return await updatePlant(this.props.plantInstance.props.id, values, this.props.history);
    }

    renderContent = () => {
        if (!this.props.plantInstance) {
            return <span/>;
        }
        return <PlantForm
            model="forms.updatePlantForm"
            onSubmit={this.handleSubmit}
            plantInstance={this.props.plantInstance}
            history={this.props.history}
        />;
    }

    render() {
        return <BasePage pageTitle={`Update Plant`} className="plant-page">
            <Async
                identifier="PlantDetailsPage"
                promise={this.promise}
                loader={<Loader type="async"/>}
                content={this.renderContent()}
                error={<ErrorPage/>}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state, ownProps) {
    const plantInstance = PlantModel.get(ownProps.match.params.id);
    const userId = state.login.get('UniqueId');
    return {
        userId, plantInstance, id: ownProps.match.params.id
    };
}

export const UpdatePlantForm =
    withRouter(connect< IUpdatePlantFormProps, any, any>(mapStateToProps)(UpdatePlantFormImpl as any));
