import * as React from 'react';
import {BaseModel} from './BaseModel';
import {IQuestions} from '../interfaces';

export interface IFlaggedFormModelProps {
    id?: string;
    questions?: IQuestions[];
    ImageUrls?: string[];
    InspectionId?: string;
    FlaggedQuestion?: boolean;
    InspectionUId?: string;
    PlantUID?: string;
    Region?: string;
    SignatureUrls?: string[];
    Completed?: boolean;
    CompletedBy?: string;
    Date?: string;
    DateCompleted?: string;
    DatePlant?: string;
    FollowUpComments?: string;
    PlantId?: string;
    UserId?: string;
    UserName?: string;
    key?: string;
    type?: string;
    category?: string;
    Flagged?: boolean;
    SubmissionDate?: string;
    QuestionsData?: IQuestions[];
    PagesCount?: number;
}

export class FlaggedFormModel extends BaseModel<IFlaggedFormModelProps> {
    static resource = 'flagged-forms';

    constructor(props: IFlaggedFormModelProps) {
        super(props);
    }
}
