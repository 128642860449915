import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setLoading, setSuccess} from '../../actions/loadingActions';
import {IHistory, ISelectOptions} from '../../interfaces';
import {dispatch} from '../../utils/generalUtils';
import {Alert} from '../reusableComponents/Alert';
import {Async} from '../reusableComponents/Async';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import './forgotPasswordForm.scss';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {actions, Form} from 'react-redux-form';
import PropTypes from 'prop-types';
import {Loader} from '../reusableComponents/Loader';
import {AlertModal} from '../../model/Alertmodel';
import {UserModel} from '../../model/UserModel';
import {resetPassword} from '../../services/loginService';
import logo from '../../images/logo_.png';
import {toast, ToastContainer} from 'react-toastify';
// import {dispatch, isFormEmpty} from '../../utils/generalUtils';
import { isFormEmpty } from '../../utils/generalUtils';
import {ErrorMessageLabel} from '../reusableComponents/Errormessagelabel/errormessagelabel';
import { EMPTY_FIELD_MESSAGE } from '../../constants/generals';


declare const require: any;
const logoURL = require('../../images/logo_.png');
const bgImage = require('../../images/bg_.jpg');

export interface IForgotPasswordFormImplProps {
    history: IHistory;
    isLoggedIn: boolean;
    alertType: string;
    userEmail: string;
    pathName: string;
    orgId: string;
}

export interface IForgotPasswordFormImplState {
    isSignUpPage: boolean;
    errorMessage: string;
    email: string;
    isValidUser: boolean;
    menuItems?: ISelectOptions[];
    isLoading: boolean;
    popOver: boolean;
}

// tslint:disable-next-line:max-line-length
export class ForgotPasswordFormImpl extends React.PureComponent<IForgotPasswordFormImplProps, IForgotPasswordFormImplState> {

    static childContextTypes = {
        formModel: PropTypes.string
    };
    forgotPasswordIdentifier: string = 'ForgotPasswordForm';

    constructor(props: IForgotPasswordFormImplProps) {
        super(props);
        this.state = {email: props.userEmail, isValidUser: false, errorMessage: '', isSignUpPage: false, isLoading: false, popOver: false};
    }

    promise = async () => {
        return null;
    }

    /**
     * To set the initial state of the loader in the login button.
     */
    componentWillMount() {
        setSuccess(this.forgotPasswordIdentifier);
        if (!this.props.isLoggedIn) {
            dispatch(actions.reset('forms.forgotPasswordForm'));
            return;
        }
        this.props.history.push('/home');
    }

    renderForgotPasswordForm = () => {
        const {handleForgotPassword} = this;
        return (
            <Form model="forms.forgotPasswordForm" className="login" onSubmit={handleForgotPassword} >
                <div className="title">
                    <img alt={'logo-url'} src={logo} style={{width: '45%', height: '100%'}}/>
                </div>
                <div className="page-title">
                    Reset Password
                </div>
                <Alert className={`forgot-password-alert`} id="forgot-password-error"/>
                { // @ts-ignore
                <AlertModal id="forgot-password-form-modal"/>}
                <div className="input-field">
                    <InputField className="tempPassword"
                                placeholder="Temporary Password"
                                type="password"
                                model=".tempPassword"
                    />
                    <InputField className="newPassword"
                                placeholder="New Password"
                                type="password"
                                model=".newPassword"
                    />
                    <InputField className="confirmPassword"
                                placeholder="Confirm Password"
                                type="password"
                                model=".confirmPassword"
                    />
                </div>
                <div>
                    <h6 className='forgotPasswordPageHint'>
                    * Password should have at least one Uppercase Letter,
                        one Lowercase character , one numeric character, 
                        one special symbol and minimum length of password should be 8 character
                    </h6>
                   {/*  <<ErrorMessageLabel className isActive={this.state.popOver}
                        errorMessage="* Password should have at least one Uppercase Letter,
                        one Lowercase character , one numeric character, 
                        one special symbol and minimum length of password should be 8 character"
                    />> */}
                </div>
                {this.state.isLoading && <div className="reset-pwd-loader">
                    <MuiThemeProvider>
                        <CircularProgress size={150} thickness={5} color="#a4bf43"/>
                    </MuiThemeProvider>
                </div>}
                <button style={
                    !this.state.isValidUser ? {
                        width: '150px',
                        height: '40px',
                    } : {}
                } className="button">Update
                </button>
            </Form>
        );
    }

    getChildContext() {
        return {formModel: 'forms.forgotPasswordForm'};
    }

    /**
     * Submits the form to the sever. Collects the data from the username field and password field.
     */
    handleForgotPassword = async ({tempPassword, newPassword, confirmPassword}: any) => {
        // setLoading(this.forgotPasswordIdentifier);
        const {props: {history, orgId}} = this;
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
        /* let userOrg = orgId;	
        if (orgId == '111') {
            userOrg = 'None';
        } */

        const requestData = {
            email: this.state.email,
            tempPassword,
            newPassword,
            confirmPassword, 
            orgId
        };

        if (isFormEmpty(requestData)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        if (!strongRegex.test(newPassword)) {
            this.setState({popOver: true});
            toast.error('Invalid password. You password should match these conditions', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); 
            return;
        }
        
        if (newPassword !== confirmPassword) {
            toast.error('New Password and Confirm Password should be same', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }

        this.setState({isLoading: true}, () => {
            resetPassword(requestData, history).then(() => {
                this.setState({
                    isLoading: false
                });
            });
        });
    }

    render() {
        return (
            <div className={`login-form`}><ToastContainer key={0}/>,
                <Async identifier="ForgotPasswordForm"
                       promise={() => setSuccess(this.forgotPasswordIdentifier)}
                       error={<div/>}
                       content={
                           <div>
                               <img alt={'bg-img'} key={1} src={bgImage} className="background" height="300" width="300"/>,
                               {this.renderForgotPasswordForm()}
                           </div>
                       }
                />
            </div>
        );
    }

    /*    render() {
            return <div>
                <Async
                    identifier="ForgotPasswordForm"
                    promise={() => setSuccess(this.forgotPasswordIdentifier)}
                    error={<div/>}
                    loader={<Loader size={50}/>}
                    content={this.renderContent()}
                />
            </div>;
        }*/
}

/**
 * To be used for fetching the login state of the user.
 * @param state
 * @param ownProps
 */
export function mapStateToProps(state: any, ownProps: any) {

    let pathName = '/home';
    let userEmail = ownProps.match.params.userId;
    let orgId = ownProps.match.params.orgId;

    if (ownProps.location.search) {
        pathName = ownProps.location.search.split('=')[1];
    }
    return {
        isLoggedIn: state.login.get('isLoggedIn'),
        alertType: state.alert.get('type'),
        userEmail,
        pathName,
        orgId
    };
}

export const ForgotPasswordForm = withRouter(connect(mapStateToProps)(ForgotPasswordFormImpl));

export const error: React.CSSProperties = {
    position: 'relative',
    top: '39%',
    width: '100%',
    textAlign: 'center',
    color: 'red'
};

const InputField = ({className, placeholder, type, model, ...props}: any) => {
    return <RRFInput
        id={className}
        className={className}
        placeholder={placeholder}
        onChange={props.onChange}
        type={type}
        model={model}
    />;
};

const buttonStyle: React.CSSProperties = {
    width: '116.48px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center'
};
