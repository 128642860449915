import * as React from 'react';
import {connect} from 'react-redux';

import {executePromiseAction, stopPromise} from '../../actions/loadingActions';
import {isEmpty} from '../../utils/generalUtils';

export interface IAsyncProps extends IAsyncStoreProps {
    promise?: (...args: any[]) => any;
    identifier: string | any;
    loader?: JSX.Element | JSX.Element[] | null;
    //content: JSX.Element | JSX.Element[];
    content: any;
    error: JSX.Element | JSX.Element[];
    initialState?: IAsyncState;
}

export interface IAsyncStoreProps {
    executePromise?: () => void;
    loadingState?: IAsyncState | any;
}

export interface IAsyncState {
    isLoading?: boolean;
    hasError?: boolean;
}

export class AsyncImpl extends React.Component<IAsyncProps, {}> {

    static defaultProps = {
        loadingState: {
            isLoading: false,
            hasError: false,
        }
    };

    executePromise = () => {
        const {identifier, promise} = this.props;
        if (promise instanceof Function) {
            executePromiseAction(
                promise(),
                identifier
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.executeAction) {
            this.executePromise();
            stopPromise('DetailsPageWrapper');
        }
    }

    componentWillMount() {
        this.executePromise();
    }

    renderChildren = () => {
        const {content, loader, error, identifier, loadingState} = this.props;
        const {isLoading, hasError} = loadingState;
        if ((isLoading || isEmpty(loadingState)) && loader) {
            return React.Children.only(loader);
        }

        if (!isLoading && !hasError) {
            return React.Children.only(content);
        }

        if (hasError && Error()) {
            return React.Children.only(error);
        }
        return null;
    }

    render() {
        return this.renderChildren();
    }
}

export function mapStateToProps(state, ownProps) {
    return {
        loadingState: state.loading.get(ownProps.identifier) || ownProps.initialState || {},
        executeAction: state.loading.get(`${ownProps.identifier}/START_PROMISE`)
    };
}

export const Async = connect<{}, {}, IAsyncProps>(mapStateToProps)(AsyncImpl as any);
