import * as React from 'react';
import {Modal} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect} from 'react-redux';
import {deletePlantDetails} from '../../../actions/dashboardAction';
import {hidePlantListModal} from '../../../actions/modalActions';
import {IFlaggedFormDetails, IHistory, IQuestions} from '../../../interfaces';
import {Alert} from '../Alert';
import {Loader} from '../Loader';

import './listModal.scss';
import {fetchFlaggedQuestions} from '../../Dashboard/dashboardDataFetcher';
import {FlaggedQuestionsForm} from '../../Dashboard/flaggedQuestionsForm';

export interface IDetailsModalProps {
    id: string;
    modalID?: string;
    dateCompleted?: string;
    modalTitle: string;
    listHeadings: string[];
    listItemComponent: React.ComponentClass<{
        index: number, UniqueId?: string, plantId?: string | IFlaggedFormDetails,
        onClick(UniqueId: string, type: string): void; inspectionData: any,
        Completed?: boolean;
    }>;
    data?: any[];
    className?: string;
    style?: React.CSSProperties;
    listTitle?: string;
    initialPromise?: any;
    formDetails?: IQuestions[];
    formType?: string;
    userId?: string;
    completedBy?: string;
    followUpComment?: string;
    history?: IHistory;

    promise?(datePlant: any): any;

    onClose: Function;
}

export interface IDetailsModalState {
    showDetails: boolean;
    showProgressBar: boolean;
    isLoading: boolean;
    formType: string;
    index?: number;
    ids: any[];
    date: Date;
    formData: any;
    inspectionFormSelected: any;
}

export class DetailsModalImpl extends React.Component<IDetailsModalProps, IDetailsModalState> {
    static defaultProps: IDetailsModalProps;
    questionCount = 0;

    constructor(props: IDetailsModalProps) {
        super(props);
        this.state = {
            showDetails: false, showProgressBar: false,
            formType: '', isLoading: false, date: new Date(),
            ids: props.data || [],
            formData: {},
            inspectionFormSelected: {}
        };
    }

    renderFormDetails = () => {
        const {formData} = this.state;
        return <FlaggedQuestionsForm
            formData={formData}
            onFlagUpdate={() => {
                this.handleOnListItemClick(this.state.inspectionFormSelected);
            }
            }
        />;
    }

    handleOnListItemClick = async (inspectionInfo) => {
        const formData = await fetchFlaggedQuestions(inspectionInfo, this);
        this.setState({showDetails: true, formData, inspectionFormSelected: inspectionInfo});
    }

    showPlantList = () => {
        const {data, listItemComponent, listTitle} = this.props;
        const ListItem = listItemComponent;
        return data && data.length > 0 ? data.map((plant, index) => {
            return <ListItem
                key={Date.now() + index}
                index={index}
                inspectionData={plant}
                onClick={() => this.handleOnListItemClick(plant)}
            />;
        }) : <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                color: 'gray',
                textAlign: 'center'
            }}>
            No inspections found.
        </div>;
    }

    hideModal = () => {
        hidePlantListModal();
        deletePlantDetails();
        this.props.onClose();
        this.setState({
            showDetails: false
        });
    }

    renderContent = () => {
        const {listHeadings} = this.props;
        return <Modal.Body style={{backgroundColor: 'black', border: '1px solid #757575'}}>
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            <Alert id="incomplete-list-update"/>
            {this.state.showDetails ? '' :
                <div className="heading-container">{listHeadings.map((heading, index) => {
                    return <div
                        key={index}
                        className="heading"
                        style={{width: `${20}%`}}
                    >
                        {heading}
                    </div>;
                })}</div>
            }
            {this.state.showDetails ?
                this.renderFormDetails() :
                <div className="list-items">
                    <PerfectScrollbar>
                        {this.showPlantList()}
                    </PerfectScrollbar>
                </div>}
        </Modal.Body>;
    }

    renderListItems = () => {
        const {
            id, data, style, listTitle, className, modalTitle, modalID,
            listItemComponent, listHeadings
        } = this.props;
        /* if (id !== modalID) {
             return <div></div>;
         }*/
        const {inspectionFormSelected} = this.state;
        return <Modal
            show={true}
            className={`${className} list-modal`}
            style={style}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton style={modalHeader}>
                <Modal.Title id="contained-modal-title" style={{color: 'white'}}>
                    {this.state.showDetails ?
                        <div className="back-button" onClick={() => this.setState({showDetails: false})}>
                            <i style={{
                                fontSize: '22px', paddingRight: '10px'
                            }} className="fa fa-arrow-circle-left" aria-hidden="true"/>
                            {`Flagged Form - ${inspectionFormSelected.FormName}`}
                        </div> : modalTitle}
                </Modal.Title>
            </Modal.Header>
            {this.renderContent()}
        </Modal>;
    }

    render() {
        return this.renderListItems();
    }

}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const DetailsModal = connect<{}, {}, IDetailsModalProps>(mapStateToProps)(DetailsModalImpl as any);

const modalHeader: React.CSSProperties = {
    backgroundColor: '#A4BF43',
    border: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
};

const Label = ({children, isFlagged, icon}) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}) => {
    return <div style={{fontSize: '18px', color: 'white'}}>{children}</div>;
};
