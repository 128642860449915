import * as React from 'react';
import {format} from 'date-fns';
import {Col, Row} from 'react-bootstrap';
import {IHistory} from '../../interfaces';
import {RDatePicker} from '../reusableComponents/DatePicker';
import DatePicker from 'react-date-picker';

import './flaggedQuestionsForm.scss';
import {saveFlagCompletions} from '../../services/dailyFormService';

export interface IFlaggedQuestRendererProps {
    formData: any;
    history?: IHistory;
    onFlagUpdate: Function;
}

export interface IFlaggedQuestRendererState {
    questionsList: Array<Object>;
    date: Date;
    showMarkAsCompleteButtonFor: string | number | null;
    followUpComments: string | null;
    followUpDate: Date;
}

export class FlaggedQuestionsForm extends React.PureComponent<IFlaggedQuestRendererProps, IFlaggedQuestRendererState> {

    constructor(props: IFlaggedQuestRendererProps) {
        super(props);
        this.state = {
            questionsList: props.formData.questionAndAnswer,
            date: new Date(),
            showMarkAsCompleteButtonFor: null,
            followUpComments: null,
            followUpDate: new Date()
        };
    }

    renderTitles = (quest: any) => {
        return (
            <div key={quest.questionId} className="field-heading">{quest.question}</div>
        );
    }

    renderSecondaryTitles = (quest: any) => {
        return (
            <div key={quest.questionId} className="secondary-heading">{quest.question}</div>
        );
    }

    handleUpdate = async (data: any) => {
        if (data.followUpRemark) {
            await saveFlagCompletions(this.props.formData.inspectionUId, data.questionId, data.followUpRemark, format(new Date(this.state.followUpDate), 'MM-dd-yyyy'), this.props.history).then((res) => {
                if (this.props.onFlagUpdate) {
                    this.props.onFlagUpdate();
                }
            });
        } else {
           alert('Please enter you comments.');
        }
    }
    handledateUpdate = async (date: any) => {
        this.setState({followUpDate:date})
    }

    

    renderFlaggedObjectiveTypeQuestions = (quest: any, localQuestNo: string | number, isSubQuest: boolean | undefined) => {
        if (!quest.followUpDate) {
            quest.followUpDate = new Date();
        }

        if (typeof quest.followUpDate === 'string') {
            quest.followUpDate = new Date(quest.followUpDate);
        }
        const renderSubQuestions = (subQuestions: any[], localQuestNo: string) => {
            return subQuestions.map((subQuest: any, index: number) => {
                const currentQuestNo = `${localQuestNo}.${index + 1}`;
                return (
                    <div key={subQuest.questionId} className={'question-row'}>
                        {this.generateQuestionAnswer(subQuest, currentQuestNo, true)}
                        {subQuest.subQuestions && subQuest.subQuestions.length > 0 && 
                             (
                                <div className="nested-questions">
                                    {subQuest.answer+' === '+subQuest.subQuestions.answertoSQ}
                                    {renderSubQuestions(subQuest.subQuestions, currentQuestNo)}
                                </div>
                            )}
                    </div>
                );
            });
        };
        const renderSubQuestion = (subQuestions: any[], parentAnswer: string, localQuestNo: string) => {
            return subQuestions
                // .filter((subQuest: any) => subQuest.answertoSQ === parentAnswer) // Check parent answer against subQuestion's answertoSQ
                .map((subQuest: any, index: number) => {
                    const currentQuestNo = `${localQuestNo}.${index + 1}`;
                    return (
                        <div key={subQuest.questionId} className={'question-row'}>
                            {this.generateQuestionAnswer(subQuest, currentQuestNo, true)}
                            {subQuest.subQuestions && subQuest.subQuestions.length > 0 && (
                                <div className="nested-questions">
                                    {renderSubQuestion(subQuest.subQuestions, subQuest.answer, currentQuestNo.toString())}
                                </div>
                            )}
                        </div>
                    );
                });
        };

        return (<div key={quest.questionId + Date.now()} className="flagged-question-container">
            {!quest.followUpRemark ? <div>
                    <div className="mark-as-complete-col" onClick={() => {
                        this.setState({
                            showMarkAsCompleteButtonFor:
                                this.state.showMarkAsCompleteButtonFor === localQuestNo ? null : localQuestNo
                        });
                    }}
                         title="Click to mark this question as Complete">
                        <i className="fa fa-flag incomplete-flag"
                           aria-hidden="true"> </i>
                    </div>
                    <div className="question-row">
                        <div className="question flagged-question">
                            {quest.question}
                        </div>
                        <div className="answer">
                            <div>{quest.answer || 'Unanswered'}</div>
                        </div>
                    </div>
                    <div
                        className="flagged-input-container"
                        style={{
                            height: this.state.showMarkAsCompleteButtonFor === localQuestNo ? '141px' : '0px',
                            opacity: this.state.showMarkAsCompleteButtonFor === localQuestNo ? 1 : 0,
                            overflow: this.state.showMarkAsCompleteButtonFor === localQuestNo ? 'unset' : 'hidden',
                            textAlign: 'center', marginBottom: '20px',
                            fontSize: '15px'
                        }}>
                        <span style={{color: '#b7b7b7', fontWeight: 'bold', paddingRight: '12px'}}>Select Date:</span>
                        {/* <RDatePicker
                            maxDate={new Date()}
                            // minDate={new Date(this.props.formData.submittedDate)}
                            value={quest.followUpDate}
                            onChange={(date: any) => quest.followUpDate = date}
                        /> */}
                        <DatePicker
                           maxDate={new Date()}
                           minDate={new Date(this.props.formData.submittedDate)}
                           value={this.state.followUpDate}
                           onChange={(date: any) => {this.setState({followUpDate : date})}}

                           locale="en-US"
                       />
                        <div className="add-comments-section">
                            <textarea
                                rows={3}
                                placeholder="Enter your comments..."
                                onChange={e => {
                                    quest.followUpRemark = e.target.value;
                                }}
                            />
                        </div>
                        <button className="mark-as-complete-button"
                                onClick={() => this.handleUpdate(quest)}
                        >
                            Mark as Complete
                        </button>
                    </div>
                </div> :
                <div className="question-row">
                    <i className="fa fa-check-square-o completed-flag" aria-hidden="true">&nbsp;</i>
                    <div className="question flagged-question">
                        {quest.question}
                    </div>
                    <div className="answer">
                        <div>{quest.answer === 'N/A' ? 'Not Applicable' : quest.answer || 'Unanswered'}</div>
                    </div>
                    <div className="answer">
                        <Label
                            icon={<i className="fa fa-check-circle" aria-hidden="true">&nbsp;</i>}
                            key={quest.questionId + 1} isFlagged={1}>Updated By:
                        </Label>
                        <Value key={quest.questionId + 2}>{quest.completedBy || 'Unanswered'}</Value>
                        <span key={quest.questionId + 6} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-comments" aria-hidden="true">&nbsp;</i>}
                            key={quest.questionId + 4} isFlagged={1}>Follow Up Comments:
                        </Label>
                        <Value key={quest.questionId + 5}>{quest.followUpRemark || 'Unanswered'}</Value>
                        <span key={quest.questionId + 7} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-calendar" aria-hidden="true">&nbsp;</i>}
                            key={quest.questionId + 8} isFlagged={1}>Date Updated:
                        </Label>
                        <Value key={quest.questionId + 9}>{format(new Date(quest.followUpDate), 'MM-dd-yyyy') || 'Unanswered'}</Value>
                        <span key={quest.questionId + 10} style={{display: 'block', paddingBottom: '10px'}}/>
                    </div>
                </div>
            }

            {quest.subQuestions && quest.subQuestions.length > 0 && 
                // quest.answer === quest.answertoSQ && 
                    quest.subQuestions.map((subQuest: any, index: number) => {
                    return (<div key={subQuest.questionId} className={'question-row'}>
                        {
                        this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)
                    }</div>);
            })}
            {/* {quest.subQuestions && quest.subQuestions.length > 0 && 
                // quest.answer === quest.answertoSQ && 
                (
                    <div className="sub-questions-container">
                        {renderSubQuestions(quest.subQuestions, localQuestNo.toString())}
                        {renderSubQuestion(quest.subQuestions, quest.answer, localQuestNo.toString())}
                    </div>
                )} */}
        </div>);
        
    }

    renderObjectiveTypeQuestions = (quest: any, localQuestNo: number | string, isSubQuest: boolean | undefined) => {
        if (quest.answer != null) {
            const renderSubQuestions = (subQuestions: any[], localQuestNo: string) => {
                return subQuestions.map((subQuest: any, index: number) => {
                    const currentQuestNo = `${localQuestNo}.${index + 1}`;
                    return (
                        <div key={subQuest.questionId} className={'question-row'}>
                            {this.generateQuestionAnswer(subQuest, currentQuestNo, true)}
                            {subQuest.subQuestions && subQuest.subQuestions.length > 0 && 
                                 (
                                    <div className="nested-questions">
                                        {subQuest.answer+' === '+subQuest.subQuestions.answertoSQ}
                                        {renderSubQuestions(subQuest.subQuestions, currentQuestNo)}
                                    </div>
                                )}
                        </div>
                    );
                });
            };
            const renderSubQuestion = (subQuestions: any[], parentAnswer: string, localQuestNo: string) => {
                return subQuestions
                    // .filter((subQuest: any) => subQuest.answertoSQ === parentAnswer) // Check parent answer against subQuestion's answertoSQ
                    .map((subQuest: any, index: number) => {
                        const currentQuestNo = `${localQuestNo}.${index + 1}`;
                        return (
                            <div key={subQuest.questionId} className={'question-row'}>
                                {this.generateQuestionAnswer(subQuest, currentQuestNo, true)}
                                {subQuest.subQuestions && subQuest.subQuestions.length > 0 && (
                                    <div className="nested-questions">
                                        {renderSubQuestion(subQuest.subQuestions, subQuest.answer, currentQuestNo.toString())}
                                    </div>
                                )}
                            </div>
                        );
                    });
            };
        return (
            <React.Fragment key={quest.questionId}>
                <div className="question-row">
                    <div className="question">
                        {quest.question}
                    </div>
                    <div className="answer">
                        <div>{quest.answer === 'N/A' ? 'Not Applicable' : quest.answer || 'Unanswered'}</div>
                    </div>
                </div>
                {/* {quest.subQuestions && quest.subQuestions.length > 0 && quest.answer === quest.answertoSQ && quest.subQuestions.map((subQuest: any, index: number) => {
                        return (<div key={subQuest.questionId + Date.now()} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                })} */}
                {quest.subQuestions && quest.subQuestions.length > 0 
                    // && quest.answer === quest.answertoSQ 
                        && quest.subQuestions.map((subQuest: any, index: number) => {
                        return (<div key={subQuest.questionId + Date.now()} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                })}
                {/* {quest.subQuestions && quest.subQuestions.length > 0 && 
                // quest.answer === quest.answertoSQ && 
                (
                    <div className="sub-questions-container">
                        {renderSubQuestions(quest.subQuestions, localQuestNo.toString())}
                        {renderSubQuestion(quest.subQuestions, quest.answer, localQuestNo.toString())}
                    </div>
                )} */}
            </React.Fragment>
        );
            }
    }

    renderComments = (quest: any, questNo: string | number, isSubQuest: boolean | undefined) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    }

    renderDate = (quest: any, questNo: string | number, isSubQuest: boolean | undefined) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    }

    generateQuestionAnswer = (quest: any, questNo: string | number, isSubQuest?: boolean | undefined) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': {
                return this.renderSecondaryTitles(quest);
            }
            case 'ObjectiveType': {
                // if (quest.isFlagged && (quest.followUpOn === quest.answer)) {
                if (quest.isFlagged && (quest.answer)) {
                    return this.renderFlaggedObjectiveTypeQuestions(quest, questNo, isSubQuest);
                } else {
                    return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest);
                }
            }
            case 'Comments': {
                return this.renderComments(quest, questNo, isSubQuest);
            }
            case 'Date': {
                return this.renderDate(quest, questNo, isSubQuest);
            }
            default: {
                return <span key={Date.now()}/>;
            }
        }
    }

    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, facilityType, permitType, PsName, lastUpdatedDate, isPermanent,inspectionDate} = this.props.formData;
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{new Date(inspectionDate) <= new Date('2024-11-30') ? isPermanent : 'NA'}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <hr className="line-break"/>
            <Row className="question-answer-list">
                {/*<PerfectScrollbar>*/}
                {
                    questionAndAnswer && questionAndAnswer.length > 0 && questionAndAnswer.map((qa: any, index: number) => {
                        return this.generateQuestionAnswer(qa, index + 1, false);
                    })
                }
                {/*</PerfectScrollbar>*/}
            </Row>
        </div>;
    }

}

/*export function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    return {
        userUID: state.login.get('UniqueId'),
        userName
    };
}*/

// export const FlaggedQuestionsForm = withRouter(connect<{}, {}, IFlaggedQuestRendererProps>(mapStateToProps)(FlaggedQuestionsFormImpl));

const Label = ({children, isFlagged, icon} : any) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}: any) => {
    return <div style={{fontSize: '18px', marginBottom: '0.5vh'}}>{children}</div>;
};
