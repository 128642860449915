import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';

import {UserModel} from '../../model/UserModel';
import {getAllUsers, getUser, updateUser} from '../../services/userService';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {UserForm} from './UserForm'; 

export interface IUpdateUserProps { 
    userId: any;
    userInstance: any; 
    history?: IHistory;
}

export class UpdateUserImpl extends React.Component<IUpdateUserProps, {}> {
    static defaultProps: IUpdateUserProps;

    constructor(props: IUpdateUserProps) {
        super(props);
    }

    handleFormSubmit = async (values) => {
        return await updateUser(values, this.props.userInstance.props.id, this.props.history);
    }

    renderUserForm = () => {
        return <UserForm
            // @ts-ignore
            id="Update Form"
            userInstance={this.props.userInstance}
            onSubmit={this.handleFormSubmit}
            formModelName="forms.updateUserForm"
        />;
    }

    promise = async () => {
        await getUser(this.props.userId);
    }

    render() {
        return (
            <BasePage pageTitle="Update User">
                <Async
                    identifier="UpdateUserForm"
                    promise={this.promise}
                    error={<ErrorPage/>}
                    loader={<Loader type="async"/>}
                    content={this.renderUserForm()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    const userInstance = UserModel.get(ownProps.match.params.id);
    const userId = ownProps.match.params.id;
    return { userId, userInstance };
} 
 
export const UpdateForm = withRouter(connect<IUpdateUserProps, any, any>(mapStateToProps)(UpdateUserImpl));
