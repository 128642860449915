import '../Maintenance/maintenance.scss';

import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';

import {OrgModel} from '../../model/OrgModel';
import {BasePage} from '../reusableComponents/BasePage';
import {ListPage} from '../reusableComponents/ListPage';
import {OrganisationsListItem} from './organisationsListItem';
import {getAllOrgs} from '../../services/orgService';

export interface IOrgsListProps {
    orgInstances: OrgModel[];
    history?: IHistory;
}

export class OrgsListImpl extends React.PureComponent<IOrgsListProps, {}> {
    static defaultProps: IOrgsListProps;

    constructor(props: IOrgsListProps) {
        super(props);
    }

    promise = async () => {
        if (OrgModel.list().length <= 1) {
            await getAllOrgs();
        }
    }

    renderAddOrgButton = () => {
        return <div className="add-button" onClick={() => this.props.history?.push('/org/create')}>
            Add organisation <i className="fa fa-plus-square" aria-hidden="true"/>
        </div>;
    }

    render() {
        return (<BasePage pageTitle="Organisations" className="list-page-base" sideButton={this.renderAddOrgButton()}>
                <ListPage
                    // @ts-ignore
                    instances={this.props.orgInstances}
                    listItemComponent={OrganisationsListItem}
                    searchBy={['orgName']}
                    instanceOf={'orgInfo'}
                    pageHeadings={['Organisation']}
                    promise={this.promise}
                    cols={[6]}
                    searchPlaceHolder="Enter Organisation name to search..."
                    emptyListMessage="No organisation found"
                />
            </BasePage>
        );
    }

}

export function mapStateToProps(state) {
    const orgInstances = OrgModel.list();
    return {
        orgInstances
    };
}

export const OrgsList = withRouter(connect< IOrgsListProps, any, any>(mapStateToProps)(OrgsListImpl));
