import './boxInput.scss';

import * as React from 'react';
import {Col, Row, OverlayTrigger, Popover} from 'react-bootstrap';
import {selectedOutfallsCountCalculator} from '../../../utils/generalUtils';

export interface IBoxInputProps {
    id: string;
    type: string;
    formsList: object;
    onClick?: (input, freq, permitType, inputType) => void;
    permitType: string;
    label: string;
    limitSelectionTo?: number;
}

export interface IBoxInputState {
    permitType: string;
}

export class BoxInput extends React.PureComponent<IBoxInputProps, IBoxInputState> {

    constructor(props: IBoxInputProps) {
        super(props);
        this.state = {
            permitType: props.permitType
        };
    }

    handleClick = (input, freq, inputType, event?: any) => {
        if (inputType === 'form' && event.target.className === 'out-fall-name') {
            return;
        }

        if (this.props.onClick) {
            this.props.onClick(input, freq, this.props.permitType, inputType);
        }
    }

    handleOutfallsHover = (form, freq, title) => {
        form.PointSources = form.PointSources.sort((a, b) => (a.PsName > b.PsName) ? 1 : ((b.PsName > a.PsName) ? -1 : 0));
        return <Popover id="popover-positioned-top" className="outfall-selector-popover" onClick={(e) => {
            e.stopPropagation()
        }
        }>
            <Popover.Title as='h2' className="outfall-popover-title">{title}</Popover.Title>
            <Popover.Content>
                <Row className="outfall-list-container">
                    <Col className="outfall-list">
                        {form.PointSources.map((ps, index) => {
                            ps.FormId = form.FormId;
                            return (<div
                                key={index}
                                onClick={($event) => {
                                    $event.stopPropagation();
                                    this.handleClick(ps, freq, 'outfalls')
                                }}
                                className={`outfall-box ${ps.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                <div className="outfall-name">{ps.PsName}</div>
                            </div>);
                        })}
                    </Col>
                    {/*    <Col className="popover-footer-button">
                    <div className="btn btn-success" onClick={() => return}>Confirm</div>
                </Col>*/}
                </Row>
            </Popover.Content>
        </Popover>;
    }

    render() {
        const { formsList } = this.props;
        return (
            <Row className="forms-list-boxes">
                <Col className="form-boxes" >
                    <div className="frequency-text">Daily</div>
                    {
                        formsList['Daily'].map((form, index) => {
                            return <div
                                key={index}
                                onClick={($event) => this.handleClick(form, 'Daily', 'form', $event)}
                                className={`form-box ${form.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                <div className="form-name">{form.FormName}</div>
                                {form.IsSelected && form.PointSources.length > 0 && <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="top"
                                    // @ts-ignore
                                    id={'daily' + index}
                                    overlay={this.handleOutfallsHover(form, 'Daily', this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                    <div
                                        className="out-fall-name">{this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'} - {selectedOutfallsCountCalculator(form.PointSources)}</div>
                                </OverlayTrigger>}
                            </div>;
                        })
                    }
                </Col> 
                <Col className="form-boxes">
                    <div className="frequency-text">Weekly</div>
                    {
                        formsList['Weekly'].map((form, index) => {
                            return <div
                                key={index}
                                onClick={($event) => this.handleClick(form, 'Weekly', 'form', $event)}
                                className={`form-box ${form.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                <div className="form-name">{form.FormName}</div>
                                {form.IsSelected && form.PointSources.length > 0 && <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    // @ts-ignore
                                    id={'weekly' + index}
                                    placement="top"
                                    overlay={this.handleOutfallsHover(form, 'Weekly', this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                    <div
                                        className="out-fall-name">{this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'} - {selectedOutfallsCountCalculator(form.PointSources)}</div>
                                </OverlayTrigger>}
                            </div>;
                        })
                    }
                </Col>
                <Col className="form-boxes">
                    <div className="frequency-text">Monthly</div>
                    {
                        formsList['Monthly'].map((form, index) => {
                            return <div
                                key={index}
                                onClick={($event) => this.handleClick(form, 'Monthly', 'form', $event)}
                                className={`form-box ${form.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                <div className="form-name">{form.FormName}</div>
                                {form.IsSelected && form.PointSources.length > 0 && <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    // @ts-ignore
                                    id={'monthly' + index}
                                    placement="top"
                                    overlay={this.handleOutfallsHover(form, 'Monthly', this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                    <div
                                        className="out-fall-name">{this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'} - {selectedOutfallsCountCalculator(form.PointSources)}</div>
                                </OverlayTrigger>}
                            </div>;
                        })
                    }
                </Col>
                <Col className="form-boxes">
                    <div className="frequency-text">Quarterly</div>
                    {
                        formsList['Quarterly'].map((form, index) => {
                            return <div
                                key={index}
                                onClick={($event) => this.handleClick(form, 'Quarterly', 'form', $event)}
                                className={`form-box ${form.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                <div className="form-name">{form.FormName}</div>
                                {form.IsSelected && form.PointSources.length > 0 && <OverlayTrigger
                                    rootClose
                                    delay={0}
                                    trigger="click"
                                    // @ts-ignore
                                    id={'quarterly' + index}
                                    placement="top"
                                    overlay={this.handleOutfallsHover(form, 'Quarterly', this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                    <div
                                        className="out-fall-name">{this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'}-{selectedOutfallsCountCalculator(form.PointSources)}</div>
                                </OverlayTrigger>}
                            </div>;
                        })
                    }
                </Col>
                {formsList && typeof formsList['Yearly'] !== 'undefined' &&
                    <Col className="form-boxes">
                        <div className="frequency-text">Yearly</div>
                        {
                            formsList['Yearly'].map((form, index) => {
                                return <div
                                    key={index}
                                    onClick={($event) => this.handleClick(form, 'Yearly', 'form', $event)}
                                    className={`form-box ${form.IsSelected ? 'active-box' : 'inactive-box'}`}>
                                    <div className="form-name">{form.FormName}</div>
                                    {form.IsSelected && form.PointSources.length > 0 && <OverlayTrigger
                                        rootClose
                                        delay={0}
                                        trigger="click"
                                        // @ts-ignore
                                        id={'Yearly' + index}
                                        placement="top"
                                        overlay={this.handleOutfallsHover(form, 'Yearly', this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)')}>
                                        <div
                                            className="out-fall-name">{this.props.permitType === 'Water' ? 'Outfall(s)' : 'Point Source(s)'}-{selectedOutfallsCountCalculator(form.PointSources)}</div>
                                    </OverlayTrigger>}
                                </div>;
                            })
                        }
                    </Col>
                }
                
            </Row>
        );
    }
}

export function mapStateToProps() {
    return {};
}
