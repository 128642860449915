import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {FlaggedFormModel} from '../../model/FlaggedFormModel';
import {getFormDetailsByType} from '../../services/dashboardService';
import {DetailsPageWrapper} from '../reusableComponents/DetailsPageWrapper';

export interface IFlaggedFormDetailsProps {
    id: string;
    formId: string;
    category: string;
    formType?: string;
    history?: IHistory;
    location?: any;
    instance: FlaggedFormModel;
    userId: string;
} 

export interface IFlaggedFormDetailsState {
    isLoading: boolean;
    comments: string;
}

export class FlaggedFormDetailsImpl extends React.Component<IFlaggedFormDetailsProps, IFlaggedFormDetailsState> {

    static defaultProps: IFlaggedFormDetailsProps;

    constructor(props: IFlaggedFormDetailsProps) {
        super(props);
        this.state = {isLoading: false, comments: ''};
    }

    promise = async () => {
        await getFormDetailsByType(this.props.formId);
    }

    renderContent = () => {
        const {category} = this.props;
        const backTo =
            this.props.instance && this.props.instance.props.Completed ?
                `/flagged-forms?searchFor=complete&&formType=${category}` :
                `/flagged-forms?searchFor=incomplete&&formType=${category}`;

        const pageTitle = this.props.instance && this.props.instance.props.Completed ? 'Complete Flagged Form Details' : 'Incomplete Flagged Form Details'

        return <DetailsPageWrapper
            // @ts-ignore
            pageTitle={pageTitle}
            backTo={backTo}
            formType="flaggedforms"
            instance={this.props.instance}
            promise={this.promise}
        />;
    }

    render() {
        return (
            this.renderContent()
        );
    }
}

export function

mapStateToProps(state: any, ownProps: any) {
    const id = ownProps.match.params.id;
    const category = ownProps.match.params.category;
    const formId = id;
    const userId = state.login.get('userId');
    const instance = FlaggedFormModel.get(id);

    return {
        instance, id, userId, formId, category
    };
}

/* export const FlaggedFormDetails = withRouter((connect<{}, {}, IFlaggedFormDetailsProps>(mapStateToProps)(FlaggedFormDetailsImpl as any))); */
export const FlaggedFormDetails = withRouter(connect<IFlaggedFormDetailsProps, any, any>(mapStateToProps)(FlaggedFormDetailsImpl));
