import './organisations.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {OrgModel} from '../../model/OrgModel';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {getOrgInfo} from '../../services/orgService';
import {formatArrayItemDisplay} from '../../utils/generalUtils';
import {IHistory} from "../../interfaces";

export interface IOrgDetailsPageProps {
    id: string;
    orgInstance: OrgModel;
    history?: IHistory;
}

export class OrgDetailsPageImpl extends React.PureComponent<IOrgDetailsPageProps, {}> {
    constructor(props: IOrgDetailsPageProps) {
        super(props);
    }

    promise = async () => {
        if (!this.props.orgInstance) {
            await getOrgInfo(this.props.id);
        }
    }

    renderContent = () => {
        if (!this.props.orgInstance) {
            return <Loader type="async"/>;
        }
        return <div className="org-details-page-wrapper">
            <div className="org-details-left-section" style={{width: '75%'}}>
                <DisplayDetails label="Name" value={this.props.orgInstance.props.orgName}/>
                <DisplayDetails label="Regions" value={formatArrayItemDisplay(this.props.orgInstance.props.orgRegion, 'regionName')}/>
                {this.props.orgInstance.props.orgLogoDetails.length > 0 && <div className="details-row org-image-container">
                    <div className="details-label">Logo:</div>
                    <img className="details-value org-image" alt={'org-img'} src={this.props.orgInstance.props.orgLogoDetails[0].imageURL}/>
                </div>}
            </div>
            <div className="buttons-container">
                <div
                    onClick={() => this.props.history?.push(`/org/update/${this.props.id}`)}
                    title={`Click to Edit the Details of Organisation`}
                    className="edit-details-button">
                    <i className="fa fa-pencil" aria-hidden="true"/>
                </div>
            </div>
        </div>;
    }

    render() {
        return (
            <BasePage key={5} pageTitle="Organisation Information">
                <Async
                    identifier="OrganisationDetailsPage"
                    promise={this.promise}
                    loader={<Loader type="async"/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state, ownProps) {
    const orgInstance = OrgModel.get(ownProps.match.params.id);
    return {
        id: ownProps.match.params.id,
        orgInstance: orgInstance ? orgInstance : null
    };
}

export const OrgDetailsPage = withRouter(connect< IOrgDetailsPageProps, any, any>(mapStateToProps)(OrgDetailsPageImpl as any));

const DisplayDetails = ({label, value}) => {
    return <div className="details-row">
        <Label labelText={label}/>
        <Value value={value}/>
    </div>;
};

const Label = ({labelText}) => {
    return <div className="details-label">
        {labelText}:
    </div>;
};

const Value = ({value}) => {
    return <div className="details-value">
        {value}
    </div>;
};
