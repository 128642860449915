import * as React from 'react';
import {CartesianGrid, Legend, Line, LineChart as RLineChart, Tooltip, XAxis, YAxis} from 'recharts';

import {ResponsiveContainer} from '../ResponsiveContainer';
import {stringToColour} from '../../../../utils/generalUtils';

export interface ILineChartProps {
    data: Object[];
    className?: string;
    customTooltip?: JSX.Element;
    onClick?: Function; 
} 
export class LineChart extends React.Component<ILineChartProps, {}> {

    render() {
        const {data, onClick, className, customTooltip} = this.props; 
        return (
            <ResponsiveContainer className={className}>
                <RLineChart data={data}
                            onClick={e => e && e.activePayload ? (onClick ? onClick(e.activePayload[0].payload) : null) : null}
                            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <XAxis interval={0} dataKey="xName"/>
                    <YAxis allowDecimals={false}/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={customTooltip}/>
                    <Legend wrapperStyle={{
                        position: 'relative'
                    }}/>
                    {Object.keys(data[0] || []).map((dataVal, index) => {
                        if (index === 0) {
                            return false;
                        }
                        return <Line
                            key={index}
                            type="monotone"
                            dataKey={dataVal}
                            stroke={stringToColour(dataVal + index.toString() )} 
                            activeDot={{r: 4}}
                        />;
                    })}
                </RLineChart>
            </ResponsiveContainer>
        );
    }
}
