import {
    HIDE_INCOMPLETE_PLANTS_MODAL,
    HIDE_UST_MODAL,
    SHOW_INCOMPLETE_PLANTS_MODAL,
    SHOW_UST_MODAL,
    UPDATE_MODAL_DATA,
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_PRINT_PREVIEW_MODAL,
    HIDE_PRINT_PREVIEW_MODAL,
} from '../constants/actions';
import {dispatch} from '../utils/generalUtils';

export function showModal(id: string, modalHeading: string, instance: any) {
    return dispatch({
        type: SHOW_MODAL,
        id,
        instance,
        modalHeading
    });
}

export function showPlantListModal(id: string, data, listTitle: string, formType: string) {
    return dispatch({
        type: SHOW_INCOMPLETE_PLANTS_MODAL,
        id,
        data,
        listTitle,
        formType
    });
}

export function hidePlantListModal(id?: string) {
    return dispatch({
        type: HIDE_INCOMPLETE_PLANTS_MODAL,
        id
    });
}

export function hideUSTModal() {
    return dispatch({
        type: HIDE_UST_MODAL,
    });
}

export function showUSTModal(formType, instance, selectedDate) {
    return dispatch({
        type: SHOW_UST_MODAL,
        formType,
        instance,
        selectedDate
    });
}

export function updateModalData(data) {
    return dispatch({
        type: UPDATE_MODAL_DATA,
        data
    });
}

export function hideModal() {
    return dispatch({
        type: HIDE_MODAL,
    });
}

export function showPrintPreviewModal(id: string) {
    return dispatch({
        type: SHOW_PRINT_PREVIEW_MODAL,
        id
    });
}

export function hidePrintPreviewModal() {
    return dispatch({
        type: HIDE_PRINT_PREVIEW_MODAL,
    });
}
