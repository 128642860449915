import {format} from 'date-fns';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setLoading, setSuccess} from '../../actions/loadingActions';
import {monthsAndYears} from '../../constants/generals';
import {IFilters, IHistory} from '../../interfaces';
// import {IFlaggedForms} from '../../interfaces';
import {getFlaggedFormList} from '../../services/monthlyFormService';
import {findCurrentQuarter, getCurentDate, splitDateIE} from '../../utils/generalUtils';
import {BasePage} from '../reusableComponents/BasePage';
import {ListPage} from '../reusableComponents/ListPage';
import {MonthPicker} from '../reusableComponents/MonthPicker';
import {SelectDropdown} from '../reusableComponents/SelectDropdown';
/* import {
    fetchCompletedInspectionsData,
    fetchCompleteOrIncompleteInspections,
    fetchFlaggedFormsList,
    fetchFormSummaryData,
    getAllInspectionByOrgFac,
    fetchYearDropdownList,
    yearsList
} from './dashboardDataFetcher'; */
import { fetchYearDropdownList } from '../Dashboard/dashboardDataFetcher';
import {RegionFilter} from '../reusableComponents/RegionFilter';
import { ListItem } from '../Reports/InspectionReportDetailer/FlaggedListItem';
import { getRegionsByOrg } from '../../services/orgService';
import { getUserData } from '../../services/loginService';
import { RegionModel } from '../../model/OrgModel';
import './flaggedReport.scss';
import moment from 'moment';
import {getQuarter} from 'date-fns';
import { Pagination } from '../reusableComponents/Pagination';



const queryString = require('query-string');

export interface IFlaggedReportsProps {
    // monthlyForms?: IFlaggedForms;
    filters?: IFilters;
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
    regionList: any;
}

export interface IFlaggedReportsState {
    data: Array<any>;
    filterBy: string;
    regionName: string;
    date: Date;
    month: string; 
    YearDropdownList: Array<Object>;
    linearGraphDate: any;
    quarterlySummaryGraphDate: { year: number, qtr: string };
    filterFreq: string;
    filterStatus:string;
    flagCount: Array<any>;
    curPageNo :any;
    allfreqFlag: boolean;
    curPageReset: boolean;
    isAsc: boolean;
    sortIndex: any;
    searchText: any;
}

export class FlaggedReportsImpl extends React.Component<IFlaggedReportsProps, IFlaggedReportsState> { 
    loggedInUserInfo = getUserData();
    constructor(props: IFlaggedReportsProps) {
        super(props);
        let lineardate = format(new Date(), 'MM/dd/yyyy');
        // let date = new Date();
        let date = moment().format('MM-DD-YYYY');
        let region = 'All';
        let status = 'All';
        let year = format(new Date(), 'yyyy');
        let frequency = 'All';
        let search = 'None';
        let qtrDate = findCurrentQuarter(moment());
        let pageNumber = 1;
        let allfreqFlag = false;
        

        let quarter = getQuarter(new Date()).toString();
        // let quarter = getQuarter(getCurentDate(date, 'MM/DD/yyyy', true)).toString();
        // let qtrDate= {qtr:quarter,year: moment().format('YYYY')}
        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
            frequency = queryString.parse(this.props.location.search).frequency;
            qtrDate.qtr = queryString.parse(this.props.location.search).qtr;
            qtrDate.year = queryString.parse(this.props.location.search).year;
            status = queryString.parse(this.props.location.search).status;
            pageNumber = queryString.parse(this.props.location.search).pageNumber;
            allfreqFlag = queryString.parse(this.props.location.search).allfreqFlag;
            search = queryString.parse(this.props.location.search).search;

        }
        // linearGraphDate : format(new Date(moment().format('MM-DD-YYYY').toString().replace(/-/g, '/')), 'MM-dd-yyyy')

        this.state = {
            data: [],
            filterBy: region,
            regionName: region,
            date : getCurentDate(date, 'MM-dd-yyyy', true),
            month: format(new Date(date.toString().replace(/-/g, '/')), 'MM-dd-yyyy'),
            YearDropdownList: [],
            linearGraphDate: format(new Date(date.toString().replace(/-/g, '/')), 'MM-dd-yyyy'),
            quarterlySummaryGraphDate : qtrDate,
            filterFreq:frequency ? frequency :'All',
            filterStatus: status,
            flagCount:[],
            curPageNo: pageNumber,
            allfreqFlag : allfreqFlag,
            curPageReset: false,
            isAsc: false,
            sortIndex: 'formDate',
            searchText: search
        };
    }

    promise = async () => {
        let date = moment().format('MM-DD-YYYY');
        // let date = format(new Date(currdate), 'MM-dd-yyyy');
        // let date = getCurentDate(moment(), 'MM-dd-yyyy');

        // let date = getCurentDate(dates, 'MM-dd-yyyy', true)
        let region = 'All';
        let status = 'All';
        let year = format(new Date(), 'yyyy');
        let frequency = 'All';
        let qtr = this.state.quarterlySummaryGraphDate.qtr;
        let pageNumber = 1;
        let allfreqFlag = false;
        let isAsc = this.state.isAsc;
        let sortIndex = this.state.sortIndex;
        let searchText = this.state.searchText;

        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
            frequency = queryString.parse(this.props.location.search).frequency;
            qtr = queryString.parse(this.props.location.search).qtr;
            year = queryString.parse(this.props.location.search).year;
            status = queryString.parse(this.props.location.search).status;
            pageNumber = queryString.parse(this.props.location.search).pageNumber;
            allfreqFlag = queryString.parse(this.props.location.search).allfreqFlag;
        }
        const YearsDropdownList = await fetchYearDropdownList(); 
        setLoading('FlaggedFormList');

        await getFlaggedFormList(frequency, date, region, status, pageNumber, allfreqFlag, isAsc, sortIndex, searchText).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                YearDropdownList : YearsDropdownList,
                flagCount: res?.data && res.data.flaggedCount && res.data.flaggedCount.length ? res.data.flaggedCount : [],
            });
        });
        setSuccess('FlaggedFormList');


        /* const {mm, yyyy} = splitDateIE(date);
        await getFlaggedFormList(mm, yyyy, 'All', region).then(res => {
            this.setState({
                // data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                YearDropdownList : YearsDropdownList,
                linearGraphDate: format(new Date(), 'MM/dd/yyyy'),

            });
        }); 
        this.setState({
            // data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
            YearDropdownList : YearsDropdownList,
            linearGraphDate: format(new Date(), 'MM/dd/yyyy'),

        }); */
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
    }

    handleFlaggedFilters = async () => {

        let freq = this.state.filterFreq;
        let reg = this.state.regionName;
        let status = this.state.filterStatus;
        let qtr = this.state.quarterlySummaryGraphDate.qtr;
        let linearDate;
        let year;
        let curPageNo = this.state.curPageNo || '1';
        let allfreqFlag = this.state.allfreqFlag;
        let isAsc = this.state.isAsc;
        let sortIndex = this.state.sortIndex;
        let searchText = this.state.searchText || 'None';
        if (this.state.curPageReset === true && allfreqFlag === false) {
            this.setState({
                // data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                curPageNo: 1,
                allfreqFlag : false,
                // flagCount: res?.data && res.data.flaggedCount && res.data.flaggedCount.length ? res.data.flaggedCount : [],


            });
            curPageNo = 1;
            allfreqFlag= false;

        } else if (this.state.curPageReset === false && allfreqFlag === true) {
            this.setState({
                // data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                curPageNo: 1,
                // allfreqFlag : false,
                // flagCount: res?.data && res.data.flaggedCount && res.data.flaggedCount.length ? res.data.flaggedCount : [],


            });
            curPageNo = 1;
        }

        // Parse the date string using the specified format 'MM-DD-YYYY'
            const parsedDate = moment(this.state.linearGraphDate, 'MM-DD-YYYY', true);

            // Check if the date is valid before formatting
            if (!parsedDate.isValid()) {
                linearDate = moment(this.state.linearGraphDate).format('MM-DD-YYYY');
                year = moment(this.state.linearGraphDate).format('YYYY');
            }else{

                // Format the year
                year = parsedDate.format('YYYY');

                // Format the date as MM-DD-YYYY
                linearDate = parsedDate.format('MM-DD-YYYY');
            }
        setLoading('FlaggedFormList');
        const queryString = `?date=${linearDate}&&region=${reg}&&frequency=${freq}&&qtr=${qtr}&&year=${year}&&status=${status}&&pageNumber=${curPageNo}&&allfreqFlag=${allfreqFlag}&&search=${searchText}`;
        await getFlaggedFormList(freq, linearDate, reg, status, curPageNo, allfreqFlag, isAsc, sortIndex, searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                // linearGraphDate: selectedMonth,
                quarterlySummaryGraphDate : findCurrentQuarter(linearDate),
                flagCount: res?.data && res.data.flaggedCount && res.data.flaggedCount.length ? res.data.flaggedCount : [],


            });
        });
        this.setState({
            // data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
            // linearGraphDate: selectedMonth,
            // quarterlySummaryGraphDate : findCurrentQuarter(selectedMonth),
            // flagCount: res?.data && res.data.flaggedCount && res.data.flaggedCount.length ? res.data.flaggedCount : [],


        });
        this.props.history?.push(`/flagged-reports${queryString}`);
        setSuccess('FlaggedFormList');

    }

    reloadQtrFormSummaryGraph = async (year?: any, qtr?: any) => {
        // const {orgId} = this.props.loggedInUserInfo;
        let dateStr = ''
        if (year || qtr) {

            const date = this.state.quarterlySummaryGraphDate;
            if (year) {
                date.year = year;
            }
            if (qtr) {
                date.qtr = qtr;
            }
            const numericString = date.qtr.replace(/[^0-9]/g, '');

            // Parse the resulting string into a number
            const numericValue = parseInt(numericString, 10);
            const month = (numericValue - 1) * 3 + 1; // 1st month of the quarter

            this.setState({
                quarterlySummaryGraphDate: date,
                linearGraphDate: format(new Date(date.year, month - 1, 1), 'MM/dd/yyyy'),
                curPageReset: true
                // apiLoading: {Daily: false, Weekly: false, Flagged: false, Quarterly: true, Yearly: false }
            }, async () => {
                /* const qtrFormSummaryGraph = await fetchFormSummaryData(orgId, 'Quarterly', this);
                this.setState({
                    quarterlySummaryGraphData: qtrFormSummaryGraph || {data: [], flaggedFormsCount: 0},
                    apiLoading: {Daily: false, Weekly: false, Flagged: false, Quarterly: false, Yearly: false }
                }, async () => {
                    await this.handleInspectionDDFilter(this.state.currentLinearInspectionForm)
                }); */
            });
            // await this.updateKeyValuePair('quarterlyDate',date)

        }
    }

    handleMonthFilter = async (e) => {
        this.setState({
            month: e
        });
        const {yyyy} = splitDateIE(e);
        let mm = '';
        let selectedMonth = '';
        monthsAndYears.map((data) => {
            if (data.value === e.substr(0, 2)) {
                selectedMonth = `${data.value}-01-${yyyy}`;
                mm = data.value;
            }
            return true;
        });

            // allfreqFlag : false

        await this.setState({
            linearGraphDate: selectedMonth,
            quarterlySummaryGraphDate : findCurrentQuarter(selectedMonth),
            curPageReset: true
        });

        if ( this.state.filterFreq === 'All' && this.state.allfreqFlag === false) {
            this.setState({ 
                allfreqFlag: true,
                curPageReset: false

             });
        }

        this.handleFlaggedFilters();

    }

    handleRegionFilter = async (filterName, regionName) => {
        let selectedMonth = '';
        this.setState({regionName});
        let trialMonth = this.state.month;
        let finalMonth = '';
        if (isNaN(Number(trialMonth[0]))) {
            finalMonth = this.state.month;
        } else {
            const {mm, dd, yyyy} = splitDateIE(trialMonth);
            monthsAndYears.map((data) => {
                if (data.value === mm) {
                    finalMonth = `${data.label} ${yyyy}`;
                }
                return true;
            });
        }
        monthsAndYears.map((data) => {
            if (data.label === finalMonth.substring(0, 3)) {
                selectedMonth = `${data.value}-01-${finalMonth.substring(finalMonth.length - 4)}`;
            }
            return true;
        });
        const {mm, dd, yyyy} = splitDateIE(selectedMonth);
        setLoading('FlaggedFormList');
        const queryString = `?date=${selectedMonth}&&region=${regionName}`;
        /* await getFlaggedFormList(mm, yyyy, '', regionName).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : []
            });
        }); */
        this.props.history?.push(`/flagged-reports${queryString}`);
        setSuccess('FlaggedFormList');
    }

    getQuarterFromDate = (dateString) =>{
        const date = new Date(dateString);
        const month = date.getMonth() + 1; // January is 0
        let quarter;
    
        if (month >= 1 && month <= 3) {
            quarter = '1st';
        } else if (month >= 4 && month <= 6) {
            quarter = '2nd';
        } else if (month >= 7 && month <= 9) {
            quarter = 3;
        } else {
            quarter = 4;
        }
    
        return quarter;
    }
    handleParentCall= async (filterArr) => {
        const mainArr = this.state.data;
        const index = mainArr.findIndex(item => 
            item.InspectionUId === filterArr.InspectionUId && item.questionNum === filterArr.questionNum
          );
          
          // If index is found, update isCompleted to 1
          if (index !== -1) {
            /* // Create a new object with updated isCompleted and spread other properties
            const updatedObject = {
              ...mainArr[index],
              isCompleted: 1
            };
          
            // Create a new array by replacing the object at the found index with the updatedObject
            const updatedMainArr = [
              ...mainArr.slice(0, index),
              updatedObject,
              ...mainArr.slice(index + 1)
            ];
          
            // Now updatedMainArr contains mainArr with the isCompleted updated to 1 for the matched object
            console.log('updatedMainArr ',updatedMainArr); */
            // this.setState({data :updatedMainArr})
            await this.promise();
          } else {
            // console.log("Object not found in mainArr");
          }
    }
    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            searchText :searchText,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText  ? this.state.curPageNo : 1
        })
        this.handleFlaggedFilters();


    }

    render() {
        const { data, month, regionName } = this.state;
        let pageno = this.state.flagCount.length > 0 ? this.state.flagCount[0]['total'] > 20  ? 
                        this.state.filterStatus === 'All' ? this.state.flagCount[0]['total']/20 
                        : this.state.filterStatus === 'Complete' ? this.state.flagCount[0]['closed']/20 
                        : this.state.flagCount[0]['open']/20 : 1: 1;
        pageno = Math.ceil(pageno); 
        return (
            <BasePage className="list-page-base flagged-reports" pageTitle="Flagged Reports">
                <ListPage
                    // @ts-ignore
                    identifier="FlaggedFormList"
                    instances={data}
                    listItemComponent={ListItem}
                    frequency={'Flagged'}
                    searchBy={['plantId', 'plantName', 'inspectionName','questionText','AnswerForFlag','formDate']}
                    instanceOf={'plantInfo'}
                    promise={this.promise}
                    pageHeadings={['#', 'Plant Info', 'Form', 'Question', 'Answer', 'Date', 'Status']}
                    listItemFields={['', 'plantId', 'inspectionName', 'questionText', 'AnswerForFlag',  'formDate', 'isCompleted']}
                    cols={[1, 1, 2, 4, 1, 2, 1]}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Flagged Reports found"
                    onFlagSubmitParents={this.handleParentCall}
                    onHandleSort={this.handleSort}
                    chartFilter={<div className="filter-container row">
                        <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                            <SelectDropdown className={'flagged-freq-filter filtersTop'}
                                menuItems={[{label: 'All', value: 'All'}, 
                                            {label: 'Daily', value: 'Daily'},
                                            {label: 'Weekly',value: 'Weekly'}, 
                                            {label: 'Monthly', value: 'Monthly'}, 
                                            {label: 'Quarterly', value: 'Quarterly'}, 
                                            {label: 'Yearly', value: 'Yearly'}]}
                                // onChange={(e: any) => this.handleFreqChange(e)}
                                onChange={async (e: any) => {
                                    await this.setState({ 
                                        filterFreq: e,
                                        allfreqFlag: e != 'All' ? false : false,
                                        curPageReset: true
                                     });
                                    this.handleFlaggedFilters(); // Call the function inside the async arrow function
                                }}
                                placeholder="Select"
                                title='Frequency'
                                defaultValue={this.state.filterFreq}
                            />
                        </div>
                        {this.state.filterFreq === 'All' || this.state.filterFreq === 'Daily' || this.state.filterFreq === 'Weekly' || this.state.filterFreq === 'Monthly' ? 
                        <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 filtersTop'}>
                            <MonthPicker
                                defaultValue={this.state.linearGraphDate}
                                years={[2013, 2015, 2016]}
                                onClick={this.handleMonthFilter}
                                popover={true}
                                title={this.state.filterFreq === 'All' ? 'Monthly' : this.state.filterFreq}
                                className={this.state.filterFreq === 'All' && this.state.allfreqFlag === false ? 'allfreqFlag':''}
                            /> 
                        </div>
                        : 
                        this.state.filterFreq === 'Quarterly' ? 
                        <>
                            <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                                <SelectDropdown className={'quarterly-form-filter filtersTop'}
                                    menuItems={[{label: 'First', value: '1st'}, {
                                        label: 'Second',
                                        value: '2nd'
                                    }, {label: 'Third', value: '3rd'}, {label: 'Fourth', value: '4th'}]}
                                    // onChange={(e: any) => this.reloadQtrFormSummaryGraph(null, e)}
                                    onChange={async (e: any) => {
                                        await this.reloadQtrFormSummaryGraph(null, e)
                                        this.handleFlaggedFilters();
                                    }}
                                    placeholder="Select"
                                    title='Quarter'
                                    defaultValue={this.state.quarterlySummaryGraphDate.qtr}
                                />
                            </div>
                            <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                                <SelectDropdown className={'yearly-form-filter filtersTop'}
                                    id={'linear-graph-year-selector'}
                                    menuItems={this.state.YearDropdownList}
                                    // onChange={this.handleLinearChartDateFilter}
                                    
                                    onChange={async (e: any) => {
                                        await this.setState({linearGraphDate: moment(e).format('MM/DD/YYYY'),
                                        curPageReset: true
                                    });
                                        this.reloadQtrFormSummaryGraph(e, this.state.quarterlySummaryGraphDate.qtr);
                                        this.handleFlaggedFilters();
                                    }}
                                    placeholder="Select"
                                    title='Year'
                                    defaultValue={this.state.quarterlySummaryGraphDate.year.toString()}
                                /> 
                            </div>
                        </>
                        :
                        <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                            <SelectDropdown className={'yearly-form-filter filtersTop'}
                                id={'linear-graph-year-selector'}
                                menuItems={this.state.YearDropdownList}
                                // onChange={this.handleLinearChartDateFilter}
                                onChange={async (e: any) => {
                                    await this.setState({linearGraphDate: moment(e).format('MM/DD/YYYY'),
                                    curPageReset: true
                                });
                                    this.handleFlaggedFilters();
                                }}
                                placeholder="Select"
                                title='Year'
                                defaultValue={this.state.quarterlySummaryGraphDate.year.toString()}
                            />
                        </div>
                        }
                        <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                            <RegionFilter
                                selectedValue={regionName}
                                // onChange={this.handleRegionFilter}
                                onChange={async (e: any,regionName:any) => {
                                    await this.setState({ regionName: regionName,curPageReset: true });
                                    this.handleFlaggedFilters();
                                }}
                                regionList={this.props.regionList}
                                className={'FlaggedForm-Region filtersTop'}
                                title='Region'
                            />
                        </div>
                        <div className ={'col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3'}>
                            <SelectDropdown className={'flagged-status-filter filtersTop'}
                                menuItems={[{label: 'All', value: 'All'},
                                            {label: 'Complete', value: 'Complete'}, 
                                            {label: 'InComplete',value: 'InComplete'} 
                                        ]}
                                // onChange={(e: any) =>{this.setState({ filterStatus: e}, async () => {this.handleFlaggedFilters});}}
                                onChange={async (e: any) => {
                                    await this.setState({ filterStatus: e ,curPageReset: true});
                                    this.handleFlaggedFilters(); // Call the function inside the async arrow function
                                }}
                                placeholder="Select"
                                defaultValue={this.state.filterStatus}
                                title='Status'
                            />
                        </div>
                        {/* <div className="filter-container">
                            <SelectDropdown className={'flagged-status-filter'}
                                menuItems={[{label: 'All', value: 'All'},
                                            {label: 'Completed', value: 'Completed'}, 
                                            {label: 'InCompleted',value: 'InCompleted'} 
                                        ]}
                                // onChange={(e: any) =>{this.setState({ filterStatus: e}, async () => {this.handleFlaggedFilters});}}
                                onChange={async (e: any) => {
                                    await this.setState({ filterStatus: e });
                                    this.handleFlaggedFilters(); // Call the function inside the async arrow function
                                }}
                                placeholder="Select"
                                defaultValue={this.state.filterStatus}
                            />
                        </div> */}
                    </div>}
                    
                    flaggedCount={<div className="flaaged-count-container">
                            <div className='inComDiv col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'><h6>Open Flags : {this.state.flagCount.length > 0 ? this.state.flagCount[0]['open']: 0}</h6></div>
                            <div className='ComDiv col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'><h6>Total / Complete : {this.state.flagCount.length > 0 ? this.state.flagCount[0]['total']: 0} / {this.state.flagCount.length > 0 ? this.state.flagCount[0]['closed']: 0}</h6> </div>
                        </div>}

                
            />
            <Pagination
                    // history={this.props.history}
                    // promise={(pageNumber) => this.handleFlaggedFilters()}
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e,curPageReset: false});
                        this.handleFlaggedFilters(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const regionList = RegionModel.list();
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');

    return { regionList, axiosPreviousToken };
}

export const FlaggedReports =
    withRouter(connect< IFlaggedReportsProps, any, any>(mapStateToProps)(FlaggedReportsImpl as any));
