import './basePage.scss';

import {MuiThemeProvider} from 'material-ui/styles';
import * as React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {ToastContainer} from 'react-toastify';

import {IHistory} from '../../../interfaces';
import {NavbarComp} from '../Navbar';
import 'react-toastify/dist/ReactToastify.css'


export interface IBasePageProps {
    className?: string;
    history?: IHistory;
    pageTitle: string | JSX.Element;
    rightCopmponent?: JSX.Element;
    style?: React.CSSProperties;
    sideButton?: JSX.Element | JSX.Element[];
    children?: any;
}

export class BasePage extends React.PureComponent<IBasePageProps, {}> {

    static defaultProps = {
        className: ''
    };

    componentDidMount() {
        document.title = this.props.pageTitle as any;
    }

    render() {
        const {className, style, pageTitle} = this.props;
        return (
            <MuiThemeProvider>
                <div className="background-style">
                    <ToastContainer theme={"dark"}/>
                    <div className="background-opacity">
                        <NavbarComp/>
                        {/* <ReactCSSTransitionGroup
                            transitionAppear={true}
                            transitionAppearTimeout={600}
                            transitionEnterTimeout={600}
                            transitionLeaveTimeout={200}
                            transitionName="SlideIn"
                        > */}
                            <div className={`base-page ${className}`} id="basePage" style={style}>
                                <div className="page-title d-print-none">
                                    <div style={{
                                        fontSize: '30px',
                                        paddingRight: '10px',
                                    }}>{<div
                                        id="back-button"
                                        className="back-button"
                                        onClick={() => window.history.back()}>
                                        <i className="fa fa-arrow-circle-left" aria-hidden="true"/>
                                    </div>}</div>
                                    <div className='title-text'>{pageTitle}</div>
                                    {this.props.sideButton}
                                </div>
                                {this.props.children}
                            </div>
                        {/* </ReactCSSTransitionGroup> */}
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}
